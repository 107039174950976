"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStoreName = getStoreName;
exports.getStoreLink = getStoreLink;
var react_native_1 = require("react-native");
var config_1 = require("@shared/config");
var i18n_1 = require("@shared/util/i18n");
function getStoreName() {
    return react_native_1.Platform.OS === 'android' ? (0, i18n_1.i18n)('upgrade.playStore') : (0, i18n_1.i18n)('upgrade.appStore');
}
function getStoreLink() {
    return react_native_1.Platform.OS === 'android'
        ? "https://play.google.com/store/apps/details?id=".concat(config_1.Config.GOOGLE_PACKAGE_NAME)
        : "https://itunes.apple.com/us/app/".concat(config_1.Config.APPLE_APP_ID);
}
