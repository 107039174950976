"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateEntityFields = useCreateEntityFields;
var lodash_1 = require("lodash");
var react_1 = require("react");
var useDefaultFields_1 = require("@shared/scenes/entity/create/hooks/useDefaultFields");
var isFieldRecordModified_1 = require("@shared/scenes/entity/util/isFieldRecordModified");
function useCreateEntityFields(_a) {
    var workflowTemplate = _a.workflowTemplate, viewTemplate = _a.viewTemplate, queryFieldOverrides = _a.queryFieldOverrides;
    var _b = (0, react_1.useState)(null), originalFields = _b[0], setOriginalFields = _b[1];
    var _c = (0, react_1.useState)({}), fields = _c[0], setFields = _c[1];
    var modifiedFields = (0, react_1.useMemo)(function () { return (0, isFieldRecordModified_1.getModifiedFields)(originalFields, fields); }, [originalFields, fields]);
    var updateField = (0, react_1.useCallback)(function (componentId, field) {
        setFields(function (previousFields) {
            var _a;
            var previousField = previousFields === null || previousFields === void 0 ? void 0 : previousFields[componentId];
            if ((0, isFieldRecordModified_1.isFieldModified)(previousField, field)) {
                return __assign(__assign({}, previousFields), (_a = {}, _a[componentId] = field, _a));
            }
            else {
                return previousFields;
            }
        });
    }, []);
    var isDirty = !(0, lodash_1.isNil)(modifiedFields) && Object.keys(modifiedFields).length > 0;
    var defaultFields = (0, useDefaultFields_1.useDefaultFields)({
        workflowTemplateId: workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.id,
        viewTemplate: viewTemplate,
        queryFieldOverrides: queryFieldOverrides,
    });
    (0, react_1.useEffect)(function () {
        if (!(0, lodash_1.isNull)(defaultFields) && (0, lodash_1.isNull)(originalFields)) {
            setOriginalFields(defaultFields);
            setFields(defaultFields);
        }
    }, [defaultFields, originalFields]);
    var resetFields = (0, react_1.useCallback)(function () {
        setOriginalFields(defaultFields);
        setFields(defaultFields !== null && defaultFields !== void 0 ? defaultFields : {});
    }, [defaultFields]);
    return (0, react_1.useMemo)(function () {
        return {
            fields: fields,
            originalFields: originalFields,
            modifiedFields: modifiedFields,
            isDirty: isDirty,
            updateField: updateField,
            resetFields: resetFields,
        };
    }, [fields, originalFields, modifiedFields, isDirty, updateField, resetFields]);
}
