"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreserveViewState = PreserveViewState;
var lodash_1 = require("lodash");
var react_1 = require("react");
var omitTypeName_1 = require("@shared/util/omitTypeName");
var TTLStorage_1 = require("@shared/util/storage/TTLStorage");
var ControlsProvider_1 = require("./ControlsProvider");
var mapCollectionCriteriaToState_1 = require("./mapCollectionCriteriaToState");
var mapStateToCollectionCriteria_1 = require("./mapStateToCollectionCriteria");
var COLLECTION_CRITERIA_TTL = 1000 * 60 * 60 * 24; // 1 day
function PreserveViewState(_a) {
    var viewTemplate = _a.viewTemplate, setIsModified = _a.setIsModified, resetState = _a.resetState;
    var storageKey = "viewTemplate-".concat(viewTemplate.id, "-collectionCriteria");
    var _b = (0, ControlsProvider_1.useControlsState)(), filters = _b.filters, sorts = _b.sorts, groups = _b.groups, availableFilters = _b.availableFilters, availableSorts = _b.availableSorts, availableGroups = _b.availableGroups;
    (0, react_1.useEffect)(function () {
        var _a;
        // save collection criteria to storage when modified
        var updatedCriteria = (0, mapStateToCollectionCriteria_1.mapStateToCollectionCriteria)({
            filters: filters,
            sorts: sorts,
            groups: groups,
        });
        var savedCriteria = (0, omitTypeName_1.omitTypeName)((_a = viewTemplate.collectionCriteria) !== null && _a !== void 0 ? _a : {});
        var isChanged = !isCriteriaEqual(savedCriteria, updatedCriteria);
        if (isChanged) {
            setIsModified(true);
            var timer_1 = setTimeout(function () {
                // while changing views, can sometimes get a false positive, so delay saving
                TTLStorage_1.TTLStorage.set(storageKey, updatedCriteria, COLLECTION_CRITERIA_TTL);
            }, 1000);
            return function () { return clearTimeout(timer_1); };
        }
        else {
            setIsModified(false);
            TTLStorage_1.TTLStorage.remove(storageKey);
        }
    }, [filters, sorts, groups, viewTemplate.collectionCriteria, storageKey, setIsModified]);
    (0, react_1.useEffect)(function () {
        // load collection criteria from storage
        function loadCollectionCriteria() {
            return __awaiter(this, void 0, void 0, function () {
                var savedCollectionCriteria, nextState;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, TTLStorage_1.TTLStorage.get(storageKey)];
                        case 1:
                            savedCollectionCriteria = _a.sent();
                            if (!(0, lodash_1.isNil)(savedCollectionCriteria)) {
                                nextState = (0, mapCollectionCriteriaToState_1.mapCollectionCriteriaToState)(savedCollectionCriteria, availableFilters, availableSorts, availableGroups);
                                resetState(nextState);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        loadCollectionCriteria();
    }, [storageKey, availableFilters, availableSorts, availableGroups, resetState]);
    return null;
}
function isCriteriaEqual(saved, updated) {
    var _a, _b, _c, _d, _e, _f;
    // cast nulls as empty arrays before comparing
    var isFiltersEqual = (0, lodash_1.isEqual)((_a = saved.filterByComponents) !== null && _a !== void 0 ? _a : [], (_b = updated.filterByComponents) !== null && _b !== void 0 ? _b : []);
    var isSortsEqual = (0, lodash_1.isEqual)((_c = saved.sortByComponents) !== null && _c !== void 0 ? _c : [], (_d = updated.sortByComponents) !== null && _d !== void 0 ? _d : []);
    var isGroupsEqual = (0, lodash_1.isEqual)((_e = saved.groups) !== null && _e !== void 0 ? _e : [], (_f = updated.groups) !== null && _f !== void 0 ? _f : []);
    return isFiltersEqual && isSortsEqual && isGroupsEqual;
}
