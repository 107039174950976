"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatThreadHeaderContent = MBChatThreadHeaderContent;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var typography_1 = require("@shared/components/typography");
var emoji_1 = require("@shared/scenes/channel/detailsForm/utils/emoji");
var styles_1 = require("@shared/styles");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
function MBChatThreadHeaderContent(_a) {
    var _b;
    var channel = _a.channel;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var channelName = channel === null || channel === void 0 ? void 0 : channel.name;
    var nameWithoutEmoji = (0, react_1.useMemo)(function () { return (channelName ? (0, emoji_1.getNameWithoutEmoji)(channelName) : ''); }, [channelName]);
    var _c = (0, hooks_1.useChannelUsers)({ channelId: channel.id }), users = _c.users, usersLoading = _c.loading;
    var navigator = (0, navigation_1.useNavigator)();
    var navigateToOptions = function () {
        navigator.navigate('ChannelInfos', { channelSlug: channel === null || channel === void 0 ? void 0 : channel.slug });
    };
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { style: react_native_1.Platform.OS !== 'android' && { alignItems: 'center' }, onPress: navigateToOptions },
        react_1.default.createElement(typography_1.MBTextBody1Bold, { color: colors.foregroundActive, numberOfLines: 1 }, nameWithoutEmoji),
        !usersLoading && (react_1.default.createElement(components_1.MBView, { row: true },
            react_1.default.createElement(typography_1.MBTextBody1, { color: colors.foregroundActive, style: { marginRight: (0, components_1.grid)(1) } }, (0, i18n_1.i18n)('channel.header.members', { count: (_b = users === null || users === void 0 ? void 0 : users.length) !== null && _b !== void 0 ? _b : 0 })),
            react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundActive, name: "info-outline", size: 20 })))));
}
