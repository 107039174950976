"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBInputCardsBottomSheet = MBInputCardsBottomSheet;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var hooks_1 = require("@shared/components/blocks/lib/cards/hooks");
var NeedsCardFilter_1 = require("@shared/components/blocks/lib/inputCards/sheets/NeedsCardFilter");
var select_1 = require("@shared/components/blocks/lib/inputCards/sheets/select");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var modal_1 = require("@shared/components/multiSelect/modal");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var hooks_2 = require("@shared/scenes/workflows/hooks");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
var ViewAllRelatedCards_1 = require("./ViewAllRelatedCards");
function getInitialRoutes(relatedChannelIds, relatedCardComponent, workflowTemplateLoading) {
    if (relatedChannelIds === null || workflowTemplateLoading) {
        return [{ name: 'loading' }];
    }
    return [
        {
            name: relatedChannelIds.length === 0 && !(0, hooks_2.hasRelatedCardFilter)(relatedCardComponent)
                ? 'needsCardFilter'
                : 'select',
        },
    ];
}
function MBInputCardsBottomSheet(_a) {
    var block = _a.block, values = _a.values, fieldValues = _a.fieldValues, max = _a.max, containerRef = _a.containerRef, onChange = _a.onChange, onRemoveCard = _a.onRemoveCard, onClose = _a.onClose, initialRoute = _a.initialRoute, relatedChannelIds = _a.relatedChannelIds, relatedCardComponent = _a.relatedCardComponent;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var nav = (0, navigation_1.useNavStack)(initialRoute || getInitialRoutes(relatedChannelIds, relatedCardComponent, false));
    (0, react_1.useEffect)(function () {
        // transition from the initial unknown loading route to the one of the two possible initial routes
        if (nav.currentRoute.name === 'loading' && relatedChannelIds !== null) {
            nav.reset(getInitialRoutes(relatedChannelIds, relatedCardComponent, false));
        }
    }, [nav, relatedCardComponent, relatedChannelIds]);
    var openLinkedCard = (0, hooks_1.useOpenLinkedCard)();
    var renderTitleBar = (0, react_1.useCallback)(function () {
        switch (nav.currentRoute.name) {
            case 'loading':
            case 'select':
            case 'view':
                return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: block.label, rightIcon: "exit", onRightIconPress: onClose }));
            case 'needsCardFilter':
                return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: (0, i18n_1.i18n)('card.blocks.cardsInput.channelSearch', { name: block.label }), rightIcon: "exit", onRightIconPress: onClose }));
            default:
                return null;
        }
    }, [block, nav, onClose]);
    var content = (0, react_1.useMemo)(function () {
        switch (nav.currentRoute.name) {
            case 'loading':
                return (react_1.default.createElement(components_1.MBView, { padding: 4, center: true },
                    react_1.default.createElement(components_1.MBLoadingIndicator, null)));
            case 'needsCardFilter':
                return react_1.default.createElement(NeedsCardFilter_1.NeedsCardFilter, null);
            case 'select':
                return (react_1.default.createElement(select_1.SelectCards, { values: values, fieldValues: fieldValues, fromChannelId: relatedChannelIds === null || relatedChannelIds === void 0 ? void 0 : relatedChannelIds[0], relatedCardComponent: relatedCardComponent, onChange: onChange, max: max }));
            case 'view':
                return (react_1.default.createElement(ViewAllRelatedCards_1.ViewAllRelatedCards, { cards: values, block: block, onPressCard: function (card) {
                        onClose();
                        openLinkedCard(card);
                    }, onEditCards: function () {
                        nav.push({ name: 'select' });
                    }, onRemoveCard: onRemoveCard }));
            default:
                return null;
        }
    }, [
        nav,
        block,
        values,
        relatedChannelIds,
        relatedCardComponent,
        onChange,
        max,
        onRemoveCard,
        onClose,
        openLinkedCard,
        fieldValues,
    ]);
    var fixedWidth = (0, contentWrapper_1.useIsDesktop)() ? modal_1.FIXED_WIDTH : null;
    return (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { containerRef: containerRef, renderTitleBar: renderTitleBar, layout: {
            offset: { x: (0, components_1.grid)(10), y: 0 },
            minWidth: fixedWidth === null || fixedWidth === void 0 ? void 0 : fixedWidth.width,
            minHeight: (0, components_1.grid)(48),
        }, onClose: onClose },
        react_1.default.createElement(components_1.MBView, { style: fixedWidth, fill: true },
            react_1.default.createElement(channel_1.CardChannelContext.Provider, { value: channel }, content))));
}
