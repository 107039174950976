"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddUsersContext = exports.useAddUsersContext = void 0;
exports.AddUsersProvider = AddUsersProvider;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var context_1 = require("@shared/util/context");
var _a = (0, context_1.createCtx)(), useAddUsersContext = _a[0], AddUsersContextProvider = _a[1], AddUsersContext = _a[2];
exports.useAddUsersContext = useAddUsersContext;
exports.AddUsersContext = AddUsersContext;
function AddUsersProvider(_a) {
    var _this = this;
    var users = _a.users, loading = _a.loading, hasMore = _a.hasMore, children = _a.children, onAdd = _a.onAdd, query = _a.query, setQuery = _a.setQuery;
    var _b = (0, react_1.useState)([]), selectedUsers = _b[0], setSelectedUsers = _b[1];
    var _c = (0, react_1.useState)(), accessLevel = _c[0], setAccessLevel = _c[1];
    var onToggleSelectedUser = (0, react_1.useCallback)(function (user) {
        if (selectedUsers.some(function (u) { return u.id === user.id; })) {
            setSelectedUsers(selectedUsers.filter(function (u) { return u.id !== user.id; }));
        }
        else {
            setSelectedUsers(__spreadArray(__spreadArray([], selectedUsers, true), [user], false));
        }
    }, [selectedUsers, setSelectedUsers]);
    var onToggleAllUsers = (0, react_1.useCallback)(function () {
        if (selectedUsers.length === (users === null || users === void 0 ? void 0 : users.length)) {
            setSelectedUsers([]);
        }
        else {
            setSelectedUsers(users !== null && users !== void 0 ? users : []);
        }
    }, [users, selectedUsers]);
    var onAddUser = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!(0, lodash_1.isNil)(accessLevel)) {
                onAdd(selectedUsers, accessLevel);
            }
            return [2 /*return*/];
        });
    }); }, [onAdd, selectedUsers, accessLevel]);
    var contextValue = {
        users: users,
        loading: loading,
        hasMore: hasMore,
        selectedUsers: selectedUsers,
        accessLevel: accessLevel,
        onToggleSelectedUser: onToggleSelectedUser,
        onAdd: onAddUser,
        onToggleAllUsers: onToggleAllUsers,
        setAccessLevel: setAccessLevel,
        query: query,
        setQuery: setQuery,
    };
    return react_1.default.createElement(AddUsersContextProvider, { value: contextValue }, children);
}
