"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferencedInOptions = ReferencedInOptions;
// @ts-strict-ignore
var lodash_1 = __importStar(require("lodash"));
var rc_menu_1 = __importStar(require("rc-menu"));
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var config_1 = require("@shared/scenes/workflows/config");
var context_1 = require("@shared/scenes/workflows/context/context");
var hooks_1 = require("@shared/scenes/workflows/edit/hooks");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var NestedOptionsRow_1 = require("../NestedOptionsRow");
var divider_1 = require("../divider");
var SelectableItemList_1 = require("./SelectableItemList");
var useReferencedInOptions_1 = require("./useReferencedInOptions");
function ReferencedInOptions(_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var component = _a.component, workflowTemplateId = _a.workflowTemplateId, workspaceDetails = _a.workspaceDetails;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _j = (0, react_1.useState)([]), openKeys = _j[0], setOpenKeys = _j[1];
    var label = (0, hooks_1.useSetComponentField)(component, 'label')[0];
    var _k = (0, useReferencedInOptions_1.useReferencedInOptions)(workflowTemplateId, workspaceDetails, component), selectedWorkflowTemplate = _k.selectedWorkflowTemplate, setSelectedWorkflowId = _k.setSelectedWorkflowId, selectedComponent = _k.selectedComponent, setSelectedComponentId = _k.setSelectedComponentId, selectableWorkflowItems = _k.selectableWorkflowItems, selectableComponentItems = _k.selectableComponentItems, workflowLoading = _k.workflowLoading, viewTemplatesLoading = _k.viewTemplatesLoading, selectedViewTemplate = _k.selectedViewTemplate, selectableViewTemplates = _k.selectableViewTemplates, setSelectedViewTemplate = _k.setSelectedViewTemplate;
    var selectedWorkspaceName = (_b = workspaceDetails.get(selectedWorkflowTemplate === null || selectedWorkflowTemplate === void 0 ? void 0 : selectedWorkflowTemplate.workflowTemplateId)) === null || _b === void 0 ? void 0 : _b.name;
    var dispatch = (0, context_1.useWorkflowDispatch)();
    var onArchive = (0, react_1.useCallback)(function () {
        dispatch({
            type: 'archiveComponent',
            args: component.id,
        });
    }, [component.id, dispatch]);
    var optionsData = (0, react_1.useMemo)(function () { return config_1.componentTemplateOptions.optionsDataForComponent(component); }, [component]);
    var updateLabel = (0, react_1.useCallback)(function (value) {
        dispatch({
            type: 'updateComponent',
            args: __assign(__assign({}, component), { label: value }),
        });
    }, [dispatch, component]);
    (0, react_1.useEffect)(function () {
        if (!(0, lodash_1.isNil)(selectedWorkflowTemplate) && (0, lodash_1.isNil)(component.label)) {
            dispatch({
                type: 'updateComponent',
                args: __assign(__assign({}, component), { label: "".concat(selectedWorkflowTemplate.name) }),
            });
        }
    }, [selectedWorkflowTemplate, selectedComponent, component, dispatch]);
    return (react_1.default.createElement(components_1.MBView, null,
        react_1.default.createElement(rc_menu_1.default, { mode: "vertical", openKeys: openKeys, onOpenChange: setOpenKeys, triggerSubMenuAction: "click", style: {
                backgroundColor: 'white',
                borderRadius: (0, components_1.grid)(1),
                boxShadow: '0 0 10px #d9d9d9',
                maxHeight: '100vh',
                overflow: 'auto',
            }, expandIcon: null },
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3 },
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.fieldType'))),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 2, row: true, centerVertically: true },
                react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundActive, name: optionsData.icon, size: (0, components_1.grid)(3) }),
                react_1.default.createElement(components_1.MBView, { paddingLeft: 1 }),
                react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundActive }, optionsData.title)),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2 },
                react_1.default.createElement(divider_1.Divider, null)),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.label')),
                react_1.default.createElement(components_1.MBView, { padding: 1 }),
                react_1.default.createElement(components_1.TextInput, { autosize: true, value: label, onChangeText: updateLabel })),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2, paddingTop: 2 },
                react_1.default.createElement(divider_1.Divider, null)),
            workflowLoading && react_1.default.createElement(components_1.MBLoadingIndicator, null),
            !workflowLoading && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
                    react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('builder.referencedInOptions.referentialWorkflow'))),
                react_1.default.createElement(rc_menu_1.SubMenu, { key: "workflowRow", style: { zIndex: 999 }, title: react_1.default.createElement(NestedOptionsRow_1.NestedOptionsRow, { name: selectedWorkspaceName !== null && selectedWorkspaceName !== void 0 ? selectedWorkspaceName : (0, i18n_1.i18n)('builder.referencedInOptions.selectWorkflow'), defaultIcon: 'duplicate-squares', selectedIcon: 'duplicate-squares', imageUrl: selectedWorkflowTemplate === null || selectedWorkflowTemplate === void 0 ? void 0 : selectedWorkflowTemplate.imageUrl, enabled: true }) },
                    react_1.default.createElement(react_native_1.View, { style: { paddingVertical: (0, components_1.grid)(1) } },
                        react_1.default.createElement(SelectableItemList_1.SelectableItemList, { items: selectableWorkflowItems, onSelectItem: function (id) {
                                if (id === (selectedWorkflowTemplate === null || selectedWorkflowTemplate === void 0 ? void 0 : selectedWorkflowTemplate.workflowTemplateId)) {
                                    setSelectedWorkflowId(null);
                                }
                                else {
                                    setSelectedWorkflowId(id);
                                }
                            }, enabled: true, selectedItem: selectedWorkflowTemplate === null || selectedWorkflowTemplate === void 0 ? void 0 : selectedWorkflowTemplate.workflowTemplateId }))))),
            !workflowLoading && !lodash_1.default.isNil(selectedWorkflowTemplate) && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
                    react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('builder.referencedInOptions.referentialComponent'))),
                react_1.default.createElement(rc_menu_1.SubMenu, { key: "componentRow", style: { zIndex: 999 }, title: react_1.default.createElement(NestedOptionsRow_1.NestedOptionsRow, { name: (_c = selectedComponent === null || selectedComponent === void 0 ? void 0 : selectedComponent.label) !== null && _c !== void 0 ? _c : (0, i18n_1.i18n)('builder.referencedInOptions.selectComponent'), defaultIcon: (_d = selectedComponent === null || selectedComponent === void 0 ? void 0 : selectedComponent.icon) !== null && _d !== void 0 ? _d : 'duplicate-squares', selectedIcon: (_e = selectedComponent === null || selectedComponent === void 0 ? void 0 : selectedComponent.icon) !== null && _e !== void 0 ? _e : 'duplicate-squares', enabled: true }) },
                    react_1.default.createElement(react_native_1.View, { style: { paddingVertical: (0, components_1.grid)(1) } },
                        react_1.default.createElement(SelectableItemList_1.SelectableItemList, { items: selectableComponentItems, onSelectItem: function (id) {
                                if (id === (selectedComponent === null || selectedComponent === void 0 ? void 0 : selectedComponent.id)) {
                                    setSelectedComponentId(null);
                                }
                                else {
                                    setSelectedComponentId(id);
                                }
                            }, enabled: true, selectedItem: selectedComponent === null || selectedComponent === void 0 ? void 0 : selectedComponent.id }))))),
            !viewTemplatesLoading && !lodash_1.default.isNil(selectedWorkflowTemplate) && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
                    react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('builder.referencedInOptions.referentialViewTemplate'))),
                react_1.default.createElement(rc_menu_1.SubMenu, { key: "viewRow", style: { zIndex: 999 }, title: react_1.default.createElement(NestedOptionsRow_1.NestedOptionsRow, { name: (_f = selectedViewTemplate === null || selectedViewTemplate === void 0 ? void 0 : selectedViewTemplate.label) !== null && _f !== void 0 ? _f : (0, i18n_1.i18n)('builder.referencedInOptions.selectView'), defaultIcon: (_g = selectedViewTemplate === null || selectedViewTemplate === void 0 ? void 0 : selectedViewTemplate.icon) !== null && _g !== void 0 ? _g : 'list', selectedIcon: (_h = selectedViewTemplate === null || selectedViewTemplate === void 0 ? void 0 : selectedViewTemplate.icon) !== null && _h !== void 0 ? _h : 'list', enabled: true }) },
                    react_1.default.createElement(react_1.default.Fragment, null,
                        lodash_1.default.isEmpty(selectableViewTemplates) && (react_1.default.createElement(components_1.MBView, { style: { padding: (0, components_1.grid)(2) } },
                            react_1.default.createElement(components_1.MBTextBody1, { style: { textAlign: 'center' } }, (0, i18n_1.i18n)('builder.referencedInOptions.emptyList', {
                                workflowName: selectedWorkspaceName,
                            })))),
                        !lodash_1.default.isEmpty(selectableViewTemplates) && (react_1.default.createElement(react_native_1.View, { style: { paddingVertical: (0, components_1.grid)(1) } },
                            react_1.default.createElement(SelectableItemList_1.SelectableItemList, { items: selectableViewTemplates, onSelectItem: function (id) {
                                    if (id === (selectedViewTemplate === null || selectedViewTemplate === void 0 ? void 0 : selectedViewTemplate.id)) {
                                        setSelectedViewTemplate(null);
                                    }
                                    else {
                                        setSelectedViewTemplate(id);
                                    }
                                }, enabled: true, selectedItem: selectedViewTemplate === null || selectedViewTemplate === void 0 ? void 0 : selectedViewTemplate.id }))))))),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2, paddingTop: 1 },
                react_1.default.createElement(divider_1.Divider, null)),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.options'))),
            react_1.default.createElement(components_1.ArchiveRow, { disabled: component.locked, onArchive: onArchive }))));
}
