"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAllBusinessUsers = useAllBusinessUsers;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
var notNil_1 = require("../notNil");
function useAllBusinessUsers(skip) {
    if (skip === void 0) { skip = false; }
    var businessId = (0, react_redux_1.useSelector)(function (store) { return store.appState.currentBusinessId; });
    var _a = (0, client_1.useQuery)(api_1.Queries.user.directory, {
        variables: {
            businessId: businessId !== null && businessId !== void 0 ? businessId : -1,
        },
        skip: skip || (0, lodash_1.isNil)(businessId),
    }), data = _a.data, loading = _a.loading;
    return (0, react_1.useMemo)(function () {
        var _a, _b, _c, _d, _e, _f;
        return {
            users: __spreadArray(__spreadArray([], ((_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.users) !== null && _c !== void 0 ? _c : []), true), ((_f = (_e = (_d = data === null || data === void 0 ? void 0 : data.me) === null || _d === void 0 ? void 0 : _d.business) === null || _e === void 0 ? void 0 : _e.removedUsers) !== null && _f !== void 0 ? _f : []), true).filter(notNil_1.notNil),
            loading: loading,
        };
    }, [data, loading]);
}
