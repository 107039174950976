"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchQuery = useSearchQuery;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
var analytics_1 = require("@shared/util/analytics");
var emptyArray = [];
function useSearchQuery(query) {
    var _a;
    var _b = (0, client_1.useQuery)(api_1.Queries.search.all, {
        variables: { input: { query: query } },
        // Note(fant): we force Apollo to not use cached data and therefore
        // make a new GraphQL request every time the query changes. The reason
        // we do this is because otherwise nested values (eg highlights) would
        // show up incorrectly. Eg if a message both matches "hell" and "hello",
        // the highlight for "hell" might be used when the actual query is
        // "hello". The reason is that those GraphQL types don't have id fields
        // and therefore Apollo's cache is confused - it doesn't know what version
        // to use, so it uses the latest one.
        fetchPolicy: 'network-only',
        onCompleted: (0, react_1.useCallback)(function (completedData) {
            if (!(0, lodash_1.isNil)(completedData === null || completedData === void 0 ? void 0 : completedData.searchEntities)) {
                analytics_1.MBAnalytics.track('Search::Query', {
                    query: query,
                    numMessages: completedData.searchEntities.filter(function (r) { return r.type === 'MESSAGE'; }).length,
                });
            }
        }, [query]),
    }), data = _b.data, error = _b.error;
    // If the search query fails, handle it gracefully. For instance,
    // the following query currently fails: "^ ( ) " : / { } ~"
    if (!(0, lodash_1.isNil)(error)) {
        return emptyArray;
    }
    // Note(fant): we return null to distinguish between
    // empty results and when we still haven't fetched results.
    return (_a = data === null || data === void 0 ? void 0 : data.searchEntities) !== null && _a !== void 0 ? _a : null;
}
