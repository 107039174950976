"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditFieldContainer = EditFieldContainer;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var getTestId_1 = require("@shared/util/getTestId");
function EditFieldContainer(_a) {
    var component = _a.component, children = _a.children, _b = _a.icon, icon = _b === void 0 ? (0, hooks_1.getComponentIcon)(component) : _b, _c = _a.label, label = _c === void 0 ? (0, hooks_1.getComponentLabel)(component) : _c, _d = _a.placeholder, placeholder = _d === void 0 ? (0, hooks_1.getComponentPlaceholder)(component) : _d, _e = _a.showIcon, showIcon = _e === void 0 ? true : _e, _f = _a.showLabel, showLabel = _f === void 0 ? false : _f, _g = _a.indentLabel, indentLabel = _g === void 0 ? false : _g, _h = _a.showPlaceholder, showPlaceholder = _h === void 0 ? false : _h, error = _a.error, onPress = _a.onPress, onClear = _a.onClear, _j = _a.includeWrapperPadding, includeWrapperPadding = _j === void 0 ? true : _j, _k = _a.testID, testID = _k === void 0 ? (0, getTestId_1.getTestId)('card-field-cards', component.id) : _k;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var isDisplayable = (0, hooks_1.isDisplayableComponent)(component);
    var neverHasIcon = component.id === 'name';
    var shouldHaveIcon = showIcon && isDisplayable && !neverHasIcon;
    var renderIcon = shouldHaveIcon && !(0, lodash_1.isNil)(icon);
    var renderLabel = showLabel && isDisplayable && !(0, lodash_1.isNil)(label);
    var renderPlaceholder = showPlaceholder && isDisplayable && !(0, lodash_1.isNil)(placeholder);
    var renderClearButton = !(0, lodash_1.isNil)(onClear);
    var hasError = !(0, lodash_1.isNil)(error);
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress, testID: testID },
        react_1.default.createElement(components_1.MBView, { style: [
                styles.wrapper,
                includeWrapperPadding && styles.wrapperPadding,
                hasError && includeWrapperPadding && styles.errorWrapper,
                hasError && {
                    borderColor: colors.error.border,
                    backgroundColor: colors.error.background,
                },
            ] },
            react_1.default.createElement(components_1.MBView, { style: [styles.row] },
                renderIcon && (react_1.default.createElement(react_native_1.View, { style: styles.icon },
                    react_1.default.createElement(components_1.MBIconV2, { name: icon, size: 40, color: hasError ? colors.error.foreground : colors.accent }))),
                shouldHaveIcon && !renderIcon && (react_1.default.createElement(react_native_1.View, { style: styles.icon },
                    react_1.default.createElement(components_1.MBIconV2, { name: "squares-stacked", size: 40, color: hasError ? colors.error.foreground : colors.accent }))),
                react_1.default.createElement(react_native_1.View, { style: styles.content },
                    renderLabel && (react_1.default.createElement(components_1.MBTextOverlineBold, { color: hasError ? colors.foregroundInactive : colors.input.label, style: [styles.label, indentLabel && styles.indentedLabel] }, label)),
                    renderPlaceholder && (react_1.default.createElement(components_1.MBView, { style: styles.placeholder },
                        react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundInactive }, placeholder))),
                    children),
                renderClearButton && (react_1.default.createElement(components_1.MBView, { style: styles.clearButton },
                    react_1.default.createElement(components_1.MBClearButton, { onPress: onClear, color: hasError ? colors.error.foreground : undefined })))),
            hasError && (react_1.default.createElement(components_1.MBView, { style: [
                    styles.error,
                    !shouldHaveIcon && styles.errorNoIcon,
                    indentLabel && styles.indentedLabel,
                ] },
                react_1.default.createElement(components_1.MBTextBody2, { color: colors.error.foreground }, error))))));
}
var styles = react_native_1.StyleSheet.create({
    wrapper: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: (0, components_1.grid)(1),
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'transparent',
        paddingVertical: (0, components_1.grid)(0.5),
    },
    wrapperPadding: {
        marginVertical: (0, components_1.grid)(0.125),
        marginHorizontal: (0, components_1.grid)(0.75),
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
        minHeight: 60,
        backgroundColor: 'transparent',
        justifyContent: 'space-between',
        paddingHorizontal: (0, components_1.grid)(3),
    },
    icon: {
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'row',
        height: '100%',
        marginRight: (0, components_1.grid)(2),
        paddingVertical: (0, components_1.grid)(1.5),
    },
    label: {
        marginBottom: (0, components_1.grid)(0.5),
    },
    placeholder: {
        paddingVertical: (0, components_1.grid)(1.25),
    },
    clearButton: {
        justifyContent: 'center',
        flexDirection: 'row',
        height: '100%',
        alignItems: 'flex-start',
        marginLeft: (0, components_1.grid)(2),
        paddingVertical: (0, components_1.grid)(1.25),
    },
    indentedLabel: {
        marginLeft: (0, components_1.grid)(7),
    },
    content: {
        flexGrow: 1,
        flexShrink: 1,
        paddingVertical: (0, components_1.grid)(1.5),
    },
    errorWrapper: {
        paddingVertical: (0, components_1.grid)(0.75),
    },
    error: {
        paddingLeft: (0, components_1.grid)(10),
        paddingTop: (0, components_1.grid)(0.5),
        paddingBottom: (0, components_1.grid)(1),
        alignSelf: 'flex-start',
    },
    errorNoIcon: {
        paddingLeft: (0, components_1.grid)(3),
    },
});
