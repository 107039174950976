"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToggle = useToggle;
var react_1 = require("react");
function useToggle(defaultIsOn) {
    if (defaultIsOn === void 0) { defaultIsOn = false; }
    var _a = (0, react_1.useState)(defaultIsOn), isOn = _a[0], setToggle = _a[1];
    var toggleOn = (0, react_1.useCallback)(function () { return setToggle(true); }, []);
    var toggleOff = (0, react_1.useCallback)(function () { return setToggle(false); }, []);
    var toggle = (0, react_1.useCallback)(function () { return setToggle(function (prevIsOn) { return !prevIsOn; }); }, []);
    return (0, react_1.useMemo)(function () { return ({ isOn: isOn, toggleOn: toggleOn, toggleOff: toggleOff, toggle: toggle, setToggle: setToggle }); }, [isOn, toggleOn, toggleOff, toggle, setToggle]);
}
