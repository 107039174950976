"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reset = exports.RESET = exports.SET_DETAILS = exports.UPDATE_WORKFLOW_TEMPLATE_NAME = exports.UPDATE_EMOJI = exports.UPDATE_IS_IMAGE_UPLOADING = exports.UPDATE_IMAGE_URL = exports.UPDATE_DESCRIPTION = exports.UPDATE_NAME = void 0;
exports.updateName = updateName;
exports.updateEmoji = updateEmoji;
exports.updateDescription = updateDescription;
exports.updateImageUrl = updateImageUrl;
exports.updateIsImageUploading = updateIsImageUploading;
exports.updateWorkflowTemplateName = updateWorkflowTemplateName;
exports.setDetails = setDetails;
exports.UPDATE_NAME = 'CHANNEL:CREATE:UPDATE_NAME';
exports.UPDATE_DESCRIPTION = 'CHANNEL:CREATE:UPDATE_DESCRIPTION';
exports.UPDATE_IMAGE_URL = 'CHANNEL:CREATE:UPDATE_IMAGE_URL';
exports.UPDATE_IS_IMAGE_UPLOADING = 'CHANNEL:CREATE:UPDATE_IS_IMAGE_UPLOADING';
exports.UPDATE_EMOJI = 'CHANNEL:CREATE:EMOJI';
exports.UPDATE_WORKFLOW_TEMPLATE_NAME = 'CHANNEL:CREATE:UPDATE_WORKFLOW_TEMPLATE';
exports.SET_DETAILS = 'CHANNEL:CREATE:STATE';
exports.RESET = 'CHANNEL:CREATE:RESET';
function updateName(name) {
    return { type: exports.UPDATE_NAME, value: name };
}
function updateEmoji(emoji) {
    return { type: exports.UPDATE_EMOJI, value: emoji };
}
function updateDescription(description) {
    return {
        type: exports.UPDATE_DESCRIPTION,
        value: description,
    };
}
function updateImageUrl(imageUrl) {
    return {
        type: exports.UPDATE_IMAGE_URL,
        value: imageUrl,
    };
}
function updateIsImageUploading(isUploading) {
    return {
        type: exports.UPDATE_IS_IMAGE_UPLOADING,
        value: isUploading,
    };
}
function updateWorkflowTemplateName(name) {
    return {
        type: exports.UPDATE_WORKFLOW_TEMPLATE_NAME,
        value: name,
    };
}
function setDetails(details) {
    return {
        type: exports.SET_DETAILS,
        value: details,
    };
}
exports.reset = { type: exports.RESET };
