"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChannelCreateFragmentTemplate = MBChannelCreateFragmentTemplate;
// @ts-strict-ignore
var formik_1 = require("formik");
var react_1 = __importStar(require("react"));
var toast_1 = require("@shared/components/toast");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
var tagOptions_1 = require("../contexts/tagOptions");
var hooks_2 = require("../hooks");
var DEFAULT_VALUE = {
    name: '',
    template: {
        version: 'v1',
        compose: {
            blockType: 'seen-by',
            position: 'prepend',
        },
        items: [],
    },
};
function MBChannelCreateFragmentTemplate(_a) {
    var _this = this;
    var channelSlug = _a.channelSlug, children = _a.children;
    var channelId = (0, hooks_1.useChannelId)(channelSlug);
    var createTemplate = (0, hooks_2.useCreateFragmentTemplate)(channelId);
    var validationSchema = (0, hooks_2.useValidationSchema)();
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var goBack = (0, navigation_1.useNavigator)().goBack;
    var onSave = (0, react_1.useCallback)(function (data_1, _a) { return __awaiter(_this, [data_1, _a], void 0, function (data, _b) {
        var setSubmitting = _b.setSubmitting;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, , 2, 3]);
                    return [4 /*yield*/, createTemplate(__assign(__assign({}, data), { template: (0, hooks_2.prepareTemplateForSubmission)(data.template) }))];
                case 1:
                    _c.sent();
                    goBack();
                    toast.show({
                        icon: 'check-filled',
                        message: (0, i18n_1.i18n)('channel.options.fragmentTemplates.saved'),
                    });
                    return [3 /*break*/, 3];
                case 2:
                    setSubmitting(false);
                    return [7 /*endfinally*/];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [createTemplate, toast, goBack]);
    return (react_1.default.createElement(formik_1.Formik, { initialValues: DEFAULT_VALUE, onSubmit: onSave, validateOnBlur: false, validateOnChange: false, validationSchema: validationSchema },
        react_1.default.createElement(tagOptions_1.TagOptionsContextProvider, null, children)));
}
