"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderSectionCard = renderSectionCard;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var form_1 = require("@shared/components/blocks/contexts/form");
var card_1 = require("@shared/components/card");
var task_1 = require("@shared/components/card/task");
var hooks_1 = require("@shared/scenes/cards/form/hooks");
var ChannelEventHandlers_1 = require("../../../workflows/hooks/ChannelEventHandlers");
var quickCreate_1 = require("./quickCreate");
var SectionCardMemo = (0, react_1.memo)(function SectionCard(_a) {
    var card = _a.item, index = _a.index, section = _a.section;
    var updateCard = (0, hooks_1.useUpdateCard)();
    var onOpenCard = (0, ChannelEventHandlers_1.useChannelEventHandlers)().onOpenCard;
    var handleSubmit = (0, react_1.useCallback)(function (fields) { return updateCard(card, fields); }, [updateCard, card]);
    if (index === 0 && section.quickCreate) {
        return (react_1.default.createElement(components_1.MBView, { padding: 1, paddingTop: 0 },
            react_1.default.createElement(quickCreate_1.MBQuickCreate, { label: section.quickCreate.label, fieldOverrides: section.quickCreate.fieldOverrides })));
    }
    var content = !!section.taskOptions ? (react_1.default.createElement(task_1.MBTaskCard, { card: card, blocks: section.cardBlocks, onPress: onOpenCard, cardTheme: section.cardTheme, taskOptions: section.taskOptions })) : (react_1.default.createElement(card_1.MBCard, { card: card, blocks: section.cardBlocks, onPress: onOpenCard, cardTheme: section.cardTheme }));
    return (react_1.default.createElement(components_1.MBView, { padding: 1, paddingTop: index === 0 ? 1 : 0 },
        react_1.default.createElement(form_1.MBCardFormProvider, { card: card, blocks: section.cardBlocks, cardDefinitionId: card === null || card === void 0 ? void 0 : card.cardDefinitionId, onSubmit: handleSubmit }, content)));
});
function renderSectionCard(data) {
    return react_1.default.createElement(SectionCardMemo, __assign({}, data));
}
