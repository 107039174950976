"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StartRows = StartRows;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/scenes/channel/list/hooks");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
var ChannelListLayoutContext_1 = require("../../webLayout/ChannelListLayoutContext");
var ScreenSize_1 = require("../../webLayout/ScreenSize");
var baseRow_1 = require("../components/collapsed/baseRow");
var slimRow_1 = require("../components/slimRow");
function StartRows() {
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _a = (0, react_1.useContext)(ChannelListLayoutContext_1.ChannelListLayoutContext), state = _a.state, persistedState = _a.persistedState, togglePersistedState = _a.togglePersistedState;
    var navigateToAllCards = (0, navigation_1.useNavigateToRoute)('BaseCardsTab');
    var navigateToDashboard = (0, navigation_1.useNavigateToRoute)('DashboardTab');
    var screenSize = (0, ScreenSize_1.useScreenSize)();
    var selected = (0, hooks_1.useAllCardsRowSelected)();
    var dashboardSelected = (0, hooks_1.useDashboardRowSelected)();
    var taskIcon = (react_1.default.createElement(components_1.MBBoxedIcon, { color: colors.avatar.foreground, backgroundColor: colors.avatar.background, source: require('@shared/assets/icons/v2/task.png'), style: { width: '100%', height: '100%' } }));
    var layoutIcon = (0, react_1.useMemo)(function () {
        return persistedState === 'collapsed' ? (react_1.default.createElement(components_1.MBBoxedIcon, { color: colors.button.foreground, backgroundColor: colors.avatar.background, source: require('@shared/assets/icons/v2/slide-right-arrow.png'), style: { width: '100%', height: '100%' } })) : (react_1.default.createElement(components_1.MBBoxedIcon, { color: colors.button.foreground, backgroundColor: colors.avatar.background, source: require('@shared/assets/icons/v2/slide-left-arrow.png'), style: { width: '100%', height: '100%' } }));
    }, [colors.avatar.background, colors.button.foreground, persistedState]);
    var layoutText = (0, react_1.useMemo)(function () {
        return persistedState === 'collapsed'
            ? (0, i18n_1.i18n)('channel.list.layout.expand')
            : (0, i18n_1.i18n)('channel.list.layout.minimize');
    }, [persistedState]);
    if (isDesktop) {
        if (state === 'collapsed') {
            return (react_1.default.createElement(components_1.MBView, null,
                screenSize === 'large' && (react_1.default.createElement(baseRow_1.MBCollapsedChannelListRow, { selected: false, onPress: togglePersistedState, paddingVertical: 0.5 },
                    react_1.default.createElement(components_1.MBView, { style: { width: (0, components_1.grid)(4), height: (0, components_1.grid)(4) } }, layoutIcon))),
                react_1.default.createElement(baseRow_1.MBCollapsedChannelListRow, { selected: selected, onPress: navigateToAllCards, paddingVertical: 0.5 },
                    react_1.default.createElement(components_1.MBView, { style: { width: (0, components_1.grid)(4), height: (0, components_1.grid)(4) } },
                        react_1.default.createElement(components_1.MBBoxedIcon, { color: colors.avatar.foreground, backgroundColor: colors.avatar.background, source: require('@shared/assets/icons/v2/task.png'), style: { width: '100%', height: '100%' } }))),
                react_1.default.createElement(baseRow_1.MBCollapsedChannelListRow, { selected: dashboardSelected, onPress: navigateToDashboard, paddingVertical: 0.5 },
                    react_1.default.createElement(components_1.MBView, { style: { width: (0, components_1.grid)(4), height: (0, components_1.grid)(4) } },
                        react_1.default.createElement(components_1.MBBoxedIcon, { color: colors.avatar.foreground, backgroundColor: colors.avatar.background, source: require('@shared/assets/icons/v2/squares.png'), style: { width: '100%', height: '100%' } })))));
        }
        return (react_1.default.createElement(react_1.default.Fragment, null,
            screenSize === 'large' && (react_1.default.createElement(slimRow_1.MBSlimRow, { selected: false, children: layoutText, image: layoutIcon, onPress: togglePersistedState, testID: "all-cards-sidebar-nav" })),
            react_1.default.createElement(slimRow_1.MBSlimRow, { selected: selected, children: (0, i18n_1.i18n)('channel.list.allCards.title'), image: taskIcon, onPress: navigateToAllCards, testID: "all-cards-sidebar-nav" }),
            react_1.default.createElement(slimRow_1.MBSlimRow, { selected: dashboardSelected, children: (0, i18n_1.i18n)('channel.list.dashboard.title'), image: react_1.default.createElement(components_1.MBBoxedIcon, { color: colors.avatar.foreground, backgroundColor: colors.avatar.background, source: require('@shared/assets/icons/v2/squares.png'), style: { width: '100%', height: '100%' } }), onPress: navigateToDashboard, testID: "dashboard-sidebar-nav" })));
    }
    return (react_1.default.createElement(components_1.MBListRow, { paddingVertical: 1, paddingLeft: 3, title: (0, i18n_1.i18n)('channel.list.allCards.title'), isSelected: selected, image: react_1.default.createElement(components_1.MBView, { fill: true, centerVertically: true }, taskIcon), onPress: navigateToAllCards, isHidingPressableIndicator: true }));
}
