"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RETURN_CHANNEL_SLUG_QUERY = void 0;
exports.CardHistoryRoute = CardHistoryRoute;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var scroll_1 = require("@shared/components/blocks/contexts/scroll");
var CardHistory_1 = require("@shared/scenes/cards/history/components/CardHistory");
var hooks_1 = require("@shared/scenes/cards/hooks");
var constants_1 = require("@shared/styles/constants");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
exports.RETURN_CHANNEL_SLUG_QUERY = 'returnChannelSlug';
function CardHistoryRoute(_a) {
    var _b;
    var params = _a.params;
    var card = (0, hooks_1.useCard)(params.cardId).card;
    var titlePrefix = (0, i18n_1.i18n)('card.history.modalHeaderPrefix');
    var title = ((_b = card === null || card === void 0 ? void 0 : card.fields) === null || _b === void 0 ? void 0 : _b.name) ? "".concat(titlePrefix, ": ").concat(card.fields.name) : titlePrefix;
    var goBack = (0, navigation_1.useNavigator)().goBack;
    var navigator = (0, navigation_1.useNavigator)();
    var navigateToChannel = function () {
        navigator.navigate('Channel', {
            channelSlug: params.channelSlug,
        });
    };
    var onBack = (0, react_1.useCallback)(function () {
        goBack();
    }, [goBack]);
    return (react_1.default.createElement(components_1.MBModalOrMobile, { desktopModalWidth: constants_1.Constants.pageModalWidth, desktopModalHeight: 'calc(95vh - 76px)' /* height of bottom button */, title: title, paddingOnWeb: "none", onBack: onBack, onClose: navigateToChannel, isCloseButtonHidden: false, headerOnMobile: react_1.default.createElement(components_1.MBWebNavigationHeader, { title: title, onPressLeft: goBack }) },
        react_1.default.createElement(scroll_1.CardBlockScrollProvider, null,
            react_1.default.createElement(CardHistory_1.CardHistory, { cardId: params.cardId }))));
}
