/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import PureComponent from '@src/components/PureComponent';
import Toastr from '@src/components/Toastr';
import type { StoreState } from '@src/types/StoreTypes';
import type { ToastrType } from '@src/types/ToastrTypes';

type Props = {
  toastr: ToastrType,
  isMobile: boolean,
};

type State = {
  message: string,
};

class ToastrContainer extends PureComponent {
  props: Props;
  state: State;
  toastrTimeout: ?number;

  constructor(props: Props) {
    super(props);
    this.state = { message: '' };
  }

  // TODO(fant): use new react lifecycle method getDerivedStateFromProps
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.toastr !== this.props.toastr) {
      clearTimeout(this.toastrTimeout);

      this.toastrTimeout = setTimeout(() => {
        this.setState({ message: nextProps.toastr.message });
        const duration = nextProps.toastr.type === 'error' ? 3500 : 2000;
        this.toastrTimeout = setTimeout(() => this.setState({ message: '' }), duration);
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.toastrTimeout);
  }

  render() {
    if (!this.state.message) {
      return null;
    }

    return (
      <Toastr
        message={this.state.message}
        type={this.props.toastr.type}
        isMobile={this.props.isMobile}
      />
    );
  }
}

export default connect((state: StoreState) => ({
  toastr: state.appState.toastr,
  isMobile: state.appState.viewportSizeMobile,
}))(ToastrContainer);
