"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toFieldJson = toFieldJson;
var Entity_1 = require("@shared/data/entity/Entity");
function toFieldJson(fields, workflowTemplate) {
    return Object.entries(fields).reduce(function (memo, _a) {
        var _b;
        var _c;
        var componentId = _a[0], field = _a[1];
        if (!(0, Entity_1.isCreateableField)(componentId, workflowTemplate)) {
            return memo;
        }
        return __assign(__assign({}, memo), (_b = {}, _b[componentId] = (_c = field === null || field === void 0 ? void 0 : field.toFieldJson()) !== null && _c !== void 0 ? _c : null, _b));
    }, {});
}
