"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFilteredDirectory = useFilteredDirectory;
var lodash_1 = require("lodash");
var react_1 = require("react");
var hooks_1 = require("@shared/util/hooks");
var notNil_1 = require("@shared/util/notNil");
function useFilteredDirectory(query) {
    var users = (0, hooks_1.useCurrentBusinessUsers)();
    return (0, react_1.useMemo)(function () { return getFilteredResults({ users: users, query: query }); }, [users, query]);
}
function getFilteredResults(_a) {
    var users = _a.users, query = _a.query;
    var queryLowercase = query.trim().toLowerCase();
    if ((0, lodash_1.isNil)(users) || (0, lodash_1.isEmpty)(queryLowercase)) {
        return [];
    }
    return users.map(function (user) { return toUserSearchResult(user, queryLowercase); }).filter(notNil_1.notNil);
}
function toUserSearchResult(user, queryLowercase) {
    var index = user.fullName.toLowerCase().indexOf(queryLowercase);
    if (index === -1) {
        return null;
    }
    return {
        item: user,
        highlight: { start: index, end: index + queryLowercase.length },
    };
}
