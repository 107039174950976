"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubformsList = SubformsList;
exports.BulkActions = BulkActions;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var api_1 = require("@shared/api");
var components_1 = require("@shared/components");
var Button_1 = require("@shared/components/filter/Button");
var toast_1 = require("@shared/components/toast");
var menuRow_1 = require("@shared/scenes/cards/bulk/components/menuRow");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var ComponentOptionDefaults_1 = require("@shared/scenes/workflows/template/ComponentOptionDefaults");
var styles_1 = require("@shared/styles");
var hooks_2 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var uuid = __importStar(require("@shared/util/uuid"));
var workflow_1 = require("@shared/util/workflow");
var SubformItem_1 = require("./SubformItem");
var WorkflowTemplatesList_1 = require("./WorkflowTemplatesList");
var useSubforms_1 = require("./useSubforms");
function SubformsList(_a) {
    var channelSlug = _a.channelSlug;
    var channel = (0, hooks_2.useChannel)({ channelSlug: channelSlug });
    var workflowTemplateId = channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId;
    var _b = (0, hooks_2.useDebouncedState)('', 300), query = _b[0], setQuery = _b[1];
    var _c = (0, useSubforms_1.useSubforms)({ workflowTemplateId: workflowTemplateId, name: query }), subforms = _c.subforms, loading = _c.loading, hasMore = _c.hasMore, loadMore = _c.loadMore;
    var _d = (0, react_1.useState)(false), isSelectionMode = _d[0], setIsSelectionMode = _d[1];
    var _e = (0, react_1.useState)([]), selectedSubforms = _e[0], setSelectedSubforms = _e[1];
    var _f = (0, react_1.useState)(false), isShowingCopyToWorkflow = _f[0], setIsShowingCopyToWorkflow = _f[1];
    (0, react_1.useEffect)(function () {
        setIsSelectionMode(!(0, lodash_1.isEmpty)(selectedSubforms));
    }, [selectedSubforms]);
    var handleToggleSelection = function (selectedSubform) {
        setSelectedSubforms(function (prevSelectedSubforms) {
            if (!(0, lodash_1.isNil)(prevSelectedSubforms.find(function (sf) { return sf.id === selectedSubform.id; }))) {
                return prevSelectedSubforms.filter(function (sf) { return sf.id !== selectedSubform.id; });
            }
            return __spreadArray(__spreadArray([], prevSelectedSubforms, true), [selectedSubform], false);
        });
    };
    var copySubformToWorkflow = useCopySubformsToWorkflow();
    var onSelectWorkflowToCopyTo = (0, react_1.useCallback)(function (workflowTemplate) {
        copySubformToWorkflow(selectedSubforms, workflowTemplate.id, function () {
            setIsShowingCopyToWorkflow(false);
            setSelectedSubforms([]);
        });
    }, [selectedSubforms, setIsShowingCopyToWorkflow, copySubformToWorkflow]);
    return (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(components_1.MBView, { paddingVertical: 1, paddingHorizontal: 1 },
            react_1.default.createElement(components_1.MBSearchBar, { value: query, onChangeText: setQuery, placeholder: (0, i18n_1.i18n)('workflowTemplate.subformsSearch') })),
        isSelectionMode && (react_1.default.createElement(react_native_1.View, { style: {
                paddingVertical: (0, components_1.grid)(2),
                paddingHorizontal: (0, components_1.grid)(1),
            } },
            react_1.default.createElement(SelectionControls, { selectedItems: selectedSubforms, setSelectedItems: setSelectedSubforms, MenuItems: react_1.default.createElement(menuRow_1.MBMenuRow, { image: react_1.default.createElement(components_1.MBIconV2, { name: "duplicate-squares", color: styles_1.Colors.v2.purple, size: (0, components_1.grid)(3) }), title: (0, i18n_1.i18n)('workflowTemplate.subformOptions.copyToWorkflowTemplate'), onPress: function () { return setIsShowingCopyToWorkflow(true); }, isHidingPressableIndicator: true, paddingVertical: 1.5 }) },
                react_1.default.createElement(BulkActionsOptionsWrapper, null, isShowingCopyToWorkflow && (react_1.default.createElement(WorkflowTemplatesList_1.WorkflowTemplatesList, { onSelect: onSelectWorkflowToCopyTo, onClose: function () { return setIsShowingCopyToWorkflow(false); } })))))),
        react_1.default.createElement(components_1.MBScrollView, { nativeShouldPersistTaps: true, nativeKeyboardDismissMode: "on-drag", style: { height: react_native_1.Dimensions.get('window').height / 2, flexBasis: 'auto' } },
            !loading && (0, lodash_1.isEmpty)(subforms) && (react_1.default.createElement(components_1.MBEmptyListPlaceholder, { title: (0, i18n_1.i18n)('workflowTemplate.subformsEmptyStateTitle'), description: (0, i18n_1.i18n)('workflowTemplate.subformsEmptyStateDescription'), image: require('@shared/assets/icons/v2/check-todo.png'), imageSize: (0, components_1.grid)(12) })),
            loading && (react_1.default.createElement(react_native_1.View, { style: {
                    height: 150,
                    alignItems: 'center',
                    justifyContent: 'center',
                } },
                react_1.default.createElement(components_1.MBLoadingIndicator, null))),
            !(0, lodash_1.isNil)(workflowTemplateId) &&
                (subforms === null || subforms === void 0 ? void 0 : subforms.map(function (_a) {
                    var subformWorkflowTemplate = _a.workflowTemplate, componentId = _a.componentId;
                    return (react_1.default.createElement(SubformItem_1.SubformItem, { key: subformWorkflowTemplate === null || subformWorkflowTemplate === void 0 ? void 0 : subformWorkflowTemplate.id, componentId: componentId, subformWorkflowTemplate: subformWorkflowTemplate, channelSlug: channelSlug, mainWorkflowTemplateId: workflowTemplateId, isSelectionMode: isSelectionMode, toggleSelection: handleToggleSelection, isSelected: !(0, lodash_1.isNil)(selectedSubforms.find(function (sf) { return sf.id === subformWorkflowTemplate.id; })) }));
                })),
            hasMore && (react_1.default.createElement(react_native_1.View, { style: { paddingVertical: (0, components_1.grid)(3) } },
                react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('card.list.showMore'), size: "small", theme: "transparent", bold: false, borderColor: styles_1.Colors.v2.gray10, onPress: loadMore, style: { alignSelf: 'center' } }))))));
}
// TBD SelectionControls, BulkActions, BulkActionsOptionsWrapper
// are a generic multi-selection menu set.
// the menus spawned by the action button should be children of BulkActionsOptionsWrapper
// and include parentRef, onClose, onSelect as props. See ./WorkflowTemplatesList.tsx for an example.
// TODO move this somewhere else
function SelectionControls(_a) {
    var selectedItems = _a.selectedItems, setSelectedItems = _a.setSelectedItems, MenuItems = _a.MenuItems, children = _a.children;
    var _b = (0, react_1.useState)(false), showActions = _b[0], setShowActions = _b[1];
    return (react_1.default.createElement(components_1.MBView, { flex: true, centerVertically: true, row: true },
        react_1.default.createElement(react_native_1.View, { style: { marginRight: (0, components_1.grid)(1) } },
            react_1.default.createElement(Button_1.Button, { title: (0, i18n_1.i18n)('card.selection.xSelected', { count: selectedItems.length }), active: true, onClick: function () { }, style: { marginLeft: 0 } })),
        react_1.default.createElement(BulkActions, { showActions: showActions, setShowActions: setShowActions, MenuItems: MenuItems, selectedCount: selectedItems.length }, children),
        react_1.default.createElement(react_native_1.View, { style: { marginLeft: (0, components_1.grid)(1) } },
            react_1.default.createElement(Button_1.Button, { title: (0, i18n_1.i18n)('common.cancel'), onClick: function () { return setSelectedItems([]); } }))));
}
function BulkActions(_a) {
    var showActions = _a.showActions, setShowActions = _a.setShowActions, MenuItems = _a.MenuItems, children = _a.children, selectedCount = _a.selectedCount;
    var containerRef = (0, react_1.useRef)(null);
    var childrenWithProps = react_1.default.Children.map(children, function (child) {
        return react_1.default.cloneElement(child, {
            actionButtonRef: containerRef,
            onSelect: function () {
                setShowActions(false);
            },
            onClose: function () {
                setShowActions(false);
            },
            selectedCount: selectedCount,
        });
    });
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.View, null,
            react_1.default.createElement(components_1.MBButton, { onPress: function () { return setShowActions(true); }, rightIcon: "arrow-point-down-heavy", rightIconStyle: { paddingLeft: (0, components_1.grid)(1.5) }, rightIconSize: (0, components_1.grid)(2), theme: "normal", title: (0, i18n_1.i18n)('common.actions'), style: { paddingLeft: (0, components_1.grid)(3), paddingVertical: (0, components_1.grid)(2.5), borderRadius: (0, components_1.grid)(2.75) } }),
            react_1.default.createElement(react_native_1.View, { ref: containerRef }),
            showActions && (react_1.default.createElement(components_1.MBBottomSheet, { containerRef: containerRef, initialSize: "full", layout: {
                    offset: { x: 0, y: (0, components_1.grid)(1) },
                    minWidth: 320,
                    maxWidth: 320,
                }, renderTitleBar: function () {
                    return null;
                }, onClose: function () { return setShowActions(false); } }, MenuItems))),
        childrenWithProps));
}
function BulkActionsOptionsWrapper(_a) {
    var actionButtonRef = _a.actionButtonRef, onClose = _a.onClose, onSelect = _a.onSelect, children = _a.children, selectedCount = _a.selectedCount;
    var childrenWithRef = react_1.Children.map(children, function (child) {
        if (!(0, react_1.isValidElement)(child)) {
            return null;
        }
        return (0, react_1.cloneElement)(child, {
            parentRef: actionButtonRef,
            onClose: function () {
                var _a, _b;
                onClose === null || onClose === void 0 ? void 0 : onClose();
                (_b = (_a = child.props).onClose) === null || _b === void 0 ? void 0 : _b.call(_a);
            },
            onSelect: function (subform) {
                var _a, _b;
                onSelect === null || onSelect === void 0 ? void 0 : onSelect(subform);
                (_b = (_a = child.props).onSelect) === null || _b === void 0 ? void 0 : _b.call(_a, subform);
            },
            selectedCount: selectedCount,
        });
    });
    return react_1.default.createElement(react_1.default.Fragment, null, childrenWithRef);
}
function useCopySubformsToWorkflow() {
    var _this = this;
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var createWorkflowTemplates = (0, client_1.useMutation)(api_1.Mutations.workflow.createWorkflowTemplates)[0];
    var updateWorkflowTemplate = (0, hooks_1.useUpdateWorkflowTemplate)()[0];
    return function (subformWorkflowTemplates, workflowTemplateId, cb) { return __awaiter(_this, void 0, void 0, function () {
        var subformWorkflowTemplateData, workflowTemplate, hasSubformComponent;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, createWorkflowTemplates({
                        variables: {
                            input: {
                                workflowTemplates: subformWorkflowTemplates.map(function (subformWorkflowTemplate) {
                                    var _a, _b;
                                    return ({
                                        isSubform: subformWorkflowTemplate.isSubform,
                                        name: subformWorkflowTemplate.name,
                                        requiresName: subformWorkflowTemplate.requiresName,
                                        components: (_a = subformWorkflowTemplate.components) === null || _a === void 0 ? void 0 : _a.map(workflow_1.toComponentOneOf),
                                        archivedComponents: (_b = subformWorkflowTemplate.archivedComponents) === null || _b === void 0 ? void 0 : _b.map(workflow_1.toComponentOneOf),
                                    });
                                }),
                            },
                        },
                    })];
                case 1:
                    subformWorkflowTemplateData = (_b.sent()).data;
                    if ((0, lodash_1.isNil)(subformWorkflowTemplateData)) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, (0, hooks_1.getLatestWorkflowTemplate)(workflowTemplateId)];
                case 2:
                    workflowTemplate = (_b.sent()).workflowTemplate;
                    hasSubformComponent = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.some(hooks_1.isSubformComponent);
                    if ((0, lodash_1.isNil)(workflowTemplate)) {
                        return [2 /*return*/];
                    }
                    if (!hasSubformComponent) return [3 /*break*/, 4];
                    return [4 /*yield*/, updateWorkflowTemplate(__assign(__assign({}, workflowTemplate), { components: workflowTemplate.components.map(function (component) {
                                if (!(0, hooks_1.isSubformComponent)(component)) {
                                    return component;
                                }
                                return __assign(__assign({}, component), { workflowTemplateIds: __spreadArray(__spreadArray([], component.workflowTemplateIds, true), subformWorkflowTemplateData.createWorkflowTemplates.map(function (sf) { return sf.id; }), true) });
                            }) }))];
                case 3:
                    _b.sent();
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, updateWorkflowTemplate(__assign(__assign({}, workflowTemplate), { components: __spreadArray(__spreadArray([], ((_a = workflowTemplate.components) !== null && _a !== void 0 ? _a : []), true), [
                            {
                                id: uuid.v4(),
                                type: 'SUBFORM',
                                icon: (0, ComponentOptionDefaults_1.getIconForComponentType)('SUBFORM'),
                                label: (0, ComponentOptionDefaults_1.getLabelForComponentType)('SUBFORM'),
                                placeholder: (0, ComponentOptionDefaults_1.getLabelForComponentType)('SUBFORM'),
                                required: false,
                                workflowTemplateIds: __spreadArray([], subformWorkflowTemplateData.createWorkflowTemplates.map(function (sf) { return sf.id; }), true),
                            },
                        ], false) }))];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6:
                    toast.show({
                        icon: 'check-filled',
                        message: (0, i18n_1.i18n)('workflowTemplate.subformOptions.confirmationMessage', {
                            count: subformWorkflowTemplates.length,
                            subform: subformWorkflowTemplates[0].name,
                            workflowTemplate: workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.name,
                        }),
                    });
                    cb === null || cb === void 0 ? void 0 : cb();
                    return [2 /*return*/];
            }
        });
    }); };
}
