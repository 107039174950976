"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroups = UserGroups;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var use_debounce_1 = require("use-debounce");
var components_1 = require("@shared/components");
var button_1 = require("@shared/components/button");
var searchBar_1 = require("@shared/components/searchBar");
var view_1 = require("@shared/components/view");
var MBView_1 = require("@shared/components/view/MBView");
var UserGroupItem_1 = require("@shared/scenes/userGroups/UserGroupItem");
var useListUserGroups_1 = require("@shared/scenes/userGroups/hooks/useListUserGroups");
var colors_1 = require("@shared/styles/colors");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
var AddUserGroup_1 = require("./AddUserGroup");
var useCreateUserGroup_1 = require("./hooks/useCreateUserGroup");
function UserGroups() {
    var _this = this;
    var _a = (0, react_1.useState)(''), _query = _a[0], setQuery = _a[1];
    var query = (0, use_debounce_1.useDebounce)(_query, 300)[0];
    var _b = (0, useListUserGroups_1.useListUserGroups)({
        name: !(0, lodash_1.isEmpty)(query) ? query.trim() : undefined,
    }), userGroups = _b.userGroups, hasMore = _b.hasMore, loading = _b.loading, loadMore = _b.loadMore;
    var hasUserGroups = !(0, lodash_1.isNil)(userGroups) && userGroups.length > 0 && !loading;
    var createUserGroup = (0, useCreateUserGroup_1.useCreateUserGroup)();
    var navigator = (0, navigation_1.useNavigator)();
    var profile = (0, hooks_1.useProfile)().profile;
    var isBusinessAdmin = profile === null || profile === void 0 ? void 0 : profile.isBusinessAdmin;
    // TODO hook keyword to group query
    var handleAddGroup = function (name) { return __awaiter(_this, void 0, void 0, function () {
        var group;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createUserGroup(name)];
                case 1:
                    group = _a.sent();
                    if (!(0, lodash_1.isNil)(group)) {
                        navigator.navigate('UserGroupsProfile', { groupId: group.id });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handlePressGroup = function (group) {
        navigator.navigate('UserGroupsProfile', { groupId: group.id });
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(MBView_1.MBView, { paddingHorizontal: "small", paddingTop: "small" },
            react_1.default.createElement(searchBar_1.MBSearchBar, { onChangeText: function (val) { return setQuery(val); }, placeholder: (0, i18n_1.i18n)('userGroups.search'), value: query })),
        react_1.default.createElement(AddUserGroup_1.AddUserGroup, { onAddGroup: handleAddGroup }),
        loading && react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true, center: true }),
        !hasUserGroups && !loading && (react_1.default.createElement(MBView_1.MBView, { centerHorizontally: true, paddingVertical: 2 },
            react_1.default.createElement(components_1.MBTextBody1, null, (0, i18n_1.i18n)('userGroups.emptyState')))),
        hasUserGroups && (react_1.default.createElement(react_native_1.ScrollView, { style: { paddingBottom: (0, view_1.grid)(2), flex: 1, alignSelf: 'stretch' } },
            userGroups.map(function (group) {
                return (react_1.default.createElement(UserGroupItem_1.UserGroupItem, { key: group.id, group: group, isBusinessAdmin: isBusinessAdmin !== null && isBusinessAdmin !== void 0 ? isBusinessAdmin : false, onPress: function () { return handlePressGroup(group); } }));
            }),
            hasMore && (react_1.default.createElement(MBView_1.MBView, { centerHorizontally: true, paddingVertical: 2 },
                react_1.default.createElement(button_1.MBButton, { title: (0, i18n_1.i18n)('userGroups.loadMore'), onPress: loadMore, size: "small", titleColor: colors_1.Colors.v2.gray60, borderColor: colors_1.Colors.v2.gray10, theme: "transparent" })))))));
}
