"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSearchResultRowContent = MBSearchResultRowContent;
exports.MBSearchResultRow = MBSearchResultRow;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var config_1 = require("@shared/config");
var util_1 = require("@shared/scenes/search/util");
var styles_1 = require("@shared/styles");
var sentry_1 = require("@shared/util/sentry");
/*
    This content formats the actual content of a result row.
    If the highlight is later in a message, it will trim the
    start of the message and add a "..." ellipsis.

    Note: this component is unit tested.
*/
function MBSearchResultRowContent(_a) {
    var prefix = _a.prefix, content = _a.content, highlight = _a.highlight, _b = _a.clipLongContent, clipLongContent = _b === void 0 ? true : _b;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var start; // start of the highlight
    var end; // end of the highlight
    var text; // actual text being renderd
    var maxBefore = config_1.Config.SEARCH_RESULT_NUM_CHARS_BEFORE_MATCH_TO_SHOW;
    // If we have more chars before the highlight starts, just keep ${maxBefore}
    // chars and add "..." before.
    // Note(fant): we need to check that the highlight is set. The reason
    // is that the backend's highlighting logic doesn't work for certain
    // queries. For eg "s a" ElasticSearch returns everything containing
    // "s" or "a". However, since indexOf("s a") does not exist for most
    // matches, the search result matches we get from the backend will be
    // empty.
    if (!highlight) {
        start = 0;
        end = 0;
        text = "".concat(prefix).concat(content);
    }
    else if (highlight.start > maxBefore && clipLongContent) {
        var ellipsis = '... ';
        // The start and end of the highlight need to be recalculated
        // since we remove (highlight.start - maxBefore) chars and add
        // (ellipsis.length) chars
        start = maxBefore + prefix.length + ellipsis.length;
        end = start + (highlight.end - highlight.start);
        // We add the prefix (as usual), then the ellipsis, and after that the
        // actual string, where we've removed leading chars.
        text = "".concat(prefix).concat(ellipsis).concat(content.slice(highlight.start - maxBefore));
    }
    else {
        start = highlight.start + prefix.length;
        end = highlight.end + prefix.length;
        text = "".concat(prefix).concat(content);
    }
    return (react_1.default.createElement(components_1.MBHighlightedText, { children: text, numberOfLines: 2, range: { start: start, end: end }, color: colors.foregroundInactive }));
}
function MBSearchResultRow(_a) {
    var title = _a.title, result = _a.result, onPress = _a.onPress, _b = _a.prefix, prefix = _b === void 0 ? '' : _b;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var handlePress = react_1.default.useCallback(function () { return onPress(result); }, [onPress, result]);
    var content = (function () {
        if (!!result.highlights.urls.length && !!result.highlights.content.length) {
            // Note(fant): when displaying a url, we show it wrapped in
            // the actual message content. The difference between
            // this and a regular message is that we have the link icon.
            return {
                icon: require('@shared/assets/icons/v2/link.png'),
                string: result.highlights.content[0].content,
                highlight: result.highlights.content[0].highlightPositions[0],
            };
        }
        if (!!result.highlights.attachments.length) {
            var fileName = result.highlights.attachments[0].content;
            var extension = fileName
                .split('.')
                .reverse()[0] // get part after "." (or everything if not found)
                .toLowerCase(); // make sure we're always comparing using lowercase
            // If the filename ends with an image extension, show an image icon
            var icon = ['webp', 'jpg', 'jpeg', 'png', 'gif', 'heic'].includes(extension)
                ? require('@shared/assets/icons/v2/image-small.png')
                : require('@shared/assets/icons/v2/file-small.png');
            // TODO(fant): check attachment extension to see if it's an image
            return {
                icon: icon,
                string: fileName,
                highlight: result.highlights.attachments[0].highlightPositions[0],
                clipLongContent: false,
            };
        }
        if (!!result.highlights.content.length) {
            return {
                icon: !!result.cardId ? require('@shared/assets/icons/v2/card-small.png') : null,
                string: result.highlights.content[0].content,
                highlight: result.highlights.content[0].highlightPositions[0],
            };
        }
        if (!!result.highlights.name.length) {
            return {
                icon: require('@shared/assets/icons/v2/card-small.png'),
                string: result.highlights.name[0].content,
                highlight: result.highlights.name[0].highlightPositions[0],
            };
        }
        sentry_1.MBSentry.exception({
            error: new Error("Unknown search result: ".concat(JSON.stringify(result))),
        });
        return null;
    })();
    if ((0, lodash_1.isNil)(content)) {
        return null;
    }
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: handlePress },
        react_1.default.createElement(components_1.MBView, { paddingLeft: "normal", padding: "small", style: { backgroundColor: colors.background } },
            react_1.default.createElement(components_1.MBView, { row: true, paddingBottom: "tiny" },
                react_1.default.createElement(components_1.MBText, { size: "large", color: colors.foregroundActive }, title),
                react_1.default.createElement(components_1.MBView, { flex: true }),
                react_1.default.createElement(components_1.MBText, { color: colors.foregroundActive }, (0, util_1.toSearchResultDateString)(result.date))),
            react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true },
                !!content.icon && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(components_1.MBIcon, { color: colors.foregroundInactive, noPadding: true, size: "small", source: content.icon }),
                    react_1.default.createElement(components_1.MBView, { paddingLeft: "small" }))),
                react_1.default.createElement(MBSearchResultRowContent, { prefix: prefix, content: content.string, highlight: content.highlight, clipLongContent: content.clipLongContent })))));
}
