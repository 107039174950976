"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatInput = void 0;
var lodash_1 = require("lodash");
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var index_1 = require("@shared/scenes/chat/input/util/index");
var analytics_1 = require("@shared/util/analytics");
var bar_1 = require("../containers/bar");
var gifModal_1 = require("../containers/gifModal");
var urgentBanner_1 = require("../containers/urgentBanner");
var withAnimation_1 = require("./mention/list/withAnimation");
var styles_1 = require("./styles");
var MBChatInput = /** @class */ (function (_super) {
    __extends(MBChatInput, _super);
    function MBChatInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            value: _this.props.initialValue || '',
            cursorPosition: !!_this.props.initialValue ? _this.props.initialValue.length : 0,
            users: [],
            groups: [],
            numUrgentRecipients: 0,
        };
        _this.inputBar = React.createRef();
        /*
              When we click on a mention, we replace the
              current mention word with the full mention.
              TODO(fant): add unit tests for this logic.
          */
        _this.onAddMention = function (mention) {
            var _a = index_1.MBChatInputUtil.addMention(_this.state.value, _this.state.cursorPosition, mention), string = _a.string, cursorPosition = _a.cursorPosition;
            _this.setState({ cursorPosition: cursorPosition, value: string });
            if (!!_this.props.onChangeText) {
                _this.props.onChangeText(string);
            }
        };
        _this.onPressUser = function (user) {
            var _a;
            analytics_1.MBAnalytics.track('Chat::Input::Mention::PressUser', {
                id: user.id,
                draftId: _this.props.draftId,
            });
            _this.onAddMention(user.username);
            (_a = _this.inputBar.current) === null || _a === void 0 ? void 0 : _a.focus();
        };
        _this.onPressGroup = function (group) {
            var _a;
            analytics_1.MBAnalytics.track('Chat::Input::Mention::PressGroup', {
                id: group.id,
                draftId: _this.props.draftId,
            });
            _this.onAddMention(group.id);
            (_a = _this.inputBar.current) === null || _a === void 0 ? void 0 : _a.focus();
        };
        _this.onChangeText = function (value) {
            _this.setState({ value: value });
            if (!!_this.props.onChangeText) {
                _this.props.onChangeText(value);
            }
        };
        _this.onAddEmoji = function (emoji) {
            var before = _this.state.value.substr(0, _this.state.cursorPosition);
            var after = _this.state.value.substr(_this.state.cursorPosition);
            var value = [before, emoji, after].join('');
            _this.onChangeText(value);
            _this.setState({ cursorPosition: _this.state.cursorPosition + emoji.length });
        };
        _this.onCursorPositionChange = function (cursorPosition) { return _this.setState({ cursorPosition: cursorPosition }); };
        _this.onSend = function () {
            _this.props.onSend(_this.state.value);
            _this.setState({ value: '', cursorPosition: 0 });
            if (!!_this.props.onChangeText) {
                _this.props.onChangeText('');
            }
        };
        _this.onCancelEdit = function () {
            _this.setState({ value: '' });
            _this.props.onCancelEdit();
        };
        return _this;
    }
    /*
          When the text value or the cursor position changes, we update
          what mentions are visible. Only users and groups (eg @all)
          are visible if their name/username matches the current
          mention query.
      */
    MBChatInput.prototype.updateMentions = function () {
        var query = index_1.MBChatInputUtil.mentionQuery(this.state.value, this.state.cursorPosition);
        if (query === undefined) {
            this.setState({ users: [], groups: [] });
            return;
        }
        if (query === '') {
            this.setState({ users: this.props.users, groups: this.props.groups });
            return;
        }
        var users = this.props.users.filter(function (u) { return (0, lodash_1.includes)(u.username, query); });
        var groups = this.props.groups.filter(function (g) { return (0, lodash_1.includes)(g.id, query); });
        this.setState({ users: users, groups: groups });
    };
    MBChatInput.prototype.updateNumUrgentRecipients = function () {
        var numUrgentRecipients = index_1.MBChatInputUtil.numUrgentRecipients(this.state.value, this.props.users);
        this.setState({ numUrgentRecipients: numUrgentRecipients });
    };
    MBChatInput.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (prevState.value !== this.state.value ||
            prevState.cursorPosition !== this.state.cursorPosition ||
            prevProps.users !== this.props.users ||
            prevProps.groups !== this.props.groups) {
            this.updateMentions();
        }
        if (prevState.value !== this.state.value ||
            prevProps.users !== this.props.users ||
            prevProps.groups !== this.props.groups) {
            // Note(fant): we only recalculate this when we have to.
            // We don't want to do this every time in render
            this.updateNumUrgentRecipients();
        }
    };
    MBChatInput.prototype.componentWillUnmount = function () {
        if (!!this.props.onUnmount) {
            this.props.onUnmount(this.state.value);
        }
    };
    MBChatInput.prototype.render = function () {
        var _a;
        var _b = this.state, users = _b.users, groups = _b.groups, numUrgentRecipients = _b.numUrgentRecipients;
        return (React.createElement(components_1.MBView, { paddingHorizontal: 2 },
            React.createElement(withAnimation_1.MBChatInputMentionListWithAnimation, { style: styles_1.Styles.mentionListPosition, users: users, groups: groups, onPressUser: this.onPressUser, onPressGroup: this.onPressGroup }),
            React.createElement(bar_1.MBChatInputBar, { innerRef: this.inputBar, draftId: this.props.draftId, autoFocus: this.props.autoFocus, isEditing: (_a = this.props.isEditing) !== null && _a !== void 0 ? _a : false, hideUrgent: this.props.hideUrgent, value: this.state.value, placeholder: this.props.placeholder, onSend: this.onSend, onCancelEdit: this.onCancelEdit, onBlur: this.props.onBlur, onFocus: this.props.onFocus, onChangeText: this.onChangeText, onSubmitEditing: this.onSend, onCursorPositionChange: this.onCursorPositionChange, onAddEmoji: this.onAddEmoji }),
            numUrgentRecipients > 0 && (React.createElement(urgentBanner_1.MBUrgentBanner, { draftId: this.props.draftId, count: numUrgentRecipients })),
            React.createElement(gifModal_1.MBChatInputGIFModal, { draftId: this.props.draftId })));
    };
    return MBChatInput;
}(React.PureComponent));
exports.MBChatInput = MBChatInput;
