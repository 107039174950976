"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWindowSize = useWindowSize;
exports.useIsDesktop = useIsDesktop;
exports.useIsMobile = useIsMobile;
var react_1 = require("react");
var react_native_1 = require("react-native");
var DESKTOP_VIEWPORT_WIDTH_THRESHOLD = 840;
function useWindowSize() {
    var _a = (0, react_1.useState)(getWindowSize), windowSize = _a[0], setWindowSize = _a[1];
    (0, react_1.useEffect)(function () {
        if (react_native_1.Platform.OS !== 'web') {
            return;
        }
        var handleResize = function () { return setWindowSize(getWindowSize()); };
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return windowSize;
}
function getWindowSize() {
    return {
        width: react_native_1.Platform.OS === 'web' ? window.innerWidth : react_native_1.Dimensions.get('screen').width,
        height: react_native_1.Platform.OS === 'web' ? window.innerHeight : react_native_1.Dimensions.get('screen').height,
    };
}
function useIsDesktop() {
    var width = useWindowSize().width;
    return react_native_1.Platform.OS === 'web' && width >= DESKTOP_VIEWPORT_WIDTH_THRESHOLD;
}
function useIsMobile() {
    var width = useWindowSize().width;
    return react_native_1.Platform.OS !== 'web' || width < DESKTOP_VIEWPORT_WIDTH_THRESHOLD;
}
