"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBDateCell = MBDateCell;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var animation_1 = require("@shared/components/animation");
var typography_1 = require("@shared/components/typography");
var styles_1 = require("@shared/styles");
var ChannelPermissionsContext_1 = require("@shared/util/permissions/ChannelPermissionsContext");
var hooks_1 = require("@shared/util/permissions/hooks");
var context_1 = require("../../../context");
function MBDateCell(_a) {
    var style = _a.style, today = _a.today, stale = _a.stale, label = _a.label, onCreateCard = _a.onCreateCard;
    var _b = (0, react_1.useContext)(context_1.CardCalendarContext), shouldScrollTodayIntoView = _b.shouldScrollTodayIntoView, onScrolledTodayIntoView = _b.onScrolledTodayIntoView;
    var containerRef = (0, react_1.useRef)();
    (0, react_1.useEffect)(function () {
        if (today && shouldScrollTodayIntoView) {
            containerRef.current.scrollIntoView();
            onScrolledTodayIntoView();
        }
    }, [onScrolledTodayIntoView, shouldScrollTodayIntoView, today]);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var channelId = (0, react_1.useContext)(ChannelPermissionsContext_1.ChannelPermissionsContext).channelId;
    var createCardPermission = (0, hooks_1.usePermission)({ action: 'create', subject: 'Card', channelId: channelId });
    var content = (0, react_1.useMemo)(function () {
        if (today) {
            return (react_1.default.createElement(components_1.MBView, { style: [styles.number, { backgroundColor: colors.accent }] },
                react_1.default.createElement(typography_1.MBTextBody2Bold, { align: "center", color: colors.background }, label)));
        }
        return (react_1.default.createElement(components_1.MBView, { style: styles.number },
            react_1.default.createElement(typography_1.MBTextBody2, { align: "center", color: stale ? colors.foregroundInactive : colors.foregroundActive }, label)));
    }, [today, stale, label, colors]);
    var mergedStyles = (0, react_1.useMemo)(function () { return (__assign(__assign({}, style), { borderTopColor: styles_1.Colors.v2.gray10, borderTopWidth: 1, borderTopStyle: 'solid', borderRightColor: styles_1.Colors.v2.gray10, borderRightWidth: 1, borderRightStyle: 'solid' })); }, [style]);
    return (react_1.default.createElement("div", { style: mergedStyles, ref: containerRef },
        react_1.default.createElement(components_1.MBHover, { style: styles.container, hoverContent: createCardPermission.enabled && (react_1.default.createElement(components_1.MBTouchableOpacity, { style: styles.addButton, onPress: onCreateCard },
                react_1.default.createElement(animation_1.MBAnimationFadeIn, { duration: 250 },
                    react_1.default.createElement(components_1.MBIconV2, { name: "plus-circle", color: styles_1.Colors.v2.gray30, size: 30 })))) }, content)));
}
var styles = react_native_1.StyleSheet.create({
    number: {
        width: (0, components_1.grid)(3),
        height: (0, components_1.grid)(3),
        borderRadius: (0, components_1.grid)(1.5),
        // Offset 50% of the difference between the fontSize and lineHeight of TypeComponent
        paddingTop: 3,
        marginHorizontal: 'auto',
    },
    container: {
        padding: (0, components_1.grid)(0.5),
    },
    addButton: {
        position: 'absolute',
        top: 4,
        right: 4,
    },
});
