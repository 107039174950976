"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationButton = NotificationButton;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var styles_2 = require("./styles");
function NotificationButton(_a) {
    var name = _a.name, onPress = _a.onPress, count = _a.count;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { style: [
            styles_2.Styles.alignTopRight,
            styles_2.Styles.rightNotificationButton,
            styles_1.SharedStyles.shadow,
            {
                backgroundColor: colors.input.backgroundActive,
                marginTop: (0, components_1.grid)(1.35),
                borderColor: colors.input.borderInactive,
                borderWidth: 1,
            },
        ], onPress: onPress, testID: "notification-button" },
        react_1.default.createElement(components_1.MBView, { flex: true, centerVertically: true, centerHorizontally: true },
            react_1.default.createElement(components_1.MBIconV2, { name: name, color: colors.accent })),
        count > 0 && (react_1.default.createElement(components_1.MBNotificationBadge, { count: count, style: [styles_2.Styles.alignTopRight, styles_2.Styles.notificationBadge] }))));
}
