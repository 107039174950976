"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildFieldRecord = buildFieldRecord;
var CardField_1 = require("@shared/data/cards/CardField");
function buildFieldRecord(_a) {
    var apiCard = _a.apiCard, components = _a.components, refs = _a.refs, defaultData = _a.defaultData, currentUser = _a.currentUser;
    return components.reduce(function (record, component) {
        record[component.id] = (0, CardField_1.buildCardField)({ apiCard: apiCard, component: component, refs: refs, defaultData: defaultData, currentUser: currentUser });
        return record;
    }, {});
}
