"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCollapsedSections = useCollapsedSections;
exports.useWorkspaceSections = useWorkspaceSections;
exports.useWorkspaceSection = useWorkspaceSection;
exports.useChannelsRefetch = useChannelsRefetch;
exports.useChannelSections = useChannelSections;
exports.useAllCardsRowSelected = useAllCardsRowSelected;
exports.useDashboardRowSelected = useDashboardRowSelected;
exports.useUnreadCount = useUnreadCount;
exports.useShowChannelSectionOptions = useShowChannelSectionOptions;
exports.useUpdateWorkspaceSection = useUpdateWorkspaceSection;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
var ChannelCreateActions = __importStar(require("@shared/scenes/channel/create/store/actions"));
var hook_1 = require("@shared/util/actionSheet/hook");
var alert_1 = require("@shared/util/alert");
var useRefetchOnReconnect_1 = require("@shared/util/apollo/useRefetchOnReconnect");
var launchDarkly_1 = require("@shared/util/featureFlags/launchDarkly");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
var notNil_1 = require("@shared/util/notNil");
var useLocalStorage_1 = require("@shared/util/storage/useLocalStorage");
var hooks_2 = require("../options/hooks");
function addFirstChannelMessageToThread(client, channel) {
    try {
        /*
          Whenever we get a new message we push it to the channel's message
          list in the Apollo cache. When we open the chat thread, the message
          will already be loaded and the user doesn't have to wait. This logic
          should ideally be tested.
        */
        // In the channel that updated, get the first (and only) message
        var message_1 = channel.messages[0];
        var data = client.readQuery({
            query: api_1.Queries.channel.chatThread,
            variables: { slug: channel.slug },
        });
        // If we have a message and a cached version of the channel...
        if (!!message_1 && !!(data === null || data === void 0 ? void 0 : data.channel)) {
            // And the message isn't already in the channel's message list, add it
            var isMessageAlreadyAdded = data.channel.messages.some(function (m) { return m.id === message_1.id; });
            if (!isMessageAlreadyAdded) {
                client.writeQuery({
                    data: __assign(__assign({}, data), { channel: __assign(__assign({}, data.channel), { messages: __spreadArray([message_1], data.channel.messages, true) }) }),
                    query: api_1.Queries.channel.chatThread,
                    variables: { slug: channel.slug },
                });
            }
        }
    }
    catch (e) {
        // Apollo just throws if readQuery has no cache to read from
    }
}
function addChannelToListIfNotExists(channel, data) {
    var _a, _b, _c, _d;
    if ((0, lodash_1.isNil)((_b = (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.channels)) {
        return data;
    }
    if (channel.type === 'LINK') {
        return data;
    }
    var isAdded = (_d = (_c = data === null || data === void 0 ? void 0 : data.me) === null || _c === void 0 ? void 0 : _c.business) === null || _d === void 0 ? void 0 : _d.channels.some(function (c) { return (c === null || c === void 0 ? void 0 : c.id) === channel.id; });
    if (isAdded) {
        return data;
    }
    return __assign(__assign({}, data), { me: __assign(__assign({}, data.me), { business: __assign(__assign({}, data.me.business), { channels: __spreadArray(__spreadArray([], data.me.business.channels, true), [channel], false) }) }) });
}
function usePermissionsRefetch() {
    var refetchInterval = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.PERMISSIONS_REFETCH_TIMER);
    var _a = (0, react_1.useState)(0), lastRefetch = _a[0], setLastRefetch = _a[1];
    var _b = (0, react_1.useState)(false), hasScheduledRefetch = _b[0], setHasScheduledRefetch = _b[1];
    var refetch = (0, react_1.useCallback)(function (client) {
        client.query({
            query: api_1.Queries.user.userGlobalPermissions,
            fetchPolicy: 'network-only',
        });
    }, []);
    var experiment = (0, react_1.useCallback)(function (client) {
        var currentTime = Date.now();
        var timeSinceLastRefetch = currentTime - lastRefetch;
        if (timeSinceLastRefetch >= refetchInterval && !hasScheduledRefetch) {
            setLastRefetch(currentTime);
            refetch(client);
        }
        else if (!hasScheduledRefetch) {
            setHasScheduledRefetch(true);
            setTimeout(function () {
                setLastRefetch(Date.now());
                setHasScheduledRefetch(false);
                refetch(client);
            }, refetchInterval - timeSinceLastRefetch);
        }
    }, [lastRefetch, refetchInterval, hasScheduledRefetch, refetch]);
    if (refetchInterval === 0 || (0, lodash_1.isNil)(refetchInterval)) {
        return refetch;
    }
    return experiment;
}
function useChannelUpdatedSubscription(businessId) {
    var refetchPermissions = usePermissionsRefetch();
    (0, client_1.useSubscription)(api_1.Subscriptions.channel.updated, {
        variables: { businessId: businessId },
        onData: (0, react_1.useCallback)(function (_a) {
            var client = _a.client, data = _a.data;
            var channel = data.data.anyChannelUpdated.channel;
            var channelListData = client.readQuery({
                query: api_1.Queries.channel.list,
                variables: { businessId: businessId },
            });
            var nextChannelListData = addChannelToListIfNotExists(channel, channelListData);
            if (nextChannelListData !== channelListData) {
                client.writeQuery({
                    data: nextChannelListData,
                    query: api_1.Queries.channel.list,
                    variables: { businessId: businessId },
                });
            }
            addFirstChannelMessageToThread(client, channel);
            client.writeQuery({
                data: { channel: channel },
                query: api_1.Queries.channel.getChannel,
                variables: { slug: channel.slug },
            });
            refetchPermissions(client);
        }, [businessId, refetchPermissions]),
        skip: (0, lodash_1.isNil)(businessId),
    });
}
function useCollapsedSections() {
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var _a = (0, useLocalStorage_1.useLocalStorage)("collapsedSections:".concat(businessId), []), collapsedSectionIds = _a[0], update = _a[1];
    var toggle = (0, react_1.useCallback)(function (id) {
        if (collapsedSectionIds.includes(id)) {
            update((0, lodash_1.without)(collapsedSectionIds, id));
        }
        else {
            update(__spreadArray(__spreadArray([], collapsedSectionIds, true), [id], false));
        }
    }, [collapsedSectionIds, update]);
    return (0, react_1.useMemo)(function () { return ({ collapsedSectionIds: collapsedSectionIds, toggle: toggle }); }, [collapsedSectionIds, toggle]);
}
function useWorkspaceSections() {
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var _a = (0, client_1.useQuery)(api_1.Queries.channel.listWorkspaceSections, { skip: !businessId }), data = _a.data, refetch = _a.refetch, subscribeToMore = _a.subscribeToMore;
    (0, react_1.useEffect)(function () {
        if (!businessId) {
            return;
        }
        var unsubscribe = subscribeToMore({
            document: api_1.Subscriptions.channel.workspaceSectionChanged,
            updateQuery: function (prev, _a) {
                var subscriptionData = _a.subscriptionData;
                if (!(subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.data)) {
                    return prev;
                }
                return { listWorkspaceSections: subscriptionData.data.workspaceSectionChanged };
            },
        });
        return unsubscribe;
    }, [businessId, subscribeToMore]);
    return (0, react_1.useMemo)(function () {
        var _a;
        return ({
            workspaceSections: (_a = data === null || data === void 0 ? void 0 : data.listWorkspaceSections) !== null && _a !== void 0 ? _a : [],
            refetch: refetch,
        });
    }, [data === null || data === void 0 ? void 0 : data.listWorkspaceSections, refetch]);
}
function useWorkspaceSection(id) {
    var workspaceSections = useWorkspaceSections().workspaceSections;
    return (0, react_1.useMemo)(function () { return workspaceSections === null || workspaceSections === void 0 ? void 0 : workspaceSections.find(function (section) { return section.id === id; }); }, [workspaceSections, id]);
}
/**
 * A hook that returns a function to refetch the queries used in useChannels()
 */
function useChannelsRefetch() {
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var refetch = (0, client_1.useQuery)(api_1.Queries.channel.list, { variables: { businessId: businessId !== null && businessId !== void 0 ? businessId : -1 }, skip: !businessId }).refetch;
    var refetchWorkspace = (0, client_1.useQuery)(api_1.Queries.channel.listWorkspaceSections, { skip: !businessId }).refetch;
    return (0, react_1.useCallback)(function () {
        refetch();
        refetchWorkspace();
    }, [refetch, refetchWorkspace]);
}
function useChannelSections() {
    var _a, _b;
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var _c = (0, client_1.useQuery)(api_1.Queries.channel.list, { variables: { businessId: businessId !== null && businessId !== void 0 ? businessId : -1 }, skip: !businessId }), data = _c.data, loading = _c.loading, refetch = _c.refetch;
    var _d = useWorkspaceSections(), workspaceSections = _d.workspaceSections, refetchSections = _d.refetch;
    useChannelUpdatedSubscription(businessId !== null && businessId !== void 0 ? businessId : -1);
    (0, useRefetchOnReconnect_1.useRefetchOnReconnect)((0, react_1.useCallback)(function () {
        refetch();
        refetchSections();
    }, [refetch, refetchSections]));
    var allChannels = (0, react_1.useMemo)(function () { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.channels) === null || _c === void 0 ? void 0 : _c.filter(notNil_1.notNil)) !== null && _d !== void 0 ? _d : []; }, [(_b = (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.channels]);
    var sections = (0, react_1.useMemo)(function () {
        var sectionsList = [];
        // Pinned workspace section
        var visibleChannels = allChannels.filter(function (channel) { return channel.visible; });
        var pinnedSectionChannels = visibleChannels.filter(function (channel) { return !(0, lodash_1.isNil)(channel.pinnedSortKey); });
        createVirtualWorkspaceSection(sectionsList, '_pinned_', (0, i18n_1.i18n)('channel.list.pinned'), pinnedSectionChannels, 'pinnedSortKey');
        // Workspace sections
        (0, lodash_1.sortBy)(workspaceSections, 'createdAt')
            .reverse()
            .forEach(function (section) {
            var _a, _b;
            var channelAssociations = (_b = (_a = section.channelAssociations) === null || _a === void 0 ? void 0 : _a.filter(notNil_1.notNil)) !== null && _b !== void 0 ? _b : [];
            var channelIds = channelAssociations
                .filter(function (association) { return notInChannels(pinnedSectionChannels, association); })
                .filter(function (association) { return inChannels(allChannels, association); })
                .filter(function (association) { return inChannels(visibleChannels, association); })
                .map(function (association) { return association.channelId; });
            if (!(0, lodash_1.isEmpty)(channelIds)) {
                sectionsList.push({
                    id: section.id,
                    name: section.title,
                    channelIds: channelIds,
                });
            }
        });
        // Visible workspaces not in a workspace section
        var workspacesSectionChannels = visibleChannels.filter(function (channel) { return !sectionsList.some(function (section) { return section.channelIds.some(function (id) { return id === channel.id; }); }); });
        createVirtualWorkspaceSection(sectionsList, '_workspaces_', (0, i18n_1.i18n)('channel.list.chats'), workspacesSectionChannels, 'updatedAt');
        // Hidden workspace section
        var hiddenSectionChannels = allChannels.filter(function (channel) { return (0, lodash_1.isNil)(channel.visible) || !channel.visible; });
        createVirtualWorkspaceSection(sectionsList, '_hidden_', (0, i18n_1.i18n)('channel.list.hidden'), hiddenSectionChannels, 'updatedAt');
        return sectionsList;
    }, [allChannels, workspaceSections]);
    return { loading: loading, refetch: refetch, channels: allChannels, sections: sections };
}
function notInChannels(channels, channelAssociation) {
    return !channels.some(function (channel) { return channel.id === channelAssociation.channelId; });
}
function inChannels(channels, channelAssociation) {
    return channels.some(function (channel) { return channel.id === channelAssociation.channelId; });
}
function createVirtualWorkspaceSection(sections, id, name, channels, sortByField) {
    if (!(0, lodash_1.isEmpty)(channels)) {
        sections.push({
            id: id,
            reserved: true,
            name: name,
            channelIds: (0, lodash_1.sortBy)(channels, sortByField)
                .reverse()
                .map(function (ch) { return ch.id; }),
        });
    }
}
function useAllCardsRowSelected() {
    var _a;
    var routes = (0, react_1.useContext)(navigation_1.ReactRouterContext).routes;
    return ((_a = (0, lodash_1.last)(routes)) === null || _a === void 0 ? void 0 : _a.path) === 'all-cards';
}
function useDashboardRowSelected() {
    var _a;
    var routes = (0, react_1.useContext)(navigation_1.ReactRouterContext).routes;
    return ((_a = (0, lodash_1.last)(routes)) === null || _a === void 0 ? void 0 : _a.path) === 'dashboard';
}
function useUnreadCount() {
    var channels = (0, hooks_1.useChannelsLite)().channels;
    return (0, react_1.useMemo)(function () {
        return (0, lodash_1.sum)(channels.map(function (c) { return c.unreadCount; }));
    }, [channels]);
}
function useCreateChannelInSection(workspaceSectionId) {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigator = (0, navigation_1.useNavigator)();
    return (0, react_1.useCallback)(function () {
        dispatch(ChannelCreateActions.setWorkspaceListSection(workspaceSectionId));
        navigator.navigate('NewMessageContainer');
    }, [dispatch, workspaceSectionId, navigator]);
}
function useShowChannelSectionOptions(_a) {
    var section = _a.section, onPressRenameSection = _a.onPressRenameSection;
    var workspaceSectionId = section.workspaceSectionId;
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var canDeleteWorkspaceSection = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.DELETE_WORKSPACE_SECTION);
    var onPressDeleteSection = useOnPressDeleteWorkspaceSection(workspaceSectionId);
    var navigator = (0, navigation_1.useNavigator)();
    return (0, hook_1.useActionSheet)({
        webHorizontalAlignment: isDesktop ? 'left' : 'right',
        options: [
            {
                title: (0, i18n_1.i18n)('channel.list.options.addPeople'),
                hidden: (0, lodash_1.isNil)(workspaceSectionId),
                onPress: function () {
                    return navigator.navigate('WorkspaceSectionAddPeople', {
                        workspaceSectionId: workspaceSectionId !== null && workspaceSectionId !== void 0 ? workspaceSectionId : '',
                    });
                },
            },
            {
                title: (0, i18n_1.i18n)('channel.list.options.renameSection'),
                hidden: !workspaceSectionId,
                onPress: onPressRenameSection,
            },
            {
                title: (0, i18n_1.i18n)('channel.list.options.deleteSection.menuTitle'),
                destructive: true,
                hidden: !workspaceSectionId || !canDeleteWorkspaceSection,
                onPress: onPressDeleteSection,
            },
            {
                title: (0, i18n_1.i18n)('channel.list.options.addChannel'),
                hidden: !!workspaceSectionId,
                onPress: useCreateChannelInSection(workspaceSectionId),
            },
        ],
    });
}
function useUpdateWorkspaceSection() {
    var _this = this;
    var updateWorkspaceSection = (0, client_1.useMutation)(api_1.Mutations.channel.updateWorkspaceSection, {
        refetchQueries: [{ query: api_1.Queries.channel.listWorkspaceSections }],
        awaitRefetchQueries: true,
    })[0];
    return (0, react_1.useCallback)(function (_a) { return __awaiter(_this, [_a], void 0, function (_b) {
        var workspaceSectionId = _b.workspaceSectionId, title = _b.title;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, updateWorkspaceSection({
                        variables: {
                            input: {
                                id: workspaceSectionId,
                                title: title,
                            },
                        },
                    })];
                case 1:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [updateWorkspaceSection]);
}
function useOnPressDeleteWorkspaceSection(workspaceSectionId) {
    var deleteWorkspaceSection = useDeleteWorkspaceSection(workspaceSectionId);
    return (0, react_1.useCallback)(function () {
        alert_1.MBAlert.confirm({
            title: (0, i18n_1.i18n)('channel.list.options.deleteSection.confirmationTitle'),
            message: (0, i18n_1.i18n)('channel.list.options.deleteSection.confirmationText'),
            confirmStyle: 'destructive',
            confirmText: (0, i18n_1.i18n)('common.delete'),
            onConfirm: deleteWorkspaceSection,
        });
    }, [deleteWorkspaceSection]);
}
function useDeleteWorkspaceSection(workspaceSectionId) {
    var _this = this;
    var refetch = useChannelsRefetch();
    var workspaceSection = useWorkspaceSection(workspaceSectionId);
    var navigator = (0, navigation_1.useNavigator)();
    var removeChannel = (0, hooks_2.useRemoveChannel)();
    return (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        var channelAssociations;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!workspaceSection) {
                        return [2 /*return*/];
                    }
                    channelAssociations = (_b = (_a = workspaceSection.channelAssociations) === null || _a === void 0 ? void 0 : _a.filter(notNil_1.notNil)) !== null && _b !== void 0 ? _b : [];
                    return [4 /*yield*/, Promise.all(channelAssociations.map(function (channel) { return removeChannel(channel.channelId); }))];
                case 1:
                    _c.sent();
                    refetch();
                    navigator.reset('Home');
                    return [2 /*return*/];
            }
        });
    }); }, [workspaceSection, refetch, navigator, removeChannel]);
}
