"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWorkflowUsers = useWorkflowUsers;
var useChannelUsers_1 = require("./useChannelUsers");
var useWorkflowChannel_1 = require("./useWorkflowChannel");
/**
 * A hook that returns the associated users for a given workflow template id.
 * @param workflowTemplateId - The id of the workflow template.
 * @returns The users for the given workflow template id.
 */
function useWorkflowUsers(workflowTemplateId) {
    var channel = (0, useWorkflowChannel_1.useWorkflowChannel)(workflowTemplateId);
    var users = (0, useChannelUsers_1.useChannelUsers)({ channelId: channel === null || channel === void 0 ? void 0 : channel.id }).users;
    return users;
}
