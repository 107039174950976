"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectoryContext = void 0;
exports.isStaff = isStaff;
exports.isPending = isPending;
exports.isDeactivated = isDeactivated;
var react_1 = require("react");
function isStaff(user) {
    return !user.isPending && !user.isRemoved;
}
function isPending(user) {
    return user.isPending;
}
function isDeactivated(user) {
    return user.isRemoved;
}
exports.DirectoryContext = (0, react_1.createContext)({});
