"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBatchFieldOverrides = useBatchFieldOverrides;
exports.getFinalFieldOverrides = getFinalFieldOverrides;
var lodash_1 = require("lodash");
var react_1 = require("react");
var useDefaultFields_1 = require("@shared/scenes/entity/create/hooks/useDefaultFields");
function useBatchFieldOverrides(components) {
    return (0, react_1.useMemo)(function () {
        var componentsWithUpcomingFieldOverrides = components === null || components === void 0 ? void 0 : components.filter(isComponentWithBatchFieldOverrides).filter(function (component) { var _a; return !(0, lodash_1.isNil)((_a = component.upcomingRecurringEntityDefault) === null || _a === void 0 ? void 0 : _a.value); });
        var componentsWithFirstFieldOverrides = components === null || components === void 0 ? void 0 : components.filter(isComponentWithBatchFieldOverrides).filter(function (component) { var _a; return !(0, lodash_1.isNil)((_a = component.firstRecurringEntityDefault) === null || _a === void 0 ? void 0 : _a.value); });
        if ((0, lodash_1.isEmpty)(componentsWithUpcomingFieldOverrides) &&
            (0, lodash_1.isEmpty)(componentsWithFirstFieldOverrides)) {
            return undefined;
        }
        var overrides = {};
        componentsWithUpcomingFieldOverrides === null || componentsWithUpcomingFieldOverrides === void 0 ? void 0 : componentsWithUpcomingFieldOverrides.forEach(function (component) {
            var _a, _b;
            if ((0, lodash_1.isNil)(overrides.upcomingEntityFieldOverrides)) {
                overrides.upcomingEntityFieldOverrides = { fields: {} };
            }
            if (!(0, lodash_1.isNil)((_a = component.upcomingRecurringEntityDefault) === null || _a === void 0 ? void 0 : _a.value)) {
                overrides.upcomingEntityFieldOverrides.fields[component.id] =
                    (_b = component.upcomingRecurringEntityDefault) === null || _b === void 0 ? void 0 : _b.value;
            }
        });
        componentsWithFirstFieldOverrides === null || componentsWithFirstFieldOverrides === void 0 ? void 0 : componentsWithFirstFieldOverrides.forEach(function (component) {
            var _a, _b;
            if ((0, lodash_1.isNil)(overrides.firstEntityFieldOverrides)) {
                overrides.firstEntityFieldOverrides = { fields: {} };
            }
            if (!(0, lodash_1.isNil)((_a = component.firstRecurringEntityDefault) === null || _a === void 0 ? void 0 : _a.value)) {
                overrides.firstEntityFieldOverrides.fields[component.id] =
                    (_b = component.firstRecurringEntityDefault) === null || _b === void 0 ? void 0 : _b.value;
            }
        });
        return overrides;
    }, [components]);
}
function isComponentWithBatchFieldOverrides(component) {
    return component.type === 'TAG' || component.type === 'AUDIT_TAG';
}
// prevents defaults from overriding manual selection.
// if field has user input, then use that input for all entities.
function getFinalFieldOverrides(_a) {
    var _b, _c, _d, _e;
    var fieldOverrides = _a.fieldOverrides, entity = _a.entity, components = _a.components;
    var overrides = {
        upcomingEntityFieldOverrides: { fields: {} },
        firstEntityFieldOverrides: { fields: {} },
    };
    Object.keys((_c = (_b = fieldOverrides === null || fieldOverrides === void 0 ? void 0 : fieldOverrides.upcomingEntityFieldOverrides) === null || _b === void 0 ? void 0 : _b.fields) !== null && _c !== void 0 ? _c : {}).forEach(function (componentId) {
        var _a, _b, _c;
        var fieldValue = (_a = entity.fields) === null || _a === void 0 ? void 0 : _a[componentId];
        var component = components === null || components === void 0 ? void 0 : components.find(function (c) { return c.id === componentId; });
        var componentDefault = (0, useDefaultFields_1.getFieldDefault)(component);
        var hasManualInput = !(0, lodash_1.isEmpty)(fieldValue) && !(0, lodash_1.isEqual)(fieldValue, componentDefault);
        if (!hasManualInput) {
            overrides.upcomingEntityFieldOverrides.fields[componentId] =
                (_c = (_b = fieldOverrides === null || fieldOverrides === void 0 ? void 0 : fieldOverrides.upcomingEntityFieldOverrides) === null || _b === void 0 ? void 0 : _b.fields) === null || _c === void 0 ? void 0 : _c[componentId];
        }
    });
    Object.keys((_e = (_d = fieldOverrides === null || fieldOverrides === void 0 ? void 0 : fieldOverrides.firstEntityFieldOverrides) === null || _d === void 0 ? void 0 : _d.fields) !== null && _e !== void 0 ? _e : {}).forEach(function (componentId) {
        var _a, _b, _c;
        var fieldValue = (_a = entity.fields) === null || _a === void 0 ? void 0 : _a[componentId];
        var component = components === null || components === void 0 ? void 0 : components.find(function (c) { return c.id === componentId; });
        var componentDefault = (0, useDefaultFields_1.getFieldDefault)(component);
        var hasManualInput = !(0, lodash_1.isEmpty)(fieldValue) && !(0, lodash_1.isEqual)(fieldValue, componentDefault);
        if (!hasManualInput) {
            overrides.firstEntityFieldOverrides.fields[componentId] =
                (_c = (_b = fieldOverrides === null || fieldOverrides === void 0 ? void 0 : fieldOverrides.firstEntityFieldOverrides) === null || _b === void 0 ? void 0 : _b.fields) === null || _c === void 0 ? void 0 : _c[componentId];
        }
    });
    return overrides;
}
