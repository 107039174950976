"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChannelFragmentTemplates = useChannelFragmentTemplates;
exports.useFragmentTemplate = useFragmentTemplate;
exports.useUpdateFragmentTemplate = useUpdateFragmentTemplate;
exports.useDeleteFragmentTemplate = useDeleteFragmentTemplate;
exports.useCreateFragmentTemplate = useCreateFragmentTemplate;
exports.prepareTemplateForSubmission = prepareTemplateForSubmission;
exports.useValidationSchema = useValidationSchema;
exports.useOnEditTemplate = useOnEditTemplate;
exports.useTemplateOptions = useTemplateOptions;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var react_1 = require("react");
var react_native_1 = require("react-native");
var Yup = __importStar(require("yup"));
var api_1 = require("@shared/api");
var toast_1 = require("@shared/components/toast");
var actionSheet_1 = require("@shared/util/actionSheet");
var hook_1 = require("@shared/util/actionSheet/hook");
var alert_1 = require("@shared/util/alert");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
function useChannelFragmentTemplates(channelId) {
    return (0, client_1.useQuery)(api_1.Queries.card.listFragmentTemplates, {
        skip: !channelId,
        variables: {
            input: {
                channelId: channelId,
            },
        },
    });
}
function useFragmentTemplate(channelId, templateId) {
    var _a = useChannelFragmentTemplates(channelId), data = _a.data, loading = _a.loading, error = _a.error;
    var fragment = (0, react_1.useMemo)(function () { var _a; return (_a = data === null || data === void 0 ? void 0 : data.listCardFragmentTemplates) === null || _a === void 0 ? void 0 : _a.find(function (_a) {
        var id = _a.id;
        return id === templateId;
    }); }, [data, templateId]);
    return { loading: loading, error: error, fragment: fragment };
}
function useUpdateFragmentTemplate(channelId) {
    var _this = this;
    var createTemplate = useCreateFragmentTemplate(channelId);
    var deleteTemplate = useDeleteFragmentTemplate(channelId);
    return (0, react_1.useCallback)(function (updatedTemplate) { return __awaiter(_this, void 0, void 0, function () {
        var originalId, name, template;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    originalId = updatedTemplate.id, name = updatedTemplate.name, template = updatedTemplate.template;
                    return [4 /*yield*/, createTemplate({ name: name, template: template })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, deleteTemplate(originalId)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [createTemplate, deleteTemplate]);
}
function useDeleteFragmentTemplate(channelId) {
    var deleteFragmentTemplate = (0, client_1.useMutation)(api_1.Mutations.cards.deleteFragmentTemplate, {
        refetchQueries: [
            { query: api_1.Queries.card.listFragmentTemplates, variables: { input: { channelId: channelId } } },
            {
                query: api_1.Queries.channel.cardDefinitions,
                variables: { input: { channelId: channelId } },
            },
        ],
    })[0];
    return (0, react_1.useCallback)(function (fragmentId) {
        return deleteFragmentTemplate({ variables: { id: fragmentId } });
    }, [deleteFragmentTemplate]);
}
function useCreateFragmentTemplate(channelId) {
    var createFragmentTemplate = (0, client_1.useMutation)(api_1.Mutations.cards.createFragmentTemplate, {
        refetchQueries: [
            { query: api_1.Queries.card.listFragmentTemplates, variables: { input: { channelId: channelId } } },
            {
                query: api_1.Queries.channel.cardDefinitions,
                variables: { input: { channelId: channelId } },
            },
            {
                query: api_1.Queries.card.listCardFieldStringTags,
                variables: { input: { channelId: channelId } },
            },
        ],
    })[0];
    return (0, react_1.useCallback)(function (input) {
        return createFragmentTemplate({
            variables: {
                input: __assign({ channelId: channelId }, input),
            },
        });
    }, [channelId, createFragmentTemplate]);
}
var iconMap = {
    text: 'pen',
    textarea: 'multi-line',
    toggle: 'check',
};
function prepareTemplateForSubmission(template) {
    var newItems = template.items
        .filter(function (_a) {
        var label = _a.label;
        return label;
    })
        .map(function (item) { return (__assign(__assign({}, item), { icon: iconMap[item.fieldType], placeholder: item.label })); });
    return __assign(__assign({}, template), { items: newItems });
}
function useValidationSchema() {
    var addTitleError = (0, i18n_1.i18n)('channel.options.fragmentTemplates.addTitle');
    var fragmentTemplateSchema = Yup.object().shape({
        name: Yup.string().required(addTitleError),
        template: Yup.object().shape({
            items: Yup.array().min(1, (0, i18n_1.i18n)('channel.options.fragmentTemplates.noItemError')),
        }),
    });
    return fragmentTemplateSchema;
}
function useOnEditTemplate(channelId) {
    var channel = (0, hooks_1.useChannel)({ channelId: channelId });
    var navigate = (0, navigation_1.useNavigator)().navigate;
    return (0, react_1.useCallback)(function (template) {
        var message = react_native_1.Platform.OS === 'web'
            ? "".concat((0, i18n_1.i18n)('channel.options.fragmentTemplates.editConfirmation'), ". ").concat((0, i18n_1.i18n)('channel.options.fragmentTemplates.editDescription'), ".")
            : (0, i18n_1.i18n)('channel.options.fragmentTemplates.editDescription');
        alert_1.MBAlert.confirm({
            title: (0, i18n_1.i18n)('channel.options.fragmentTemplates.editConfirmation'),
            message: message,
            confirmText: (0, i18n_1.i18n)('common.ok'),
            onConfirm: function () {
                navigate('ChannelFragmentTemplatesEdit', {
                    channelSlug: channel === null || channel === void 0 ? void 0 : channel.slug,
                    templateId: template === null || template === void 0 ? void 0 : template.id,
                });
            },
        });
    }, [channel, navigate]);
}
function useTemplateOptions(template) {
    var _this = this;
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var _a = (0, react_1.useState)(false), loading = _a[0], setLoading = _a[1];
    var deleteTemplateMutation = useDeleteFragmentTemplate(template === null || template === void 0 ? void 0 : template.channelId);
    var onEditTemplate = useOnEditTemplate(template === null || template === void 0 ? void 0 : template.channelId);
    var onDeleteTemplate = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    return [4 /*yield*/, deleteTemplateMutation(template === null || template === void 0 ? void 0 : template.id)];
                case 2:
                    _a.sent();
                    toast.show({
                        icon: 'check-filled',
                        message: (0, i18n_1.i18n)('channel.options.fragmentTemplates.deleted', { count: 1 }),
                    });
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [deleteTemplateMutation, template, toast]);
    var displayOptions = (0, hook_1.useActionSheet)({
        webHorizontalAlignment: 'right',
        options: [
            {
                title: (0, i18n_1.i18n)('common.edit'),
                onPress: function () { return onEditTemplate(template); },
            },
            {
                destructive: true,
                title: (0, i18n_1.i18n)('common.delete'),
                onPress: function (parent) {
                    if (react_native_1.Platform.OS === 'web') {
                        actionSheet_1.MBActionSheet.show({
                            parent: parent,
                            title: (0, i18n_1.i18n)('card.details.deleteConfirmation.title'),
                            webHorizontalAlignment: 'right',
                            options: [
                                {
                                    title: (0, i18n_1.i18n)('card.details.deleteConfirmation.confirm'),
                                    destructive: true,
                                    onPress: onDeleteTemplate,
                                },
                                {
                                    title: (0, i18n_1.i18n)('common.cancel'),
                                },
                            ],
                        });
                    }
                    else {
                        alert_1.MBAlert.confirm({
                            title: (0, i18n_1.i18n)('channel.options.fragmentTemplates.deleteConfirmation'),
                            message: (0, i18n_1.i18n)('channel.options.fragmentTemplates.deleteDescription'),
                            confirmText: (0, i18n_1.i18n)('card.details.deleteConfirmation.confirm'),
                            confirmStyle: 'destructive',
                            onConfirm: onDeleteTemplate,
                        });
                    }
                },
            },
        ],
    });
    return { loading: loading, displayOptions: displayOptions };
}
