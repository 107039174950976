"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatInput = MBChatInput;
var React = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var Actions = __importStar(require("@shared/scenes/chat/input/store/actions"));
var ChatThreadActions = __importStar(require("@shared/scenes/chat/thread/store/actions"));
var index_1 = require("../../components/index");
function MBChatInput(_a) {
    var placeholder = _a.placeholder, draftId = _a.draftId, users = _a.users, groups = _a.groups, hideUrgent = _a.hideUrgent, autoFocus = _a.autoFocus, onSend = _a.onSend, onFocus = _a.onFocus, onBlur = _a.onBlur, preventSendingMessage = _a.preventSendingMessage;
    var dispatch = (0, react_redux_1.useDispatch)();
    var attachments = (0, react_redux_1.useSelector)(function (store) { return store.chatInput.attachmentsFromDraftId[draftId] || []; });
    var urgent = (0, react_redux_1.useSelector)(function (store) { return store.chatInput.urgentFromDraftId[draftId] || false; });
    var storedContent = (0, react_redux_1.useSelector)(function (state) { return state.chatInput.contentFromDraftId[draftId]; });
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var messageBeingEdited = (0, react_redux_1.useSelector)(function (store) { return store.chatMessages.messageBeingEditedFromDraftId[draftId]; });
    var endEditingMessage = React.useCallback(function () { return ChatThreadActions.endEditingMessage(draftId)(dispatch); }, [draftId, dispatch]);
    var handleSend = React.useCallback(function (content) {
        // 1. Reset the store for the given draft
        Actions.disableUrgent(draftId)(dispatch);
        Actions.clearAttachments(draftId)(dispatch);
        if (preventSendingMessage) {
            // 2. If we're eg in the new message flow, we only want to
            // call the onSend callback, instead of adding the message
            // to the send queue.
            onSend === null || onSend === void 0 ? void 0 : onSend({ urgent: urgent, content: content, attachments: attachments, editing: null });
        }
        else if (!!messageBeingEdited) {
            // 3. If editing, edit the message
            ChatThreadActions.editMessage({
                content: content,
                channelSlug: draftId,
                message: messageBeingEdited,
            })(dispatch);
        }
        else {
            // 4. Otherwise, send the message
            ChatThreadActions.sendMessageFromDraft({ urgent: urgent, content: content, attachments: attachments, editing: null }, draftId)(dispatch);
            // 5. Scroll to the bottom of the chat thread
            // Note, we don't do this when editing a message since we don't
            // want to scroll away from the message the user just edited.
            onSend === null || onSend === void 0 ? void 0 : onSend({ urgent: urgent, content: content, attachments: attachments, editing: null });
        }
    }, [draftId, urgent, preventSendingMessage, messageBeingEdited, attachments, onSend, dispatch]);
    // When the input component unmounts, we store the current content in
    // redux so that we can init the chat input with that value when the
    // user returns to a specific draftId.
    var storeContent = React.useCallback(function (content) { return Actions.updateContent(draftId, content)(dispatch); }, [draftId, dispatch]);
    return (
    // Why do we set a key to the component? Because we want it to reset
    // when changing chat/starting to edit a message. This will cause
    // initialValue to be set again.
    React.createElement(index_1.MBChatInput, { key: "".concat(messageBeingEdited && messageBeingEdited.id, ":").concat(draftId), isEditing: !!messageBeingEdited, initialValue: !!messageBeingEdited ? messageBeingEdited.content : storedContent, draftId: draftId, autoFocus: autoFocus !== undefined ? autoFocus : isDesktop, hideUrgent: hideUrgent, placeholder: placeholder, users: users, groups: groups, onSend: handleSend, onFocus: onFocus, onBlur: onBlur, onCancelEdit: endEditingMessage, onUnmount: storeContent }));
}
