"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowTemplatesList = WorkflowTemplatesList;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var use_debounce_1 = require("use-debounce");
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
function WorkflowTemplatesList(_a) {
    var parentRef = _a.parentRef, onSelect = _a.onSelect, onClose = _a.onClose, selectedCount = _a.selectedCount;
    var _b = (0, react_1.useState)(''), _query = _b[0], setQuery = _b[1];
    var query = (0, use_debounce_1.useDebounce)(_query, 300)[0];
    var _c = (0, hooks_1.useWorkflows)({
        input: { isSubform: false },
        workspaceName: (0, lodash_1.isEmpty)(query) ? undefined : query,
        isMember: true,
    }), workflowsFromChannels = _c.workflows, loadingFromChannels = _c.loading, hasMoreFromChannels = _c.hasMore, loadMoreFromChannels = _c.loadMore;
    var _d = (0, hooks_1.useWorkflows)({
        input: { isSubform: false, name: (0, lodash_1.isEmpty)(query) ? undefined : query },
        isMember: true,
    }), workflowsFromListWorkflowTemplates = _d.workflows, loadingFromListWorkflowTemplates = _d.loading, hasMoreFromListWorkflowTemplates = _d.hasMore, loadMoreFromListWorkflowTemplates = _d.loadMore;
    var loading = loadingFromChannels || loadingFromListWorkflowTemplates;
    var hasMore = hasMoreFromChannels || hasMoreFromListWorkflowTemplates;
    var loadMore = loadMoreFromChannels || loadMoreFromListWorkflowTemplates;
    var workflows = (0, lodash_1.uniqBy)((workflowsFromChannels !== null && workflowsFromChannels !== void 0 ? workflowsFromChannels : []).concat(workflowsFromListWorkflowTemplates !== null && workflowsFromListWorkflowTemplates !== void 0 ? workflowsFromListWorkflowTemplates : []), 'workflowTemplate.id');
    var _e = (0, react_1.useState)(null), selected = _e[0], setSelected = _e[1];
    return (react_1.default.createElement(components_1.MBBottomSheet, { containerRef: parentRef, layout: {
            offset: { x: 0, y: (0, components_1.grid)(1) },
            minHeight: (0, components_1.grid)(48),
        }, onClose: onClose, renderTitleBar: function () { return (react_1.default.createElement(components_1.MBView, { paddingTop: 2, paddingHorizontal: 2 },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: styles_1.Colors.v2.gray50, style: { marginBottom: (0, components_1.grid)(1) } }, (0, i18n_1.i18n)('workflowTemplate.selectWorkflow').toUpperCase()),
            react_1.default.createElement(components_1.MBView, { paddingTop: 1, paddingBottom: 1 },
                react_1.default.createElement(components_1.MBSearchBar, { value: query, onChangeText: setQuery, placeholder: (0, i18n_1.i18n)('common.search') })))); } },
        react_1.default.createElement(components_1.MBScrollView, null,
            !loading && (0, lodash_1.isEmpty)(workflows) && (react_1.default.createElement(components_1.MBEmptyListPlaceholder, { title: (0, i18n_1.i18n)('workflowTemplate.subformOptions.emptyCopyToWorkflowTemplateTitle'), description: (0, i18n_1.i18n)('workflowTemplate.subformOptions.emptyCopyToWorkflowTemplateDescription'), image: require('@shared/assets/icons/v2/check-todo.png'), imageSize: (0, components_1.grid)(9) })),
            loading && (react_1.default.createElement(react_native_1.View, { style: {
                    height: 150,
                    alignItems: 'center',
                    justifyContent: 'center',
                } },
                react_1.default.createElement(components_1.MBLoadingIndicator, null))), workflows === null || workflows === void 0 ? void 0 :
            workflows.map(function (_a) {
                var workflowTemplate = _a.workflowTemplate, workspaceSection = _a.workspaceSection, workspace = _a.workspace;
                return (react_1.default.createElement(WorkflowTemplateRow, { key: workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.id, workflowTemplate: workflowTemplate, workspaceSection: workspaceSection, workspace: workspace, onPress: function () { return setSelected(workflowTemplate !== null && workflowTemplate !== void 0 ? workflowTemplate : null); }, isSelected: (selected === null || selected === void 0 ? void 0 : selected.id) === (workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.id) }));
            }),
            hasMore && (react_1.default.createElement(react_native_1.View, { style: { paddingVertical: (0, components_1.grid)(3) } },
                react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('card.list.showMore'), size: "small", theme: "transparent", bold: false, borderColor: styles_1.Colors.v2.gray10, onPress: loadMore, style: { alignSelf: 'center' } })))),
        !(0, lodash_1.isNil)(selected) && (react_1.default.createElement(react_native_1.View, { style: { paddingVertical: (0, components_1.grid)(2) } },
            react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('workflowTemplate.subformOptions.copySubforms', {
                    count: selectedCount,
                }), size: "small", onPress: function () { return onSelect(selected); }, style: { alignSelf: 'center' } })))));
}
function WorkflowTemplateRow(_a) {
    var _b, _c, _d, _e, _f;
    var onPress = _a.onPress, isSelected = _a.isSelected, workflowTemplate = _a.workflowTemplate, workspaceSection = _a.workspaceSection, workspace = _a.workspace;
    var imageUrl = (_b = workspace === null || workspace === void 0 ? void 0 : workspace.imageUrl) !== null && _b !== void 0 ? _b : (_c = workflowTemplate.channelDefaultConfiguration) === null || _c === void 0 ? void 0 : _c.imageUrl;
    var name = (_e = (_d = workspace === null || workspace === void 0 ? void 0 : workspace.name) !== null && _d !== void 0 ? _d : workflowTemplate.name) !== null && _e !== void 0 ? _e : '';
    var sectionName = (_f = workspaceSection === null || workspaceSection === void 0 ? void 0 : workspaceSection.name) !== null && _f !== void 0 ? _f : '';
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress },
        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingVertical: 1, paddingHorizontal: 2, fill: true, style: { justifyContent: 'space-between' } },
            react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, style: { maxWidth: (0, components_1.grid)(48) } },
                react_1.default.createElement(components_1.MBImage, { source: { uri: imageUrl }, style: { height: (0, components_1.grid)(2.5), width: (0, components_1.grid)(2.5), marginRight: (0, components_1.grid)(1.5) } }),
                react_1.default.createElement(components_1.MBView, { style: { marginRight: (0, components_1.grid)(2), maxWidth: '90%' } },
                    react_1.default.createElement(components_1.MBTextBody1, { color: styles_1.Colors.v2.purple, numberOfLines: 2 }, name),
                    react_1.default.createElement(components_1.MBTextBody2, { color: styles_1.Colors.v2.gray50, numberOfLines: 2 }, sectionName))),
            react_1.default.createElement(components_1.MBSelectableDot, { radio: true, selected: isSelected, size: (0, components_1.grid)(4) }))));
}
