"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultTagRow = DefaultTagRow;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var tag_1 = require("@shared/components/blocks/lib/tags/tag");
var styles_1 = require("@shared/styles");
function DefaultTagRow(_a) {
    var _b, _c;
    var component = _a.component, overridePath = _a.overridePath;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var value = !(0, lodash_1.isNil)(overridePath) ? (_b = component[overridePath]) === null || _b === void 0 ? void 0 : _b.value : (_c = component.default) === null || _c === void 0 ? void 0 : _c.value;
    if ((0, lodash_1.isEmpty)(value)) {
        return null;
    }
    return (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingHorizontal: 2, paddingVertical: 1, style: styles_1.cursorPointerStyle },
        react_1.default.createElement(components_1.MBView, { flex: true, row: true, wrap: true, style: { flexWrap: 'wrap' } }, value.map(function (item) {
            var _a, _b;
            var tag = (0, lodash_1.first)((_a = component.options) === null || _a === void 0 ? void 0 : _a.filter(function (option) { return option.value === item; }));
            return (react_1.default.createElement(tag_1.Tag, { key: tag === null || tag === void 0 ? void 0 : tag.value, color: tag === null || tag === void 0 ? void 0 : tag.backgroundColor, textStyle: components_1.MBTypographyStyles.body2, label: (_b = tag === null || tag === void 0 ? void 0 : tag.label) !== null && _b !== void 0 ? _b : '' }));
        })),
        react_1.default.createElement(components_1.MBIconV2, { name: "arrow-point-right", color: colors.foregroundActive, size: (0, components_1.grid)(3) })));
}
