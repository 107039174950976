"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var view_1 = require("../view");
exports.Styles = react_native_1.StyleSheet.create({
    button: {
        borderWidth: 2,
        borderColor: 'transparent',
    },
    buttonSizeSmall: {
        minHeight: 36,
        borderRadius: 18,
    },
    buttonSizeNormal: {
        minHeight: 44,
        borderRadius: 22,
    },
    buttonWithMoreOptions: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
    splitButton: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        paddingLeft: (0, view_1.grid)(2),
        marginLeft: 2,
    },
    icon: {
        width: 16,
        height: 16,
        padding: 0,
    },
    wrap: {
        alignSelf: 'flex-start',
        whiteSpace: 'normal',
        flexGrow: 0,
        flexShrink: 1,
    },
});
