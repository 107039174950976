"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChannelUsers = useChannelUsers;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
function useChannelUsers(_a) {
    var channelId = _a.channelId;
    var _b = (0, client_1.useQuery)(api_1.Queries.channel.listChannelMemberships, {
        variables: {
            input: {
                channelId: channelId !== null && channelId !== void 0 ? channelId : -1,
                onlyAssignableUsers: true,
            },
        },
        skip: (0, lodash_1.isNil)(channelId),
    }), data = _b.data, loading = _b.loading;
    return (0, react_1.useMemo)(function () {
        var _a, _b;
        return {
            users: (_b = (_a = data === null || data === void 0 ? void 0 : data.listChannelMemberships) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
                var user = _a.user;
                return user;
            })) !== null && _b !== void 0 ? _b : [],
            loading: loading,
        };
    }, [data === null || data === void 0 ? void 0 : data.listChannelMemberships, loading]);
}
