"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Actions = void 0;
exports.canPerform = canPerform;
var Actions;
(function (Actions) {
    Actions[Actions["Remove"] = 0] = "Remove";
    Actions[Actions["UpdateRole"] = 1] = "UpdateRole";
})(Actions || (exports.Actions = Actions = {}));
/**
 * [Can] currentUser [perform] action [on] subject?
 */
function canPerform(currentUser, action, subject) {
    if (currentUser.role === 'MEMBER') {
        // members can't do anything
        return false;
    }
    if (action === Actions.Remove) {
        if (currentUser.id === subject.id) {
            // can't remove yourself
            return false;
        }
        if (currentUser.role === 'ADMIN' && subject.role === 'OWNER') {
            // admins can't remove owners
            return false;
        }
    }
    if (action[0] === Actions.UpdateRole) {
        var role = action[1];
        if (currentUser.id === subject.id) {
            // can't change your own role
            return false;
        }
        if (currentUser.role === 'ADMIN') {
            if (subject.role === 'OWNER') {
                // admins can't change role of owners
                return false;
            }
            if (role === 'OWNER') {
                // admins can't promote anyone to owner
                return false;
            }
        }
        if (currentUser.role === 'OWNER') {
            // owners can update anyones role
        }
    }
    return true;
}
