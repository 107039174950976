"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardQuantityRow = MBCardQuantityRow;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var MBInlineCard_1 = require("@shared/components/blocks/lib/cards/MBInlineCard");
var typography_1 = require("@shared/components/typography");
var styles_1 = require("@shared/styles");
function MBCardQuantityRow(_a) {
    var _b;
    var card = _a.card, _c = _a.editable, editable = _c === void 0 ? false : _c, onRemove = _a.onRemove, onChange = _a.onChange, onPressCard = _a.onPressCard, onPressBlock = _a.onPressBlock;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var removeCard = (0, react_1.useCallback)(function () { return onRemove(card); }, [onRemove, card]);
    var quantity = (_b = card.quantity) !== null && _b !== void 0 ? _b : 0;
    var _d = (0, react_1.useState)(String(quantity)), value = _d[0], setValue = _d[1];
    var handleChangeQuantity = (0, react_1.useCallback)(function (updatedValue) {
        var cleanedValue = updatedValue.replace(/\D/g, '');
        setValue(cleanedValue);
        if (!cleanedValue) {
            return;
        }
        var updatedQuantity = Number(cleanedValue);
        onChange(__assign(__assign({}, card), { quantity: updatedQuantity }));
    }, [card, onChange]);
    (0, react_1.useEffect)(function () {
        setValue(String(quantity));
    }, [quantity]);
    return (react_1.default.createElement(components_1.MBView, { row: true, paddingLeft: 2, paddingRight: 3 },
        editable ? (react_1.default.createElement(components_1.MBTextInput, { editable: editable, value: value, size: "normal", keyboardType: "number-pad", noPadding: true, style: [styles.input, { borderColor: colors.input.borderInactive }], onChangeText: handleChangeQuantity })) : (react_1.default.createElement(components_1.MBView, { center: true, style: styles.input },
            react_1.default.createElement(typography_1.MBTextBody1Bold, { color: colors.foregroundActive }, value))),
        react_1.default.createElement(components_1.MBTouchableOpacity, { style: styles.cardRow, onPress: onPressBlock },
            react_1.default.createElement(MBInlineCard_1.MBInlineCard, { card: card, onPress: onPressCard })),
        editable && react_1.default.createElement(components_1.MBClearButton, { onPress: removeCard })));
}
var styles = react_native_1.StyleSheet.create({
    input: {
        height: (0, components_1.grid)(4.75),
        width: (0, components_1.grid)(5),
        textAlign: 'center',
        borderRadius: (0, components_1.grid)(0.5),
    },
    cardRow: {
        alignItems: 'flex-start',
        flex: 1,
        paddingLeft: (0, components_1.grid)(2),
    },
});
