"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCreateChannelOverviewContent = MBCreateChannelOverviewContent;
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var userList_1 = require("../containers/userList");
function MBCreateChannelOverviewContent(_a) {
    var onCreateNewGroup = _a.onCreateNewGroup, onCreateNewWorkflow = _a.onCreateNewWorkflow, onPressUser = _a.onPressUser, onInviteContacts = _a.onInviteContacts;
    var _b = (0, hooks_1.useDebouncedState)('', 500), query = _b[0], setQuery = _b[1];
    var isDesktop = (0, hooks_1.useIsDesktop)();
    return (React.createElement(components_1.MBScrollView, { nativeShouldPersistTaps: true, nativeKeyboardDismissMode: "on-drag" },
        React.createElement(components_1.MBView, { padding: "small", paddingTop: isDesktop ? 'none' : 'small' },
            React.createElement(components_1.MBSearchBar, { value: query, onChangeText: setQuery, autoFocus: true, placeholder: (0, i18n_1.i18n)('channel.create.searchUsersPlaceholder') })),
        !query && (React.createElement(NewWorkspaceButtons, { onCreateNewGroup: onCreateNewGroup, onCreateNewWorkflow: onCreateNewWorkflow, onInviteContacts: onInviteContacts })),
        React.createElement(userList_1.MBUserList, { query: query, onPressUser: onPressUser })));
}
function NewWorkspaceButtons(_a) {
    var onCreateNewGroup = _a.onCreateNewGroup, onCreateNewWorkflow = _a.onCreateNewWorkflow, onInviteContacts = _a.onInviteContacts;
    return (React.createElement(components_1.MBView, { paddingVertical: 1 },
        React.createElement(NewWorkspaceCtaButton, { title: (0, i18n_1.i18n)('channel.create.newGroup'), subtitle: (0, i18n_1.i18n)('channel.create.newGroupSubtitle'), icon: require('@shared/assets/icons/v2/chat.png'), onPress: onCreateNewGroup }),
        React.createElement(NewWorkspaceCtaButton, { title: (0, i18n_1.i18n)('channel.create.newWorkflow'), subtitle: (0, i18n_1.i18n)('channel.create.newWorkflowSubtitle'), icon: require('@shared/assets/icons/v2/check-todo.png'), onPress: onCreateNewWorkflow }),
        react_native_1.Platform.OS !== 'web' && (React.createElement(NewWorkspaceCtaButton, { title: (0, i18n_1.i18n)('channel.create.inviteContacts'), subtitle: (0, i18n_1.i18n)('channel.create.inviteContactsSubtitle'), icon: require('@shared/assets/icons/v2/address-book.png'), onPress: onInviteContacts }))));
}
function NewWorkspaceCtaButton(_a) {
    var title = _a.title, subtitle = _a.subtitle, icon = _a.icon, onPress = _a.onPress;
    return (React.createElement(components_1.MBView, { paddingBottom: 1 },
        React.createElement(components_1.MBAddRow, { title: title, subtitle: React.createElement(components_1.MBText, { size: "small", color: styles_1.Colors.v2.gray50, style: { marginTop: (0, components_1.grid)(0.25) } }, subtitle), icon: icon, color: styles_1.Colors.v2.white, backgroundColor: styles_1.Colors.v2.green, onPress: onPress })));
}
