"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTrackPaywallExperimentClick = useTrackPaywallExperimentClick;
exports.useTrackPaywallExperimentView = useTrackPaywallExperimentView;
exports.usePaywallExperiment = usePaywallExperiment;
var react_1 = require("react");
var useCanViewCollectionView_1 = require("@shared/data/billing/hooks/useCanViewCollectionView");
var analytics_1 = require("@shared/util/analytics");
var __1 = require("../");
function useTrackPaywallExperimentClick(group) {
    return (0, react_1.useCallback)(function () {
        analytics_1.MBAnalytics.track('PaywallExperiment::Click', {
            group: group,
        });
    }, [group]);
}
function useTrackPaywallExperimentView(group) {
    var isPaid = (0, useCanViewCollectionView_1.useCanViewCollectionView)();
    (0, react_1.useEffect)(function () {
        if (isPaid) {
            return;
        }
        analytics_1.MBAnalytics.track('PaywallExperiment::View', {
            group: group,
        });
    }, [group, isPaid]);
}
function usePaywallExperiment() {
    var groupName = (0, __1.useFeatureFlag)(__1.PAYWALL_EXPERIMENT);
    return {
        isRedactedGroup: groupName === 'redacted',
        isBannerGroup: groupName === 'banner',
        groupName: groupName,
    };
}
