"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTrigger = useTrigger;
exports.useExecuteOnTrigger = useExecuteOnTrigger;
var react_1 = require("react");
/**
 * This hook sets up state and callback tuple that allows a parent component to invoke a callback within
 * a child component without using refs or inversion contexts. This is accomplished by simply incrementing
 * a number and passing that as a prop to a child component. Inside the child component, the companion hook
 * (useExecuteOnTrigger) is used to detect the number incrementing and then invoke a function as needed.
 */
function useTrigger() {
    var _a = (0, react_1.useState)(0), trigger = _a[0], setTrigger = _a[1];
    var onTrigger = (0, react_1.useCallback)(function () { return setTrigger(trigger + 1); }, [trigger]);
    return [trigger, onTrigger];
}
/**
 * To be used inside a child component who's parent component is using useTrigger(). Note that
 * if the callback changes over time, it may be invoked if the trigger is non-zero. Please ensure
 * your callback is memoised or static.
 */
function useExecuteOnTrigger(trigger, callback) {
    (0, react_1.useEffect)(function () {
        if (trigger > 0) {
            callback();
        }
    }, [trigger, callback]);
}
