"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultViewSubtypeSelected = exports.availableFormats = exports.componentTemplateOptions = void 0;
exports.prepareComponent = prepareComponent;
exports.iconForNumberFormat = iconForNumberFormat;
exports.titleForNumberFormat = titleForNumberFormat;
exports.getIconForView = getIconForView;
exports.prepareViewData = prepareViewData;
exports.getAvailableViewSubtypes = getAvailableViewSubtypes;
exports.getViewSubtypeName = getViewSubtypeName;
exports.getIconForViewSubtype = getIconForViewSubtype;
exports.isComponentGroupable = isComponentGroupable;
exports.isViewGroupable = isViewGroupable;
exports.getDefaultListViewComponentIds = getDefaultListViewComponentIds;
var lodash_1 = __importStar(require("lodash"));
var i18n_1 = require("@shared/util/i18n");
var uuid_1 = require("@shared/util/uuid");
var hooks_1 = require("./hooks");
var ComponentOptionDefaults_1 = require("./template/ComponentOptionDefaults");
function prepareComponent(component) {
    return __assign({ id: component.id || (0, uuid_1.v4)() }, component);
}
exports.componentTemplateOptions = ComponentOptionDefaults_1.defaultComponentTemplateOptions;
exports.availableFormats = ['FLOAT', 'CURRENCY', 'PERCENT'];
function iconForNumberFormat(format) {
    switch (format) {
        case 'PERCENT':
            return 'percent';
        case 'CURRENCY':
            return 'money-dollar-circle';
        case 'FLOAT':
        default:
            return 'hashtag';
    }
}
function titleForNumberFormat(format) {
    return (0, i18n_1.i18n)("workflowTemplate.numberFormat.".concat(format || 'FLOAT'));
}
// Temporarily using the types from Card Definitions
function getIconForView(view, viewTemplates) {
    var viewTemplate = viewTemplates === null || viewTemplates === void 0 ? void 0 : viewTemplates.find(function (item) { return item.id === view.slug; });
    if (!lodash_1.default.isNil(viewTemplate)) {
        switch (viewTemplate.subtype) {
            case 'BOARD':
                return 'board';
            case 'CALENDAR':
                return 'calendar';
            case 'TABLE':
                return 'table-grid';
            default:
                return 'list';
        }
    }
    if (view.context === 'agenda') {
        return 'calendar';
    }
    else if (view.context === 'kanban') {
        return 'board';
    }
    else {
        return 'list';
    }
}
function getShouldHideComponentsByDefault(subtype) {
    return ['LIST', 'CALENDAR', 'BOARD'].includes(subtype !== null && subtype !== void 0 ? subtype : '');
}
function getShouldHideLabelsByDefault(subtype) {
    return ['TABLE', 'CALENDAR', 'BOARD'].includes(subtype !== null && subtype !== void 0 ? subtype : '');
}
function prepareViewData(input, visibleComponents) {
    return __assign({ id: (0, uuid_1.v4)(), type: 'COLLECTION', isPublic: false, defaultHiddenComponents: getShouldHideComponentsByDefault(input.subtype), defaultHiddenLabels: getShouldHideLabelsByDefault(input.subtype), defaultReadonlyComponents: true, displayInCards: false, displayLegacyRecurrence: false, displayLegacyReminders: false, displayLegacyStatusButton: false, displayMessageThread: false, summarizeReadonlyTags: true, sortOrder: null, collectionCriteria: {
            sortByComponents: null,
            filterByComponents: null,
            groups: null,
            // TODO if saving
            // omniSearchQuery
        }, componentsViewOptions: visibleComponents }, input);
}
function getAvailableViewSubtypes() {
    return ['LIST', 'BOARD', 'TABLE', 'CALENDAR'];
}
function getViewSubtypeName(subtype) {
    switch (subtype) {
        case 'BOARD':
            return (0, i18n_1.i18n)('workflowTemplate.viewTypeLabels.board');
        case 'CALENDAR':
            return (0, i18n_1.i18n)('workflowTemplate.viewTypeLabels.calendar');
        case 'TABLE':
            return (0, i18n_1.i18n)('workflowTemplate.viewTypeLabels.table');
        case 'LIST':
        default:
            return (0, i18n_1.i18n)('workflowTemplate.viewTypeLabels.list');
    }
}
function getIconForViewSubtype(subtype) {
    switch (subtype) {
        case 'BOARD':
            return 'board';
        case 'CALENDAR':
            return 'calendar';
        case 'TABLE':
            return 'table-grid';
        case 'LIST':
        default:
            return 'list';
    }
}
exports.defaultViewSubtypeSelected = 'LIST';
function isGroupableRelatedCardComponent(component) {
    return (0, hooks_1.isRelatedCardComponent)(component);
}
function isComponentGroupable(component) {
    var GROUPABLE_TYPES = ['TAG'];
    var GROUPABLE_IDS = ['assignees'];
    return (GROUPABLE_TYPES.includes(component.type) ||
        GROUPABLE_IDS.includes(component.id) ||
        isGroupableRelatedCardComponent(component));
}
function isViewGroupable(view) {
    return view.type === 'COLLECTION' && view.subtype !== 'CALENDAR';
}
var LIST_VIEW_DEFAULT_TYPES = ['TAG', 'PERSON', 'RELATED_CARD', 'COMBINED_TAGS', 'DATE'];
var MAX_LIST_VIEW_DEFAULT_COMPONENTS = 5;
function getDefaultListViewComponentIds(workflowTemplate) {
    if ((0, lodash_1.isNil)(workflowTemplate)) {
        return [];
    }
    return __spreadArray([
        {
            componentId: ComponentOptionDefaults_1.STATIC_COMPONENT_IDS.NAME,
            overrides: {
                type: 'TEXT',
                isHidden: false,
            },
        }
    ], workflowTemplate.components
        .filter(function (component) { return LIST_VIEW_DEFAULT_TYPES.includes(component.type); })
        .filter(function (c) { return !(0, hooks_1.isInputComponent)(c) || c.isHidden !== true; })
        .slice(0, MAX_LIST_VIEW_DEFAULT_COMPONENTS)
        .map(function (_a) {
        var id = _a.id, type = _a.type;
        return ({
            componentId: id,
            overrides: {
                type: type,
                isHidden: false,
            },
        });
    }), true);
}
