"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowInstall = WorkflowInstall;
var react_1 = require("react");
var navigation_1 = require("@shared/util/navigation");
var WorkflowInstallStore_1 = require("./WorkflowInstallStore");
function WorkflowInstall(_a) {
    var location = _a.location;
    var navigator = (0, navigation_1.useNavigator)();
    (0, react_1.useEffect)(function () {
        if (typeof location.query.workflowId === 'string') {
            WorkflowInstallStore_1.DefaultWorkflowIdToInstallStore.addWorkflowIdToStore(location.query.workflowId);
        }
        navigator.navigate('Home');
    });
    return null;
}
