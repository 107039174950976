"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateCardEntity = useCreateCardEntity;
var lodash_1 = require("lodash");
var react_1 = require("react");
var BatchContext_1 = require("@shared/scenes/cards/entityBulkActions/BatchCreateContext/BatchContext");
var useFormErrors_1 = require("@shared/scenes/entity/create/hooks/useFormErrors");
var toFieldJson_1 = require("@shared/scenes/entity/util/toFieldJson");
var useEntityFormViewTemplate_1 = require("@shared/scenes/entity/util/useEntityFormViewTemplate");
var useViewComponents_1 = require("@shared/scenes/entity/util/useViewComponents");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var useCreateEntityFields_1 = require("./useCreateEntityFields");
var useSaveNewCardEntity_1 = require("./useSaveNewCardEntity");
function useCreateCardEntity(_a) {
    var _this = this;
    var _b;
    var workflowTemplateId = _a.workflowTemplateId, viewTemplateId = _a.viewTemplateId, queryFieldOverrides = _a.queryFieldOverrides;
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var viewTemplate = (0, useEntityFormViewTemplate_1.useEntityFormViewTemplate)({
        workflowTemplateId: workflowTemplateId,
        viewTemplateId: viewTemplateId,
        type: 'CREATE',
    });
    var _c = (0, react_1.useState)('loading'), status = _c[0], setStatus = _c[1];
    var _d = (0, react_1.useState)(false), saveAttemptedWithLocalErrors = _d[0], setSaveAttemptedWithLocalErrors = _d[1];
    var _e = (0, react_1.useState)(null), batchCreateError = _e[0], setBatchCreateError = _e[1];
    var _f = (0, BatchContext_1.useBatch)(), shouldBatchCreate = _f.shouldBatchCreate, batchCreate = _f.batchCreate;
    var _g = (0, useCreateEntityFields_1.useCreateEntityFields)({
        workflowTemplate: workflowTemplate,
        viewTemplate: viewTemplate,
        queryFieldOverrides: queryFieldOverrides,
    }), fields = _g.fields, updateField = _g.updateField, isDirty = _g.isDirty, resetFields = _g.resetFields;
    var _h = (0, useSaveNewCardEntity_1.useSaveNewEntity)(workflowTemplateId), saveEntity = _h.saveEntity, saveEntityLoading = _h.loading, apiErrors = _h.errors;
    var components = (0, useViewComponents_1.useViewComponents)({
        workflowTemplate: workflowTemplate,
        viewTemplate: viewTemplate,
        includeServerManagedComponents: false,
        includeInputComponentsOnly: true,
    });
    var _j = (0, useFormErrors_1.useFormErrors)({
        apiErrors: batchCreateError ? [batchCreateError] : apiErrors,
        fields: fields,
        components: components,
        includeRequiredErrors: saveAttemptedWithLocalErrors,
    }), errors = _j.errors, isValid = _j.isValid;
    (0, react_1.useEffect)(function () {
        setStatus(function (prevStatus) {
            if (saveEntityLoading) {
                return 'loading';
            }
            else if (!(0, lodash_1.isEmpty)(errors)) {
                return 'error';
            }
            else if (prevStatus !== 'success') {
                return 'input';
            }
            else {
                return prevStatus;
            }
        });
    }, [errors, saveEntityLoading, status]);
    (0, react_1.useEffect)(function () {
        if (isValid) {
            setSaveAttemptedWithLocalErrors(false);
        }
    }, [isValid]);
    var save = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        var error_1, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isValid) {
                        setSaveAttemptedWithLocalErrors(true);
                        return [2 /*return*/];
                    }
                    setSaveAttemptedWithLocalErrors(false);
                    if (!shouldBatchCreate) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, batchCreate({
                            workflowTemplateId: workflowTemplateId !== null && workflowTemplateId !== void 0 ? workflowTemplateId : '',
                            fields: (0, toFieldJson_1.toFieldJson)(fields, workflowTemplate),
                        })];
                case 2:
                    _a.sent();
                    setStatus('success');
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    setBatchCreateError(error_1);
                    setStatus('error');
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 8];
                case 5:
                    _a.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, saveEntity(fields)];
                case 6:
                    _a.sent();
                    setStatus('success');
                    return [3 /*break*/, 8];
                case 7:
                    error_2 = _a.sent();
                    setStatus('error');
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); }, [
        fields,
        saveEntity,
        isValid,
        shouldBatchCreate,
        batchCreate,
        workflowTemplateId,
        workflowTemplate,
    ]);
    var resetForm = (0, react_1.useCallback)(function () {
        resetFields();
        setStatus('input');
    }, [resetFields, setStatus]);
    return {
        components: components,
        fields: fields,
        errors: errors,
        viewTemplateTitle: (_b = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.name) !== null && _b !== void 0 ? _b : '',
        updateField: updateField,
        onPressSave: save,
        isLoading: status === 'loading',
        status: status,
        isDirty: isDirty,
        setStatus: setStatus,
        resetForm: resetForm,
    };
}
