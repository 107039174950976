/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { gql, graphql } from '@common/graphql';
import Loading from '@src/components/Loading';
import LoginPeopleList from '@src/components/LoginPeopleList';
import PureComponent from '@src/components/PureComponent';
import { setBizToken } from '@src/core/AuthTokenManager';
import { apiFetch } from '@src/core/HTTPAPI';
import { actions } from '@src/core/Router';
import { updateAuth } from '@src/core/Store';
import authenticate from '@src/core/authenticate';
import updateCurrentBusinessId from '@src/redux/actions/updateCurrentBusinessId';
import type { StoreState } from '@src/types/StoreTypes';

type Props = {
  viewportHeight: number,
  isMobile: boolean,
  data: {
    myBusiness?: {
      img: string,
      name: string,
      users: Array<{
        id: number,
        firstName: string,
        lastName: string,
        img: string,
        hasPasscode: boolean,
        lastVisitedAt: ?string,
        lastRemindedAt: ?string,
        createdAt: string,
      }>,
    },
  },
  updateCurrentBusinessId: typeof updateCurrentBusinessId,
};

type State = {
  error: ?string,
};

class LoginPeopleListContainer extends PureComponent<*, Props, State> {
  isSelecting: boolean;

  static defaultProps = {
    data: {},
  };

  state = { error: '' };

  handleUserSelect = (event, userId) => {
    if (this.isSelecting) {
      return;
    }
    this.isSelecting = true;

    if (!this.props.data.myBusiness) {
      return;
    }
    const user = this.props.data.myBusiness.users.find(u => u.id === userId);
    let passcode = '0000';
    if (user && user.hasPasscode) {
      passcode = prompt('Please enter your passcode'); // eslint-disable-line no-alert
    }
    apiFetch('api/auth', { method: 'POST', data: { userId, passcode } })
      .then(data => {
        this.props.updateCurrentBusinessId(data.businessId);
        authenticate(data);
      })
      .catch(err => {
        this.isSelecting = false;
        if (err.status === 401) {
          // Don't log wrong passcode errors
          this.setState({ error: 'Wrong Passcode.' });
          return Promise.resolve();
        }
        this.setState({ error: 'Unexpected Error Happened. Please try again' });
        return Promise.reject(err);
      });
  };

  handleLogOut = () => {
    setBizToken('');
    updateAuth('/signin');
  };

  handleCreateAccount = ev => {
    ev && typeof ev.preventDefault === 'function' && ev.preventDefault();
    actions.pushRoute('/auth/user/create');
  };

  render() {
    if (!this.props.data.myBusiness) {
      return <Loading isCentered={true} />;
    }
    return (
      <div style={{ height: this.props.viewportHeight }}>
        <LoginPeopleList
          error={this.state.error}
          onUserSelect={this.handleUserSelect}
          onCreateAccount={this.handleCreateAccount}
          onLogOut={this.handleLogOut}
          isMobile={this.props.isMobile}
          business={this.props.data.myBusiness}
        />
      </div>
    );
  }
}

const ConnectedLoginPeopleListContainer = connect(
  (state: StoreState) => ({
    isMobile: state.appState.viewportSizeMobile,
    viewportHeight: state.appState.viewportHeight,
  }),
  { updateCurrentBusinessId },
)(LoginPeopleListContainer);

const businessMemberListQuery = gql`
  query LoginPeopleListContainerQuery {
    myBusiness {
      id
      img
      name
      users {
        id
        firstName
        lastName
        img
        hasPasscode
        lastVisitedAt
        lastRemindedAt
        createdAt
      }
    }
  }
`;

export default graphql(businessMemberListQuery, {
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
})(ConnectedLoginPeopleListContainer);
