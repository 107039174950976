"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUnreadCardCount = useUnreadCardCount;
exports.useUnreadMessagesCount = useUnreadMessagesCount;
var useChannelDetails_1 = require("./useChannelDetails");
function useUnreadCardCount(channelSlug) {
    var channel = (0, useChannelDetails_1.useChannelDetails)({ channelSlug: channelSlug });
    if (channel) {
        return channel.unreadCount - channel.unreadMessagesCount;
    }
    else {
        return 0;
    }
}
function useUnreadMessagesCount(channelSlug) {
    var _a;
    var channel = (0, useChannelDetails_1.useChannelDetails)({ channelSlug: channelSlug });
    return (_a = channel === null || channel === void 0 ? void 0 : channel.unreadMessagesCount) !== null && _a !== void 0 ? _a : 0;
}
