"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Card = Card;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var label_1 = require("@shared/components/blocks/utils/label");
var utils_1 = require("@shared/data/cards/utils");
var context_1 = require("@shared/scenes/channel/webLayout/context");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var CollectionViewCard_1 = require("../../collectionView/components/CollectionViewCard");
var CardCollectionViewField_1 = require("../../collectionView/components/fields/CardCollectionViewField");
var listViewCardStyles_1 = require("./listViewCardStyles");
function Card(_a) {
    var viewTemplate = _a.viewTemplate, workflowTemplate = _a.workflowTemplate, openEntity = _a.openEntity, entity = _a.entity, analytics = _a.analytics, _b = _a.canUpdateCard, canUpdateCard = _b === void 0 ? false : _b, _c = _a.isRedacted, isRedacted = _c === void 0 ? false : _c, _d = _a.hasOverflowMenu, hasOverflowMenu = _d === void 0 ? true : _d, cardStyles = _a.cardStyles, testID = _a.testID;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var mode = (0, react_1.useContext)(context_1.WebLayoutContext).mode;
    var subtype = viewTemplate.subtype;
    var isWideCardLayout = getIsWideCardLayout({ mode: mode, subtype: subtype });
    var _e = useVisibleComponents({
        workflowTemplate: workflowTemplate,
        viewTemplate: viewTemplate,
        entity: entity,
    }), firstComponent = _e[0], otherComponents = _e[1];
    var onPress = function () {
        openEntity === null || openEntity === void 0 ? void 0 : openEntity(entity.id, entity.navigationViewTemplateId);
    };
    var style = {
        paddingHorizontal: (0, components_1.grid)(1),
        paddingVertical: (0, components_1.grid)(0.5),
    };
    return (react_1.default.createElement(components_1.MBView, { flex: true, style: style, testID: testID },
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress, activeOpacity: 0.9 },
            react_1.default.createElement(CollectionViewCard_1.CollectionViewCard, { workflowTemplate: workflowTemplate, entity: entity, openEntity: openEntity, hasOverflowMenu: hasOverflowMenu, cardStyles: cardStyles },
                react_1.default.createElement(components_1.MBView, { style: { opacity: isRedacted ? 0.6 : 1.0 } },
                    react_1.default.createElement(components_1.MBView, { paddingRight: 2 }, !(0, lodash_1.isNil)(firstComponent) && (react_1.default.createElement(CardCollectionViewComponent, { component: firstComponent, entity: entity, workflowTemplateId: workflowTemplate.id, fieldStyles: listViewCardStyles_1.headerFieldStyles, showLabel: isRedacted ? false : !viewTemplate.defaultHiddenLabels, colors: colors, analytics: analytics, canUpdateCard: canUpdateCard, isRedacted: isRedacted, isCollapsed: !isWideCardLayout }))),
                    react_1.default.createElement(components_1.MBView, { style: [isWideCardLayout && { flexDirection: 'row', flexWrap: 'wrap' }] },
                        !isRedacted &&
                            otherComponents.map(function (component) {
                                return (react_1.default.createElement(CardCollectionViewComponent, { key: component.id, component: component, entity: entity, workflowTemplateId: workflowTemplate.id, fieldStyles: isWideCardLayout ? listViewCardStyles_1.wideCardFieldStyles : listViewCardStyles_1.narrowCardFieldStyles, showLabel: !viewTemplate.defaultHiddenLabels, colors: colors, analytics: analytics, canUpdateCard: canUpdateCard, isRedacted: false, isCollapsed: !isWideCardLayout }));
                            }),
                        isRedacted && (react_1.default.createElement(react_native_1.View, { style: {
                                backgroundColor: styles_1.Colors.v2.grayBackground,
                                borderRadius: (0, components_1.grid)(1),
                                height: (0, components_1.grid)(1.5),
                                maxWidth: (0, components_1.grid)(35),
                                width: '100%',
                                marginLeft: (0, components_1.grid)(2),
                                marginBottom: (0, components_1.grid)(2),
                            } }))))))));
}
function getIsWideCardLayout(_a) {
    var mode = _a.mode, subtype = _a.subtype;
    switch (subtype) {
        case 'BOARD':
            return false;
        default:
            return ['split-workflow', 'workflow'].includes(mode);
    }
}
function useVisibleComponents(_a) {
    var workflowTemplate = _a.workflowTemplate, viewTemplate = _a.viewTemplate, entity = _a.entity;
    return (0, react_1.useMemo)(function () {
        var filtered = workflowTemplate.components
            .filter(function (component) {
            var _a;
            var viewOptions = (_a = viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (options) { return options.componentId === component.id; });
            var field = entity.fields.get(component.id);
            var needsFieldValue = (0, hooks_1.isInputComponent)(component);
            var isFieldEmpty = (0, lodash_1.isNil)(field) || field.isEmpty;
            var isComponentHidden = (0, utils_1.isComponentHidden)(viewTemplate, viewOptions, component);
            return !isComponentHidden && (!isFieldEmpty || !needsFieldValue);
        })
            .map(function (component) { return (0, utils_1.getComponentWithOverrides)(component, viewTemplate); });
        var firstComponent = filtered[0], otherComponents = filtered.slice(1);
        return [firstComponent, otherComponents];
    }, [workflowTemplate, viewTemplate, entity]);
}
function getIcon(component) {
    return 'icon' in component ? component.icon : null;
}
function getLabel(component) {
    if ((0, hooks_1.isInputComponent)(component)) {
        return component.label;
    }
    if ((0, hooks_1.isCombinedTagsComponent)(component)) {
        return component.label;
    }
    if ((0, hooks_1.isInputButtonComponent)(component)) {
        return null;
    }
    return null;
}
function CardCollectionViewComponent(_a) {
    var component = _a.component, entity = _a.entity, workflowTemplateId = _a.workflowTemplateId, fieldStyles = _a.fieldStyles, showLabel = _a.showLabel, colors = _a.colors, analytics = _a.analytics, canUpdateCard = _a.canUpdateCard, height = _a.height, _b = _a.isRedacted, isRedacted = _b === void 0 ? false : _b, _c = _a.isCollapsed, isCollapsed = _c === void 0 ? false : _c;
    var field = entity.fields.get(component.id);
    var componentIconName = getIcon(component);
    var componentLabel = getLabel(component);
    return (react_1.default.createElement(components_1.MBView, { row: true, paddingVertical: showLabel ? 1 : 0.5, paddingBottom: isRedacted || (isCollapsed && !showLabel) ? 0 : 1, paddingHorizontal: 0.75, style: { minHeight: isRedacted || (isCollapsed && !showLabel) ? 0 : height } },
        !(0, lodash_1.isEmpty)(componentIconName) && (react_1.default.createElement(components_1.MBView, { paddingTop: 0.3 },
            react_1.default.createElement(components_1.MBIconV2, { name: componentIconName, size: (0, components_1.grid)(4), color: colors.accent }))),
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 1, fill: true },
            showLabel && (0, lodash_1.isString)(componentLabel) && !(0, lodash_1.isEmpty)(componentLabel) && (react_1.default.createElement(label_1.MBBlockLabel, { label: componentLabel })),
            react_1.default.createElement(CardCollectionViewField_1.CardCollectionViewField, { field: field, workflowTemplateId: workflowTemplateId, entityId: entity.id, component: component, fieldStyles: fieldStyles, isCollapsed: isCollapsed, showLabel: showLabel, canUpdateCard: canUpdateCard, analytics: analytics }))));
}
