"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonField = void 0;
exports.usePersons = usePersons;
var lodash_1 = require("lodash");
var react_1 = require("react");
var hooks_1 = require("@shared/util/hooks");
var notNil_1 = require("@shared/util/notNil");
var CURRENT_USER_TOKEN = '{me}';
var PersonField = /** @class */ (function () {
    function PersonField(jsonBlob, personRefs, currentUser) {
        this.fieldType = 'PersonField';
        this.jsonBlob = jsonBlob;
        this.currentUser = currentUser;
        if (jsonBlob === CURRENT_USER_TOKEN) {
            this.users = currentUser ? [currentUser] : [];
        }
        else {
            this.users = !(0, lodash_1.isNil)(personRefs)
                ? jsonBlob.map(function (id) { return personRefs === null || personRefs === void 0 ? void 0 : personRefs.find(function (person) { return person.id === id; }); }).filter(notNil_1.notNil)
                : [];
        }
    }
    PersonField.prototype.validate = function () {
        return null;
    };
    PersonField.prototype.isEqual = function (field) {
        return field instanceof PersonField && (0, lodash_1.isEqual)(this.jsonBlob, field.jsonBlob);
    };
    PersonField.prototype.toFieldJson = function () {
        if (this.jsonBlob === CURRENT_USER_TOKEN) {
            return !(0, lodash_1.isNil)(this.currentUser) ? [this.currentUser.id] : [];
        }
        return this.jsonBlob;
    };
    Object.defineProperty(PersonField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isEmpty)(this.jsonBlob);
        },
        enumerable: false,
        configurable: true
    });
    return PersonField;
}());
exports.PersonField = PersonField;
function usePersons(field) {
    var missingPersons = (0, react_1.useMemo)(function () {
        var _a;
        var ids = (0, lodash_1.isArray)(field === null || field === void 0 ? void 0 : field.jsonBlob) ? field.jsonBlob.filter(lodash_1.isNumber) : [];
        var userIds = (_a = field === null || field === void 0 ? void 0 : field.users.map(function (user) { return user.id; })) !== null && _a !== void 0 ? _a : [];
        return ids.filter(function (id) { return !userIds.includes(id); });
    }, [field]);
    var _a = (0, hooks_1.useListUsersById)(missingPersons), users = _a.users, loading = _a.loading;
    var persons = (0, react_1.useMemo)(function () { var _a; return __spreadArray(__spreadArray([], ((_a = field === null || field === void 0 ? void 0 : field.users) !== null && _a !== void 0 ? _a : []), true), users, true); }, [field, users]);
    return { loading: loading, persons: persons };
}
