"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBMenu = MBMenu;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var components_1 = require("@shared/components");
var useRefetchOnReconnect_1 = require("@shared/util/apollo/useRefetchOnReconnect");
var hooks_1 = require("@shared/util/hooks");
var navigation_1 = require("@shared/util/navigation");
var components_2 = require("../components");
var useLogout_1 = require("./useLogout");
function MBMenu(_a) {
    var _b;
    var selectedItem = _a.selectedItem, onSwitchBusiness = _a.onSwitchBusiness;
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var _c = (0, hooks_1.useProfile)(), profile = _c.profile, refetch = _c.refetch;
    var businesses = (0, hooks_1.useBusinesses)();
    var business = (0, react_1.useMemo)(function () { return businesses === null || businesses === void 0 ? void 0 : businesses.find(function (b) { return b.id === businessId; }); }, [businesses, businessId]);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    (0, useRefetchOnReconnect_1.useRefetchOnReconnect)(refetch);
    var logout = (0, useLogout_1.useLogout)();
    var openDirectory = (0, navigation_1.useNavigateToRoute)('Directory');
    var openUserGroups = (0, navigation_1.useNavigateToRoute)('UserGroups');
    var navigateToLinks = (0, navigation_1.useNavigateToRoute)('BaseToolsTab');
    var navigateToBilling = (0, navigation_1.useNavigateToRoute)('Billing');
    if ((0, lodash_1.isNil)(profile) || (0, lodash_1.isNil)(business)) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true, padding: "large" });
    }
    return (react_1.default.createElement(components_2.MBMenuComponent, { selectedItem: selectedItem, user: profile, business: business, isBusinessAdmin: (_b = profile.isBusinessAdmin) !== null && _b !== void 0 ? _b : false, isBusinessSwitcherEnabled: !isDesktop, onLogOut: logout, onLinks: navigateToLinks, onBilling: navigateToBilling, onDirectory: openDirectory, onUserGroups: openUserGroups, onSwitchBusiness: onSwitchBusiness }));
}
