"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardEntity = void 0;
// @ts-strict-ignore
var schemas_1 = require("@coast/schemas");
var CardField_1 = require("./CardField");
var utils_1 = require("./utils");
var CardEntity = /** @class */ (function () {
    function CardEntity(_a) {
        var apiCard = _a.apiCard, workflowTemplateId = _a.workflowTemplateId, refs = _a.refs, viewTemplate = _a.viewTemplate, currentUser = _a.currentUser;
        var _this = this;
        var _b, _c;
        this.id = apiCard.id;
        this.fields = new Map();
        this.allFields = new Map();
        this.workflowTemplateId = workflowTemplateId;
        this.currentUser = currentUser;
        var workflowTemplate = refs.workflowTemplateRefs.find(function (w) { return w.id === workflowTemplateId; });
        this.navigationViewTemplateId = (_c = (_b = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.behavior) === null || _b === void 0 ? void 0 : _b.onOpenCard.selectors.find(function (selector) { return (0, schemas_1.evaluateSelector)(selector.selector, apiCard.fields); })) === null || _c === void 0 ? void 0 : _c.viewTemplateId;
        workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.forEach(function (component) {
            var viewOptions = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions.find(function (options) { return options.componentId === component.id; });
            if (!(0, utils_1.isComponentHidden)(viewTemplate, viewOptions, component)) {
                _this.fields.set(component.id, (0, CardField_1.buildCardField)({ apiCard: apiCard, component: component, refs: refs, currentUser: currentUser }));
            }
            _this.allFields.set(component.id, (0, CardField_1.buildCardField)({ apiCard: apiCard, component: component, refs: refs, currentUser: currentUser }));
        });
    }
    return CardEntity;
}());
exports.CardEntity = CardEntity;
