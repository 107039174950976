"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAllChannelUsers = useAllChannelUsers;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
var notNil_1 = require("../notNil");
function useAllChannelUsers(_a) {
    var _b, _c;
    var channelSlug = _a.channelSlug;
    var _d = (0, client_1.useQuery)(api_1.Queries.channel.allUsers, {
        variables: { slug: channelSlug !== null && channelSlug !== void 0 ? channelSlug : '' },
        skip: (0, lodash_1.isNil)(channelSlug),
    }), data = _d.data, loading = _d.loading;
    return (0, react_1.useMemo)(function () {
        var _a, _b, _c, _d, _e;
        return {
            users: (_b = (_a = data === null || data === void 0 ? void 0 : data.channel) === null || _a === void 0 ? void 0 : _a.users.filter(notNil_1.notNil)) !== null && _b !== void 0 ? _b : [],
            removedUsers: (_e = (_d = (_c = data === null || data === void 0 ? void 0 : data.channel) === null || _c === void 0 ? void 0 : _c.removedUsers) === null || _d === void 0 ? void 0 : _d.filter(notNil_1.notNil)) !== null && _e !== void 0 ? _e : [],
            loading: loading,
        };
    }, [(_b = data === null || data === void 0 ? void 0 : data.channel) === null || _b === void 0 ? void 0 : _b.users, (_c = data === null || data === void 0 ? void 0 : data.channel) === null || _c === void 0 ? void 0 : _c.removedUsers, loading]);
}
