"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowNumberComponent = WorkflowNumberComponent;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var util_1 = require("@shared/components/blocks/lib/inputNumber/util");
var hooks_1 = require("../../hooks");
var WorkflowTextComponent_1 = require("./WorkflowTextComponent");
/*
  TODO
  This component is only used as a readOnly lookup component.
  Will need changes to use in forms. See inputNumber.
*/
function WorkflowNumberComponent(_a) {
    var initialData = _a.initialData, component = _a.component, isReadOnly = _a.isReadOnly, showLabel = _a.showLabel, paddingHorizontal = _a.paddingHorizontal, iconPad = _a.iconPad, showIcon = _a.showIcon;
    var numberFormat = component.format;
    var displayString = (0, react_1.useMemo)(function () { return formatData(initialData, numberFormat); }, [initialData, numberFormat]);
    var readOnly = isReadOnly || component.readonly;
    if (!(0, hooks_1.isNumberComponent)(component)) {
        return null;
    }
    if (readOnly) {
        return (react_1.default.createElement(WorkflowTextComponent_1.WorkflowTextComponent, { initialData: displayString, component: component, isReadOnly: true, showLabel: showLabel, paddingHorizontal: paddingHorizontal, iconPad: iconPad, showIcon: showIcon }));
    }
    return null;
}
function formatData(data, numberFormat) {
    if ((0, lodash_1.isNil)(data)) {
        return null;
    }
    if (Array.isArray(data)) {
        var displayableData = data.filter(function (d) { return !(0, lodash_1.isNil)(d); });
        return displayableData.length > 0
            ? displayableData.map(function (d) { return (0, util_1.formatNumber)(d, numberFormat); }).join(', ')
            : null;
    }
    return (0, util_1.formatNumber)(data, numberFormat);
}
