"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCollectionCardTemplate = useCollectionCardTemplate;
var react_1 = require("react");
var hooks_1 = require("@shared/scenes/cards/hooks");
var hooks_2 = require("@shared/util/hooks");
var constants_1 = require("./constants");
var useCachedTemplateSlug_1 = require("./useCachedTemplateSlug");
function useCollectionCardTemplate(_a) {
    var channelSlug = _a.channelSlug, templateSlug = _a.templateSlug, context = _a.context;
    var previousTemplateSlug = (0, useCachedTemplateSlug_1.useCachedTemplateSlug)(channelSlug, templateSlug);
    var channel = (0, hooks_2.useChannel)({ channelSlug: channelSlug });
    var cardDefinition = (0, hooks_1.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).cardDefinition;
    var templateQuery = (0, react_1.useMemo)(function () {
        var isQueryingSpecificTemplate = !!templateSlug || !!context;
        if (!isQueryingSpecificTemplate) {
            return {
                channelSlug: channelSlug,
                contexts: constants_1.collectionContexts,
                templateSlug: previousTemplateSlug,
                cardDefinition: cardDefinition,
            };
        }
        return {
            channelSlug: channelSlug,
            cardDefinition: cardDefinition,
            templateSlug: templateSlug,
            contexts: !!context ? [context] : constants_1.collectionContexts,
        };
    }, [channelSlug, previousTemplateSlug, templateSlug, context, cardDefinition]);
    return (0, hooks_1.useCardTemplate)(templateQuery);
}
