"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.megaModalHistory = void 0;
exports.setSharedHistory = setSharedHistory;
exports.useNavigator = useNavigator;
exports.useNavigationFocus = useNavigationFocus;
exports.useNavigationBeforeLeave = useNavigationBeforeLeave;
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var index_web_1 = require("@shared/util/sentry/index.web");
var hooks_1 = require("./hooks");
var routeMapping_1 = require("./routeMapping");
var globalHistory;
exports.megaModalHistory = (0, react_router_1.createMemoryHistory)();
function setSharedHistory(history) {
    globalHistory = history;
}
function getLocationDescriptorForRoute(routeName, params) {
    var _a, _b;
    var newPath = (_b = (_a = routeMapping_1.browserRouteMapping[routeName]) === null || _a === void 0 ? void 0 : _a.call(routeMapping_1.browserRouteMapping, params)) !== null && _b !== void 0 ? _b : '';
    var _c = newPath.split('?'), pathname = _c[0], query = _c[1];
    return {
        pathname: pathname,
        search: !(0, lodash_1.isNil)(query) ? "?".concat(query) : undefined,
        // Keeping routeName and params here to be used in navigation interceptors (e.g: useNavigationBeforeLeave)
        state: {
            name: routeName,
            params: params,
        },
    };
}
function handleRoute(op, routeName, params) {
    if (!!routeMapping_1.browserRouteMapping[routeName]) {
        var location_1 = getLocationDescriptorForRoute(routeName, params);
        globalHistory[op](location_1);
        exports.megaModalHistory.push('/');
    }
    else if (!!routeMapping_1.megaModalRouteMapping[routeName]) {
        exports.megaModalHistory[op](routeMapping_1.megaModalRouteMapping[routeName](params)); // TODO: fix use of any
    }
    else {
        index_web_1.MBSentry.exception({ error: new Error("invalid web route: ".concat(routeName)) });
    }
}
function useNavigator() {
    var navigate = (0, react_1.useCallback)(function (routeName, params) {
        handleRoute('push', routeName, params);
    }, []);
    var replace = (0, react_1.useCallback)(function (routeName, params) { return handleRoute('replace', routeName, params); }, []);
    var goBack = (0, react_1.useCallback)(function () {
        var location = globalHistory.getCurrentLocation();
        if (!(0, lodash_1.isEmpty)(location.key)) {
            globalHistory.goBack();
        }
        else {
            handleRoute('replace', 'Home', undefined);
        }
    }, []);
    return (0, react_1.useMemo)(function () { return ({
        navigate: navigate,
        replace: replace,
        reset: navigate,
        goBack: goBack,
        canGoBack: function () { return true; },
        openMenu: function () { return exports.megaModalHistory.push('/menu'); },
        closeMenu: function () { return exports.megaModalHistory.push('/'); },
        getHistory: function () { return globalHistory; },
    }); }, [goBack, navigate, replace]);
}
function useNavigationFocus() {
    var hidden = (0, react_redux_1.useSelector)(function (state) { return state.appState.isHidden; });
    return !hidden;
}
function useNavigationBeforeLeave(callback) {
    var _a = (0, react_1.useContext)(hooks_1.ReactRouterContext), router = _a.router, routes = _a.routes;
    (0, react_1.useEffect)(function () {
        if (!!router) {
            router.setRouteLeaveHook(routes[routes.length - 1], function (route) {
                return callback(route === null || route === void 0 ? void 0 : route.state);
            });
        }
    }, [callback, router, routes]);
}
