"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RETURN_CHANNEL_SLUG_QUERY = void 0;
exports.CardBulkUpdateRoute = CardBulkUpdateRoute;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var scroll_1 = require("@shared/components/blocks/contexts/scroll");
var toast_1 = require("@shared/components/toast");
var CardEntityBulkEditForm_1 = require("@shared/scenes/entity/edit/components/CardEntityBulkEditForm");
var useEditRecurringEntity_1 = require("@shared/scenes/entity/edit/hooks/useEditRecurringEntity");
var constants_1 = require("@shared/styles/constants");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
var ComponentFocus_1 = require("../components/ComponentFocus");
var BulkUpdateOverflowButton_1 = require("./BulkUpdateOverflowButton");
var useShouldShowUpdateSubmitButton_1 = require("./useShouldShowUpdateSubmitButton");
exports.RETURN_CHANNEL_SLUG_QUERY = 'returnChannelSlug';
function CardBulkUpdateRoute(_a) {
    var params = _a.params, location = _a.location;
    var entityId = params.cardId, channelSlug = params.channelSlug, workflowTemplateId = params.workflowTemplateId, recurringEntitiesScheduleId = params.recurringEntitiesScheduleId, allOrUpcoming = params.allOrUpcoming;
    var channel = (0, hooks_1.useChannel)({ channelSlug: channelSlug });
    var _b = (0, navigation_1.useNavigator)(), goBack = _b.goBack, replace = _b.replace;
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var returnTo = (0, react_1.useMemo)(function () {
        if ((0, lodash_1.isNil)(location.query[exports.RETURN_CHANNEL_SLUG_QUERY])) {
            return undefined;
        }
        return {
            channelSlug: location.query[exports.RETURN_CHANNEL_SLUG_QUERY],
            cardId: location.query.returnCardId,
        };
    }, [location.query]);
    var onBack = (0, react_1.useCallback)(function () {
        if ((0, lodash_1.isNil)(returnTo)) {
            goBack();
        }
        else {
            replace((0, lodash_1.isNil)(returnTo === null || returnTo === void 0 ? void 0 : returnTo.cardId) ? 'CardCreate' : 'CardUpdate', __assign(__assign({}, returnTo), { restoreSavedData: true }));
        }
    }, [goBack, replace, returnTo]);
    var _c = (0, useEditRecurringEntity_1.useEditRecurringEntity)({
        entityId: entityId,
        workflowTemplateId: workflowTemplateId,
        recurringEntitiesScheduleId: recurringEntitiesScheduleId,
        allOrUpcoming: allOrUpcoming,
    }), components = _c.components, fields = _c.fields, updateField = _c.updateField, errors = _c.errors, isDirty = _c.isDirty, onPressSave = _c.onPressSave, isLoading = _c.isLoading, status = _c.status;
    var isSaveSuccessful = status === 'success';
    var showUnsavedChangesWarning = !isSaveSuccessful && isDirty;
    (0, navigation_1.useUnsavedChangesNavigationWarning)(showUnsavedChangesWarning);
    var updateAll = allOrUpcoming === 'all';
    var successMessage = updateAll
        ? (0, i18n_1.i18n)('card.bulkUpdate.success.all')
        : (0, i18n_1.i18n)('card.bulkUpdate.success.upcoming');
    (0, react_1.useEffect)(function () {
        // close modal if save was successful
        if (isSaveSuccessful) {
            goBack();
            toast.show({
                icon: 'check-filled',
                message: successMessage,
            });
        }
    }, [isSaveSuccessful, channelSlug, goBack, successMessage, toast]);
    var title = (0, i18n_1.i18n)('card.bulkUpdate.title');
    var rightContentProps = {
        channel: channel,
        isDirty: isDirty,
        onPressSave: onPressSave,
        isLoading: isLoading,
        channelSlug: channelSlug,
        entityId: entityId,
        workflowTemplateId: workflowTemplateId,
        recurringEntitiesScheduleId: recurringEntitiesScheduleId,
    };
    return (react_1.default.createElement(components_1.MBModalOrMobile, { desktopModalWidth: constants_1.Constants.pageModalWidth, desktopModalHeight: 'calc(95vh - 76px)' /* height of bottom button */, title: title, paddingOnWeb: "none", onBack: onBack, desktopModalRightButton: react_1.default.createElement(RightContent, __assign({}, rightContentProps)), headerOnMobile: react_1.default.createElement(components_1.MBWebNavigationHeader, { title: title, rightButton: react_1.default.createElement(RightContent, __assign({}, rightContentProps)), onPressLeft: goBack }) },
        react_1.default.createElement(ComponentFocus_1.ComponentFocusProvider, null,
            react_1.default.createElement(scroll_1.CardBlockScrollProvider, null,
                isLoading && react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true }),
                !isLoading && (react_1.default.createElement(CardEntityBulkEditForm_1.CardEntityBulkEditForm, { fields: fields, updateField: updateField, components: components, errors: errors, workflowTemplateId: params.workflowTemplateId, allOrUpcoming: params.allOrUpcoming, entityId: entityId }))))));
}
function RightContent(_a) {
    var channel = _a.channel, isDirty = _a.isDirty, onPressSave = _a.onPressSave, isLoading = _a.isLoading, channelSlug = _a.channelSlug, entityId = _a.entityId, workflowTemplateId = _a.workflowTemplateId, recurringEntitiesScheduleId = _a.recurringEntitiesScheduleId;
    return (react_1.default.createElement(components_1.MBView, { row: true, paddingRight: 1 },
        react_1.default.createElement(SaveButton, { channel: channel, isDirty: isDirty, onPressSave: onPressSave, isLoading: isLoading }),
        react_1.default.createElement(BulkUpdateOverflowButton_1.BulkUpdateOverflowButton, { entityId: entityId, workflowTemplateId: workflowTemplateId, recurringEntitiesScheduleId: recurringEntitiesScheduleId, channelSlug: channelSlug })));
}
function SaveButton(_a) {
    var _b;
    var channel = _a.channel, isDirty = _a.isDirty, onPressSave = _a.onPressSave, isLoading = _a.isLoading;
    var showSaveButton = (0, useShouldShowUpdateSubmitButton_1.useShouldShowUpdateSubmitButton)(isDirty, (_b = channel === null || channel === void 0 ? void 0 : channel.id) !== null && _b !== void 0 ? _b : -1);
    if (!showSaveButton) {
        return null;
    }
    return react_1.default.createElement(components_1.MBHeaderButton, { title: (0, i18n_1.i18n)('common.save'), onPress: onPressSave, loading: isLoading });
}
