"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardDetails = MBCardDetails;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var blocks_1 = require("@shared/components/blocks");
var form_1 = require("@shared/components/blocks/contexts/form");
var render_1 = require("@shared/components/blocks/contexts/render");
var scroll_1 = require("@shared/components/blocks/contexts/scroll");
var hooks_1 = require("@shared/scenes/cards/hooks");
var navigation_1 = require("@shared/util/navigation");
var PrintContext_1 = require("@shared/util/print/PrintContext");
var styles = react_native_1.StyleSheet.create({
    flex: {
        flex: 1,
    },
    fieldContainer: {
        width: '100%',
        maxWidth: 456, // copied from Figma
        alignSelf: 'center',
    },
});
function useScrollToTopOnNavigationFocus() {
    var focused = (0, navigation_1.useNavigationFocus)();
    var scrollView = (0, react_1.useContext)(scroll_1.CardBlockScrollContext);
    (0, react_1.useEffect)(function () {
        var _a;
        if (react_native_1.Platform.OS !== 'web' && !!focused) {
            (_a = scrollView === null || scrollView === void 0 ? void 0 : scrollView.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ y: 0, animated: true });
        }
    }, [focused, scrollView]);
}
function Content(_a) {
    var card = _a.card, template = _a.template, onEdit = _a.onEdit;
    var _b = (0, hooks_1.useStickyBlocks)(template.cardBlocks), top = _b.top, scroll = _b.scroll, bottom = _b.bottom;
    var scrollView = (0, react_1.useContext)(scroll_1.CardBlockScrollContext);
    useScrollToTopOnNavigationFocus();
    var printContext = (0, react_1.useContext)(PrintContext_1.PrintContext);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(blocks_1.MBCardBlocks, { blocks: top, card: card, sticky: true }),
        react_1.default.createElement(react_native_1.ScrollView, { ref: scrollView, style: [styles.flex, styles.fieldContainer], keyboardShouldPersistTaps: "always", keyboardDismissMode: "on-drag" },
            react_1.default.createElement(blocks_1.MBCardBlocks, { blocks: scroll, card: card, onFieldPress: onEdit })),
        react_1.default.createElement(components_1.MBView, { style: [styles.fieldContainer] },
            react_1.default.createElement(blocks_1.MBCardBlocks, { blocks: bottom, card: card, sticky: true })),
        !!printContext && (react_1.default.createElement(components_1.MBView, { style: { width: 0, height: 0, overflow: 'hidden' } },
            react_1.default.createElement(components_1.MBView, { fullWidth: true, ref: printContext.contentRef },
                react_1.default.createElement(components_1.MBImage, { style: { width: 164, height: 64, marginBottom: (0, components_1.grid)(2) }, source: require('@shared/assets/images/v2/logo/color-positive.png') }),
                react_1.default.createElement(PrintContext_1.PrintModeContext.Provider, { value: true },
                    react_1.default.createElement(blocks_1.MBCardBlocks, { blocks: scroll, card: card, onFieldPress: onEdit })))))));
}
function MBCardDetails(props) {
    var _a;
    return (react_1.default.createElement(render_1.CardRenderContext.Provider, { value: "detail" },
        react_1.default.createElement(scroll_1.CardBlockScrollProvider, null,
            react_1.default.createElement(react_native_1.SafeAreaView, { style: styles.flex },
                react_1.default.createElement(form_1.MBCardFormProvider, { card: props.card, blocks: props.template.cardBlocks, cardDefinitionId: (_a = props.card) === null || _a === void 0 ? void 0 : _a.cardDefinitionId, onSubmit: props.onSubmit },
                    react_1.default.createElement(Content, __assign({}, props)))),
            react_1.default.createElement(components_1.MBNativeKeyboardSpacer, null))));
}
