"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCardBlocksWithWidth = useCardBlocksWithWidth;
exports.useColumnsToRender = useColumnsToRender;
var react_1 = require("react");
var utils_1 = require("@shared/components/blocks/utils");
function useCardBlocksWithWidth(blocks) {
    return (0, react_1.useMemo)(function () {
        var blocksToRender = blocks.filter(function (block) {
            return Boolean(block['tableHeader']);
        });
        var blockWidths = (0, utils_1.getBlockWidths)(blocksToRender);
        return blocksToRender.map(function (block, i) { return ({ block: block, width: blockWidths[i] }); });
    }, [blocks]);
}
function useColumnsToRender(card, columns) {
    return (0, react_1.useMemo)(function () {
        if (card.planLimitImposed) {
            return columns.filter(function (_a) {
                var block = _a.block;
                return block.name === 'name';
            });
        }
        else {
            return columns;
        }
    }, [columns, card]);
}
