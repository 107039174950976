"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
exports.Styles = react_native_1.StyleSheet.create({
    pill: {
        backgroundColor: styles_1.Colors.v2.backgroundGrayLight,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: styles_1.Colors.v2.gray50_15,
        borderRadius: (0, view_1.grid)(0.5),
        height: 'auto',
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
        margin: 4,
        flexShrink: 0,
        flexGrow: 0,
        paddingHorizontal: (0, view_1.grid)(0),
        paddingVertical: (0, view_1.grid)(0.25),
    },
    oneLine: {
        // @ts-ignore
        wordBreak: 'break-all',
    },
    round: {
        paddingHorizontal: (0, view_1.grid)(0.5),
        borderRadius: (0, view_1.grid)(2),
    },
    noBorder: {
        borderWidth: 0,
        paddingHorizontal: (0, view_1.grid)(0),
    },
    noLeftMargin: {
        marginLeft: 0,
    },
    noRightMargin: {
        marginRight: 0,
    },
    pillSection: {
        height: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexShrink: 1,
        alignItems: 'center',
        paddingVertical: (0, view_1.grid)(0.25),
        paddingHorizontal: (0, view_1.grid)(1),
        flexDirection: 'row',
    },
    notFirstSection: {
        borderLeftWidth: 1,
        borderStyle: 'solid',
        borderLeftColor: styles_1.Colors.v2.gray10,
    },
    italic: {
        // prevents cutting off italic ascenders
        paddingRight: (0, view_1.grid)(0.25),
        fontStyle: 'italic',
    },
    preventWrap: {
        // @ts-ignore
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    sectionHasRemove: {
        paddingRight: 0,
    },
    remove: {
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        justifyContent: 'center',
        paddingHorizontal: (0, view_1.grid)(0.5),
        // @ts-ignore
        cursor: 'pointer',
    },
    disabled: {
        opacity: 0.5,
    },
    loading: {
        paddingVertical: (0, view_1.grid)(0.25),
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
