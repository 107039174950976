import '@src/core/ServiceWorkerRegistration';

// https://stackoverflow.com/questions/42119878
import 'normalize.css';
import './index.css';
import './composes.css'; // To get it prefixed
import '@shared/styles/vars.css';
import '@shared/components/carousel/styles.css';
import { ApolloProvider } from '@apollo/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import { createApolloClient } from '@shared/api/client';
import { ResponseHeadersContext, useResponseHeaders } from '@shared/api/headers';
import { CardSelectionProvider } from '@shared/api/providers/cardSelection';
import { EntitySelectionProvider } from '@shared/api/providers/entitySelection';
import { useReconnectWebsocketOnBusinessChange } from '@shared/api/useReconnectWebsocketOnBusinessChange';
import { useResendMessages } from '@shared/api/useResendMessages';
import { MBView } from '@shared/components';
import { MBToastProvider } from '@shared/components/toast';
import { Config, setupConfig } from '@shared/config';
import { useIdentifySegment, useIdentifySentry } from '@shared/scenes/auth/identifyHooks';
import { MBBillingProvider } from '@shared/scenes/billing/provider';
import { CallProvider } from '@shared/scenes/calling/provider/index';
import { MBCardActionsProvider } from '@shared/scenes/cards/actions/provider';
import { ConnectivityNotifier } from '@shared/scenes/connection/connectivity';
import { ConnectionProvider } from '@shared/scenes/connection/provider';
import { MBUnhandledError } from '@shared/scenes/error/unhandled';
import {
  useDocumentTitle,
  useFavicon,
  useReturnQueryHandler,
} from '@shared/scenes/navigation/hooks';
import { MBMegaModal } from '@shared/scenes/navigation/megamodal/components/index';
import { useDispatchViewportDimensions } from '@shared/store/useDispatchViewportDimensions';
import { useBranchListener } from '@shared/util/branch/hooks';
import { useInitLaunchDarkly } from '@shared/util/featureFlags/launchDarkly';
import { GoogleMapsProvider } from '@shared/util/googleMaps/GoogleMapsContext';
import { MBSentry } from '@shared/util/sentry';
import { CleanupStorage } from '@shared/util/storage/CleanupStorage';
import { init as initFacebookPixel } from '@src/core/FacebookPixel';
import Segment from '@src/core/Segment';
import { addOnVisibilityChange, getVisibility } from '@src/core/VisibilityAPI';
import fetchAndInsertUserIdIntoStore from '@src/redux/actions/fetchAndInsertUserIdIntoStore';
import { configureStore } from '@src/redux/configureStore';
import { ForcedLogout } from './components/ForcedLogout';
import { useDataFreshness } from './hooks/useDataFreshness';
import { AppRouter } from './routes';

moment.locale(navigator.language);
global._babelPolyfill = false;

let LaunchDarklyProvider;

// When code changes, re-render the whole app
if (module.hot) {
  module.hot.accept('./routes.js', function () {
    ReactDOM.render(<App />, document.getElementById('root'));
  });
}

function HooksThatNeedProviders({ children }) {
  useFavicon();
  useDocumentTitle();
  useIdentifySentry();
  useIdentifySegment();
  useReconnectWebsocketOnBusinessChange();
  useReturnQueryHandler();
  useDispatchViewportDimensions();
  useResendMessages();
  useBranchListener();
  useInitLaunchDarkly();

  return children;
}

function DataFreshness({ client }) {
  useDataFreshness(client);
  return null;
}
const safeAreaInsets = { top: 0, left: 0, right: 0, bottom: 0 };

function App() {
  const [store, setStore] = useState(undefined);
  const [client, setClient] = useState(undefined);

  const { link, headers } = useResponseHeaders();
  const notifier = useMemo(() => new ConnectivityNotifier(), []);

  useEffect(() => {
    window.addEventListener('offline', () => notifier.offline('window::offline'));
    window.addEventListener('online', () => notifier.online('window::online'));

    const init = async () => {
      MBSentry.init();
      Segment.init();
      initFacebookPixel();

      const newStore = configureStore();
      const newClient = createApolloClient([link], notifier);

      await fetchAndInsertUserIdIntoStore(newStore.dispatch);

      setStore(newStore);
      setClient(newClient);

      function onVisibilityChange({ isHidden }) {
        newStore.dispatch({ type: 'VISIBILITY_CHANGE', isHidden });
      }

      onVisibilityChange(getVisibility());
      addOnVisibilityChange(onVisibilityChange);
    };

    init();
  }, [link, notifier]);

  if (!store || !client || !LaunchDarklyProvider) {
    return null;
  }

  return (
    <LaunchDarklyProvider>
      <ConnectionProvider notifier={notifier}>
        <SafeAreaInsetsContext.Provider value={safeAreaInsets}>
          <MBUnhandledError>
            <ResponseHeadersContext.Provider value={headers}>
              <Provider store={store}>
                <ApolloProvider client={client}>
                  <GoogleMapsProvider>
                    <IntercomProvider autoBoot appId={Config.INTERCOM_APP_ID}>
                      <CallProvider>
                        <MBBillingProvider>
                          <MBCardActionsProvider>
                            <MBToastProvider>
                              <CardSelectionProvider>
                                <EntitySelectionProvider>
                                  <MBView fill>
                                    <AppRouter />
                                    <MBMegaModal />
                                    <HooksThatNeedProviders>
                                      <ForcedLogout />
                                      <DataFreshness client={client} />
                                    </HooksThatNeedProviders>
                                  </MBView>
                                </EntitySelectionProvider>
                              </CardSelectionProvider>
                            </MBToastProvider>
                          </MBCardActionsProvider>
                        </MBBillingProvider>
                      </CallProvider>
                    </IntercomProvider>
                  </GoogleMapsProvider>
                </ApolloProvider>
              </Provider>
            </ResponseHeadersContext.Provider>
          </MBUnhandledError>
        </SafeAreaInsetsContext.Provider>
        <CleanupStorage />
      </ConnectionProvider>
    </LaunchDarklyProvider>
  );
}

(async () => {
  await setupConfig();

  LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: Config.LAUNCH_DARKLY_CLIENT_KEY,
    options: {
      bootstrap: 'localStorage',
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  ReactDOM.render(<App />, document.getElementById('root'));
})();
