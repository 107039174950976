"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loop = loop;
var react_native_1 = require("react-native");
// Note: react-native-web's Animated API does not have Animated.loop.
// This is a util function for that. Note that this might not be optimal
// since JS is invoked every time the loop restarts.
function loop(animation, resetAnimation) {
    // If we have Animated.loop (aka we're on native), just return that.
    if (!!react_native_1.Animated.loop) {
        return react_native_1.Animated.loop(animation);
    }
    var isRunning = true;
    // Otherwise, we do our own light version of the loop animation implementation found here:
    // https://github.com/facebook/react-native/blob/1151c096dab17e5d9a6ac05b61aacecd4305f3db
    //  /Libraries/Animated/src/AnimatedImplementation.js
    return {
        start: function () {
            var restart = function () {
                resetAnimation === null || resetAnimation === void 0 ? void 0 : resetAnimation();
                animation.start(restart);
            };
            if (isRunning) {
                restart();
            }
        },
        stop: function () {
            animation.stop();
            isRunning = false;
        },
        reset: function () {
            resetAnimation === null || resetAnimation === void 0 ? void 0 : resetAnimation();
        },
    };
}
