"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVisibleComponents = useVisibleComponents;
var react_1 = require("react");
var utils_1 = require("@shared/data/cards/utils");
var hooks_1 = require("@shared/scenes/workflows/hooks");
function useVisibleComponents(_a) {
    var workflowTemplate = _a.workflowTemplate, viewTemplate = _a.viewTemplate, _b = _a.includeServerManagedComponents, includeServerManagedComponents = _b === void 0 ? true : _b, _c = _a.includeInputComponentsOnly, includeInputComponentsOnly = _c === void 0 ? false : _c;
    return (0, react_1.useMemo)(function () {
        var _a;
        return getVisibleComponents((_a = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components) !== null && _a !== void 0 ? _a : [], viewTemplate)
            .filter(function (component) { return includeServerManagedComponents || !component.isServerManaged; })
            .filter(function (component) { return !includeInputComponentsOnly || (0, hooks_1.isInputComponent)(component); });
    }, [
        workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components,
        viewTemplate,
        includeServerManagedComponents,
        includeInputComponentsOnly,
    ]);
}
function getVisibleComponents(components, viewTemplate) {
    return components.filter(function (component) {
        var _a;
        var viewOptions = (_a = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (option) { return option.componentId === component.id; });
        return !(0, utils_1.isComponentHidden)(viewTemplate, viewOptions, component);
    });
}
