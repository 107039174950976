"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoCodeInputBlockDate = NoCodeInputBlockDate;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var form_1 = require("@shared/components/blocks/contexts/form");
var DateField_1 = require("@shared/data/entity/fields/DateField");
var EntityBatchField_1 = require("@shared/data/entity/fields/EntityBatchField");
var DateEditField_1 = require("@shared/scenes/entity/components/fields/DateEditField");
var hooks_1 = require("@shared/scenes/workflows/hooks");
function NoCodeInputBlockDate(_a) {
    var _b;
    var workflowTemplateId = _a.workflowTemplateId, block = _a.block;
    var _c = (0, react_1.useContext)(form_1.CardFormContext), fields = _c.values, setFieldValue = _c.setFieldValue;
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var component = (_b = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components) === null || _b === void 0 ? void 0 : _b.filter(hooks_1.isDateComponent).find(function (c) { return c.id === block.name; });
    var dateData = fields[block.name];
    var field = (0, react_1.useMemo)(function () {
        return (0, lodash_1.isNil)(dateData) ? null : new DateField_1.DateField(dateData);
    }, [dateData]);
    var entityBatchField = (0, react_1.useMemo)(function () {
        return (component === null || component === void 0 ? void 0 : component.allowEntityBatchCreation)
            ? new EntityBatchField_1.EntityBatchField(fields.entityBatch, null)
            : undefined;
    }, [component, fields.entityBatch]);
    var updateField = (0, react_1.useCallback)(function (componentId, newDate) {
        setFieldValue(componentId, !(0, lodash_1.isNil)(newDate) ? newDate === null || newDate === void 0 ? void 0 : newDate.date : null);
    }, [setFieldValue]);
    if ((0, lodash_1.isNil)(component)) {
        return null;
    }
    return (react_1.default.createElement(DateEditField_1.DateEditField, { component: component, field: field, entityBatchField: entityBatchField, updateField: updateField, workflowTemplateId: workflowTemplateId, includeWrapperPadding: false }));
}
