"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatAttachment = void 0;
var react_redux_1 = require("react-redux");
var attachment_1 = require("../components/attachment");
function mapStateToProps(state, ownProps) {
    return {
        progress: state.upload.fileUploadProgressById[ownProps.attachment.fileKey],
    };
}
exports.MBChatAttachment = (0, react_redux_1.connect)(mapStateToProps, null)(attachment_1.MBChatAttachment);
