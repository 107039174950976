"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberEditField = NumberEditField;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var util_1 = require("@shared/components/blocks/lib/inputNumber/util");
var NumberField_1 = require("@shared/data/entity/fields/NumberField");
var ComponentFocus_1 = require("@shared/scenes/cards/form/components/ComponentFocus");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var hooks_2 = require("@shared/util/hooks");
var EditFieldContainer_1 = require("./EditFieldContainer");
function NumberEditField(_a) {
    var field = _a.field, component = _a.component, updateField = _a.updateField, error = _a.error;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, hooks_2.useBoolean)(false), isFocused = _b[0], onFocus = _b[1], onBlur = _b[2];
    var _c = (0, ComponentFocus_1.useComponentFocus)(), setComponentFocused = _c.setComponentFocused, setComponentBlurred = _c.setComponentBlurred;
    var handleFocus = (0, react_1.useCallback)(function () {
        onFocus();
        setComponentFocused();
    }, [onFocus, setComponentFocused]);
    var handleBlur = (0, react_1.useCallback)(function () {
        onBlur();
        setComponentBlurred();
    }, [onBlur, setComponentBlurred]);
    var updateNumber = (0, react_1.useCallback)(function (textValue) {
        var numericValue = (0, util_1.parseNumber)(textValue, component.format);
        var isValid = !(0, lodash_1.isNil)(numericValue) && !isNaN(numericValue);
        if (!isValid && !(0, lodash_1.isEmpty)(textValue)) {
            return;
        }
        updateField(component.id, !(0, lodash_1.isEmpty)(textValue)
            ? new NumberField_1.NumberField(convertFromInputToFieldsBlob(numericValue, component.format), component.format)
            : null);
    }, [updateField, component.id, component.format]);
    var onClear = (0, react_1.useCallback)(function () {
        updateField(component.id, null);
    }, [updateField, component.id]);
    var hasValue = !(0, lodash_1.isNil)(field === null || field === void 0 ? void 0 : field.jsonBlob);
    var showClearButton = hasValue && !component.readonly;
    return (react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, showLabel: hasValue, onClear: showClearButton ? onClear : undefined, error: !isFocused ? error : undefined },
        react_1.default.createElement(components_1.MBTextInput, { noBorder: true, noPadding: true, inputMode: "decimal", keyboardType: "decimal-pad", onBlur: handleBlur, onFocus: handleFocus, value: !(0, lodash_1.isNil)(field === null || field === void 0 ? void 0 : field.jsonBlob) ? getStringValue(field, component) : '', onChangeText: updateNumber, placeholder: (0, hooks_1.getComponentPlaceholder)(component), placeholderColor: colors.foregroundInactive, editable: !component.readonly, style: { paddingVertical: (0, lodash_1.isNil)(field === null || field === void 0 ? void 0 : field.jsonBlob) ? (0, components_1.grid)(1.25) : undefined } })));
}
function getStringValue(field, component) {
    if ((0, lodash_1.isNil)(field)) {
        return '';
    }
    else {
        return String(convertFromFieldsBlobToInput(field.jsonBlob, component.format));
    }
}
function convertFromFieldsBlobToInput(fieldsBlobNumber, mode) {
    if (mode === 'CURRENCY') {
        return (0, lodash_1.round)(fieldsBlobNumber / 100, 2);
    }
    return fieldsBlobNumber;
}
function convertFromInputToFieldsBlob(inputNumber, mode) {
    if (mode === 'CURRENCY') {
        return inputNumber * 100;
    }
    return inputNumber;
}
