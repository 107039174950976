"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatImageAttachmentWithPreview = MBChatImageAttachmentWithPreview;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var index_1 = require("./index");
/*
    Wrapper around MBChatImageAttachment to show image preview when pressed
*/
function MBChatImageAttachmentWithPreview(_a) {
    var attachment = _a.attachment, style = _a.style, isSending = _a.isSending, progress = _a.progress, onLongPress = _a.onLongPress, maxHeight = _a.maxHeight, backgroundColor = _a.backgroundColor, onClick = _a.onClick;
    return (React.createElement(components_1.MBView, { style: [style, !(0, lodash_1.isNil)(maxHeight) && { height: maxHeight }], testID: "MBChatImageAttachmentWithPreview" },
        React.createElement(components_1.MBTouchableOpacity, { fill: true, center: !(0, lodash_1.isNil)(maxHeight), disabled: isSending, activeOpacity: 0.7, onPress: function () { return onClick(attachment); }, onLongPress: onLongPress },
            React.createElement(index_1.MBChatImageAttachment, { attachment: attachment, isSending: isSending, progress: progress, maxHeight: maxHeight, backgroundColor: backgroundColor }))));
}
