"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeTrackerExtraSections = TimeTrackerExtraSections;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var checkbox_1 = require("@shared/components/checkbox");
var styles_1 = require("@shared/styles");
var launchDarkly_1 = require("@shared/util/featureFlags/launchDarkly");
var i18n_1 = require("@shared/util/i18n");
var hooks_1 = require("../../hooks");
function TimeTrackerExtraSections(_a) {
    var component = _a.component;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var isGeolocationFeatureEnabled = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.TIME_TRACKER_COMPONENT_GEOLOCATION);
    var _b = (0, hooks_1.useSetComponentField)(component, 'startButtonLabel'), startButtonLabel = _b[0], setStartButtonLabel = _b[1];
    var _c = (0, hooks_1.useSetComponentField)(component, 'endButtonLabel'), endButtonLabel = _c[0], setEndButtonLabel = _c[1];
    var _d = (0, hooks_1.useSetComponentField)(component, 'requiresGeolocation'), requiresGeolocation = _d[0], setRequiresGeolocation = _d[1];
    var _e = (0, hooks_1.useSetComponentField)(component, 'autoStart'), autoStart = _e[0], setAutoStart = _e[1];
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.startTimeTrackerLabel')),
            react_1.default.createElement(components_1.MBView, { paddingTop: 1 },
                react_1.default.createElement(components_1.TextInput, { value: startButtonLabel, onChangeText: setStartButtonLabel }))),
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.endTimeTrackerLabel')),
            react_1.default.createElement(components_1.MBView, { paddingTop: 1 },
                react_1.default.createElement(components_1.TextInput, { value: endButtonLabel, onChangeText: setEndButtonLabel }))),
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.autoStartLabel')),
            react_1.default.createElement(components_1.MBView, { paddingTop: 1, row: true, centerVertically: true },
                react_1.default.createElement(checkbox_1.Checkbox, { checked: autoStart, onChange: setAutoStart }),
                react_1.default.createElement(components_1.MBTextBody1, { align: "left" },
                    " ",
                    (0, i18n_1.i18n)('workflowTemplate.autoStartLabel')))),
        isGeolocationFeatureEnabled && (react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.requiresGeolocationLabel')),
            react_1.default.createElement(components_1.MBView, { paddingTop: 1, row: true, centerVertically: true },
                react_1.default.createElement(checkbox_1.Checkbox, { checked: requiresGeolocation, onChange: setRequiresGeolocation }),
                react_1.default.createElement(components_1.MBTextBody1, { align: "left" },
                    " ",
                    (0, i18n_1.i18n)('workflowTemplate.require')))))));
}
