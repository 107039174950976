"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDidPropChange = useDidPropChange;
var react_1 = require("react");
var usePrevious_1 = require("./usePrevious");
function useDidPropChange(val, callback) {
    var prev = (0, usePrevious_1.usePrevious)(val);
    (0, react_1.useEffect)(function () {
        if (prev !== val) {
            callback();
        }
    }, [prev, val, callback]);
}
