"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeImgixParams = mergeImgixParams;
exports.buildImgixUrl = buildImgixUrl;
exports.containedImageUrl = containedImageUrl;
exports.fullSizeImageUri = fullSizeImageUri;
exports.jsonImageUri = jsonImageUri;
exports.downloadFullSizeImageUri = downloadFullSizeImageUri;
exports.resizeImageUri = resizeImageUri;
exports.greyscaleImageUri = greyscaleImageUri;
/**
 * Oh the wonderful world of images...
 *
 * We have decided as a team to create the image post-processing abstraction layer in the
 * frontend and have it be purposefully built upon imgix. If we decide to move away from
 * imgix OR move our abstraction to the BE; we will refactor this module and the components
 * that depend on it.
 *
 * Assumptions:
 * 1) Images can be retrieved via imgix
 * 2) Images reside on S3 as an origin to imgix
 * 3) Images can be resized on-the-fly via imgix
 * 4) Images can have filters applied (i.e. greyscale) on-the-fly via imgix
 * 5) Clients should only request images having a size no greater than can be display on that user agent
 */
var react_native_1 = require("react-native");
var s3Staginghost = /coast-staging-uploads.s3.us-(west|east)-[12].amazonaws.com/;
var s3Prodhost = /coast-production-files.s3.us-(west|east)-[12].amazonaws.com/;
var imgixHost = /imgix.net/;
function asImgix(uri) {
    return uri
        ? uri
            .replace(s3Staginghost, 'coast-staging.imgix.net')
            .replace(s3Prodhost, 'coast-production.imgix.net')
        : uri;
}
// merges any existing query params with passed params
// only accepts imgix url.
function mergeImgixParams(uri, params) {
    if (uri && imgixHost.test(uri)) {
        var _a = uri.split('?'), base = _a[0], args = _a[1];
        var a_1 = args ? args.split('&') : [];
        Object.keys(params).forEach(function (k) { return a_1.push(params[k] ? "".concat(k, "=").concat(params[k]) : k); });
        if (a_1.length) {
            return "".concat(base, "?").concat(a_1.join('&'));
        }
        return base;
    }
    return uri;
}
// builds imgix url with any params and accepts s3 or imgix url
// light wrapper for mergeImgixParams above
function buildImgixUrl(url, params) {
    return mergeImgixParams(asImgix(url), params);
}
// helper to get an image that proportionately fits inside `dims`,
// adjusted for dpr and compressed. can take s3 or imgix url.
function containedImageUrl(url, dims) {
    return buildImgixUrl(url, {
        w: dims[0],
        h: dims[1],
        dpr: react_native_1.PixelRatio.get(),
        fit: 'max',
        auto: 'compress',
    });
}
// given s3 url, returns imgix for full size image
function fullSizeImageUri(uri) {
    return buildImgixUrl(uri, { dpr: react_native_1.PixelRatio.get(), fit: 'max', auto: 'compress' });
}
function jsonImageUri(uri) {
    return imgixHost.test(asImgix(uri)) ? buildImgixUrl(uri, { fm: 'json' }) : null;
}
// given s3 url, returns imgix download for full size image
function downloadFullSizeImageUri(uri) {
    return "".concat(mergeImgixParams(fullSizeImageUri(uri), { dl: null }));
}
// helper to get an image sized to width/height.
// accepts s3 or imgix url.
// adjusted for dpr.
function resizeImageUri(uri, width, height, minWidth, minHeight) {
    var dpr = react_native_1.PixelRatio.get();
    var params = {
        dpr: dpr,
    };
    if (width) {
        params.w = width;
    }
    if (height) {
        params.h = height;
    }
    if (minWidth) {
        params['min-w'] = minWidth;
    }
    if (minHeight) {
        params['min-h'] = minHeight;
    }
    return mergeImgixParams(asImgix(uri), params);
}
function greyscaleImageUri(uri) {
    return mergeImgixParams(asImgix(uri), { sat: '-100' });
}
