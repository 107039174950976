"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildCardField = buildCardField;
var lodash_1 = require("lodash");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var AddressField_1 = require("../entity/fields/AddressField");
var CombinedTagsField_1 = require("../entity/fields/CombinedTagsField");
var DateField_1 = require("../entity/fields/DateField");
var EmailField_1 = require("../entity/fields/EmailField");
var EntityBatchField_1 = require("../entity/fields/EntityBatchField");
var FileField_1 = require("../entity/fields/FileField");
var GenericUntypedJsonBlobField_1 = require("../entity/fields/GenericUntypedJsonBlobField");
var GeolocationField_1 = require("../entity/fields/GeolocationField");
var NumberField_1 = require("../entity/fields/NumberField");
var PersonField_1 = require("../entity/fields/PersonField");
var RelatedEntityField_1 = require("../entity/fields/RelatedEntityField");
var RelatedLookupField_1 = require("../entity/fields/RelatedLookupField");
var ScheduledAutomationField_1 = require("../entity/fields/ScheduledAutomationField");
var SignatureField_1 = require("../entity/fields/SignatureField");
var SubformField_1 = require("../entity/fields/SubformField");
var TagField_1 = require("../entity/fields/TagField");
var TextField_1 = require("../entity/fields/TextField");
var TimeTrackerField_1 = require("../entity/fields/TimeTrackerField");
var TodoField_1 = require("../entity/fields/TodoField");
function buildCardField(_a) {
    var _b, _c;
    var apiCard = _a.apiCard, component = _a.component, refs = _a.refs, defaultData = _a.defaultData, currentUser = _a.currentUser;
    var data = (_c = (_b = apiCard === null || apiCard === void 0 ? void 0 : apiCard.fields) === null || _b === void 0 ? void 0 : _b[component.id]) !== null && _c !== void 0 ? _c : defaultData;
    if ((0, hooks_1.isRelatedCardLookupComponent)(component) && apiCard) {
        return (0, RelatedLookupField_1.RelatedLookupField)(apiCard, component, refs);
    }
    if ((0, hooks_1.isCombinedTagsComponent)(component) && apiCard) {
        return new CombinedTagsField_1.CombinedTagsField(component.componentIds, apiCard);
    }
    if ((0, lodash_1.isNil)(data)) {
        return null;
    }
    if ((0, hooks_1.isNumberComponent)(component)) {
        return new NumberField_1.NumberField(data, component.format);
    }
    if ((0, hooks_1.isTagComponent)(component) && component.options) {
        return new TagField_1.TagField(data, component.id, component.options);
    }
    if ((0, hooks_1.isAddressComponent)(component)) {
        return new AddressField_1.AddressField(data);
    }
    if ((0, hooks_1.isDateComponent)(component)) {
        return new DateField_1.DateField(data);
    }
    if ((0, hooks_1.isEntityBatchComponent)(component)) {
        return new EntityBatchField_1.EntityBatchField(data, refs.recurringEntitiesScheduleRefs);
    }
    if ((0, hooks_1.isEmailComponent)(component)) {
        return new EmailField_1.EmailField(data);
    }
    if ((0, hooks_1.isFileComponent)(component)) {
        return new FileField_1.FileField(data);
    }
    if ((0, hooks_1.isGeolocationComponent)(component)) {
        return new GeolocationField_1.GeolocationField(data);
    }
    if ((0, hooks_1.isPersonComponent)(component)) {
        return new PersonField_1.PersonField(data, refs.personRefs, currentUser);
    }
    if ((0, hooks_1.isRelatedCardComponent)(component)) {
        return new RelatedEntityField_1.RelatedEntityField(data, refs.workflowEntityRefs);
    }
    if ((0, hooks_1.isScheduledAutomationComponent)(component)) {
        return new ScheduledAutomationField_1.ScheduledAutomationField(data);
    }
    if ((0, hooks_1.isSignatureComponent)(component)) {
        return new SignatureField_1.SignatureField(data);
    }
    if ((0, hooks_1.isSubformComponent)(component)) {
        return new SubformField_1.SubformField(data, refs.workflowTemplateRefs);
    }
    if ((0, hooks_1.isTextComponent)(component)) {
        return new TextField_1.TextField(data);
    }
    if ((0, hooks_1.isTimeTrackerComponent)(component)) {
        return new TimeTrackerField_1.TimeTrackerField(data);
    }
    if ((0, hooks_1.isTodoComponent)(component)) {
        return new TodoField_1.TodoField(data);
    }
    // if we have data for this component id, but no other component type matches,
    // return a generic field
    return new GenericUntypedJsonBlobField_1.GenericUntypedJsonBlobField(data);
}
