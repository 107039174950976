"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateItemName = generateItemName;
function generateId(len) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < len; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
function generateItemName(currentItems) {
    var MAX_ITERATIONS = 20;
    var FIELD_SIZE = 3;
    var i = 0;
    var _loop_1 = function () {
        var fieldName = "f" + generateId(FIELD_SIZE);
        var conflict = currentItems.some(function (_a) {
            var name = _a.name;
            return name === fieldName;
        });
        if (!conflict) {
            return { value: fieldName };
        }
        else {
            return "continue";
        }
    };
    while (i < MAX_ITERATIONS) {
        var state_1 = _loop_1();
        if (typeof state_1 === "object")
            return state_1.value;
    }
    throw new Error('It was not possible to generate a unique field name');
}
