/* @flow */
import React, { useCallback } from 'react';
import { MBModalOrMobile } from '@shared/components/web/modalOrMobile';
import { MBChannelNotificationSettings } from '@shared/scenes/channel/notificationSettings/containers';
import { AdvancedChannelOptions } from '@shared/scenes/channel/options/components/advancedOptions';
import { MBChannelOptions } from '@shared/scenes/channel/options/containers/index';
import { MBChannelUserList } from '@shared/scenes/channel/userList/container';
import { ColorThemeContext } from '@shared/styles';
import { i18n } from '@shared/util/i18n';
import { useNavigator } from '@shared/util/navigation';
import { BackableTopNavBar } from '@src/components/TopNavBar';
import { actions } from '@src/core/Router';
import addMessageToastr from '@src/redux/actions/addMessageToastr';
import { Wrap } from '../Wrap';

const onBack = () => actions.popRoute();

export function ChannelOptionsContainer({ params }) {
  const navigator = useNavigator();
  return (
    <Wrap
      title={i18n('channel.options.detailsTitle')}
      children={
        <MBChannelOptions
          slug={params.channelSlug}
          onSentReminderToUser={() => addMessageToastr('1 reminder sent!')}
          onRemovedUserFromChannel={() => addMessageToastr('1 member removed!')}
        />
      }
      backgroundColor={React.useContext(ColorThemeContext).backgroundInactive}
      onBack={navigator.goBack}
      onClose={null}
    />
  );
}

ChannelOptionsContainer.displayName = 'ChannelOptionsContainer';

export function ChannelUserListContainer({ params }) {
  const navigator = useNavigator();
  return (
    <Wrap
      title={i18n('channel.userList.title')}
      children={<MBChannelUserList channelSlug={params.channelSlug} />}
      onBack={navigator.goBack}
      onClose={null}
    />
  );
}

ChannelUserListContainer.displayName = 'ChannelUserListContainer';

export function ChannelNotificationSettingsContainer({ params }: any) {
  const navigator = useNavigator();
  const openChannel = useCallback(
    () => navigator.navigate('Channel', { channelSlug: params.channelSlug }),
    [navigator, params.channelSlug],
  );
  return (
    <MBModalOrMobile
      title="Notification Settings"
      headerOnMobile={<BackableTopNavBar title="Notification Settings" />}
      onClose={openChannel}
      onBack={onBack}
    >
      <MBChannelNotificationSettings slug={params.channelSlug} />
    </MBModalOrMobile>
  );
}

export function ChannelAdvancedOptionsContainer({ params }) {
  const navigator = useNavigator();
  return (
    <Wrap
      title={i18n('channel.options.advanced.title')}
      children={<AdvancedChannelOptions {...params} />}
      onBack={navigator.goBack}
      onClose={null}
    />
  );
}
