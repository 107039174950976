"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePrevious = usePrevious;
// @ts-strict-ignore
var react_1 = require("react");
function usePrevious(val) {
    var prev = (0, react_1.useRef)();
    (0, react_1.useEffect)(function () {
        prev.current = val;
    });
    return prev.current;
}
