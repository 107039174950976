"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionViewControls = CollectionViewControls;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var entitySelection_1 = require("@shared/api/providers/entitySelection");
var Button_1 = require("@shared/components/filter/Button");
var FilterMenu_1 = require("@shared/components/filter/FilterMenu");
var GroupMenu_1 = require("@shared/components/filter/GroupMenu");
var GroupMultiMenu_1 = require("@shared/components/filter/GroupMultiMenu");
var SaveViewMenu_1 = require("@shared/components/filter/SaveViewMenu");
var SearchField_1 = require("@shared/components/filter/SearchField");
var ShareViewMenu_1 = require("@shared/components/filter/ShareViewMenu");
var SortMenu_1 = require("@shared/components/filter/SortMenu");
var ControlsProvider_1 = require("@shared/components/filter/state/ControlsProvider");
var view_1 = require("@shared/components/view");
var entityBulkActions_1 = require("@shared/scenes/cards/entityBulkActions");
var context_1 = require("@shared/scenes/channel/webLayout/context");
var styles_1 = require("@shared/styles");
var launchDarkly_1 = require("@shared/util/featureFlags/launchDarkly");
var i18n_1 = require("@shared/util/i18n");
/* NOTE: some of the controls tree is web-only (divs not views, CSS). Native has its own layout. */
function CollectionViewControls(_a) {
    var workflowTemplateId = _a.workflowTemplateId, viewTemplate = _a.viewTemplate, calendarDateComponentId = _a.calendarDateComponentId, updateViewTemplate = _a.updateViewTemplate, _b = _a.showGroup, showGroup = _b === void 0 ? true : _b, style = _a.style;
    var isGroupingMultipleEnabled = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.MULTIPLE_FILTER_GROUPS);
    var selectionActive = (0, entitySelection_1.useEntitySelection)().selectionActive;
    var isModified = (0, ControlsProvider_1.useControlsState)().isModified;
    var mode = (0, react_1.useContext)(context_1.WebLayoutContext).mode;
    var isWorkflowOnly = mode === 'workflow';
    var containerStyle = __assign({ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: isWorkflowOnly ? 'space-between' : 'flex-start', paddingTop: 15, paddingLeft: 15, paddingBottom: 15, position: 'sticky', left: 0, top: 0, zIndex: 1, backgroundColor: styles_1.Colors.v2.grayBackground, overflow: 'auto' }, style);
    var leftContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    };
    var rightContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: isWorkflowOnly ? (0, view_1.grid)(10) : (0, view_1.grid)(5),
    };
    return (react_1.default.createElement("div", { style: containerStyle },
        selectionActive && (react_1.default.createElement(entityBulkActions_1.EntitySelectionControls, { workflowTemplateId: workflowTemplateId, calendarDateComponentId: calendarDateComponentId })),
        !selectionActive && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("div", { style: leftContainerStyle },
                react_1.default.createElement(SearchField_1.SearchField, null),
                react_1.default.createElement(FilterMenu_1.FilterMenu, null),
                react_1.default.createElement(SortMenu_1.SortMenu, null),
                showGroup && !isGroupingMultipleEnabled && react_1.default.createElement(GroupMenu_1.GroupMenu, null),
                showGroup && isGroupingMultipleEnabled && react_1.default.createElement(GroupMultiMenu_1.GroupMultiMenu, null)),
            react_1.default.createElement("div", { style: rightContainerStyle },
                isModified && (react_1.default.createElement(ModifiedButtons, { updateViewTemplate: updateViewTemplate, viewTemplate: viewTemplate, workflowTemplateId: workflowTemplateId })),
                react_1.default.createElement(ShareViewMenu_1.ShareViewMenu, { viewTemplate: viewTemplate, workflowTemplateId: workflowTemplateId }))))));
}
function ModifiedButtons(_a) {
    var updateViewTemplate = _a.updateViewTemplate, viewTemplate = _a.viewTemplate, workflowTemplateId = _a.workflowTemplateId;
    var revertToSaved = (0, ControlsProvider_1.useControlsActions)().revertToSaved;
    return (react_1.default.createElement(react_native_1.View, { style: { display: 'flex', flexDirection: 'row', alignItems: 'center' } },
        react_1.default.createElement(Button_1.Button, { active: false, onClick: revertToSaved, showArrow: false, title: (0, i18n_1.i18n)('card.actions.save.revert'), style: { marginRight: (0, view_1.grid)(0.25) } }),
        react_1.default.createElement(SaveViewMenu_1.SaveViewMenu, { updateViewTemplate: updateViewTemplate, viewTemplate: viewTemplate, workflowTemplateId: workflowTemplateId })));
}
