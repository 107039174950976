"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditView = useEditView;
exports.getModifiedName = getModifiedName;
var lodash_1 = require("lodash");
var react_1 = require("react");
var utils_1 = require("@shared/data/cards/utils");
var ComponentTypeUtils_1 = require("@shared/scenes/workflows/ComponentTypeUtils");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var config_1 = require("../../config");
var DEFAULT_ICON_FOR_COMPONENT = 'multi-line';
function useEditView(_a) {
    var workflowTemplateId = _a.workflowTemplateId;
    var _b = (0, react_1.useState)(false), loading = _b[0], setLoading = _b[1];
    var _c = (0, react_1.useState)(), viewFields = _c[0], setViewFields = _c[1];
    var _d = (0, react_1.useState)(), viewId = _d[0], setViewId = _d[1];
    var viewTemplate = (0, hooks_1.useViewTemplate)(workflowTemplateId, viewId).viewTemplate;
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var updateViewTemplate = (0, hooks_1.useUpdateViewTemplate)(workflowTemplateId);
    (0, react_1.useEffect)(function () {
        setViewFields(viewTemplate);
    }, [viewTemplate]);
    var setCurrentView = (0, react_1.useCallback)(function (newViewId) {
        if ((0, lodash_1.isNil)(newViewId)) {
            if (!(0, lodash_1.isNil)(viewTemplate) && !(0, lodash_1.isEqual)(viewTemplate, viewFields) && !loading) {
                setViewId(undefined);
                setLoading(true);
                var modifiedName = getModifiedName(viewFields === null || viewFields === void 0 ? void 0 : viewFields.name, viewTemplate.name);
                var updatedFields = __assign(__assign(__assign({}, viewTemplate), viewFields), { name: modifiedName });
                updateViewTemplate(updatedFields).finally(function () { return setLoading(false); });
            }
        }
        else {
            setViewId(newViewId);
        }
    }, [viewFields, viewTemplate, updateViewTemplate, loading]);
    var setViewField = (0, react_1.useCallback)(function (data) {
        if (!(0, lodash_1.isNil)(viewFields)) {
            setViewFields(__assign(__assign({}, viewFields), data));
        }
    }, [viewFields]);
    var viewComponents = (0, react_1.useMemo)(function () {
        return workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.filter(function (c) { return isComponentInView(c, viewFields); }).map(function (component) {
            var _a, _b;
            var _c = getComponentInfo(component), id = _c.id, label = _c.label, icon = _c.icon;
            var componentOverride = (_a = viewFields === null || viewFields === void 0 ? void 0 : viewFields.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (_a) {
                var componentId = _a.componentId;
                return componentId === component.id;
            });
            var visible = !(0, utils_1.isComponentHidden)(viewTemplate, componentOverride, component);
            return {
                id: id,
                label: label,
                icon: icon ? icon : (_b = (0, ComponentTypeUtils_1.getIcon)(component)) !== null && _b !== void 0 ? _b : DEFAULT_ICON_FOR_COMPONENT,
                visible: visible,
                disabled: config_1.componentTemplateOptions.isComponentVisibilityDisabled(component, viewFields),
            };
        });
    }, [workflowTemplate, viewFields, viewTemplate]);
    var toggleComponentVisibility = (0, react_1.useCallback)(function (component) {
        var _a, _b;
        var componentHasViewOptions = (_a = viewFields === null || viewFields === void 0 ? void 0 : viewFields.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.some(function (_a) {
            var id = _a.componentId;
            return id === component.id;
        });
        var newComponentOptions;
        if (componentHasViewOptions) {
            newComponentOptions = (_b = viewFields === null || viewFields === void 0 ? void 0 : viewFields.componentsViewOptions) === null || _b === void 0 ? void 0 : _b.map(function (viewOptions) {
                var _a;
                if (viewOptions.componentId !== component.id) {
                    return viewOptions;
                }
                return __assign(__assign({}, viewOptions), { isHidden: component.visible, overrides: __assign(__assign({}, ((_a = viewOptions.overrides) !== null && _a !== void 0 ? _a : {})), { isHidden: component.visible }) });
            });
        }
        else {
            var newViewOptions = {
                componentId: component.id,
                isHidden: component.visible,
                overrides: {
                    isHidden: component.visible,
                },
            };
            newComponentOptions = __spreadArray(__spreadArray([], ((viewFields === null || viewFields === void 0 ? void 0 : viewFields.componentsViewOptions) || []), true), [newViewOptions], false);
        }
        setViewField({ componentsViewOptions: newComponentOptions });
    }, [setViewField, viewFields]);
    return (0, react_1.useMemo)(function () { return ({
        currentViewId: viewId,
        setCurrentView: setCurrentView,
        setViewField: setViewField,
        viewFields: viewFields,
        loading: loading,
        viewComponents: viewComponents,
        toggleComponentVisibility: toggleComponentVisibility,
    }); }, [
        setCurrentView,
        setViewField,
        viewFields,
        loading,
        viewComponents,
        toggleComponentVisibility,
        viewId,
    ]);
}
function getComponentInfo(component) {
    if ((0, hooks_1.isInputComponent)(component) || (0, hooks_1.isCombinedTagsComponent)(component)) {
        return { id: component.id, label: component.label, icon: component.icon };
    }
    if ((0, hooks_1.isInputButtonComponent)(component)) {
        return { id: component.id, label: component.buttonText, icon: 'button-press' };
    }
    return { id: component.id, label: '', icon: null };
}
function isComponentInView(component, viewTemplate) {
    if ((0, hooks_1.isInputComponent)(component)) {
        return true;
    }
    else if ((0, hooks_1.isInputButtonComponent)(component)) {
        return (viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.subtype) === 'TABLE' || (viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.subtype) === 'LIST';
    }
    else if ((0, hooks_1.isCombinedTagsComponent)(component)) {
        return (viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.subtype) === 'TABLE' || (viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.subtype) === 'LIST';
    }
    else if ((0, hooks_1.isTimeTrackerComponent)(component)) {
        return (viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.subtype) === 'TABLE' || (viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.subtype) === 'LIST';
    }
    return false;
}
function getModifiedName(updatedName, existingName) {
    var trimmedName = updatedName === null || updatedName === void 0 ? void 0 : updatedName.trim();
    if ((0, lodash_1.isNil)(trimmedName) || (0, lodash_1.isEmpty)(trimmedName)) {
        return existingName;
    }
    else {
        return trimmedName;
    }
}
