/* @flow */
import * as React from 'react';

import { Colors } from '@shared/styles';
import { MBBoxedIcon } from '@shared/components';

import NavBar from '@src/components/NavBar';

import styles from './TopNavBar.css';
import { useNavigator } from '@shared/util/navigation';

type DesktopState = {
  showSnoozeTooltip: boolean,
};

type MobileProps = {|
  leftElement?: ?any, // React.Node,
  leftAction?: Function,
  center: ?any, // React.Node
  rightElement?: ?any, // React.Node,
  rightAction?: Function,
  banner?: ?any, // React.Node,
|};

type BackableProps = {|
  onBack?: Function,
  title: ?(React.Element<*> | string),
  rightElement?: ?any, // React.Node,
  rightAction?: Function,
  banner?: ?any, // React.Node,
|};

type SnoozeTimeLabel = {|
  duration?: number, // eslint-disable-line react/no-unused-prop-types
  label: string, // eslint-disable-line react/no-unused-prop-types
|};

export function MobileTopNavBar(props: MobileProps) {
  return (
    <NavBar isLarge={true}>
      <div className={styles.mobileButton} onClick={props.leftAction}>
        {props.leftElement}
      </div>
      <div className={styles.mobileTitle}>{props.center}</div>
      <div className={styles.mobileButton} onClick={props.rightAction}>
        {props.rightElement}
      </div>
      {props.banner}
    </NavBar>
  );
}

export function BackableTopNavBar(props: BackableProps) {
  const navigator = useNavigator();
  return (
    <MobileTopNavBar
      leftElement={
        <MBBoxedIcon
          color={Colors.v2.purple}
          backgroundColor={null}
          source={require('@shared/assets/icons/v2/arrow-point-left.png')}
        />
      }
      leftAction={props.onBack || navigator.goBack}
      center={<div className={styles.mobileTitle}>{props.title}</div>}
      rightElement={props.rightElement}
      rightAction={props.rightAction}
      banner={props.banner}
    />
  );
}
