"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoCodeBlockContainer = NoCodeBlockContainer;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var layoutConfig_1 = require("@shared/components/blocks/contexts/layoutConfig");
var render_1 = require("@shared/components/blocks/contexts/render");
var viewTemplate_1 = require("@shared/components/blocks/contexts/viewTemplate");
var utils_1 = require("@shared/data/cards/utils");
var hooks_1 = require("../../scenes/workflows/hooks");
var WorkflowComponent_1 = require("../../scenes/workflows/views/view-components/WorkflowComponent");
var form_1 = require("./contexts/form");
function NoCodeBlockContainer(_a) {
    var fields = _a.fields, componentId = _a.componentId, workflowTemplateId = _a.workflowTemplateId;
    var _b = (0, react_1.useState)(false), isReadOnly = _b[0], setIsReadOnly = _b[1];
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var viewTemplate = (0, react_1.useContext)(viewTemplate_1.ViewTemplateContext);
    var component = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.find(function (c) { return c.id === componentId; });
    var renderContext = (0, react_1.useContext)(render_1.CardRenderContext);
    var isCollectionView = ['list', 'table'].includes(renderContext);
    var iconPad = isCollectionView ? 1 : 2;
    if (renderContext === 'calendar') {
        iconPad = 0;
    }
    var displayLabel = (0, layoutConfig_1.useLayoutConfig)().staticBlock.displayLabel;
    var setFieldValue = (0, react_1.useContext)(form_1.CardFormContext).setFieldValue;
    var componentOptions = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions.find(function (options) { return options.componentId === component.id; });
    (0, react_1.useEffect)(function () {
        if ((0, lodash_1.isNil)(component) || (0, lodash_1.isNil)(viewTemplate)) {
            return;
        }
        setIsReadOnly((0, utils_1.isComponentReadOnlyInView)(component, viewTemplate));
    }, [component, viewTemplate]);
    if ((0, lodash_1.isNil)(component)) {
        return null;
    }
    return (react_1.default.createElement(WorkflowComponent_1.WorkflowComponent, { component: component, components: workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components, fields: fields, onDataChanged: function (id, value) { return setFieldValue(id, value); }, initialData: fields[component === null || component === void 0 ? void 0 : component.id], isReadOnly: isReadOnly, showLabel: !(viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.defaultHiddenLabels) && !!displayLabel, paddingHorizontal: isCollectionView ? 2 : 3, iconPad: iconPad, overrides: componentOptions === null || componentOptions === void 0 ? void 0 : componentOptions.overrides }));
}
