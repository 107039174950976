"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBatchPresets = useBatchPresets;
var lodash_1 = require("lodash");
var rrule_1 = require("rrule");
var utils_1 = require("../utils");
function useBatchPresets(_a) {
    var date = _a.date, count = _a.count, until = _a.until, skip = _a.skip;
    if ((0, lodash_1.isNil)(date) || skip) {
        return [];
    }
    var sharedOptions = (0, utils_1.getSharedOptions)(date, count, until);
    var dateObj = (0, utils_1.getUTCDate)(new Date(date));
    var weekday = utils_1.DaysOfWeek[(0, utils_1.getDayOfWeek)(dateObj)];
    var presets = [
        __assign(__assign({}, sharedOptions), { freq: rrule_1.Frequency.DAILY }),
        __assign(__assign({}, sharedOptions), { freq: rrule_1.Frequency.WEEKLY, byweekday: [weekday] }),
    ];
    var nthWeekdayOfMonth = (0, utils_1.getNthWeekdayOfMonth)(dateObj);
    if (nthWeekdayOfMonth < 5) {
        // every month on nth weekday (e.g., 2nd Monday)
        presets.push(__assign(__assign({}, sharedOptions), { freq: rrule_1.Frequency.MONTHLY, byweekday: [weekday.nth(nthWeekdayOfMonth)] }));
    }
    presets.push(__assign(__assign({}, sharedOptions), { freq: rrule_1.Frequency.MONTHLY, bymonthday: dateObj.getUTCDate() }), __assign(__assign({}, sharedOptions), { freq: rrule_1.Frequency.YEARLY, bymonth: [dateObj.getUTCMonth() + 1], bymonthday: [dateObj.getUTCDate()] }), __assign(__assign({}, sharedOptions), { freq: rrule_1.Frequency.WEEKLY, byweekday: [rrule_1.RRule.MO, rrule_1.RRule.TU, rrule_1.RRule.WE, rrule_1.RRule.TH, rrule_1.RRule.FR] }));
    return presets;
}
