"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferencedInEditField = ReferencedInEditField;
var client_1 = require("@apollo/client");
var native_1 = require("@react-navigation/native");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var api_1 = require("@shared/api");
var components_1 = require("@shared/components");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var ControlsProvider_1 = require("@shared/components/filter/state/ControlsProvider");
var provider_1 = require("@shared/scenes/billing/upgradeModal/provider");
var CardList_1 = require("@shared/scenes/cards/listView/components/CardList");
var CardListViewHooks_1 = require("@shared/scenes/cards/listView/hooks/CardListViewHooks");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var useWorkspaceDetails_1 = require("@shared/scenes/workflows/hooks/useWorkspaceDetails");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
var notNil_1 = require("@shared/util/notNil");
var omitTypeName_1 = require("@shared/util/omitTypeName");
var ChannelPermissionsProvider_1 = require("@shared/util/permissions/ChannelPermissionsProvider");
var COUNT_REFETCH_TIMEOUT_MS = 500;
function ReferencedInEditField(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var component = _a.component, entityId = _a.entityId;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _p = (0, react_1.useState)(false), showingList = _p[0], setShowingList = _p[1];
    var _q = useWorkflowReferencedInComponent(component, entityId), workspaceDetails = _q.workspaceDetails, countLoading = _q.countLoading, workflowDetailsLoading = _q.workflowDetailsLoading, referencedWorkflow = _q.referencedWorkflow, count = _q.count, viewTemplate = _q.viewTemplate, navigateToCreate = _q.navigateToCreate, refetchCount = _q.refetchCount;
    var containerRef = (0, react_1.useRef)(null);
    var workspaceName = (_e = (_b = component.label) !== null && _b !== void 0 ? _b : (_d = workspaceDetails.get((_c = referencedWorkflow === null || referencedWorkflow === void 0 ? void 0 : referencedWorkflow.id) !== null && _c !== void 0 ? _c : '')) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : referencedWorkflow === null || referencedWorkflow === void 0 ? void 0 : referencedWorkflow.name;
    var workspaceId = (_h = (_g = workspaceDetails.get((_f = referencedWorkflow === null || referencedWorkflow === void 0 ? void 0 : referencedWorkflow.id) !== null && _f !== void 0 ? _f : '')) === null || _g === void 0 ? void 0 : _g.id) !== null && _h !== void 0 ? _h : 0;
    var workspaceImageUrl = (_l = (_k = workspaceDetails.get((_j = referencedWorkflow === null || referencedWorkflow === void 0 ? void 0 : referencedWorkflow.id) !== null && _j !== void 0 ? _j : '')) === null || _k === void 0 ? void 0 : _k.imageUrl) !== null && _l !== void 0 ? _l : (_m = referencedWorkflow === null || referencedWorkflow === void 0 ? void 0 : referencedWorkflow.channelDefaultConfiguration) === null || _m === void 0 ? void 0 : _m.imageUrl;
    (0, react_1.useEffect)(function () {
        if (!showingList) {
            refetchCount();
        }
    }, [showingList, refetchCount]);
    return (react_1.default.createElement(components_1.MBView, { ref: containerRef },
        react_native_1.Platform.OS !== 'web' && react_1.default.createElement(RefetchCount, { refetchCount: refetchCount }),
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: function () { return setShowingList(function (prev) { return !prev; }); } },
            react_1.default.createElement(components_1.MBView, { row: true, style: styles.container, fill: true, centerVertically: true },
                workflowDetailsLoading && react_1.default.createElement(components_1.MBLoadingIndicator, null),
                !workflowDetailsLoading && !(0, lodash_1.isEmpty)(workspaceImageUrl) && (react_1.default.createElement(components_1.MBImage, { style: styles.workflowImage, source: {
                        uri: workspaceImageUrl !== null && workspaceImageUrl !== void 0 ? workspaceImageUrl : '',
                    } })),
                react_1.default.createElement(components_1.MBView, { style: styles.textContainer },
                    react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive, numberOfLines: 2, ellipsizeMode: "tail" }, workspaceName !== null && workspaceName !== void 0 ? workspaceName : ''),
                    countLoading && react_1.default.createElement(components_1.MBLoadingIndicator, null),
                    !countLoading && (react_1.default.createElement(components_1.MBTextBody1, null, (0, i18n_1.i18n)('card.blocks.inCards.cards', { count: count !== null && count !== void 0 ? count : 0, max: 10000 })))),
                react_1.default.createElement(components_1.MBTouchableIcon, { style: styles.plusButton, onPress: navigateToCreate, color: colors.foregroundActive, noPadding: true, source: require('@shared/assets/icons/v2/plus-circle.png') }))),
        showingList && !(0, lodash_1.isNil)(referencedWorkflow) && (react_1.default.createElement(components_1.MBBottomSheet, { containerRef: containerRef, onClose: function () { return setShowingList(false); }, initialSize: 'full', title: workspaceName !== null && workspaceName !== void 0 ? workspaceName : '' },
            react_1.default.createElement(components_1.MBView, { style: styles.listContainer },
                react_1.default.createElement(ReferencedWorkflowCollectionView, { workflowTemplate: referencedWorkflow, viewTemplate: viewTemplate, baseNavigationPath: (_o = workspaceDetails.get(referencedWorkflow === null || referencedWorkflow === void 0 ? void 0 : referencedWorkflow.id)) === null || _o === void 0 ? void 0 : _o.baseNavigationPath, component: component, workspaceId: workspaceId, entityId: entityId, closeSheet: function () { return setShowingList(false); } }))))));
}
function ReferencedWorkflowCollectionView(_a) {
    var _b, _c, _d;
    var workflowTemplate = _a.workflowTemplate, viewTemplate = _a.viewTemplate, component = _a.component, baseNavigationPath = _a.baseNavigationPath, workspaceId = _a.workspaceId, entityId = _a.entityId, closeSheet = _a.closeSheet;
    if ((0, lodash_1.isNil)(viewTemplate)) {
        return (react_1.default.createElement(components_1.MBView, { style: { padding: (0, components_1.grid)(2) } },
            react_1.default.createElement(components_1.MBTextBody1, { style: { textAlign: 'center' } }, (0, i18n_1.i18n)('builder.referencedInOptions.noView'))));
    }
    var viewTemplateWithFilter = __assign(__assign({}, viewTemplate), { collectionCriteria: {
            filterByComponents: __spreadArray([
                {
                    componentId: component.relatedCardComponentId,
                    value: {
                        containsCurrentUser: null,
                        containsNumber: null,
                        containsString: { values: [entityId] },
                        dateIs: null,
                        dateRange: null,
                        dateTokenIs: null,
                        isEmpty: null,
                        numberIs: null,
                        stringWildcard: null,
                    },
                }
            ], getFiltersFromViewTemplate(viewTemplate), true),
            sortByComponents: (_d = (_c = (_b = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.collectionCriteria) === null || _b === void 0 ? void 0 : _b.sortByComponents) === null || _c === void 0 ? void 0 : _c.map(omitTypeName_1.omitTypeName)) !== null && _d !== void 0 ? _d : [],
            groups: [],
        } });
    return (react_1.default.createElement(ChannelPermissionsProvider_1.ChannelPermissionsProvider, { channelId: workspaceId },
        react_1.default.createElement(ControlsProvider_1.ControlsProvider, { workflowTemplateId: workflowTemplate.id, viewTemplate: viewTemplateWithFilter },
            react_1.default.createElement(provider_1.MBUpgradeModalProvider, null,
                react_1.default.createElement(ReferencedWorkflowListView, { workflowTemplate: workflowTemplate, baseNavigationPath: baseNavigationPath !== null && baseNavigationPath !== void 0 ? baseNavigationPath : '', viewTemplate: viewTemplate, workspaceId: workspaceId, closeSheet: closeSheet })))));
}
function ReferencedWorkflowListView(_a) {
    var workflowTemplate = _a.workflowTemplate, baseNavigationPath = _a.baseNavigationPath, viewTemplate = _a.viewTemplate, workspaceId = _a.workspaceId, closeSheet = _a.closeSheet;
    var _b = (0, CardListViewHooks_1.useCardListView)(workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.id, viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.id, workspaceId, baseNavigationPath), entities = _b.entities, openEntity = _b.openEntity, hasMoreCards = _b.hasMoreCards, fetchMore = _b.fetchMore, isMoreCardsLoading = _b.isMoreCardsLoading, isCardsLoading = _b.isCardsLoading, canUpdateCard = _b.canUpdateCard;
    var openEntityAndClose = (0, react_1.useCallback)(function (id, path) {
        openEntity(id, path);
        closeSheet();
    }, [closeSheet, openEntity]);
    if (canUpdateCard) {
        return (react_1.default.createElement(channel_1.MBCardChannelProvider, { channelSlug: baseNavigationPath !== null && baseNavigationPath !== void 0 ? baseNavigationPath : '' },
            react_1.default.createElement(components_1.MBView, { paddingVertical: 1, paddingHorizontal: 0.5 },
                react_1.default.createElement(CardList_1.CardList, { canUpdateCard: canUpdateCard, workflowTemplate: workflowTemplate, viewTemplate: viewTemplate, entities: entities, openEntity: openEntityAndClose, hasMore: hasMoreCards, showMore: fetchMore, isMoreLoading: isMoreCardsLoading, isCardsLoading: isCardsLoading, isGroup: false, hasOverflowMenu: false }))));
    }
    return (react_1.default.createElement(components_1.MBView, { paddingVertical: 1, paddingHorizontal: 0.5 },
        react_1.default.createElement(CardList_1.CardList, { canUpdateCard: canUpdateCard, workflowTemplate: workflowTemplate, viewTemplate: viewTemplate, entities: entities, openEntity: openEntityAndClose, hasMore: hasMoreCards, showMore: fetchMore, isMoreLoading: isMoreCardsLoading, isCardsLoading: isCardsLoading, isGroup: false, hasOverflowMenu: false })));
}
function RefetchCount(_a) {
    var refetchCount = _a.refetchCount;
    (0, native_1.useFocusEffect)((0, react_1.useCallback)(function () {
        // this timeout is a workaround for indexing lag
        var refetchTimeout = setTimeout(refetchCount, COUNT_REFETCH_TIMEOUT_MS);
        return function () {
            if (refetchTimeout) {
                clearTimeout(refetchTimeout);
            }
        };
    }, [refetchCount]));
    return null;
}
function useWorkflowReferencedInComponent(component, entityId) {
    var _a, _b, _c;
    var referencedFromWorkflowTemplate = (0, hooks_1.useWorkflowTemplate)(component.referencedFromWorkflowTemplateId);
    var navigator = (0, navigation_1.useNavigator)();
    var workspaceDetails = (0, useWorkspaceDetails_1.useWorkspaceDetails)();
    var _d = (0, hooks_1.useViewTemplate)(component.referencedFromWorkflowTemplateId, component.referencingViewTemplateId), viewTemplate = _d.viewTemplate, viewTemplateLoading = _d.loading;
    var navigateToCreate = (0, react_1.useCallback)(function () {
        var _a;
        var _b, _c, _d;
        var navigationPath = (_d = workspaceDetails.get((_c = (_b = referencedFromWorkflowTemplate.workflowTemplate) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : '')) === null || _d === void 0 ? void 0 : _d.baseNavigationPath;
        if (!(0, lodash_1.isNil)(navigationPath)) {
            var navEndpoint = 'CardCreate';
            var navOptions = {
                channelSlug: navigationPath,
                fieldOverrides: (_a = {},
                    _a[component.relatedCardComponentId] = [{ id: entityId }],
                    _a),
            };
            navigator.navigate(navEndpoint, navOptions);
        }
    }, [
        entityId,
        component.relatedCardComponentId,
        navigator,
        (_a = referencedFromWorkflowTemplate.workflowTemplate) === null || _a === void 0 ? void 0 : _a.id,
        workspaceDetails,
    ]);
    var count = (0, client_1.useQuery)(api_1.Queries.workflow.listWorkflowEntities, {
        variables: {
            input: {
                workflowTemplateId: component.referencedFromWorkflowTemplateId,
                filterByComponents: __spreadArray([
                    {
                        componentId: component.relatedCardComponentId,
                        value: {
                            containsCurrentUser: null,
                            containsNumber: null,
                            containsString: { values: [entityId] },
                            dateIs: null,
                            dateRange: null,
                            dateTokenIs: null,
                            isEmpty: null,
                            numberIs: null,
                            stringWildcard: null,
                        },
                    }
                ], getFiltersFromViewTemplate(viewTemplate), true),
            },
        },
        fetchPolicy: 'cache-and-network',
    });
    return {
        workspaceDetails: workspaceDetails,
        countLoading: count.loading,
        workflowDetailsLoading: referencedFromWorkflowTemplate.loading,
        referencedWorkflow: referencedFromWorkflowTemplate.workflowTemplate,
        count: (_c = (_b = count.data) === null || _b === void 0 ? void 0 : _b.list) === null || _c === void 0 ? void 0 : _c.totalResults,
        viewTemplate: viewTemplate,
        viewTemplateLoading: viewTemplateLoading,
        navigateToCreate: navigateToCreate,
        refetchCount: count.refetch,
    };
}
function getFiltersFromViewTemplate(viewTemplate) {
    var _a, _b, _c;
    return ((_c = (_b = (_a = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.collectionCriteria) === null || _a === void 0 ? void 0 : _a.filterByComponents) === null || _b === void 0 ? void 0 : _b.filter(notNil_1.notNil).map(omitTypeName_1.omitTypeName)) !== null && _c !== void 0 ? _c : []);
}
var styles = react_native_1.StyleSheet.create({
    container: {
        minHeight: (0, components_1.grid)(5),
        paddingHorizontal: (0, components_1.grid)(3),
        paddingVertical: (0, components_1.grid)(2),
        justifyContent: 'space-between',
    },
    workflowImage: {
        height: (0, components_1.grid)(5),
        width: (0, components_1.grid)(5),
        borderRadius: (0, components_1.grid)(2.5),
        marginRight: 9,
    },
    textContainer: {
        paddingLeft: (0, components_1.grid)(1),
        flex: 1,
    },
    plusButton: {
        alignSelf: 'flex-end',
    },
    listContainer: {
        width: 400,
        maxHeight: react_native_1.Platform.OS === 'web' ? 600 : undefined,
        flex: 1,
    },
});
