"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CombinedTagsOptions = CombinedTagsOptions;
var lodash_1 = require("lodash");
var rc_menu_1 = require("rc-menu");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var context_1 = require("@shared/scenes/workflows/context/context");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var NestedOptionsRow_1 = require("../NestedOptionsRow");
var defaultOptions_1 = require("../defaultOptions");
var CombinedTagsMenu_1 = require("./CombinedTagsMenu");
function CombinedTagsOptions(_a) {
    var component = _a.component, workflowTemplateId = _a.workflowTemplateId;
    var extraSectionsGenerator = useOptions(workflowTemplateId, component);
    return (react_1.default.createElement(defaultOptions_1.MBWorkflowDefaultComponentOptions, { component: component, extraSections: extraSectionsGenerator, includePlaceholder: false }));
}
function useOptions(workflowTemplateId, component) {
    var dispatch = (0, context_1.useWorkflowDispatch)();
    var currentWorkflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var availableTagComponents = currentWorkflowTemplate.workflowTemplate.components.filter(function (c) { return c.type === 'TAG'; });
    var onTagComponentSelected = function (chosenComponent, setOpenKeys) {
        var _a, _b;
        setOpenKeys(function (prev) { return __spreadArray(__spreadArray([], prev.filter(function (k) { return k !== 'combinedTagMenu'; }), true), ['combinedTagMenu'], false); });
        var alreadySelected = (_a = component.componentIds) === null || _a === void 0 ? void 0 : _a.includes(chosenComponent.id);
        var componentIds = __spreadArray([], (((_b = component.componentIds) === null || _b === void 0 ? void 0 : _b.filter(function (id) { return id !== chosenComponent.id; })) || []), true);
        if (!alreadySelected) {
            componentIds.push(chosenComponent.id);
        }
        dispatch({
            type: 'updateComponent',
            args: __assign(__assign({}, component), { componentIds: componentIds }),
        });
    };
    return function (_a) {
        var setOpenKeys = _a.setOpenKeys;
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('builder.combinedTags.selectedTagField'))),
            react_1.default.createElement(rc_menu_1.SubMenu, { key: "combinedTagMenu", style: { zIndex: 999 }, forceSubMenuRender: true, title: react_1.default.createElement(NestedOptionsRow_1.NestedOptionsRow, { name: (0, lodash_1.isEmpty)(component.componentIds)
                        ? (0, i18n_1.i18n)('builder.combinedTags.selectTagField')
                        : (0, i18n_1.i18n)('builder.combinedTags.selectedFields', {
                            count: component.componentIds.length,
                        }), defaultIcon: 'tag', selectedIcon: 'tag' }) },
                react_1.default.createElement(CombinedTagsMenu_1.CombinedTagsMenu, { selectedComponentIds: component.componentIds, components: availableTagComponents, onTagComponentSelect: function (chosenComponent) {
                        onTagComponentSelected(chosenComponent, setOpenKeys);
                    } }))));
    };
}
