"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRelatedEntitySearch = useRelatedEntitySearch;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var use_debounce_1 = require("use-debounce");
var api_1 = require("@shared/api");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var notNil_1 = require("@shared/util/notNil");
var omitTypeName_1 = require("@shared/util/omitTypeName");
var useFilterByComponents_1 = require("./useFilterByComponents");
function useRelatedEntitySearch(relatedCardComponent, fields) {
    var _a, _b;
    var workflowTemplateId = relatedCardComponent.workflowTemplateId;
    var _c = (0, react_1.useState)(''), _keyword = _c[0], setKeyword = _c[1];
    var keyword = (0, use_debounce_1.useDebounce)(_keyword, 300)[0];
    var _d = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId), workflowTemplate = _d.workflowTemplate, isWorkflowTemplateLoading = _d.loading;
    var sortByComponents = useSortByComponents(relatedCardComponent);
    var filterByComponents = (0, useFilterByComponents_1.useFilterByComponents)({
        workflowTemplate: workflowTemplate,
        fields: fields,
        relatedCardComponent: relatedCardComponent,
    });
    var _e = (0, client_1.useQuery)(api_1.Queries.workflow.listWorkflowEntities, {
        variables: {
            input: {
                workflowTemplateId: workflowTemplateId !== null && workflowTemplateId !== void 0 ? workflowTemplateId : '',
                limit: 50,
                offset: 0,
                sortByComponents: sortByComponents,
                filterByComponents: filterByComponents,
                omniSearchQuery: !(0, lodash_1.isEmpty)(keyword) ? keyword : null,
            },
        },
        skip: (0, lodash_1.isNil)(workflowTemplateId) || isWorkflowTemplateLoading,
        fetchPolicy: 'cache-and-network',
    }), listEntities = _e.data, isEntitiesLoading = _e.loading, refetch = _e.refetch;
    (0, react_1.useEffect)(function () {
        refetch();
    }, [refetch]);
    var searchResults = (0, react_1.useMemo)(function () {
        var _a, _b, _c;
        return (_c = (_b = (_a = listEntities === null || listEntities === void 0 ? void 0 : listEntities.list) === null || _a === void 0 ? void 0 : _a.entities) === null || _b === void 0 ? void 0 : _b.filter(notNil_1.notNil)) !== null && _c !== void 0 ? _c : [];
    }, [(_a = listEntities === null || listEntities === void 0 ? void 0 : listEntities.list) === null || _a === void 0 ? void 0 : _a.entities]);
    var reset = (0, react_1.useCallback)(function () { return setKeyword(''); }, [setKeyword]);
    return {
        keyword: keyword,
        reset: reset,
        onSearch: setKeyword,
        searchResults: searchResults,
        loading: isWorkflowTemplateLoading || isEntitiesLoading,
        hasMore: !!((_b = listEntities === null || listEntities === void 0 ? void 0 : listEntities.list) === null || _b === void 0 ? void 0 : _b.hasMore),
    };
}
function useSortByComponents(component) {
    var _a;
    return (0, react_1.useMemo)(function () {
        var _a, _b, _c;
        var defaultSort = {
            componentId: 'updatedAt',
            direction: 'DESC',
        };
        return ((_c = (_b = (_a = component.listDefault) === null || _a === void 0 ? void 0 : _a.sortByComponents) === null || _b === void 0 ? void 0 : _b.filter(notNil_1.notNil).map(omitTypeName_1.omitTypeName)) !== null && _c !== void 0 ? _c : [defaultSort]);
    }, [(_a = component.listDefault) === null || _a === void 0 ? void 0 : _a.sortByComponents]);
}
