"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBAddForm = MBAddForm;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var typography_1 = require("@shared/components/typography");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var context_1 = require("../context");
var addFormRow_1 = require("./addFormRow");
/**
 * MBAddForm
 *
 * This component is used in the business megamodal, but is also used in
 * onboarding. So be sure to test both when making changes in here.
 */
function MBAddForm() {
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _a = (0, react_1.useContext)(context_1.InviteContext), rows = _a.rows, onAddRow = _a.onAddRow, onUpdateRow = _a.onUpdateRow;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { row: true, paddingBottom: 1, style: { alignItems: 'flex-end' } },
            react_1.default.createElement(components_1.MBView, { flex: true, paddingHorizontal: 1 },
                react_1.default.createElement(typography_1.MBTextOverlineBold, { color: colors.foregroundActive }, (0, i18n_1.i18n)('inviteSomeone.addForm.name.title'))),
            react_1.default.createElement(components_1.MBView, { paddingLeft: 2 }),
            react_1.default.createElement(components_1.MBView, { flex: true, paddingHorizontal: 1 },
                react_1.default.createElement(typography_1.MBTextOverlineBold, { color: colors.foregroundActive }, (0, i18n_1.i18n)('inviteSomeone.addForm.phoneNumberOrEmail.title')))),
        rows.map(function (row, index) { return (react_1.default.createElement(addFormRow_1.MBAddFormRow, { key: index, index: index, data: row, onChange: onUpdateRow })); }),
        react_1.default.createElement(components_1.MBButton, { wrap: true, theme: "transparent", paddingHorizontal: "none", onPress: onAddRow, title: (0, i18n_1.i18n)('inviteSomeone.addForm.addRow'), attributionId: "invite.form.addRow" })));
}
