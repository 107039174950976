"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNativeScrollToIndex = useNativeScrollToIndex;
// @ts-strict-ignore
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var MAX_NUM_TRIES = 20;
/*
    This function is used by search to scroll to the correct position.
    The logic is a little complicated, so bear with me.

    Scrolling to a specific message on native is REALLY hard:
    https://github.com/facebook/react-native/issues/13202
    Because React Native's VirtualizedLists (eg SectionList)
    try to be smart about what they render, they don't know
    at what offset the message #20 is, which makes it hard
    to scroll to it. The reason is that it needs to calculate
    the height of all other rows before #20, which takes time.

    This function takes a desired index to scroll to and tries
    scrolling to it. When this fails - which it will do as long
    as the list doesn't know the offset of that item - the
    SectionList's onScrollToIndexFailed will be triggered. That
    function should run the retry function this hook returns.

    In that way, we will retry until the SectionList succeeds.
*/
function useNativeScrollToIndex(itemIndex, sectionIndex, sectionList) {
    var _a = React.useState(0), tries = _a[0], setTries = _a[1];
    var scroll = React.useCallback(function () {
        // don't do this on web
        if (react_native_1.Platform.OS === 'web') {
            return;
        }
        if (!sectionList) {
            return;
        }
        // Make sure we eventually cancel if retry is called too many times.
        if (tries >= MAX_NUM_TRIES) {
            return;
        }
        sectionList.scrollToLocation({
            itemIndex: itemIndex,
            sectionIndex: sectionIndex,
            animated: true,
        });
        setTries(tries + 1);
    }, [itemIndex, sectionIndex, sectionList, tries, setTries]);
    // We should try scrolling when the sectionList is set and the itemIndex
    // is a number. As long as we pass in itemIndex as undefined, we won't
    // try to scroll. By the chat threads that use this function, itemIndex
    // is undefined until it has loaded all messages and knows the index
    // of the message to scroll to.
    var shouldScroll = !!sectionList && typeof itemIndex === 'number';
    // This hook will only be fired once shouldScroll changes. Therefore
    // it will get run (1) the first time, and (2) the first time shouldScroll
    // is true. Basically it makes sure scroll() is only called once.
    React.useEffect(function () {
        shouldScroll && scroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldScroll]);
    // The retry function tries to scroll with a slight delay. This will
    // go on until the sectionList succeeds and doesn't call onScrollToIndexFailed.
    var retry = React.useCallback(function () {
        setTimeout(scroll, 50);
    }, [scroll]);
    return retry;
}
