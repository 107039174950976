"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDisplayableComponent = isDisplayableComponent;
exports.isAddressComponent = isAddressComponent;
exports.isAuditCheckboxComponent = isAuditCheckboxComponent;
exports.isAuditTagComponent = isAuditTagComponent;
exports.isAuditTextComponent = isAuditTextComponent;
exports.isCombinedTagsComponent = isCombinedTagsComponent;
exports.isDateComponent = isDateComponent;
exports.isEmailComponent = isEmailComponent;
exports.isFileComponent = isFileComponent;
exports.isGeolocationComponent = isGeolocationComponent;
exports.isNumberComponent = isNumberComponent;
exports.isPersonComponent = isPersonComponent;
exports.isScheduledAutomationComponent = isScheduledAutomationComponent;
exports.isRelatedCardComponent = isRelatedCardComponent;
exports.isRelatedCardLookupComponent = isRelatedCardLookupComponent;
exports.isSignatureComponent = isSignatureComponent;
exports.isSubformComponent = isSubformComponent;
exports.isTagComponent = isTagComponent;
exports.isInputComponent = isInputComponent;
exports.isSortableComponent = isSortableComponent;
exports.isGroupableComponent = isGroupableComponent;
exports.isStaticTextComponent = isStaticTextComponent;
exports.isTextComponent = isTextComponent;
exports.isTodoComponent = isTodoComponent;
exports.isInputButtonComponent = isInputButtonComponent;
exports.isTimeTrackerComponent = isTimeTrackerComponent;
exports.isReferencedInComponent = isReferencedInComponent;
exports.isEntityBatchComponent = isEntityBatchComponent;
exports.getComponentIcon = getComponentIcon;
exports.getComponentLabel = getComponentLabel;
exports.getComponentPlaceholder = getComponentPlaceholder;
exports.hasSelectableDefault = hasSelectableDefault;
exports.hasDefault = hasDefault;
exports.hasRelatedCardFilter = hasRelatedCardFilter;
exports.hasRelatedCardEntityFilter = hasRelatedCardEntityFilter;
exports.getPrimaryNodeAttribute = getPrimaryNodeAttribute;
exports.isAuditComponent = isAuditComponent;
exports.useWorkflowTemplate = useWorkflowTemplate;
exports.useWorkflowTemplateWithCardDefinition = useWorkflowTemplateWithCardDefinition;
exports.useWorkflowEntity = useWorkflowEntity;
exports.useWorkflowEntities = useWorkflowEntities;
exports.useViewTemplates = useViewTemplates;
exports.useAllViewTemplates = useAllViewTemplates;
exports.useViewTemplate = useViewTemplate;
exports.useViewTemplateWithUITemplate = useViewTemplateWithUITemplate;
exports.useDeleteViewTemplate = useDeleteViewTemplate;
exports.useCreateViewTemplate = useCreateViewTemplate;
exports.useDuplicateViewTemplate = useDuplicateViewTemplate;
exports.useUpdateViewTemplate = useUpdateViewTemplate;
exports.useGetWorkflowTemplateLazyQuery = useGetWorkflowTemplateLazyQuery;
exports.useListWorkflowTemplates = useListWorkflowTemplates;
exports.useCreateWorkflowTemplate = useCreateWorkflowTemplate;
exports.useUpdateSubformComponentWorkflows = useUpdateSubformComponentWorkflows;
exports.useUpdateWorkflowTemplate = useUpdateWorkflowTemplate;
exports.getLatestWorkflowTemplate = getLatestWorkflowTemplate;
exports.useWorkflows = useWorkflows;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
var client_2 = require("@shared/api/client");
var iconV2_1 = require("@shared/components/image/iconV2");
var hooks_1 = require("@shared/scenes/channel/list/hooks");
var useWorkspaceDetails_1 = require("@shared/scenes/workflows/hooks/useWorkspaceDetails");
var notNil_1 = require("@shared/util/notNil");
var workflow_1 = require("@shared/util/workflow");
function isDisplayableComponent(component) {
    return component.type !== 'INPUT_BUTTON' && component.type !== 'STATIC_TEXT';
}
function isAddressComponent(component) {
    return component.type === 'ADDRESS';
}
function isAuditCheckboxComponent(component) {
    return component.type === 'AUDIT_CHECKBOX';
}
function isAuditTagComponent(component) {
    return component.type === 'AUDIT_TAG';
}
function isAuditTextComponent(component) {
    return component.type === 'AUDIT_TEXT';
}
function isCombinedTagsComponent(component) {
    return component.type === 'COMBINED_TAGS';
}
function isDateComponent(component) {
    return component.type === 'DATE';
}
function isEmailComponent(component) {
    return component.type === 'EMAIL';
}
function isFileComponent(component) {
    return component.type === 'FILE';
}
function isGeolocationComponent(component) {
    return component.type === 'GEOLOCATION';
}
function isNumberComponent(component) {
    return component.type === 'NUMBER';
}
function isPersonComponent(component) {
    return component.type === 'PERSON';
}
function isScheduledAutomationComponent(component) {
    return (component === null || component === void 0 ? void 0 : component.type) === 'SCHEDULED_AUTOMATION';
}
function isRelatedCardComponent(component) {
    return (component === null || component === void 0 ? void 0 : component.type) === 'RELATED_CARD';
}
function isRelatedCardLookupComponent(component) {
    return (component === null || component === void 0 ? void 0 : component.type) === 'RELATED_CARD_LOOKUP';
}
function isSignatureComponent(component) {
    return component.type === 'SIGNATURE';
}
function isSubformComponent(component) {
    return component.type === 'SUBFORM';
}
function isTagComponent(component) {
    return component.type === 'TAG';
}
// https://github.com/coast-app/core-api/blob/master/packages/core-models/src/workflow-template/components/ComponentUtils.ts#L128
function isInputComponent(component) {
    return (!isStaticTextComponent(component) &&
        !isInputButtonComponent(component) &&
        !isCombinedTagsComponent(component) &&
        !isReferencedInComponent(component));
}
function isSortableComponent(component) {
    return isInputComponent(component) && !!component.isSortable;
}
function isGroupableComponent(component) {
    return (isTagComponent(component) || isPersonComponent(component) || isRelatedCardComponent(component));
}
function isStaticTextComponent(component) {
    return 'STATIC_TEXT' === component.type;
}
function isTextComponent(component) {
    return component.type === 'TEXT';
}
function isTodoComponent(component) {
    return component.type === 'TODO';
}
function isInputButtonComponent(component) {
    return component.type === 'INPUT_BUTTON';
}
function isTimeTrackerComponent(component) {
    return component.type === 'TIME_TRACKER';
}
function isReferencedInComponent(component) {
    return component.type === 'REFERENCED_IN';
}
function isEntityBatchComponent(component) {
    return component.type === 'ENTITY_BATCH';
}
function getComponentIcon(component) {
    if ('icon' in component && !(0, lodash_1.isNil)(component.icon) && component.icon in iconV2_1.MBIconV2NameMap) {
        return component.icon;
    }
}
function getComponentLabel(component) {
    var _a;
    if ('label' in component) {
        return (_a = component.label) !== null && _a !== void 0 ? _a : undefined;
    }
}
function getComponentPlaceholder(component) {
    var _a;
    if ('placeholder' in component) {
        return (_a = component.placeholder) !== null && _a !== void 0 ? _a : undefined;
    }
}
function hasSelectableDefault(component) {
    return (isPersonComponent(component) ||
        isRelatedCardComponent(component) ||
        isScheduledAutomationComponent(component) ||
        isSubformComponent(component) ||
        isTagComponent(component) ||
        isAuditTagComponent(component));
}
function hasDefault(component) {
    return !isEmailComponent(component);
}
function hasRelatedCardFilter(component) {
    var _a;
    // workflowTemplateId is technically optional, intended to be nonoptional, but can still be null due to previous
    // frontend edge cases. If we can ever guarantee that all workflowTemplateIds are nonnull then we can remove this
    // function, because it is expected that all RelatedCardComponents have at least a workflow template filter. Because
    // we cannot guarantee this today (2024-10-02) we check for null as well other filters to be safe and guard against
    // any misconfigured components.
    //
    // component is also only optional through the legacy (Low Code) workflow.
    return (!(0, lodash_1.isNil)(component === null || component === void 0 ? void 0 : component.workflowTemplateId) || !(0, lodash_1.isEmpty)((_a = component === null || component === void 0 ? void 0 : component.listDefault) === null || _a === void 0 ? void 0 : _a.filterByComponents));
}
function hasRelatedCardEntityFilter(component) {
    return !(0, lodash_1.isNil)(component.workflowTemplateId);
}
function getPrimaryNodeAttribute(component) {
    if (isAuditTagComponent(component)) {
        return 'tags';
    }
    if (isAuditCheckboxComponent(component)) {
        return 'checked';
    }
    if (isAuditTextComponent(component)) {
        return 'text';
    }
}
function isAuditComponent(component) {
    return (isAuditTagComponent(component) ||
        isAuditCheckboxComponent(component) ||
        isAuditTextComponent(component));
}
function useWorkflowTemplate(id) {
    var _a;
    var _b = (0, client_1.useQuery)(api_1.Queries.workflow.getWorkflowTemplate, {
        variables: { id: id !== null && id !== void 0 ? id : '' },
        skip: (0, lodash_1.isNil)(id),
    }), data = _b.data, loading = _b.loading, error = _b.error;
    var workflowTemplate = (_a = data === null || data === void 0 ? void 0 : data.getWorkflowTemplate) !== null && _a !== void 0 ? _a : undefined;
    return { workflowTemplate: workflowTemplate, loading: loading, error: error };
}
function useWorkflowTemplateWithCardDefinition(id) {
    var _a;
    var _b = (0, client_1.useQuery)(api_1.Queries.workflow.getWorkflowTemplateWithCardDefinition, {
        variables: { id: id !== null && id !== void 0 ? id : '' },
        skip: (0, lodash_1.isNil)(id),
    }), data = _b.data, loading = _b.loading, error = _b.error;
    var workflowTemplate = (_a = data === null || data === void 0 ? void 0 : data.getWorkflowTemplate) !== null && _a !== void 0 ? _a : undefined;
    return { workflowTemplate: workflowTemplate, loading: loading, error: error };
}
function useWorkflowEntity(id) {
    var _a, _b, _c, _d, _e, _f;
    var _g = (0, client_1.useQuery)(api_1.Queries.workflow.getWorkflowEntity, { variables: { input: { id: id } }, skip: (0, lodash_1.isNil)(id) }), data = _g.data, loading = _g.loading, error = _g.error;
    var workflowEntity = (_a = data === null || data === void 0 ? void 0 : data.workflowEntity) === null || _a === void 0 ? void 0 : _a.entity;
    var workflowTemplate = (_d = (_c = (_b = data === null || data === void 0 ? void 0 : data.workflowEntity) === null || _b === void 0 ? void 0 : _b.refs) === null || _c === void 0 ? void 0 : _c.workflowTemplateRefs) === null || _d === void 0 ? void 0 : _d.find(function (wft) { return wft.id === (workflowEntity === null || workflowEntity === void 0 ? void 0 : workflowEntity.workflowTemplateId); });
    var refs = (_f = (_e = data === null || data === void 0 ? void 0 : data.workflowEntity) === null || _e === void 0 ? void 0 : _e.refs) !== null && _f !== void 0 ? _f : undefined;
    return { workflowEntity: workflowEntity, workflowTemplate: workflowTemplate, refs: refs, loading: loading, error: error };
}
function useWorkflowEntities(workflowTemplateId, workflowEntityIds) {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = (0, client_1.useQuery)(api_1.Queries.workflow.getWorkflowEntities, {
        variables: {
            input: { ids: workflowEntityIds !== null && workflowEntityIds !== void 0 ? workflowEntityIds : [], workflowTemplateId: workflowTemplateId !== null && workflowTemplateId !== void 0 ? workflowTemplateId : '' },
        },
        skip: (0, lodash_1.isNil)(workflowTemplateId) || (0, lodash_1.isEmpty)(workflowEntityIds),
    }), data = _h.data, loading = _h.loading, error = _h.error;
    var workflowEntities = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.workflowEntities) === null || _a === void 0 ? void 0 : _a.entities) === null || _b === void 0 ? void 0 : _b.filter(function (e) { return !(0, lodash_1.isNil)(e); })) !== null && _c !== void 0 ? _c : [];
    var workflowTemplate = (_g = (_f = (_e = (_d = data === null || data === void 0 ? void 0 : data.workflowEntities) === null || _d === void 0 ? void 0 : _d.refs) === null || _e === void 0 ? void 0 : _e.workflowTemplateRefs) === null || _f === void 0 ? void 0 : _f.find(function (wft) { return wft.id === workflowTemplateId; })) !== null && _g !== void 0 ? _g : undefined;
    return { workflowTemplate: workflowTemplate, workflowEntities: workflowEntities, loading: loading, error: error };
}
function useViewTemplates(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var workflowTemplateId = _a.workflowTemplateId, type = _a.type, subtype = _a.subtype, name = _a.name, isPublic = _a.isPublic, excludeSubtypes = _a.excludeSubtypes, limit = _a.limit, skip = _a.skip;
    var _k = (0, react_1.useState)(false), moreLoading = _k[0], setMoreLoading = _k[1];
    var _l = (0, react_1.useState)(false), offsetUpdated = _l[0], setOffsetUpdated = _l[1];
    var queryVariables = {
        input: {
            workflowTemplateId: workflowTemplateId !== null && workflowTemplateId !== void 0 ? workflowTemplateId : '',
            type: type,
            subtype: subtype,
            isPublic: isPublic,
            excludeSubtypes: excludeSubtypes,
            name: name,
            limit: limit,
            sort: {
                field: 'CREATED_AT',
                direction: 'ASC',
            },
        },
    };
    var _m = (0, client_1.useQuery)(api_1.Queries.viewTemplate.listViewTemplatesV2, {
        variables: queryVariables,
        skip: skip || (0, lodash_1.isNil)(workflowTemplateId),
    }), data = _m.data, loading = _m.loading, error = _m.error, fetchMoreTemplates = _m.fetchMore;
    var offset = (0, react_1.useMemo)(function () {
        var _a, _b, _c;
        setOffsetUpdated(true);
        return (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.listViewTemplatesV2) === null || _a === void 0 ? void 0 : _a.viewTemplates) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0;
    }, [(_c = (_b = data === null || data === void 0 ? void 0 : data.listViewTemplatesV2) === null || _b === void 0 ? void 0 : _b.viewTemplates) === null || _c === void 0 ? void 0 : _c.length]);
    var fetchMore = (0, react_1.useCallback)(function () {
        var _a;
        if (((_a = data === null || data === void 0 ? void 0 : data.listViewTemplatesV2) === null || _a === void 0 ? void 0 : _a.hasMore) && !loading && !moreLoading && offsetUpdated) {
            setOffsetUpdated(false);
            setMoreLoading(true);
            fetchMoreTemplates({
                variables: {
                    input: __assign(__assign({}, queryVariables.input), { offset: offset }),
                },
            }).finally(function () {
                setMoreLoading(false);
            });
        }
    }, [
        (_d = data === null || data === void 0 ? void 0 : data.listViewTemplatesV2) === null || _d === void 0 ? void 0 : _d.hasMore,
        fetchMoreTemplates,
        loading,
        moreLoading,
        offset,
        offsetUpdated,
        queryVariables.input,
    ]);
    return {
        viewTemplates: (_g = (_f = (_e = data === null || data === void 0 ? void 0 : data.listViewTemplatesV2) === null || _e === void 0 ? void 0 : _e.viewTemplates) === null || _f === void 0 ? void 0 : _f.filter(function (v) { return !(0, lodash_1.isNil)(v); })) !== null && _g !== void 0 ? _g : [],
        hasMore: (_j = (_h = data === null || data === void 0 ? void 0 : data.listViewTemplatesV2) === null || _h === void 0 ? void 0 : _h.hasMore) !== null && _j !== void 0 ? _j : false,
        fetchMore: fetchMore,
        loading: loading,
        moreLoading: moreLoading,
        error: error,
    };
}
function useAllViewTemplates(workflowTemplateId, type, subtype, name, isPublic, skip) {
    var _a = useViewTemplates({
        workflowTemplateId: workflowTemplateId,
        type: type,
        subtype: subtype,
        name: name,
        isPublic: isPublic,
        skip: skip,
    }), viewTemplates = _a.viewTemplates, hasMore = _a.hasMore, fetchMore = _a.fetchMore, loading = _a.loading, moreLoading = _a.moreLoading, error = _a.error;
    (0, react_1.useEffect)(function () {
        if (hasMore && !moreLoading && viewTemplates.length > 0) {
            fetchMore();
        }
    }, [fetchMore, hasMore, moreLoading, viewTemplates.length]);
    return {
        viewTemplates: viewTemplates.filter(function (v) { return !(0, lodash_1.isNil)(v); }),
        loading: loading || moreLoading,
        error: error,
    };
}
function useViewTemplate(workflowTemplateId, viewTemplateId) {
    var _a = (0, client_1.useQuery)(api_1.Queries.viewTemplate.getViewTemplate, {
        variables: {
            input: {
                workflowTemplateId: workflowTemplateId !== null && workflowTemplateId !== void 0 ? workflowTemplateId : '',
                id: viewTemplateId !== null && viewTemplateId !== void 0 ? viewTemplateId : '',
            },
        },
        skip: (0, lodash_1.isNil)(workflowTemplateId) || (0, lodash_1.isEmpty)(viewTemplateId),
    }), data = _a.data, loading = _a.loading, error = _a.error;
    return {
        viewTemplate: data === null || data === void 0 ? void 0 : data.getViewTemplate,
        loading: loading,
        error: error,
    };
}
function useViewTemplateWithUITemplate(workflowTemplateId, viewTemplateId) {
    var _a = (0, client_1.useQuery)(api_1.Queries.viewTemplate.getViewTemplateWithUITemplate, {
        variables: {
            input: { workflowTemplateId: workflowTemplateId !== null && workflowTemplateId !== void 0 ? workflowTemplateId : '', id: viewTemplateId !== null && viewTemplateId !== void 0 ? viewTemplateId : '' },
        },
        skip: (0, lodash_1.isNil)(workflowTemplateId) || (0, lodash_1.isNil)(viewTemplateId),
    }), data = _a.data, loading = _a.loading, error = _a.error;
    return {
        viewTemplate: data === null || data === void 0 ? void 0 : data.getViewTemplate,
        loading: loading,
        error: error,
    };
}
function useDeleteViewTemplate(workflowTemplateId) {
    var apollo = (0, client_1.useApolloClient)();
    return (0, react_1.useCallback)(function (viewTemplateId) {
        return apollo.mutate(__assign({ mutation: api_1.Mutations.viewTemplate.deleteViewTemplate, variables: {
                input: {
                    workflowTemplateId: workflowTemplateId,
                    id: viewTemplateId,
                },
            } }, refetchAndInvalidateQueriesAfterMutatingViewTemplates(workflowTemplateId)));
    }, [apollo, workflowTemplateId]);
}
function useCreateViewTemplate(workflowTemplateId) {
    var apollo = (0, client_1.useApolloClient)();
    return (0, react_1.useCallback)(function (viewTemplate) {
        if ((0, lodash_1.isNil)(workflowTemplateId)) {
            throw new Error('workflowTemplateId is required');
        }
        return apollo.mutate(__assign({ mutation: api_1.Mutations.viewTemplate.createViewTemplate, variables: {
                input: __assign(__assign({}, viewTemplate), { workflowTemplateId: workflowTemplateId }),
            } }, refetchAndInvalidateQueriesAfterMutatingViewTemplates(workflowTemplateId)));
    }, [apollo, workflowTemplateId]);
}
function useDuplicateViewTemplate(workflowTemplateId) {
    var apollo = (0, client_1.useApolloClient)();
    return (0, react_1.useCallback)(function (viewTemplateId, name) {
        if ((0, lodash_1.isNil)(workflowTemplateId)) {
            throw new Error('workflowTemplateId is required');
        }
        return apollo.mutate(__assign({ mutation: api_1.Mutations.viewTemplate.duplicateViewTemplate, variables: {
                input: {
                    name: name,
                    viewTemplateId: viewTemplateId,
                    workflowTemplateId: workflowTemplateId,
                },
            } }, refetchAndInvalidateQueriesAfterMutatingViewTemplates(workflowTemplateId)));
    }, [apollo, workflowTemplateId]);
}
function useUpdateViewTemplate(workflowTemplateId) {
    var apollo = (0, client_1.useApolloClient)();
    return (0, react_1.useCallback)(function (viewTemplate) {
        var _a;
        var mutationPreparedViewTemplate = (0, lodash_1.omit)(viewTemplate, 'cardUITemplate', 'createdAt', 'updatedAt');
        return apollo.mutate(__assign({ mutation: api_1.Mutations.viewTemplate.updateViewTemplate, variables: {
                input: __assign(__assign({}, mutationPreparedViewTemplate), { componentsViewOptions: (_a = viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.map(function (viewOption) {
                        return {
                            componentId: viewOption.componentId,
                            isHidden: viewOption.isHidden,
                            overrides: !(0, lodash_1.isNil)(viewOption.overrides)
                                ? (0, workflow_1.toComponentOneOf)(viewOption.overrides)
                                : null,
                        };
                    }), workflowTemplateId: workflowTemplateId }),
            } }, refetchAndInvalidateQueriesAfterMutatingViewTemplates(workflowTemplateId !== null && workflowTemplateId !== void 0 ? workflowTemplateId : null)));
    }, [apollo, workflowTemplateId]);
}
function useGetWorkflowTemplateLazyQuery() {
    var _this = this;
    var getWorkflowTemplate = (0, client_1.useLazyQuery)(api_1.Queries.workflow.getWorkflowTemplateWithCardDefinition)[0];
    return (0, react_1.useCallback)(function (id) { return __awaiter(_this, void 0, void 0, function () {
        var variables, result;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!!(0, lodash_1.isNil)(id)) return [3 /*break*/, 2];
                    variables = { id: id };
                    return [4 /*yield*/, getWorkflowTemplate({ variables: variables })];
                case 1:
                    result = _b.sent();
                    return [2 /*return*/, (_a = result.data) === null || _a === void 0 ? void 0 : _a.getWorkflowTemplate];
                case 2: return [2 /*return*/];
            }
        });
    }); }, [getWorkflowTemplate]);
}
function useListWorkflowTemplates(_a) {
    var _b, _c, _d, _e;
    var _f = _a.skip, skip = _f === void 0 ? false : _f, _g = _a.input, input = _g === void 0 ? {} : _g, _h = _a.loadAll, loadAll = _h === void 0 ? false : _h, _j = _a.fetchPolicy, fetchPolicy = _j === void 0 ? 'network-only' : _j;
    var _k = (0, react_1.useState)(false), isLoadingMore = _k[0], setisLoadingMore = _k[1];
    var _l = (0, react_1.useState)(false), offsetUpdated = _l[0], setOffsetUpdated = _l[1];
    var variables = (0, react_1.useMemo)(function () { return ({
        input: __assign({ isPublic: false }, input),
    }); }, [input]);
    var _m = (0, client_1.useQuery)(api_1.Queries.workflow.listWorkflowTemplatesV2, {
        variables: variables,
        skip: skip,
        fetchPolicy: fetchPolicy,
    }), data = _m.data, loading = _m.loading, error = _m.error, fetchMore = _m.fetchMore;
    var offset = (0, react_1.useMemo)(function () {
        var _a, _b, _c;
        setOffsetUpdated(true);
        return (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.listWorkflowTemplatesV2) === null || _a === void 0 ? void 0 : _a.workflowTemplates) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0;
    }, [(_c = (_b = data === null || data === void 0 ? void 0 : data.listWorkflowTemplatesV2) === null || _b === void 0 ? void 0 : _b.workflowTemplates) === null || _c === void 0 ? void 0 : _c.length]);
    var workflowTemplates = (_d = data === null || data === void 0 ? void 0 : data.listWorkflowTemplatesV2) === null || _d === void 0 ? void 0 : _d.workflowTemplates;
    var hasMore = (_e = data === null || data === void 0 ? void 0 : data.listWorkflowTemplatesV2) === null || _e === void 0 ? void 0 : _e.hasMore;
    var loadMore = (0, react_1.useCallback)(function () {
        if (hasMore && !loading && !isLoadingMore && offsetUpdated) {
            setOffsetUpdated(false);
            setisLoadingMore(true);
            void fetchMore({
                variables: {
                    input: __assign(__assign({}, variables.input), { offset: offset }),
                },
            }).finally(function () { return setisLoadingMore(false); });
        }
    }, [offset, fetchMore, variables, hasMore, isLoadingMore, loading, offsetUpdated]);
    (0, react_1.useEffect)(function () {
        if (loadAll && hasMore && !loading && !isLoadingMore && offsetUpdated) {
            loadMore();
        }
    }, [hasMore, isLoadingMore, loadAll, loading, loadMore, offsetUpdated]);
    return (0, react_1.useMemo)(function () { return ({ workflowTemplates: workflowTemplates, loading: loading, error: error, hasMore: hasMore, loadMore: loadMore }); }, [workflowTemplates, loading, error, hasMore, loadMore]);
}
function useCreateWorkflowTemplate(isSubform) {
    var _this = this;
    var _a = (0, client_1.useMutation)(api_1.Mutations.workflow.createWorkflow), createWorkflow = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    return [
        (0, react_1.useCallback)(function (workflowTemplate) { return __awaiter(_this, void 0, void 0, function () {
            var result;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, createWorkflow({
                            variables: {
                                input: {
                                    name: workflowTemplate.name,
                                    requiresName: workflowTemplate.requiresName,
                                    isSubform: isSubform,
                                    components: workflowTemplate.components.map(workflow_1.toComponentOneOf),
                                },
                            },
                            refetchQueries: [
                                {
                                    query: api_1.Queries.workflow.listWorkflowTemplates,
                                    variables: { input: { isPublic: false, isSubform: true } },
                                },
                            ],
                            awaitRefetchQueries: true,
                        })];
                    case 1:
                        result = _b.sent();
                        return [2 /*return*/, (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.createWorkflowTemplate];
                }
            });
        }); }, [createWorkflow, isSubform]),
        { loading: loading, error: error },
    ];
}
function useUpdateSubformComponentWorkflows(workflowTemplateId, subformComponentId) {
    var _this = this;
    var updateWorkflow = useUpdateWorkflowTemplate()[0];
    return (0, react_1.useCallback)(function (newWorkflowTemplateId) { return __awaiter(_this, void 0, void 0, function () {
        var workflowTemplate, updatedWorkflowTemplate;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if ((0, lodash_1.isNil)(newWorkflowTemplateId)) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getLatestWorkflowTemplate(workflowTemplateId)];
                case 1:
                    workflowTemplate = (_a.sent()).workflowTemplate;
                    updatedWorkflowTemplate = (0, lodash_1.cloneDeep)(workflowTemplate);
                    if (!(0, lodash_1.isNil)(updatedWorkflowTemplate)) {
                        addWorkflowToSubformComponent(updatedWorkflowTemplate, subformComponentId, newWorkflowTemplateId);
                    }
                    if (!!(0, lodash_1.isNil)(updatedWorkflowTemplate)) return [3 /*break*/, 3];
                    return [4 /*yield*/, updateWorkflow(updatedWorkflowTemplate)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [subformComponentId, updateWorkflow, workflowTemplateId]);
}
function addWorkflowToSubformComponent(workflowTemplate, subformComponentId, newWorkflowTemplateId) {
    for (var _i = 0, _a = workflowTemplate.components; _i < _a.length; _i++) {
        var component = _a[_i];
        if (isSubformComponent(component) && subformComponentId === component.id) {
            component.workflowTemplateIds = component.workflowTemplateIds.concat(newWorkflowTemplateId);
            break;
        }
    }
}
function useUpdateWorkflowTemplate(params) {
    var _this = this;
    var _a = (0, client_1.useMutation)(api_1.Mutations.workflow.updateWorkflow), updateWorkflow = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    var refetchQueries = (0, react_1.useMemo)(function () {
        return (0, lodash_1.isNil)(params === null || params === void 0 ? void 0 : params.channelId)
            ? []
            : [
                {
                    query: api_1.Queries.card.listCardFieldStringTags,
                    variables: { input: { channelId: params.channelId } },
                },
            ];
    }, [params === null || params === void 0 ? void 0 : params.channelId]);
    return [
        (0, react_1.useCallback)(function (workflowTemplate) { return __awaiter(_this, void 0, void 0, function () {
            var cacheId, cachedData;
            var _a;
            return __generator(this, function (_b) {
                cacheId = "WorkflowTemplate".concat(workflowTemplate.id);
                cachedData = client_2.apolloClient.readFragment({
                    id: cacheId,
                    fragment: api_1.Fragments.workflow.WorkflowTemplateWithCardDefinition,
                    fragmentName: 'WorkflowTemplateWithCardDefinition',
                });
                return [2 /*return*/, updateWorkflow({
                        variables: {
                            input: {
                                id: workflowTemplate.id,
                                name: workflowTemplate.name,
                                requiresName: workflowTemplate.requiresName,
                                components: workflowTemplate.components.map(workflow_1.toComponentOneOf),
                                archivedComponents: (_a = workflowTemplate.archivedComponents) === null || _a === void 0 ? void 0 : _a.map(workflow_1.toComponentOneOf),
                            },
                        },
                        optimisticResponse: {
                            updateWorkflowTemplate: __assign(__assign({}, cachedData), workflowTemplate),
                        },
                        refetchQueries: refetchQueries,
                        awaitRefetchQueries: true,
                    })];
            });
        }); }, [updateWorkflow, refetchQueries]),
        { loading: loading, error: error },
    ];
}
function getLatestWorkflowTemplate(workflowTemplateId) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, data, loading, error;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, client_2.apolloClient.query({
                        query: api_1.Queries.workflow.getWorkflowTemplate,
                        fetchPolicy: 'network-only',
                        variables: {
                            id: workflowTemplateId,
                        },
                    })];
                case 1:
                    _a = _c.sent(), data = _a.data, loading = _a.loading, error = _a.error;
                    return [2 /*return*/, {
                            workflowTemplate: (_b = data === null || data === void 0 ? void 0 : data.getWorkflowTemplate) !== null && _b !== void 0 ? _b : undefined,
                            loading: loading,
                            error: error,
                        }];
            }
        });
    });
}
function refetchAndInvalidateQueriesAfterMutatingViewTemplates(workflowTemplateId) {
    return {
        refetchQueries: [
            {
                query: api_1.Queries.workflow.getWorkflowTemplate,
                variables: { id: workflowTemplateId },
            },
            api_1.Queries.viewTemplate.getViewTemplate,
        ],
        update: function (cache) {
            cache.modify({
                fields: function (fieldValue, _a) {
                    var fieldName = _a.fieldName, storeFieldName = _a.storeFieldName, DELETE = _a.DELETE;
                    // This is a workaround because we want to pass `listViewTemplatesV2` to `refetchQueries` but there are no
                    // active `listViewTemplatesV2` queries in some places of the app where we allow the user to modify view
                    // templates. We explicitly do not cache view templates independently, so the contents of the view templates
                    // are cached in the query responses and needs to be managed.
                    return fieldName === 'listViewTemplatesV2' &&
                        ((0, lodash_1.isNil)(workflowTemplateId) ||
                            storeFieldName.includes("\"workflowTemplateId\":\"".concat(workflowTemplateId, "\"")))
                        ? DELETE
                        : fieldValue;
                },
            });
        },
    };
}
/**
 * Provides information about a list of workflow templates and their workspaces.
 *
 * If a workspace name is provided, it will filter the channel list by name and then query the related
 * workflow templates by id.
 */
function useWorkflows(_a) {
    var _b = _a.skip, skip = _b === void 0 ? false : _b, _c = _a.input, input = _c === void 0 ? {} : _c, _d = _a.loadAll, loadAll = _d === void 0 ? false : _d, _e = _a.fetchPolicy, fetchPolicy = _e === void 0 ? 'network-only' : _e, _f = _a.isMember, isMember = _f === void 0 ? false : _f, workspaceName = _a.workspaceName;
    var workspaceDetails = (0, useWorkspaceDetails_1.useWorkspaceDetails)();
    var channelsResponse = (0, hooks_1.useChannelSections)();
    var shouldSkip = skip !== null && skip !== void 0 ? skip : false;
    if (!(0, lodash_1.isNil)(workspaceName) && !(0, lodash_1.isNil)(input.name)) {
        throw new Error('workspaceName and input.name cannot both be provided');
    }
    if (!(0, lodash_1.isNil)(workspaceName)) {
        var channels = channelsResponse.channels.filter(function (c) {
            return c.name.toLowerCase().includes(workspaceName.toLowerCase());
        });
        input.ids = channels.map(function (c) { return c.workflowTemplateId; }).filter(notNil_1.notNil);
        if ((0, lodash_1.isEmpty)(input.ids)) {
            shouldSkip = true;
        }
    }
    var _g = useListWorkflowTemplates({
        input: input,
        fetchPolicy: fetchPolicy,
        loadAll: loadAll,
        skip: shouldSkip,
    }), workflowTemplates = _g.workflowTemplates, loading = _g.loading, hasMore = _g.hasMore, loadMore = _g.loadMore;
    var workflows = workflowTemplates === null || workflowTemplates === void 0 ? void 0 : workflowTemplates.map(function (wft) {
        var channel = channelsResponse.channels.find(function (c) { return c.workflowTemplateId === wft.id; });
        return {
            workflowTemplate: wft,
            workspace: workspaceDetails.get(wft.id),
            workspaceSection: channelsResponse.sections.find(function (s) { var _a; return s.channelIds.includes((_a = channel === null || channel === void 0 ? void 0 : channel.id) !== null && _a !== void 0 ? _a : 0); }),
            isMember: !(0, lodash_1.isNil)(workspaceDetails.get(wft.id)),
        };
    }).filter(function (wft) { return (isMember ? wft.isMember : true); });
    return {
        workflows: workflows,
        loading: loading,
        hasMore: hasMore,
        loadMore: loadMore,
    };
}
