"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBillingLimitBanners = MBBillingLimitBanners;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var billingLimit_1 = require("@shared/components/banner/billingLimit");
var config_1 = require("@shared/config");
var component_1 = require("@shared/scenes/billing/metricsCounter/component");
var provider_1 = require("@shared/scenes/billing/provider");
var analytics_1 = require("@shared/util/analytics");
var i18n_1 = require("@shared/util/i18n");
var useLocalStorage_1 = require("@shared/util/storage/useLocalStorage");
var KEY_PREFIX = 'BillingLimitBanner.v1';
var DEFAULT_STORAGE_VALUE = { dismissed: false };
var STORAGE_EXPIRATION_TTL = 1000 * 60 * 60 * 24 * 7; // 7 days
function MBBillingLimitBanners() {
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var _a = (0, provider_1.useBillingContext)(), billing = _a.billing, loading = _a.loading;
    var key = (0, react_1.useMemo)(function () { return "".concat(KEY_PREFIX, ".").concat(businessId); }, [businessId]);
    var _b = (0, useLocalStorage_1.useLocalStorage)(key, DEFAULT_STORAGE_VALUE, STORAGE_EXPIRATION_TTL), dismissedState = _b[0], updateDismissedState = _b[1], dismissedStateLoading = _b[2];
    var handleDismiss = (0, react_1.useCallback)(function (slug) {
        analytics_1.MBAnalytics.track('BillingLimitBanner::Skip', { slug: slug });
        updateDismissedState({ dismissed: true });
    }, [updateDismissedState]);
    var isDismissed = dismissedState.dismissed;
    var isPaidPlan = billing.isPaidPlan;
    if (dismissedStateLoading || loading || isDismissed || isPaidPlan) {
        return null;
    }
    else {
        return react_1.default.createElement(MBBillingLimitBannerContent, __assign({}, billing, { handleDismiss: handleDismiss }));
    }
}
function MBBillingLimitBannerContent(_a) {
    var cards = _a.cards, cardLimit = _a.cardLimit, messages = _a.messages, messageLimit = _a.messageLimit, storage = _a.storage, storageLimit = _a.storageLimit, handleDismiss = _a.handleDismiss;
    var exceededCards = isExceeded(cards, cardLimit);
    var exceededMessages = isExceeded(messages, messageLimit);
    var exceededStorage = isExceeded(storage, storageLimit);
    if (exceededCards) {
        return (react_1.default.createElement(billingLimit_1.MBBillingLimitBanner, { onDismiss: handleDismiss, slug: "card-limit", title: (0, i18n_1.i18n)('billing.banners.cardLimit.title', {
                count: exceededCards.limit.toLocaleString(),
            }), message: (0, i18n_1.i18n)('billing.banners.cardLimit.message', {
                numDays: config_1.Config.PRICING_LIMIT_HISTORY_DAYS,
            }) }));
    }
    if (exceededMessages) {
        return (react_1.default.createElement(billingLimit_1.MBBillingLimitBanner, { onDismiss: handleDismiss, slug: "message-limit", title: (0, i18n_1.i18n)('billing.banners.messageLimit.title', {
                count: exceededMessages.limit.toLocaleString(),
            }), message: (0, i18n_1.i18n)('billing.banners.messageLimit.message', {
                numDays: config_1.Config.PRICING_LIMIT_HISTORY_DAYS,
            }) }));
    }
    if (exceededStorage) {
        return (react_1.default.createElement(billingLimit_1.MBBillingLimitBanner, { onDismiss: handleDismiss, slug: "storage-limit", title: (0, i18n_1.i18n)('billing.banners.storageLimit.title', {
                count: (0, component_1.bytesToGB)(exceededStorage.limit),
            }), message: (0, i18n_1.i18n)('billing.banners.storageLimit.message', {
                numDays: config_1.Config.PRICING_LIMIT_HISTORY_DAYS,
            }) }));
    }
    return null;
}
function isExceeded(amount, limit) {
    if (!(0, lodash_1.isNil)(amount) && !(0, lodash_1.isNil)(limit) && amount > limit) {
        return { amount: amount, limit: limit };
    }
    else {
        return undefined;
    }
}
