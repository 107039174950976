"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResetCardActionFiltersWhenTemplateChanges = useResetCardActionFiltersWhenTemplateChanges;
var react_1 = require("react");
var provider_1 = require("@shared/scenes/cards/actions/provider");
var hooks_1 = require("@shared/scenes/cards/hooks");
var hooks_2 = require("@shared/util/hooks");
function useResetCardActionFiltersWhenTemplateChanges(template) {
    var _a = (0, react_1.useContext)(provider_1.CardFilterStateContext), setAppliedFilters = _a.setAppliedFilters, setExcludedFieldNames = _a.setExcludedFieldNames;
    var previousTemplate = (0, hooks_2.usePrevious)(template);
    (0, react_1.useEffect)(function () {
        if (template && previousTemplate !== template) {
            setAppliedFilters([]);
            var excludedFieldNames = [];
            var groupBy = template.version === 'v2' &&
                (template.context === 'list' || template.context === 'kanban') &&
                template.groupBy;
            if (groupBy) {
                if (groupBy.type === 'tag') {
                    excludedFieldNames.push(groupBy.tagName);
                }
                else if (groupBy.type === 'card') {
                    excludedFieldNames.push(groupBy.cardName);
                }
                else if (groupBy.type === 'assignees') {
                    excludedFieldNames.push('assignees');
                }
            }
            if ((template.version === 'v2' &&
                (template.context === 'list' || template.context === 'kanban')) ||
                template.context === 'agenda') {
                // We just need to know if the template is filtering by "{me}"; pass a stub find function
                var filterByMe = (0, hooks_1.getCardPaginationAssigneeFilter)(template, function () { return ({}); });
                if (filterByMe) {
                    excludedFieldNames.push('assignees');
                }
            }
            if (template.context === 'agenda' ||
                ((template.context === 'list' || template.context === 'kanban') &&
                    template.version === 'v1')) {
                excludedFieldNames.push('startDate');
            }
            setExcludedFieldNames(excludedFieldNames);
        }
    }, [previousTemplate, template, setExcludedFieldNames, setAppliedFilters]);
}
