// @flow
import React, { useCallback } from 'react';
import { MBModalOrMobile } from '@shared/components';
import { Constants } from '@shared/styles/constants';
import { MBAnalytics } from '@shared/util/analytics';
import { useNavigator } from '@shared/util/navigation';
import { BackableTopNavBar } from '@src/components/TopNavBar';
import { actions } from '@src/core/Router';

function onBack() {
  MBAnalytics.track('Modal::Back');
  actions.popRoute();
}

type WrapProps = {
  title: string,
  backgroundColor?: string,
  closeDisabled?: boolean,
  paddingOnWeb?: string,
  webOverflow?: boolean,
  button?: any,
  children?: any,
  onClose?: () => void,
};

export function Wrap({
  title,
  button,
  children,
  paddingOnWeb,
  webOverflow,
  closeDisabled,
  backgroundColor,
  onClose,
}: WrapProps) {
  const { goBack } = useNavigator();
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      goBack();
    }
  }, [onClose, goBack]);
  return (
    <MBModalOrMobile
      title={title}
      webOverflow={webOverflow}
      headerOnMobile={<BackableTopNavBar title={title} rightElement={button} />}
      desktopModalRightButton={button}
      onClose={closeDisabled ? undefined : handleClose}
      onBack={onBack}
      paddingOnWeb={paddingOnWeb}
      backgroundColor={backgroundColor}
      desktopModalWidth={Constants.pageModalWidth}
    >
      {children}
    </MBModalOrMobile>
  );
}
