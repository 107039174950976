"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isButtonSupportedComponent = isButtonSupportedComponent;
exports.InputButtonOptions = InputButtonOptions;
var lodash_1 = require("lodash");
var rc_menu_1 = __importStar(require("rc-menu"));
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var context_1 = require("@shared/scenes/workflows/context/context");
var NestedOptionsRow_1 = require("@shared/scenes/workflows/edit/components/optionsMenu/NestedOptionsRow");
var SelectableItemList_1 = require("@shared/scenes/workflows/edit/components/optionsMenu/referencedInOptions/SelectableItemList");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var hooks_2 = require("../../hooks");
var divider_1 = require("./divider");
function isButtonSupportedComponent(component) {
    return (0, hooks_1.isTagComponent)(component);
}
function InputButtonOptions(props) {
    var _a;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var label = (0, hooks_2.useSetComponentField)(props.component, 'buttonText')[0];
    var linkableComponents = (_a = props.components) === null || _a === void 0 ? void 0 : _a.filter(isButtonSupportedComponent);
    var dispatch = (0, context_1.useWorkflowDispatch)();
    var setButtonText = (0, react_1.useCallback)(function (value) {
        dispatch({
            type: 'updateComponent',
            args: __assign(__assign({}, props.component), { buttonText: value }),
        });
    }, [dispatch, props.component]);
    var setButtonComponent = (0, react_1.useCallback)(function (componentId) {
        var component = props.components
            .filter(hooks_1.isInputComponent)
            .find(function (item) { return item.id === componentId; });
        dispatch({
            type: 'updateComponent',
            args: __assign(__assign({}, props.component), { buttonText: (0, i18n_1.i18n)('builder.inputButtonOptions.buttonTextPrefill', {
                    componentName: component.label,
                }), inputComponentId: component.id }),
        });
    }, [dispatch, props.component, props.components]);
    var linkedComponent = props.components.find(function (item) { return item.id === props.component.inputComponentId; });
    var rowIcon = !(0, lodash_1.isNil)(linkedComponent) && (0, hooks_1.isInputComponent)(linkedComponent)
        ? linkedComponent === null || linkedComponent === void 0 ? void 0 : linkedComponent.icon
        : 'search-look-up';
    var rowLabel = !(0, lodash_1.isNil)(linkedComponent) && (0, hooks_1.isInputComponent)(linkedComponent)
        ? linkedComponent === null || linkedComponent === void 0 ? void 0 : linkedComponent.label
        : (0, i18n_1.i18n)('workflowTemplate.linkedField');
    var onArchive = (0, react_1.useCallback)(function () {
        dispatch({
            type: 'archiveComponent',
            args: props.component.id,
        });
    }, [props.component.id, dispatch]);
    var linkableComponentsListItems = linkableComponents === null || linkableComponents === void 0 ? void 0 : linkableComponents.map(function (component) {
        return { id: component.id, label: component.label, icon: component.icon };
    });
    return (react_1.default.createElement(components_1.MBView, null,
        react_1.default.createElement(rc_menu_1.default, { mode: "vertical", triggerSubMenuAction: "click", style: {
                backgroundColor: 'white',
                borderRadius: (0, components_1.grid)(1),
                boxShadow: '0 0 10px #d9d9d9',
                maxHeight: '100vh',
                overflow: 'auto',
            }, expandIcon: null },
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3 },
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.fieldType'))),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 2, row: true, centerVertically: true },
                react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundActive }, (0, i18n_1.i18n)('workflowTemplate.component.type.label.button'))),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2 },
                react_1.default.createElement(divider_1.Divider, null)),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.component.type.label.buttonText')),
                react_1.default.createElement(components_1.MBView, { padding: 1 }),
                react_1.default.createElement(components_1.TextInput, { autosize: false, value: label !== null && label !== void 0 ? label : '', onChangeText: setButtonText })),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingTop: 1 },
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.linkedField'))),
            react_1.default.createElement(rc_menu_1.SubMenu, { key: "relatedCardLookup", style: { zIndex: 999 }, forceSubMenuRender: true, title: react_1.default.createElement(NestedOptionsRow_1.NestedOptionsRow, { name: rowLabel, defaultIcon: rowIcon, selectedIcon: rowIcon }) },
                react_1.default.createElement(react_native_1.View, { style: { paddingVertical: (0, components_1.grid)(1) } },
                    react_1.default.createElement(SelectableItemList_1.SelectableItemList, { items: linkableComponentsListItems, onSelectItem: setButtonComponent, enabled: true, selectedItem: props.component.inputComponentId }))),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.options'))),
            react_1.default.createElement(components_1.ArchiveRow, { disabled: props.component.locked, onArchive: onArchive }))));
}
