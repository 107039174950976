"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
exports.MBAuthContentWrapperHeader = MBAuthContentWrapperHeader;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var headerButton_1 = require("@shared/components/nav/headerButton");
var typography_1 = require("@shared/components/typography");
var styles_1 = require("@shared/styles");
var navigation_1 = require("@shared/util/navigation");
exports.styles = react_native_1.StyleSheet.create({
    height: {
        height: (0, components_1.grid)(8),
    },
    leftIcon: {
        position: 'absolute',
        left: 0,
    },
    rightIcon: {
        position: 'absolute',
        right: 0,
    },
});
function MBAuthContentWrapperHeader(_a) {
    var title = _a.title, style = _a.style, _b = _a.leftIcon, leftIcon = _b === void 0 ? require('@shared/assets/icons/v2/arrow-point-left.png') : _b, leftTitle = _a.leftTitle, onPressLeft = _a.onPressLeft, rightIcon = _a.rightIcon, rightTitle = _a.rightTitle, onPressRight = _a.onPressRight;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var goBack = (0, navigation_1.useNavigator)().goBack;
    var defaultedOnPressLeft = onPressLeft === undefined ? goBack : onPressLeft;
    return (react_1.default.createElement(components_1.MBView, { style: style },
        react_1.default.createElement(components_1.MBView, { center: true, style: exports.styles.height },
            !!title && (react_1.default.createElement(typography_1.MBTextH4, { color: colors.foregroundActive, numberOfLines: 1 }, title)),
            (!!leftIcon || !!leftTitle) && !!defaultedOnPressLeft && (react_1.default.createElement(headerButton_1.MBNavigationHeaderButton, { icon: leftIcon, title: leftTitle, style: exports.styles.leftIcon, onPress: defaultedOnPressLeft })),
            (!!rightIcon || !!rightTitle) && !!onPressRight && (react_1.default.createElement(headerButton_1.MBNavigationHeaderButton, { icon: rightIcon, title: rightTitle, style: exports.styles.rightIcon, onPress: onPressRight })))));
}
