"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDefaultFields = useDefaultFields;
exports.getFieldDefault = getFieldDefault;
var lodash_1 = require("lodash");
var react_1 = require("react");
var CardField_1 = require("@shared/data/cards/CardField");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var user_1 = require("@shared/util/user");
function useDefaultFields(_a) {
    var workflowTemplateId = _a.workflowTemplateId, viewTemplate = _a.viewTemplate, queryFieldOverrides = _a.queryFieldOverrides;
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId !== null && workflowTemplateId !== void 0 ? workflowTemplateId : undefined).workflowTemplate;
    var allComponents = (workflowTemplate !== null && workflowTemplate !== void 0 ? workflowTemplate : {}).components;
    var components = (0, react_1.useMemo)(function () { var _a; return (_a = allComponents === null || allComponents === void 0 ? void 0 : allComponents.filter(hooks_1.isInputComponent)) !== null && _a !== void 0 ? _a : []; }, [allComponents]);
    var currentUser = (0, user_1.useCurrentUser)();
    return (0, react_1.useMemo)(function () {
        if ((0, lodash_1.isEmpty)(components) || (0, lodash_1.isNil)(viewTemplate) || (0, lodash_1.isNil)(currentUser)) {
            return null;
        }
        var fieldsBuilder = {};
        components.forEach(function (component) {
            var _a, _b;
            var fieldOverrides = (_b = (_a = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (option) {
                return option.componentId === component.id;
            })) === null || _b === void 0 ? void 0 : _b.overrides;
            var builtField = (0, CardField_1.buildCardField)({
                apiCard: null,
                component: component,
                refs: {
                    personRefs: null,
                    workflowEntityRefs: null,
                    workflowTemplateRefs: null,
                    recurringEntitiesScheduleRefs: null,
                },
                defaultData: getFieldDefault(component, fieldOverrides, queryFieldOverrides),
                currentUser: currentUser,
            });
            if (!(0, lodash_1.isNil)(builtField)) {
                fieldsBuilder[component.id] = builtField;
            }
        });
        return fieldsBuilder;
    }, [components, viewTemplate, queryFieldOverrides, currentUser]);
}
function getFieldDefault(component, fieldOverrides, queryFieldOverrides) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var queryOverride = !(0, lodash_1.isNil)(component) ? queryFieldOverrides === null || queryFieldOverrides === void 0 ? void 0 : queryFieldOverrides[component.id] : null;
    if (!(0, lodash_1.isNil)(queryOverride)) {
        return queryOverride;
    }
    var componentToUse = !(0, lodash_1.isNil)(fieldOverrides) && 'default' in fieldOverrides && !(0, lodash_1.isNil)(fieldOverrides.default)
        ? fieldOverrides
        : component;
    if ((0, lodash_1.isNil)(componentToUse)) {
        return null;
    }
    if ((0, hooks_1.isTextComponent)(componentToUse)) {
        if ((0, lodash_1.isNil)((_a = componentToUse.default) === null || _a === void 0 ? void 0 : _a.text) || (0, lodash_1.isEmpty)((_b = componentToUse.default) === null || _b === void 0 ? void 0 : _b.text)) {
            return null;
        }
        return componentToUse.default.text;
    }
    if ((0, hooks_1.isTagComponent)(componentToUse) || (0, hooks_1.isAuditTagComponent)(componentToUse)) {
        if ((0, lodash_1.isNil)((_c = componentToUse.default) === null || _c === void 0 ? void 0 : _c.value) || (0, lodash_1.isEmpty)((_d = componentToUse.default) === null || _d === void 0 ? void 0 : _d.value)) {
            return null;
        }
        return componentToUse.default.value;
    }
    if ((0, hooks_1.isScheduledAutomationComponent)(componentToUse)) {
        if ((0, lodash_1.isNil)(componentToUse.default) ||
            (0, lodash_1.isNil)((_e = componentToUse.default) === null || _e === void 0 ? void 0 : _e.automationTriggerDurations) ||
            (0, lodash_1.isEmpty)((_f = componentToUse.default) === null || _f === void 0 ? void 0 : _f.automationTriggerDurations)) {
            return null;
        }
        var scheduledAutomation = {
            automationTriggerDurations: componentToUse.default.automationTriggerDurations.filter(function (v, i) {
                var _a, _b;
                return !(0, lodash_1.isNil)((_a = componentToUse.default) === null || _a === void 0 ? void 0 : _a.automationTriggerDurations)
                    ? ((_b = componentToUse.default) === null || _b === void 0 ? void 0 : _b.automationTriggerDurations.indexOf(v)) === i
                    : false;
            }),
        };
        return scheduledAutomation;
    }
    if ((0, hooks_1.isRelatedCardComponent)(componentToUse)) {
        if ((0, lodash_1.isNil)((_g = componentToUse.default) === null || _g === void 0 ? void 0 : _g.cardIds) || (0, lodash_1.isEmpty)((_h = componentToUse.default) === null || _h === void 0 ? void 0 : _h.cardIds)) {
            return null;
        }
        return componentToUse.default.cardIds.map(function (id) { return ({ id: id }); });
    }
    if ((0, hooks_1.isPersonComponent)(componentToUse)) {
        return !(0, lodash_1.isEmpty)((_j = componentToUse.default) === null || _j === void 0 ? void 0 : _j.userIds)
            ? (_k = componentToUse.default) === null || _k === void 0 ? void 0 : _k.userIds
            : (_m = (_l = componentToUse.default) === null || _l === void 0 ? void 0 : _l.token) !== null && _m !== void 0 ? _m : null;
    }
    if ((0, hooks_1.isSubformComponent)(componentToUse)) {
        if ((0, lodash_1.isNil)((_o = componentToUse.default) === null || _o === void 0 ? void 0 : _o.workflowTemplateId) ||
            (0, lodash_1.isEmpty)((_p = componentToUse.default) === null || _p === void 0 ? void 0 : _p.workflowTemplateId)) {
            return null;
        }
        var subform = {
            workflowTemplateId: componentToUse.default.workflowTemplateId,
            data: {},
        };
        return subform;
    }
    return null;
}
