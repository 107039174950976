"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monthlyPrice = monthlyPrice;
exports.yearlyPrice = yearlyPrice;
function monthlyPrice(price) {
    return price.interval === 'month';
}
function yearlyPrice(price) {
    return price.interval === 'year';
}
