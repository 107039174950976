"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toString = toString;
// @ts-strict-ignore
// TODO(fant): i18n
function toString(message) {
    var _a, _b;
    if (message.subType === 'CHANNEL_CREATED') {
        return "".concat(message.sender.username, " created this group");
    }
    if (message.subType === 'MESSAGE_DELETED' || message.subType === 'COMMENT_DELETED') {
        return 'Message removed by sender';
    }
    if (message.subType === 'USER_JOINED') {
        return "".concat(message.sender.username, " is on board!");
    }
    if (message.subType === 'USERS_ADDED') {
        return "".concat(message.meta.users.map(function (u) { return u.username; }).join(', '), " added by ").concat(message.sender.username);
    }
    if (message.subType === 'USER_REMOVED_FROM_CHANNEL') {
        return "".concat(message.meta.users.map(function (u) { return u.username; }).join(', '), " removed by ").concat(message.sender.username);
    }
    if (message.subType === 'ME_REMOVED_FROM_CHANNEL') {
        var channelName = message.meta.channelName;
        var byUser = message.meta.users[0].username;
        return "You've been removed from \"".concat(channelName, "\" by ").concat(byUser);
    }
    if (message.subType === 'USER_NOTIFIED' || message.subType === 'USERS_TEXTED') {
        var names = message.meta.users.map(function (u) { return u.username; });
        switch (names.length) {
            case 1:
                return 'Sent by SMS';
            case 2:
                return "Sent by SMS to ".concat(names[0]);
            case 3:
                return "Sent by SMS to ".concat(names[0], " and ").concat(names[1]);
            case 4:
                return "Sent by SMS to ".concat(names[0], ", ").concat(names[1], " and ").concat(names[2]);
            default:
                var numOther = names.length - 2; // 2 = number of directly mentioned names
                return "Sent by SMS to ".concat(names[0], ", ").concat(names[1], " and ").concat(numOther, " others");
        }
    }
    if (((_a = message.subType) === null || _a === void 0 ? void 0 : _a.startsWith('THREAD_')) || ((_b = message.subType) === null || _b === void 0 ? void 0 : _b.startsWith('CARD_'))) {
        return message.content;
    }
    return null;
}
