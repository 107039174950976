"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNavigateToOnboarding = useNavigateToOnboarding;
exports.useNavigateToOnboardingIfNeeded = useNavigateToOnboardingIfNeeded;
exports.MBAuthRequiredGuard = MBAuthRequiredGuard;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
var activityFeed_1 = require("@shared/api/providers/activityFeed");
var cardPagination_1 = require("@shared/api/providers/cardPagination");
var util_1 = require("@shared/scenes/auth/util");
var hooks_1 = require("@shared/scenes/onboarding/lastStep/hooks");
var WorkflowInstallStore_1 = require("@shared/scenes/workflows/install/WorkflowInstallStore");
var navigation_1 = require("@shared/util/navigation");
function useNavigateToOnboarding(replace) {
    if (replace === void 0) { replace = false; }
    var navigator = (0, navigation_1.useNavigator)();
    var shouldShowLastStep = (0, hooks_1.useOnboardingLastStepState)().shouldShow;
    return (0, react_1.useCallback)(function (user) {
        var fn = replace ? navigator.replace : navigator.navigate;
        if (!user.fullName) {
            fn('OnboardingProfile');
            return true;
        }
        if (!user.businesses.length) {
            fn('OnboardingCreateOrJoinBusiness');
            return true;
        }
        if (shouldShowLastStep) {
            fn('OnboardingLastStep');
            return true;
        }
        return false;
    }, [navigator, replace, shouldShowLastStep]);
}
function useNavigateToOnboardingIfNeeded() {
    var data = (0, client_1.useQuery)(api_1.Queries.user.me, { fetchPolicy: 'network-only' }).data;
    var navigateToOnboarding = useNavigateToOnboarding(true);
    (0, react_1.useEffect)(function () {
        if (!!(data === null || data === void 0 ? void 0 : data.me)) {
            navigateToOnboarding(data.me);
        }
    }, [data === null || data === void 0 ? void 0 : data.me, navigateToOnboarding]);
}
function MBAuthRequiredGuard(_a) {
    var children = _a.children, _b = _a.shouldNavigateToOnboarding, shouldNavigateToOnboarding = _b === void 0 ? true : _b;
    var navigate = (0, navigation_1.useNavigator)().navigate;
    var navigateToOnboarding = useNavigateToOnboarding(true);
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var hasAuthToken = !(0, lodash_1.isNil)(util_1.MBAuthUtil.getAuthToken());
    var hasBizAuthToken = !(0, lodash_1.isNil)(util_1.MBAuthUtil.getBizAuthToken());
    var _c = (0, client_1.useQuery)(api_1.Queries.user.me, {
        skip: !hasAuthToken,
    }), data = _c.data, loading = _c.loading, error = _c.error;
    (0, react_1.useEffect)(function () {
        if (loading) {
            return;
        }
        if (!hasAuthToken && !hasBizAuthToken) {
            // TODO(fant): replace once we transition to the new auth/onboarding
            navigate('Login');
            return;
        }
        if (!hasAuthToken && hasBizAuthToken) {
            // TODO(fant): replace once we transition to the new auth/onboarding
            navigate('LoginBusinessUserList');
            return;
        }
        var me = data === null || data === void 0 ? void 0 : data.me;
        if (!me && !error) {
            navigate('LoginExistingAccount');
            return;
        }
        if (!me && !!error) {
            // TODO(fant): what do we do when the query fails?
            return;
        }
        if (!(0, lodash_1.isNil)(me) && shouldNavigateToOnboarding) {
            navigateToOnboarding(me);
        }
        // TODO(fant): push to channel creation page if not skipped
        if (!businessId || !(me === null || me === void 0 ? void 0 : me.businesses.some(function (b) { return (b === null || b === void 0 ? void 0 : b.id) === businessId; }))) {
            // Change current business id
            return;
        }
        if (!(0, lodash_1.isEmpty)(WorkflowInstallStore_1.DefaultWorkflowIdToInstallStore.workflowIdToInstall())) {
            navigate('CreateChannelWorkflowLibrary');
        }
    }, [
        loading,
        data,
        hasAuthToken,
        hasBizAuthToken,
        error,
        shouldNavigateToOnboarding,
        businessId,
        navigate,
        navigateToOnboarding,
    ]);
    return (react_1.default.createElement(react_1.default.Fragment, null, (hasAuthToken || hasBizAuthToken) && (react_1.default.createElement(activityFeed_1.ActivityFeedProvider, null,
        react_1.default.createElement(cardPagination_1.CardPaginationProvider, null, children)))));
}
