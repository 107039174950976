"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBillingProvider = MBBillingProvider;
exports.useBillingContext = useBillingContext;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
var container_1 = require("@shared/scenes/billing/metricsCounter/container");
/**
 * Source: https://github.com/coast-app/core-api/blob/c5a6175e1afc9d637738ce129afe65bb048efada/packages/core-api/src/modules/billing/stripe/StripeClient.ts#L231
 */
var ACTIVE_SUBSCRIPTION_STATUSES = [
    'active',
    'trialing',
    'past_due',
    'incomplete',
];
var BillingContext = (0, react_1.createContext)({
    billing: {},
    loading: false,
    refetch: function () { return Promise.resolve(); },
});
function MBBillingProvider(_a) {
    var _this = this;
    var children = _a.children;
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var _b = (0, react_1.useState)({}), billing = _b[0], setBilling = _b[1];
    var _c = (0, react_1.useState)(false), loading = _c[0], setLoading = _c[1];
    var apolloClient = (0, client_1.useApolloClient)();
    var refetch = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        var usageQuery, billingQuery, userCountQuery, _a, usageData, billingData, userCountData, subscriptions, subscription, isPaidPlan, messages, storage, cards, messageLimit, storageLimit, cardLimit, updatedBilling_1, e_1;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        return __generator(this, function (_p) {
            switch (_p.label) {
                case 0:
                    if ((0, lodash_1.isNil)(businessId)) {
                        return [2 /*return*/];
                    }
                    setLoading(true);
                    usageQuery = apolloClient.query({
                        query: api_1.Queries.billing.usageMetrics,
                        fetchPolicy: 'network-only',
                    });
                    billingQuery = apolloClient.query({
                        query: api_1.Queries.billing.get,
                        fetchPolicy: 'network-only',
                    });
                    userCountQuery = apolloClient.query({
                        query: api_1.Queries.billing.getUserCount,
                        variables: { businessId: businessId },
                        fetchPolicy: 'network-only',
                    });
                    _p.label = 1;
                case 1:
                    _p.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, Promise.all([
                            usageQuery,
                            billingQuery,
                            userCountQuery,
                        ])];
                case 2:
                    _a = _p.sent(), usageData = _a[0], billingData = _a[1], userCountData = _a[2];
                    if (!usageData || !billingData) {
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    subscriptions = (_c = (_b = billingData.data.billing) === null || _b === void 0 ? void 0 : _b.customer.subscriptions) !== null && _c !== void 0 ? _c : undefined;
                    subscription = subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions[0];
                    isPaidPlan = subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.some(function (item) { return ACTIVE_SUBSCRIPTION_STATUSES.includes(item.status) && item.isPaidPlan; });
                    messages = (0, container_1.findMetric)('MESSAGES', usageData.data);
                    storage = (0, container_1.findMetric)('STORAGE', usageData.data);
                    cards = (0, container_1.findMetric)('CARDS', usageData.data);
                    messageLimit = (_e = (_d = subscription === null || subscription === void 0 ? void 0 : subscription.limits) === null || _d === void 0 ? void 0 : _d.find(function (limit) { return limit.type === 'MESSAGES'; })) === null || _e === void 0 ? void 0 : _e.value;
                    storageLimit = (_g = (_f = subscription === null || subscription === void 0 ? void 0 : subscription.limits) === null || _f === void 0 ? void 0 : _f.find(function (limit) { return limit.type === 'STORAGE'; })) === null || _g === void 0 ? void 0 : _g.value;
                    cardLimit = (_j = (_h = subscription === null || subscription === void 0 ? void 0 : subscription.limits) === null || _h === void 0 ? void 0 : _h.find(function (limit) { return limit.type === 'CARDS'; })) === null || _j === void 0 ? void 0 : _j.value;
                    updatedBilling_1 = {
                        subscriptions: subscriptions,
                        status: subscription === null || subscription === void 0 ? void 0 : subscription.status,
                        isPaidPlan: isPaidPlan,
                        messages: messages,
                        messageLimit: messageLimit,
                        cards: cards,
                        cardLimit: cardLimit,
                        storage: storage,
                        storageLimit: storageLimit,
                        numUsers: (_o = (_m = (_l = (_k = userCountData === null || userCountData === void 0 ? void 0 : userCountData.data) === null || _k === void 0 ? void 0 : _k.me) === null || _l === void 0 ? void 0 : _l.business) === null || _m === void 0 ? void 0 : _m.userCount) !== null && _o !== void 0 ? _o : 0,
                    };
                    setBilling(function (prev) {
                        if (!(0, lodash_1.isEqual)(prev, updatedBilling_1)) {
                            return updatedBilling_1;
                        }
                        else {
                            return prev;
                        }
                    });
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _p.sent();
                    console.error(e_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [apolloClient, businessId]);
    (0, react_1.useEffect)(function () {
        // a slight delay to avoid an 'invariant violation' which causes Apollo to
        // cancel the query when switching orgs
        var timer = setTimeout(function () { return refetch(); });
        return function () { return clearTimeout(timer); };
    }, [refetch]);
    var value = { billing: billing, loading: loading, refetch: refetch };
    return react_1.default.createElement(BillingContext.Provider, { value: value }, children);
}
function useBillingContext() {
    return (0, react_1.useContext)(BillingContext);
}
