"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CARDS_PER_GROUP_PAGE = exports.CARDS_PER_PAGE = void 0;
exports.useUpdateList = useUpdateList;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
var DraggableContext_1 = require("@shared/scenes/cards/listView/components/DraggableContext");
var hooks_1 = require("@shared/util/hooks");
var notNil_1 = require("@shared/util/notNil");
var useCardIsInFilterGroup_1 = require("./useCardIsInFilterGroup");
var useSortedCardList_1 = require("./useSortedCardList");
exports.CARDS_PER_PAGE = 50;
exports.CARDS_PER_GROUP_PAGE = 10;
var TOTAL_RESULTS_COUNT_UPDATE_DELAY_MS = 2500;
function useUpdateList(variables) {
    var client = (0, client_1.useApolloClient)();
    var getCardIsInFilterGroup = (0, useCardIsInFilterGroup_1.useCardIsInFilterGroup)();
    var getSortedCards = (0, useSortedCardList_1.useSortedCardList)();
    var updateCount = useUpdateCount(variables);
    var onUpdate = function (options) {
        var _a, _b;
        if ((0, lodash_1.isNil)(options.data.data)) {
            return;
        }
        var workflowTemplateId = variables.input.workflowTemplateId;
        var _c = options.data.data.event, type = _c.type, cards = _c.entities, refs = _c.refs;
        var incomingWorkflowEntityRefs = refs.workflowEntityRefs, incomingPersonRefs = refs.personRefs, incomingRecurringEntitiesScheduleRefs = refs.recurringEntitiesScheduleRefs, incomingWorkflowTemplateRefs = refs.workflowTemplateRefs;
        var getNewCards = function () {
            var groupedCards = (0, lodash_1.groupBy)(cards, 'workflowTemplateId');
            return groupedCards[workflowTemplateId];
        };
        var cardGroup = getNewCards();
        // event is not for this workflow template, nothing to update
        if ((0, lodash_1.isEmpty)(cardGroup)) {
            return;
        }
        var getExistingCards = function () {
            return client.readQuery({
                query: api_1.Queries.workflow.listWorkflowEntities,
                variables: variables,
            });
        };
        var existingCards = getExistingCards();
        // this query is not in the cache, nothing to update
        if ((0, lodash_1.isNil)(existingCards)) {
            return;
        }
        var updateList = function (data) {
            return client.writeQuery({
                query: api_1.Queries.workflow.listWorkflowEntities,
                variables: variables,
                data: data,
            });
        };
        updateCount();
        var _d = (_a = existingCards.list.refs) !== null && _a !== void 0 ? _a : {}, existingWorkflowEntityRefs = _d.workflowEntityRefs, existingPersonRefs = _d.personRefs, existingRecurringEntitiesScheduleRefs = _d.recurringEntitiesScheduleRefs, existingWorkflowTemplateRefs = _d.workflowTemplateRefs;
        switch (type) {
            case 'WORKFLOW_ENTITY_CREATED': {
                updateList(__assign(__assign({}, existingCards), { list: __assign(__assign(__assign({}, existingCards.list), getNewCardsList({
                        existingCards: existingCards,
                        incomingCardGroup: cardGroup,
                        getCardIsInFilterGroup: getCardIsInFilterGroup,
                        getSortedCards: getSortedCards,
                    })), { refs: __assign(__assign({}, existingCards.list.refs), { workflowEntityRefs: mergeRefs(existingWorkflowEntityRefs, incomingWorkflowEntityRefs), personRefs: mergeRefs(existingPersonRefs, incomingPersonRefs), recurringEntitiesScheduleRefs: mergeRefs(existingRecurringEntitiesScheduleRefs, incomingRecurringEntitiesScheduleRefs), workflowTemplateRefs: mergeRefs(existingWorkflowTemplateRefs, incomingWorkflowTemplateRefs) }) }) }));
                break;
            }
            case 'WORKFLOW_ENTITY_UPDATED': {
                updateList(__assign(__assign({}, existingCards), { list: __assign(__assign(__assign({}, existingCards.list), getNewCardsList({
                        existingCards: existingCards,
                        incomingCardGroup: cardGroup,
                        getCardIsInFilterGroup: getCardIsInFilterGroup,
                        getSortedCards: getSortedCards,
                    })), { refs: __assign(__assign({}, existingCards.list.refs), { workflowEntityRefs: mergeRefs(existingWorkflowEntityRefs, incomingWorkflowEntityRefs), personRefs: mergeRefs(existingPersonRefs, incomingPersonRefs), recurringEntitiesScheduleRefs: mergeRefs(existingRecurringEntitiesScheduleRefs, incomingRecurringEntitiesScheduleRefs), workflowTemplateRefs: mergeRefs(existingWorkflowTemplateRefs, incomingWorkflowTemplateRefs) }) }) }));
                break;
            }
            case 'WORKFLOW_ENTITY_DELETED': {
                var newCards_1 = __spreadArray([], ((_b = existingCards.list.entities) !== null && _b !== void 0 ? _b : []), true).filter(notNil_1.notNil);
                var totalResults_1 = existingCards.list.totalResults;
                cardGroup.forEach(function (card) {
                    var existingCardIndex = newCards_1.findIndex(function (c) { return c.id === card.id; });
                    if (existingCardIndex > -1) {
                        totalResults_1 = Math.max(0, totalResults_1 - 1);
                        newCards_1 = newCards_1.filter(function (c) { return c.id !== card.id; });
                    }
                });
                updateList(__assign(__assign({}, existingCards), { list: __assign(__assign({}, existingCards.list), { entities: newCards_1, totalResults: totalResults_1, refs: __assign(__assign({}, existingCards.list.refs), { workflowEntityRefs: mergeRefs(existingWorkflowEntityRefs, incomingWorkflowEntityRefs), personRefs: mergeRefs(existingPersonRefs, incomingPersonRefs), recurringEntitiesScheduleRefs: mergeRefs(existingRecurringEntitiesScheduleRefs, incomingRecurringEntitiesScheduleRefs), workflowTemplateRefs: mergeRefs(existingWorkflowTemplateRefs, incomingWorkflowTemplateRefs) }) }) }));
                break;
            }
        }
    };
    (0, client_1.useSubscription)(api_1.Subscriptions.workflow.event, {
        onData: onUpdate,
        variables: variables,
        shouldResubscribe: true,
    });
}
function getNewCardsList(_a) {
    var _b;
    var existingCards = _a.existingCards, incomingCardGroup = _a.incomingCardGroup, getCardIsInFilterGroup = _a.getCardIsInFilterGroup, getSortedCards = _a.getSortedCards;
    var existingEntities = (_b = existingCards.list.entities) !== null && _b !== void 0 ? _b : [];
    var entities = __spreadArray([], existingEntities, true).filter(notNil_1.notNil);
    var totalResults = existingCards.list.totalResults;
    incomingCardGroup === null || incomingCardGroup === void 0 ? void 0 : incomingCardGroup.forEach(function (card) {
        var isCardInFilterGroup = getCardIsInFilterGroup(card);
        var existingCardIndex = entities.findIndex(function (c) { return c.id === card.id; });
        var cardExistsInGroup = existingCardIndex > -1;
        // matches filters and is already in group
        if (cardExistsInGroup && isCardInFilterGroup) {
            entities[existingCardIndex] = __assign({}, card);
            // matches filters but is not in group
        }
        else if (!cardExistsInGroup && isCardInFilterGroup) {
            totalResults += 1;
            entities = __spreadArray([__assign({}, card)], entities, true);
            // is in group but doesn't match filters
        }
        else if (cardExistsInGroup) {
            totalResults = Math.max(0, totalResults - 1);
            entities = __spreadArray([], entities.filter(function (nc) { return nc.id !== card.id; }), true);
        }
    });
    return {
        entities: getSortedCards(entities),
        totalResults: totalResults,
    };
}
function mergeRefs(existingRefList, incomingRefList) {
    var _a;
    if ((0, lodash_1.isNil)(existingRefList) || (0, lodash_1.isNil)(incomingRefList)) {
        // if either is null, return the other one, or null
        return (_a = existingRefList !== null && existingRefList !== void 0 ? existingRefList : incomingRefList) !== null && _a !== void 0 ? _a : null;
    }
    // merge refs, using incomingRefList as the source of truth, and re-add any
    // existing refs that are not in the incomingRefList
    var mergedRefList = existingRefList.reduce(function (memo, existingRef) {
        if (memo.some(function (incomingRef) { return incomingRef.id === existingRef.id; })) {
            return memo;
        }
        else {
            return __spreadArray(__spreadArray([], memo, true), [existingRef], false);
        }
    }, incomingRefList);
    return mergedRefList;
}
function useUpdateCount(variables) {
    var client = (0, client_1.useApolloClient)();
    var isDragging = (0, DraggableContext_1.useDraggableContext)().isDragging;
    var _a = (0, hooks_1.useBoolean)(false), shouldUpdateCount = _a[0], updateCount = _a[1], stopUpdatingCount = _a[2];
    (0, react_1.useEffect)(function () {
        if (!shouldUpdateCount || isDragging) {
            return;
        }
        // Update totalResults after any update, after a delay, using limit: 0 to
        // just query for the count. We can't do this immediately because the query
        // hasn't had time to update yet and will return the old count.
        // Also checking for activeCards so we don't bother updating the count while
        // the user is dragging a card. Once they drop the card, totalResults will
        // be updated if necessary.
        // TODO use ListWorkflowEntitiesCountQuery
        var queryForCount = function () {
            client.query({
                query: api_1.Queries.workflow.listWorkflowEntities,
                variables: __assign(__assign({}, variables), { input: __assign(__assign({}, variables.input), { limit: 0 }) }),
                fetchPolicy: 'network-only',
            });
            stopUpdatingCount();
        };
        var timer = setTimeout(queryForCount, TOTAL_RESULTS_COUNT_UPDATE_DELAY_MS);
        return function () { return clearTimeout(timer); };
    }, [shouldUpdateCount, isDragging, client, variables, stopUpdatingCount]);
    return updateCount;
}
