"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ANON_USER_TOKEN_PREFIX = exports.ANON_USER_TOKEN_KEY = exports.defaultFlags = exports.BATCH_UPLOAD_USERS = exports.BACK_BUTTON_HISTORY = exports.USER_GROUPS = exports.PERMISSIONS_REFETCH_TIMER = exports.COMPONENT_HIDE = exports.DELETE_WORKSPACE_SECTION = exports.DATA_FRESHNESS = exports.NO_CODE_CREATE_FORM = exports.NO_CODE_UPDATE_FORM = exports.AUTOMATIONS_IN_CARD_FORM = exports.MULTIPLE_FILTER_GROUPS = exports.TIME_TRACKER_COMPONENT_GEOLOCATION = exports.ABSOLUTE_TIMEOUT = exports.INACTIVITY_TIMEOUT = exports.PAYWALL_EXPERIMENT = void 0;
exports.useLaunchDarklyIdentity = useLaunchDarklyIdentity;
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var util_1 = require("@shared/scenes/auth/util");
var hooks_1 = require("@shared/util/hooks");
var storage_1 = require("@shared/util/storage");
var uuid = __importStar(require("@shared/util/uuid"));
// all flags here
exports.PAYWALL_EXPERIMENT = 'paywall-experiment';
exports.INACTIVITY_TIMEOUT = 'inactivity-timeout';
exports.ABSOLUTE_TIMEOUT = 'absolute-timeout';
exports.TIME_TRACKER_COMPONENT_GEOLOCATION = 'time-tracker-component-geolocation';
exports.MULTIPLE_FILTER_GROUPS = 'multi-filter-groups';
exports.AUTOMATIONS_IN_CARD_FORM = 'automations-in-card-form';
exports.NO_CODE_UPDATE_FORM = 'no-code-entity-update-form';
exports.NO_CODE_CREATE_FORM = 'no-code-entity-create-form';
exports.DATA_FRESHNESS = 'data-freshness';
exports.DELETE_WORKSPACE_SECTION = 'delete-workspace-section';
exports.COMPONENT_HIDE = 'component-hide';
exports.PERMISSIONS_REFETCH_TIMER = 'permissions-refetch-timer';
exports.USER_GROUPS = 'user-groups';
exports.BACK_BUTTON_HISTORY = 'back-button-history';
exports.BATCH_UPLOAD_USERS = 'batch-upload-users';
// set default flag state here
exports.defaultFlags = (_a = {},
    _a[exports.PAYWALL_EXPERIMENT] = '', // redacted or banner
    _a[exports.INACTIVITY_TIMEOUT] = 0,
    _a[exports.ABSOLUTE_TIMEOUT] = 0,
    _a[exports.TIME_TRACKER_COMPONENT_GEOLOCATION] = false,
    _a[exports.MULTIPLE_FILTER_GROUPS] = false,
    _a[exports.AUTOMATIONS_IN_CARD_FORM] = false,
    _a[exports.NO_CODE_UPDATE_FORM] = false,
    _a[exports.NO_CODE_CREATE_FORM] = false,
    _a[exports.BACK_BUTTON_HISTORY] = false,
    _a[exports.DATA_FRESHNESS] = {
        enabled: false,
        logPrefix: '',
        maxRetries: 1,
        refetchThrottleMs: 1000,
        sleepDetectorIntervalMs: 1000,
        sleepDetectorTriggerRefetchMs: 1000,
    },
    _a[exports.DELETE_WORKSPACE_SECTION] = false,
    _a[exports.COMPONENT_HIDE] = false,
    _a[exports.PERMISSIONS_REFETCH_TIMER] = 0,
    _a[exports.USER_GROUPS] = false,
    _a[exports.BATCH_UPLOAD_USERS] = false,
    _a);
exports.ANON_USER_TOKEN_KEY = 'ldAnonToken';
exports.ANON_USER_TOKEN_PREFIX = 'anon-'; // shows in LD as "anon-<token>"
function useLaunchDarklyIdentity() {
    var _this = this;
    var _a = (0, react_1.useState)(null), identity = _a[0], setIdentity = _a[1];
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var profile = (0, hooks_1.useProfile)().profile;
    var details = (0, hooks_1.usePersonalDetails)().details;
    (0, react_1.useEffect)(function () {
        var getIdentity = function () { return __awaiter(_this, void 0, void 0, function () {
            var authToken, cachedAnonToken, newAnonUserKey, business, user, ldUser;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        authToken = util_1.MBAuthUtil.getAuthToken();
                        return [4 /*yield*/, storage_1.MBStorage.get(exports.ANON_USER_TOKEN_KEY)];
                    case 1:
                        cachedAnonToken = _a.sent();
                        if (!((0, lodash_1.isEmpty)(authToken) && (0, lodash_1.isEmpty)(cachedAnonToken))) return [3 /*break*/, 3];
                        newAnonUserKey = uuid.v4();
                        return [4 /*yield*/, storage_1.MBStorage.set(exports.ANON_USER_TOKEN_KEY, newAnonUserKey)];
                    case 2:
                        _a.sent();
                        setIdentity({
                            key: "".concat(exports.ANON_USER_TOKEN_PREFIX).concat(newAnonUserKey),
                            anonymous: true,
                        });
                        return [2 /*return*/];
                    case 3:
                        if ((0, lodash_1.isEmpty)(authToken) && !(0, lodash_1.isEmpty)(cachedAnonToken)) {
                            setIdentity({
                                key: "".concat(exports.ANON_USER_TOKEN_PREFIX).concat(cachedAnonToken),
                                anonymous: true,
                            });
                            return [2 /*return*/];
                        }
                        if (businessId && profile && details) {
                            business = profile.businesses.find(function (b) { return (b === null || b === void 0 ? void 0 : b.id) === businessId; });
                            user = __assign(__assign({}, profile), details);
                            ldUser = {
                                key: String(user.id),
                                firstName: user.firstName,
                                lastName: user.lastName,
                                custom: {
                                    businessId: business === null || business === void 0 ? void 0 : business.id,
                                    businessName: business === null || business === void 0 ? void 0 : business.name,
                                },
                            };
                            setIdentity(ldUser);
                            return [2 /*return*/];
                        }
                        setIdentity(null);
                        return [2 /*return*/];
                }
            });
        }); };
        getIdentity();
    }, [businessId, profile, details]);
    return identity;
}
