"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardCreateForm = MBCardCreateForm;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var viewTemplate_1 = require("@shared/components/blocks/contexts/viewTemplate");
var correctMissingBlockData_1 = require("@shared/components/blocks/utils/correctMissingBlockData");
var templateNotFound_1 = require("@shared/scenes/cards/components/templateNotFound");
var components_2 = require("@shared/scenes/cards/form/components");
var hooks_1 = require("@shared/scenes/cards/form/hooks");
var hooks_2 = require("@shared/scenes/workflows/hooks");
var hooks_3 = require("@shared/util/hooks");
var navigation_1 = require("@shared/util/navigation");
var BatchContext_1 = require("../../entityBulkActions/BatchCreateContext/BatchContext");
var hooks_4 = require("../../hooks");
var hooks_5 = require("./hooks");
function CardCreateForm(_a) {
    var _this = this;
    var channelSlug = _a.channelSlug, templateSlug = _a.templateSlug, fieldOverrides = _a.fieldOverrides, cardDefinitionId = _a.cardDefinitionId, restoreSavedData = _a.restoreSavedData, onSubmitFormButtonCallback = _a.onSubmitFormButtonCallback;
    var channel = (0, hooks_3.useChannel)({ channelSlug: channelSlug });
    var cardDefinition = (0, hooks_4.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, cardDefinitionId).cardDefinition;
    var workflowTemplate = (0, hooks_2.useWorkflowTemplateWithCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).workflowTemplate;
    var _b = (0, hooks_5.useTemplateWithEditContextFallback)(channelSlug, templateSlug, cardDefinition), template = _b.template, loading = _b.loading;
    var blocks = (0, react_1.useMemo)(function () { return (0, correctMissingBlockData_1.correctMissingBlockData)(template === null || template === void 0 ? void 0 : template.cardBlocks, workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components); }, [template, workflowTemplate]);
    var _c = (0, BatchContext_1.useBatch)(), shouldBatchCreate = _c.shouldBatchCreate, batchCreate = _c.batchCreate;
    var viewTemplate = (0, hooks_2.useViewTemplate)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, template === null || template === void 0 ? void 0 : template.slug).viewTemplate;
    var createCard = (0, hooks_1.useCreateCard)(cardDefinitionId);
    var createCardBatch = (0, hooks_1.useCreateBatchCards)();
    var generateCardsForRecurrence = (0, hooks_1.useGenerateCardsForRecurrence)();
    var navigator = (0, navigation_1.useNavigator)();
    var navigateToChannel = (0, react_1.useCallback)(function () { return navigator.navigate('Channel', { channelSlug: channelSlug }, { preferGoBack: true }); }, [navigator, channelSlug]);
    var submit = (0, react_1.useCallback)(function (fields) { return __awaiter(_this, void 0, void 0, function () {
        var fieldsPerAssignee, cards;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!fields.batchByAssignee) return [3 /*break*/, 2];
                    fieldsPerAssignee = fields.assignees.map(function (assignee) { return (__assign(__assign({}, fields), { assignees: [assignee] })); });
                    // TODO(fant): create batch tag?
                    return [4 /*yield*/, createCardBatch(fieldsPerAssignee.map(function (assigneeFields) { return (__assign(__assign({}, assigneeFields), { cardDefinitionId: cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.id, workflowTemplateId: channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId })); }))];
                case 1:
                    // TODO(fant): create batch tag?
                    _a.sent();
                    return [3 /*break*/, 8];
                case 2:
                    if (!shouldBatchCreate) return [3 /*break*/, 4];
                    return [4 /*yield*/, batchCreate({
                            workflowTemplateId: channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId,
                            fields: fields,
                        })];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 8];
                case 4:
                    if (!(!(0, lodash_1.isNil)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId) && !(0, lodash_1.isNil)(fields.recurrence))) return [3 /*break*/, 6];
                    cards = generateCardsForRecurrence(channel.workflowTemplateId, fields);
                    return [4 /*yield*/, createCardBatch(cards)];
                case 5:
                    _a.sent();
                    return [3 /*break*/, 8];
                case 6: return [4 /*yield*/, createCard(cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, fields)];
                case 7:
                    _a.sent();
                    _a.label = 8;
                case 8:
                    navigateToChannel();
                    return [2 /*return*/];
            }
        });
    }); }, [
        shouldBatchCreate,
        channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId,
        navigateToChannel,
        createCardBatch,
        cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.id,
        batchCreate,
        generateCardsForRecurrence,
        createCard,
    ]);
    var mergedFieldOverrides = (0, react_1.useMemo)(function () { return (0, lodash_1.merge)({}, template === null || template === void 0 ? void 0 : template.fieldOverrides, fieldOverrides); }, [fieldOverrides, template === null || template === void 0 ? void 0 : template.fieldOverrides]);
    if (loading) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true });
    }
    if (!template) {
        return react_1.default.createElement(templateNotFound_1.MBTemplateNotFound, null);
    }
    return (react_1.default.createElement(viewTemplate_1.ViewTemplateProvider, { template: viewTemplate },
        react_1.default.createElement(components_2.MBCardForm, { card: {}, cardDefinitionId: cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.id, blocks: blocks, fieldOverrides: mergedFieldOverrides, onSubmit: submit, onSubmitFormButtonCallback: onSubmitFormButtonCallback, restoreSavedData: restoreSavedData })));
}
function MBCardCreateForm(props) {
    return (react_1.default.createElement(channel_1.MBCardChannelProvider, { channelSlug: props.channelSlug },
        react_1.default.createElement(BatchContext_1.LowCodeBatchCreateConextProviderWrapper, null,
            react_1.default.createElement(CardCreateForm, __assign({}, props)))));
}
