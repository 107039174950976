"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNavigateToTemplate = useNavigateToTemplate;
var react_1 = require("react");
var alert_1 = require("@shared/util/alert");
var launchDarkly_1 = require("@shared/util/featureFlags/launchDarkly");
var navigation_1 = require("@shared/util/navigation");
function useNavigateToTemplate(channelSlug) {
    var navigator = (0, navigation_1.useNavigator)();
    var backButtonHistory = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.BACK_BUTTON_HISTORY);
    return (0, react_1.useCallback)(function (template) {
        if (!template.slug) {
            alert_1.MBAlert.alert({
                message: "Cannot show the template \"".concat(template.title, "\". Please provide a template slug."),
            });
        }
        else {
            if (!backButtonHistory) {
                navigator.replace('Channel', { channelSlug: channelSlug, templateSlug: template.slug });
            }
            navigator.navigate('ChannelCardList', { channelSlug: channelSlug, templateSlug: template.slug });
        }
    }, [channelSlug, navigator, backButtonHistory]);
}
