/* @flow */
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MBLoadingIndicator } from '@shared/components';
import { useChannelListOnboarding } from '@shared/scenes/onboarding/useChannelListOnboarding';
import { useNavigator } from '@shared/util/navigation';

const appLoaderQuery = gql`
  query AppLoaderQuery($businessId: Int) {
    me {
      id
      business(businessId: $businessId) {
        id
        channels {
          id
          slug
          type
        }
        defaultChannel {
          id
          slug
          type
        }
      }
    }
  }
`;

function AppLoaderContainer() {
  const businessId = useSelector(state => state.appState.currentBusinessId);
  const isDesktop = useSelector(state => state.appState.isDesktop);

  const { data } = useQuery(appLoaderQuery, {
    skip: !businessId,
    variables: { businessId },
  });
  const me = !!data ? data.me : undefined;
  const channelListOnboarding = useChannelListOnboarding();

  const { navigate, replace } = useNavigator();
  useEffect(() => {
    if (!businessId || !me || !me.business) {
      return;
    }
    if (isDesktop) {
      const defaultChannel = me.business.defaultChannel;
      const highlightedChannel = !isEmpty(channelListOnboarding.highlightedChannelIds)
        ? me.business.channels.find(c => channelListOnboarding.highlightedChannelIds[0] === c.id)
        : undefined;

      const channel = highlightedChannel || defaultChannel;
      if (!!channel) {
        navigate('Channel', { channelSlug: channel.slug });
      } else {
        navigate('BaseCardsTab');
      }
    } else {
      replace('BaseChannelsTab');
    }
  }, [businessId, me, channelListOnboarding, navigate, replace, isDesktop]);

  return <MBLoadingIndicator fill />;
}

export default AppLoaderContainer;
