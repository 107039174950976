"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardStyles = void 0;
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var WIDGET_SIZE = 168;
exports.DashboardStyles = react_native_1.StyleSheet.create({
    widget: {
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: (0, components_1.grid)(0.5),
        shadowOpacity: 1,
        padding: (0, components_1.grid)(1.5),
        elevation: 3,
        borderRadius: (0, components_1.grid)(1),
        marginHorizontal: (0, components_1.grid)(1),
        marginVertical: (0, components_1.grid)(1),
        flex: 1,
        justifyContent: 'space-between',
        width: WIDGET_SIZE,
        minHeight: WIDGET_SIZE,
    },
    touchable: {
        display: 'flex',
    },
    wrapper: {
        display: 'flex',
        flex: 1,
    },
    favoriteIcon: {
        position: 'absolute',
        top: (0, components_1.grid)(1),
        right: (0, components_1.grid)(1),
        zIndex: 1,
    },
    overflowButton: {
        position: 'absolute',
        top: (0, components_1.grid)(1),
        right: (0, components_1.grid)(1),
        zIndex: 2,
    },
    refreshButton: {
        position: 'absolute',
        top: (0, components_1.grid)(1),
        right: (0, components_1.grid)(1),
        zIndex: 3,
    },
    count: {
        flexGrow: 0,
    },
    countSpinner: {
        alignItems: 'flex-start',
        height: components_1.MBTypographyStylesRaw.h3.lineHeight,
    },
    subtitle: {
        marginVertical: (0, components_1.grid)(1),
    },
    content: {
        marginBottom: (0, components_1.grid)(2),
    },
});
