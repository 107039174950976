"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBlinkingBackground = MBBlinkingBackground;
exports.MBBlinkingOpacity = MBBlinkingOpacity;
var ms = __importStar(require("milliseconds"));
var React = __importStar(require("react"));
var animation_1 = require("@shared/components/animation");
// Math.cos(t)                              => cosine wave (x: 0 => 6.24, y: -1   => 1)
// Math.cos(t * 2 * Math.PI)                => cosine wave (x: 0 => 1,    y: -1   => 1)
// Math.cos(t * 2 * Math.PI) / 2            => cosine wave (x: 0 => 1,    y: -0.5 => 0.5)
// Math.cos(t * 2 * Math.PI) / 2 + 0.5      => cosine wave (x: 0 => 1,    y:  0   => 1)
// Math.cos(t * 2 * Math.PI * 2) / 2 + 0.5  => repeat the loop 2x
// To visualize this, search for this on Google:
// y=cos(x*pi*4)/2+0.5
function blinkEasing(t) {
    var waitRatio = 0.5;
    // wait for X% of the animation
    if (t < waitRatio) {
        return 1;
    }
    // u: the normalized progress between (waitRatio) => 1
    // If waitRatio is 0.5, we first wayt 50% of the animation.
    // At t=0.75, u would be 50% (50% from 0.5 -> 1.0)
    var u = (t - waitRatio) / (1 - waitRatio);
    // then go to the secondary color and back
    return Math.cos(u * 2 * Math.PI * 1.5) / 2 + 0.5;
}
/*
    This component changes background colors between from and to.
    If "from" is not set, it will only show "to"
*/
function MBBlinkingBackground(_a) {
    var from = _a.from, to = _a.to, style = _a.style, children = _a.children;
    return (React.createElement(animation_1.MBAnimation, { disabled: !from, duration: ms.seconds(3), easing: blinkEasing, backgroundColor: !!from ? { from: from, to: to } : { to: to, from: to }, style: [style, { backgroundColor: to }], children: children }));
}
function MBBlinkingOpacity(_a) {
    var disabled = _a.disabled, style = _a.style, children = _a.children;
    return (React.createElement(animation_1.MBAnimation, { disabled: disabled, opacity: { from: 1, to: 0.3 }, duration: ms.seconds(3), easing: blinkEasing, style: style, children: children }));
}
