"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardCollectionDesktopNavControls = MBCardCollectionDesktopNavControls;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var cardSelection_1 = require("@shared/api/providers/cardSelection");
var components_1 = require("@shared/components");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var styles_1 = require("@shared/components/button/styles");
var actions_1 = require("@shared/scenes/cards/actions");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var viewMenu_1 = require("@shared/scenes/workflows/views/components/viewMenu");
var styles_2 = require("@shared/styles");
var hooks_2 = require("@shared/util/hooks");
var ChannelPermissionsContext_1 = require("@shared/util/permissions/ChannelPermissionsContext");
var hooks_3 = require("@shared/util/permissions/hooks");
var desktop_1 = require("../../createButton/components/desktop");
var LayoutSelector_1 = require("./LayoutSelector");
var isLowCodeViewTemplate_1 = require("./isLowCodeViewTemplate");
var selectionHeader_1 = require("./selectionHeader");
var templateSwitcher_1 = require("./templateSwitcher");
function MBCardCollectionDesktopNavControls(_a) {
    var channelSlug = _a.channelSlug, templates = _a.templates, selectedTemplate = _a.selectedTemplate, onSelectTemplate = _a.onSelectTemplate, viewLoading = _a.viewLoading;
    var colors = (0, react_1.useContext)(styles_2.ColorThemeContext);
    var isSelectionActive = (0, cardSelection_1.useCardSelectionState)().active;
    var channel = (0, hooks_2.useChannel)({ channelSlug: channelSlug });
    var channelPermissions = (0, react_1.useContext)(ChannelPermissionsContext_1.ChannelPermissionsContext);
    var channelAbility = channelPermissions.channelAbility;
    var viewTemplate = (0, hooks_1.useViewTemplate)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.slug).viewTemplate;
    var isLowCodeView = (0, isLowCodeViewTemplate_1.isLowCodeViewTemplate)({ viewTemplate: viewTemplate });
    var showSelectionControls = isSelectionActive && isLowCodeView;
    var mappedTemplates = templates === null || templates === void 0 ? void 0 : templates.map(function (t) {
        return { slug: t.slug, title: t.title, context: t.context };
    });
    return (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingLeft: 2, paddingRight: 4, nativeID: "desktopNavControls" },
        showSelectionControls && (react_1.default.createElement(channel_1.MBCardChannelProvider, { channelSlug: channelSlug },
            react_1.default.createElement(selectionHeader_1.MBCardSelectionHeader, null))),
        !showSelectionControls && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(LayoutSelector_1.LayoutSelector, { testID: "header-layout-selector-button" }),
            viewLoading && (react_1.default.createElement(react_native_1.View, { style: { paddingHorizontal: (0, components_1.grid)(2) } },
                react_1.default.createElement(components_1.MBLoadingIndicator, null))),
            !viewLoading && (react_1.default.createElement(viewMenu_1.MBWorkflowViewMenu, { channelSlug: channelSlug, selectedTemplateSlug: selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.slug, onSelectTemplate: onSelectTemplate, testID: "header-view-template-menu", templates: mappedTemplates, renderTrigger: function (showingSheet) { return (react_1.default.createElement(components_1.MBView, { nativeID: "cardCollectionTemplateSwitcher", row: true, paddingHorizontal: 2, style: [
                        styles_1.Styles.buttonSizeNormal,
                        { backgroundColor: colors.backgroundInactive, marginHorizontal: (0, components_1.grid)(2) },
                    ], testID: "header-view-template-button" },
                    react_1.default.createElement(templateSwitcher_1.MBCardCollectionTemplateSwitcher, { isDesktop: true, selectedTemplate: selectedTemplate, arrowDirection: showingSheet ? 'up' : 'down' }))); } })),
            isLowCodeView && (react_1.default.createElement(channel_1.MBCardChannelProvider, { channelSlug: channelSlug },
                react_1.default.createElement(actions_1.MBCardActions, null))),
            react_1.default.createElement(hooks_3.Can, { I: "create", a: "Card", ability: channelAbility },
                react_1.default.createElement(desktop_1.MBCreateCardButtonDesktop, { channelSlug: channelSlug }))))));
}
