"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsChannelDetailsDirty = useIsChannelDetailsDirty;
var react_1 = require("react");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var hooks_2 = require("@shared/util/hooks");
var emoji_1 = require("../../detailsForm/utils/emoji");
var useChannelDetails_1 = require("./useChannelDetails");
function useIsChannelDetailsDirty(_a) {
    var _b, _c;
    var channelSlug = _a.channelSlug;
    var channel = (0, hooks_2.useChannel)({ channelSlug: channelSlug });
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId);
    var form = (0, useChannelDetails_1.useChannelDetails)();
    var channelName = channel === null || channel === void 0 ? void 0 : channel.name;
    var currentName = (0, react_1.useMemo)(function () { return (channelName ? (0, emoji_1.getNameWithoutEmoji)(channelName) : ''); }, [channelName]);
    var currentEmoji = (0, react_1.useMemo)(function () { return (channelName ? (0, emoji_1.getEmojiFromString)(channelName) : ''); }, [channelName]);
    var isNameDirty = form.name !== currentName;
    var isEmojiDirty = form.emoji !== currentEmoji;
    var isDescriptionDirty = form.description !== ((_b = channel === null || channel === void 0 ? void 0 : channel.description) !== null && _b !== void 0 ? _b : '');
    var isImageUrlDirty = form.imageUrl !== (channel === null || channel === void 0 ? void 0 : channel.imageUrl);
    var isWorkflowTemplateNameDirty = form.workflowTemplateName !== ((_c = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.workflowTemplate) === null || _c === void 0 ? void 0 : _c.name);
    return (isNameDirty ||
        isDescriptionDirty ||
        isImageUrlDirty ||
        isEmojiDirty ||
        form.isImageUploading ||
        isWorkflowTemplateNameDirty);
}
