"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChannelDetails = useChannelDetails;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var api_1 = require("@shared/api");
function useChannelDetails(_a) {
    var _b;
    var channelSlug = _a.channelSlug;
    var data = (0, client_1.useQuery)(api_1.Queries.channel.details, {
        variables: { slug: channelSlug !== null && channelSlug !== void 0 ? channelSlug : '' },
        skip: (0, lodash_1.isNil)(channelSlug),
    }).data;
    return (_b = data === null || data === void 0 ? void 0 : data.channel) !== null && _b !== void 0 ? _b : undefined;
}
