"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowTextComponent = WorkflowTextComponent;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var WorkflowComponentWrapper_1 = require("./WorkflowComponentWrapper");
function WorkflowTextComponent(_a) {
    var initialData = _a.initialData, component = _a.component, onDataChanged = _a.onDataChanged, isReadOnly = _a.isReadOnly, showLabel = _a.showLabel, paddingHorizontal = _a.paddingHorizontal, iconPad = _a.iconPad, showIcon = _a.showIcon;
    var textComponent = component;
    var _b = (0, react_1.useState)(formatData(initialData)), value = _b[0], setValue = _b[1];
    var readOnly = isReadOnly || textComponent.readonly;
    var onChange = (0, react_1.useCallback)(function (data) {
        setValue(data);
        onDataChanged(textComponent.id, data);
    }, [onDataChanged, textComponent]);
    if (!showLabel && !showIcon) {
        return (react_1.default.createElement(WorkflowTextContent, { readOnly: readOnly, value: value, initialData: initialData, onChange: onChange, textComponent: textComponent }));
    }
    return (react_1.default.createElement(WorkflowComponentWrapper_1.WorkflowComponentWrapper, { paddingHorizontal: paddingHorizontal, iconPad: iconPad, component: textComponent, showLabel: showLabel, showIcon: showIcon },
        react_1.default.createElement(WorkflowTextContent, { readOnly: readOnly, value: value, initialData: initialData, onChange: onChange, textComponent: textComponent })));
}
function WorkflowTextContent(_a) {
    var readOnly = _a.readOnly, value = _a.value, initialData = _a.initialData, onChange = _a.onChange, textComponent = _a.textComponent;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        !readOnly && (react_1.default.createElement(components_1.MBView, null,
            react_1.default.createElement(components_1.MBTextInput, { noBorder: true, noPadding: true, value: formatData(value), onChangeText: onChange, placeholder: textComponent.placeholder }))),
        readOnly && (react_1.default.createElement(components_1.MBView, { fullWidth: true },
            react_1.default.createElement(components_1.MBTextBody1, { color: isEmptyData(initialData) ? styles_1.Colors.v2.gray50 : styles_1.Colors.v2.purple }, formatData(initialData) || textComponent.label)))));
}
function formatData(data) {
    if (Array.isArray(data)) {
        var displayableData = data.filter(isDisplayableDatum);
        return displayableData.length > 0 ? displayableData.join(', ') : null;
    }
    return isDisplayableDatum(data) ? data : null;
}
function isDisplayableDatum(datum) {
    return !(0, lodash_1.isNil)(datum) && datum.length > 0;
}
function isEmptyData(data) {
    if (Array.isArray(data)) {
        return !data.some(isDisplayableDatum);
    }
    return !isDisplayableDatum(data);
}
