"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProfile = useProfile;
exports.usePersonalDetails = usePersonalDetails;
exports.useBusinesses = useBusinesses;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
var notNil_1 = require("../notNil");
function useProfile() {
    var _a;
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var _b = (0, client_1.useQuery)(api_1.Queries.user.profile, {
        variables: { businessId: businessId !== null && businessId !== void 0 ? businessId : -1 },
        skip: (0, lodash_1.isNil)(businessId),
    }), data = _b.data, refetch = _b.refetch, loading = _b.loading;
    return {
        profile: (_a = data === null || data === void 0 ? void 0 : data.me) !== null && _a !== void 0 ? _a : undefined,
        refetch: refetch,
        loading: loading,
    };
}
function usePersonalDetails() {
    var _a;
    var _b = (0, client_1.useQuery)(api_1.Queries.user.myPersonalDetails), data = _b.data, refetch = _b.refetch, loading = _b.loading;
    return {
        details: (_a = data === null || data === void 0 ? void 0 : data.me) !== null && _a !== void 0 ? _a : undefined,
        refetch: refetch,
        loading: loading,
    };
}
function useBusinesses() {
    var profile = useProfile().profile;
    return (0, react_1.useMemo)(function () { var _a; return (_a = profile === null || profile === void 0 ? void 0 : profile.businesses.filter(notNil_1.notNil)) !== null && _a !== void 0 ? _a : undefined; }, [profile]);
}
