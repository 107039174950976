"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatThread = MBChatThread;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_dom_1 = require("react-dom");
var components_1 = require("@shared/components");
var index_web_1 = require("@shared/components/scrollview/index.web");
var barWithDetailsModal_1 = require("@shared/scenes/chat/notifyPendingUsers/containers/barWithDetailsModal");
var hooks_1 = require("@shared/util/hooks");
var message_1 = require("../../containers/message");
var bar_1 = require("../message/isTyping/bar");
var styles_1 = require("./styles");
function mergeRefs() {
    var refs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        refs[_i] = arguments[_i];
    }
    var filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) {
        return null;
    }
    return function (instance) {
        filteredRefs.forEach(function (ref) {
            if (typeof ref === 'function') {
                ref(instance);
            }
            else {
                ref.current = instance;
            }
        });
    };
}
/*
    This component makes the chat aligned at the bottom. On load, it scrolls to the bottom
    of the chat's scrollview. When content is updated (older messages loaded or new message
    received), it offsets the content to maintain its distance to the bottom (as opposed to
    the distance from the top, which is default behaviour).

    Why don't we just use flex-direction: column-reverse? I'm glad you asked :) When doing
    this, Firefox behaves weirdly. Potentially related to this:
    https://bugzilla.mozilla.org/show_bug.cgi?id=1135332

    Assumptions: content does not change size without the scrollview's props being updated.
    If that is the case (eg link unfurl expanded), then the scroll offset won't appear as
    if it is stuck to the bottom

    TODO(fant): this whole file should probably be replaced with recyclerlistview
*/
var MBBottomScrollView = /** @class */ (function (_super) {
    __extends(MBBottomScrollView, _super);
    function MBBottomScrollView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MBBottomScrollView.prototype.componentDidMount = function () {
        // "as any" because tsc uses scrollview.native types
        var sv = this.scrollview;
        // Why do we use offsetHeight here instead of scrollHeight and scrollTop?
        // Because on Firefox, this works consistently. scrollHeight+scrollTop
        // randomly fail.
        var distanceFromBottom = sv.children[0].offsetHeight - sv.offsetHeight;
        sv.scrollTop = distanceFromBottom;
        // Make sure eg onStartReached is called properly (all
        // things that happen when MBScrollView.onScroll is called)
        _super.prototype.componentDidMount.call(this);
    };
    // TODO(fant): replace with getDerivedStateFromProps
    MBBottomScrollView.prototype.UNSAFE_componentWillUpdate = function () {
        // "as any" because tsc uses scrollview.native types
        var sv = this.scrollview;
        if (!sv) {
            return;
        }
        this.distanceFromBottomBeforeUpdate = sv.scrollHeight - sv.scrollTop;
    };
    MBBottomScrollView.prototype.componentDidUpdate = function () {
        // "as any" because tsc uses scrollview.native types
        var sv = this.scrollview;
        var distanceFromBottomAfterUpdate = sv.scrollHeight - sv.scrollTop;
        // Make sure that the distance to the bottom of the scrollview does
        // not change when it is updated. By doing this, the scrollview won't
        sv.scrollTop += distanceFromBottomAfterUpdate - this.distanceFromBottomBeforeUpdate;
        // Make sure to call onScroll so that the state (isTopScrollable, isBottomScrollable)
        // is properly set. The regular onScroll callback won't be called when children change.
        _super.prototype.onScroll.call(this);
    };
    return MBBottomScrollView;
}(index_web_1.MBScrollView));
/*
    Note: the web chat thread looks very similar to the unperformant
    version for Storybook. We have not optimized this using MBFlatList
    or similar earlier. So for now we make this naive approach where
    we always render every message.

    TODO(fant): this needs to be done properly...
    1. Use flatlist (or recyclerlistview) to not re-render messages all the time
    2. Figure out how to use Apollo (web specific) so that object
       references don't update on every render. Right now
       "nextProps.message !== this.props.message" even though
       nothing has changed. This causes a rerender of every message
    3. Cannot use messages.reverse() on web because Apollo doesn't allow it
*/
function MBChatThread(_a) {
    var messages = _a.messages, cachedMessages = _a.cachedMessages, typingUsers = _a.typingUsers, hasFetchedAllMessages = _a.hasFetchedAllMessages, headerView = _a.headerView, channelSlug = _a.channelSlug, isLoadingMore = _a.isLoadingMore, canLoadMore = _a.canLoadMore, isDM = _a.isDM, onLoadMore = _a.onLoadMore, scrollRef = _a.scrollRef, selectedMessageId = _a.selectedMessageId;
    var _b = (0, hooks_1.useBreakpointOnWidthExceeds)(375, true), breakpoint = _b.breakpoint, handleOnSize = _b.handleOnSize;
    var ref = (0, react_1.useRef)();
    var mergedRef = (0, react_1.useMemo)(function () { return mergeRefs(scrollRef, ref); }, [scrollRef, ref]);
    (0, react_1.useEffect)(function () {
        if (!!ref.current && canLoadMore && !isLoadingMore) {
            var node = (0, react_dom_1.findDOMNode)(ref.current.scrollview);
            var scrollable = node.scrollHeight > node.clientHeight;
            if (!scrollable) {
                onLoadMore();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages.length, isLoadingMore, canLoadMore]);
    return (react_1.default.createElement(components_1.MBView, { fill: true, onSize: handleOnSize },
        react_1.default.createElement(MBBottomScrollView, { ref: mergedRef, onStartReached: onLoadMore, webContentStyle: styles_1.Styles.webScrollviewContentStyle },
            hasFetchedAllMessages && headerView,
            react_1.default.createElement(components_1.MBView, { style: { flexDirection: 'column-reverse' }, paddingHorizontal: breakpoint ? 5 : 1 },
                cachedMessages.map(function (message) { return (react_1.default.createElement(message_1.MBMessage, { key: message.meta.uuid || message.id || message.createdAt, isDM: isDM, message: message, channelSlug: channelSlug, isSelected: message.id === selectedMessageId })); }),
                messages.map(function (message) { return (react_1.default.createElement(message_1.MBMessage, { key: message.meta.uuid || message.id || message.createdAt, isDM: isDM, message: message, channelSlug: channelSlug, isSelected: message.id === selectedMessageId })); })),
            react_1.default.createElement(components_1.MBView, { paddingTop: 2 })),
        react_1.default.createElement(components_1.MBView, { style: styles_1.Styles.isTypingBarBottomPosition },
            react_1.default.createElement(bar_1.MBTypingUsersBar, { users: typingUsers })),
        react_1.default.createElement(barWithDetailsModal_1.MBNotifyBarWithDetailsModal, { channelSlug: channelSlug })));
}
