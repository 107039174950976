/* @flow */
import moment from 'moment';
import React from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { expireSnoozedUser } from '@common/core/snoozeUtils';
import { compose, gql, graphql, withApollo } from '@common/graphql';
import { MBView } from '@shared/components';
import { MBBottomSheetProvider } from '@shared/components/bottomSheet/provider';
import { MBUpgradeModalProvider } from '@shared/scenes/billing/upgradeModal/provider';
import { CallBanner } from '@shared/scenes/calling/banner';
import { MBChannelChat } from '@shared/scenes/channel/chat/components/index';
import {
  ChannelListLayoutContext,
  ChannelListLayoutProvider,
} from '@shared/scenes/channel/webLayout/ChannelListLayoutContext';
import { WebLayoutProvider } from '@shared/scenes/channel/webLayout/context';
import { MBConnectionBanner } from '@shared/scenes/connection/containers/banner';
import { ColorThemeContext, ColorThemeProvider, SharedStyles } from '@shared/styles';
import { ReactRouterContext } from '@shared/util/navigation';
import Loading from '@src/components/Loading';
import Overlay from '@src/components/Overlay';
import PureComponent from '@src/components/PureComponent';
import ToastrContainer from '@src/containers/ToastrContainer';
import * as Channel from '@src/containers/channel';
import { setUserPushToken } from '@src/core/AuthTokenManager';
import { tryRegisterPushToken } from '@src/core/PushNotificationManager';
import { actions, utils } from '@src/core/Router';
import addMessageToastr from '@src/redux/actions/addMessageToastr';
import type { RouteType } from '@src/types/RouterTypes';
import type { StoreState } from '@src/types/StoreTypes';
import styles from './LayoutContainer.css';

type Props = {
  children: React.Element<*>,
  isDesktop: boolean,
  isMobile: boolean,
  viewportHeight: number,
  routes: Array<RouteType>,
  params: any,
  query: any,
  isHidden: boolean,
  data: {
    me?: {
      id: number,
      eventInteractions: {
        tooltipShowAddPeople: boolean,
        tooltipShowSwitchBusiness: boolean,
      },
      snoozeUntil: ?string,
    },
    refetch: Function,
  },
  registerToken: Function,
  addUserEventInteraction: Function,
  snoozeUser: (until: ?Date) => any,
  client: Object,
};

type State = {
  tooltips: {
    addPeople: boolean,
    switchBusiness: boolean,
  },
  businessExpanded: boolean,
  isSubmitting: boolean,
  cacheData: boolean,
};

type FullScreenModalProps = {
  children?: React.Element<any>,
  isShowing?: boolean,
};

function FullScreenModal({ children, isShowing }: FullScreenModalProps) {
  return (
    <div className={isShowing ? styles.fullScreenModalShow : styles.fullScreenModal}>
      {children}
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getTooltips(props: Props) {
  return {
    addPeople: false,
    switchBusiness: false,
  };
}

class LayoutContainer extends PureComponent {
  props: Props;
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      isSubmitting: false,
      tooltips: getTooltips(props),
      businessExpanded: false,
      cacheData: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (!window.navigator.onLine) {
      setTimeout(() => this.setState({ cacheData: true }), 3);
    } else {
      this.setState({ cacheData: true });
    }
  }

  componentDidMount() {
    tryRegisterPushToken()
      .then(t => {
        if (t) {
          setUserPushToken(t);
          return this.props.registerToken(t);
        }
        return null;
      })
      .catch(error =>
        console.log(`Registering to receive push notifications did not succeed: ${error}`),
      );
  }

  // TODO(fant): use new react lifecycle method getDerivedStateFromProps
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.data.me !== this.props.data.me) {
      this.setState({ tooltips: getTooltips(nextProps) });
    }

    if (this.props.isHidden && !nextProps.isHidden && nextProps.data.me) {
      expireSnoozedUser(this.props.client, nextProps.data.me);
    }
  }

  handleClosePanel = () => {
    if (this.state.tooltips.addPeople || this.state.tooltips.switchBusiness) {
      this.handleCloseTooltip();
      return;
    }

    const { taskId } = this.props.params;
    const { params, routes } = this.props;
    const basePrefix = utils.createURLFromParams(params, routes);

    if (this.showTaskEdit()) {
      actions.pushRoute(`${basePrefix}tasks/${taskId || ''}`);
      return;
    }
    if (this.showTaskCreate() || this.showTaskDetails()) {
      actions.pushRoute(`${basePrefix}tasks`);
      return;
    }

    actions.pushRoute(basePrefix);
  };

  handleCloseTooltip = (ev: ?BrowserEvent) => {
    ev && typeof ev.preventDefault === 'function' && ev.preventDefault();

    if (this.state.tooltips.addPeople) {
      this.props.addUserEventInteraction('TOOLTIP_SHOW_ADD_PEOPLE');
    } else if (this.state.tooltips.switchBusiness) {
      this.props.addUserEventInteraction('TOOLTIP_SHOW_SWITCH_BUSINESS');
    }
  };

  handleSignOut = () => actions.pushRoute('/logout');

  handleSnoozeChange = until => {
    this.props
      .snoozeUser(until)
      .catch(() =>
        addMessageToastr(
          `Failed to turn ${until ? 'off' : 'on'} notifications, please try again.`,
          'error',
        ),
      );
  };

  render() {
    if (!this.state.cacheData) {
      return null;
    }

    if (this.props.isMobile) {
      const showChannels = this.showChannels();
      const noScrollClass = showChannels ? 'layoutContainerNoScroll' : '';
      return (
        <MBBottomSheetProvider>
          <MBUpgradeModalProvider>
            <ReactRouterContext.Provider
              value={{
                router: this.props.router,
                routes: this.props.routes,
                params: this.props.params,
              }}
            >
              <div className={styles.root}>
                <MBConnectionBanner />
                <div className={`${styles.mobileContainer} ${noScrollClass}`}>
                  {this.props.children}
                  <FullScreenModal isShowing={showChannels}>
                    <Channel.List params={this.props.params} />
                    {(this.state.tooltips.addPeople || this.state.tooltips.switchBusiness) && (
                      <Overlay onClose={this.handleCloseTooltip} />
                    )}
                  </FullScreenModal>
                </div>
                <ToastrContainer />
              </div>
            </ReactRouterContext.Provider>
          </MBUpgradeModalProvider>
        </MBBottomSheetProvider>
      );
    }

    const messages = this.renderSidebarMessageList();

    const isOnAllCardsRoute = this.props.routes.some(r => r.path === 'all-cards');
    return (
      <MBBottomSheetProvider>
        <MBUpgradeModalProvider>
          <ReactRouterContext.Provider
            value={{
              router: this.props.router,
              routes: this.props.routes,
              params: this.props.params,
            }}
          >
            <ChannelListLayoutProvider>
              <WebLayoutProvider
                channelSlug={isOnAllCardsRoute ? undefined : this.props.params.channelSlug}
              >
                <ChannelListLayoutContext.Consumer>
                  {({ state, animationTime, navbarWidth, isHover, onMouseEnter, onMouseLeave }) => (
                    <div className={styles.root} data-testid="layout-container">
                      <MBConnectionBanner />
                      <CallBanner />
                      <MBView fill row>
                        <ColorThemeProvider>
                          <ColorThemeContext.Consumer>
                            {colors => (
                              <View
                                onMouseEnter={onMouseEnter}
                                style={[
                                  {
                                    alignSelf: 'stretch',
                                    width:
                                      state === 'collapsed' || state === 'animating-in'
                                        ? 70
                                        : navbarWidth,
                                    marginRight: isHover ? 70 - navbarWidth : 0,
                                    transition: `${animationTime}s width, ${animationTime}s margin`,
                                    overflow: 'auto',
                                    backgroundColor: colors.background,
                                    zIndex: 1,
                                  },
                                  isHover ? SharedStyles.shadow : {},
                                ]}
                              >
                                <View
                                  testID="electron-drag-region-shift"
                                  style={{ display: 'none' }}
                                />
                                <Channel.List params={this.props.params} />
                              </View>
                            )}
                          </ColorThemeContext.Consumer>
                        </ColorThemeProvider>
                        <View style={{ flex: 1 }} onMouseEnter={onMouseLeave}>
                          {this.props.children}
                        </View>
                        {!this.hideSidebar() && (
                          <MBView flex style={{ maxWidth: 320 }}>
                            {messages}
                          </MBView>
                        )}
                      </MBView>
                      {(this.state.tooltips.addPeople || this.state.tooltips.switchBusiness) && (
                        <Overlay onClose={this.handleCloseTooltip} />
                      )}
                    </div>
                  )}
                </ChannelListLayoutContext.Consumer>
              </WebLayoutProvider>
            </ChannelListLayoutProvider>
          </ReactRouterContext.Provider>
        </MBUpgradeModalProvider>
      </MBBottomSheetProvider>
    );
  }

  renderSidebarMessageList() {
    if (!this.canShowSidebarMessageList()) {
      return null;
    }
    if (this.props.params.channelSlug) {
      return (
        <MBChannelChat
          channelSlug={this.props.params.channelSlug}
          isFocused={true}
          selectedMessage={(() => {
            const id = this.props.params.selectedMessageId;
            const createdAt = this.props.params.selectedMessageCreatedAt;

            if (!id || !createdAt) {
              return null;
            }
            return {
              id: Number(id),
              // TODO(fant): the reason we hackily subtract 1 second here is because we
              // need to include the current message in what we fetch from the backend.
              // Right now the query is "message.createdAt < since", but we want "<="
              createdAt: moment(Number(createdAt)).subtract(1, 'second').toISOString(),
            };
          })()}
        />
      );
    }
    return <Loading />;
  }

  taskAdded(value: boolean) {
    this.setState({ isSubmitting: value });
  }

  canShowSidebarMessageList() {
    return this.props.routes.some(r => r.enableSidebarMessageList);
  }
  canShowCommentThread() {
    return this.props.routes.some(r => r.enableCommentThread);
  }
  showChannels() {
    return this.props.routes.some(r => r.showChannels);
  }
  hideSidebar() {
    return this.props.routes.some(r => r.hideSidebar);
  }
}

const addUserEventInteractionMutation = gql`
  mutation AddUserEventInteractionMutation($input: AddUserEventInteractionInput!) {
    addUserEventInteraction(input: $input) {
      user {
        id
        eventInteractions {
          tooltipShowAddPeople
          tooltipShowSwitchBusiness
        }
      }
    }
  }
`;

const snoozeUserMutation = gql`
  mutation SnoozeUserMutation($input: SnoozeUserInput!) {
    snoozeUser(input: $input) {
      user {
        id
        snoozeUntil
      }
    }
  }
`;

const addTokenMutation = gql`
  mutation AddUserPushTokenMutation($input: UserPushTokenInput!) {
    addUserPushToken(input: $input) {
      success
    }
  }
`;

const withAddTokenMutation = graphql(addTokenMutation, {
  props: ({ mutate }) => ({
    registerToken: token =>
      mutate({
        variables: {
          input: {
            source: 'WEB',
            token: JSON.stringify(token),
          },
        },
      }),
  }),
});

const withAddUserEventInteractionMutation = graphql(addUserEventInteractionMutation, {
  props: ({ mutate }) => ({
    addUserEventInteraction: name => mutate({ variables: { input: { name } } }),
  }),
});

const withSnoozeUserMutation = graphql(snoozeUserMutation, {
  props: ({ mutate }) => ({
    snoozeUser: until =>
      mutate({
        variables: { input: { until } },
      }),
  }),
});

const LayoutContainerQuery = gql`
  query LayoutContainerQuery {
    me {
      id
      eventInteractions {
        tooltipShowAddPeople
        tooltipShowSwitchBusiness
      }
      snoozeUntil
    }
  }
`;

const withData = graphql(LayoutContainerQuery, {
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
});

const withGraphQL = compose(
  withApollo,
  withData,
  withAddTokenMutation,
  withAddUserEventInteractionMutation,
  withSnoozeUserMutation,
);

const GraphqlLayoutContainer = withGraphQL(LayoutContainer);

export default connect((state: StoreState, props: Object) => ({
  isDesktop: state.appState.viewportSizeDesktop,
  isMobile: state.appState.viewportSizeMobile,
  isHidden: state.appState.isHidden,
  viewportHeight: state.appState.viewportHeight,
  routes: props.routes,
  params: props.params,
  query: props.location.query,
}))(GraphqlLayoutContainer);
