"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBGeneralChatBanner = MBGeneralChatBanner;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var navigation_1 = require("@shared/util/navigation");
var notNil_1 = require("@shared/util/notNil");
var base_1 = require("./base");
function MBGeneralChatBanner(_a) {
    var channel = _a.channel;
    var openDirectory = (0, navigation_1.useNavigateToRoute)('Directory');
    var users = (0, react_1.useMemo)(function () { var _a; return (_a = channel.users.filter(notNil_1.notNil)) !== null && _a !== void 0 ? _a : []; }, [channel.users]);
    return (react_1.default.createElement(base_1.MBBaseChatBanner, { channel: channel, subtitle: users.map(function (u) { return u.fullName; }).join(', '), content: react_1.default.createElement(components_1.MBText, { color: styles_1.Colors.v2.greenSecondary90 },
            'Everyone added to ',
            react_1.default.createElement(components_1.MBText, { bold: true, color: styles_1.Colors.v2.greenSecondary90 }, channel.business.name),
            ' will automatically see messages in this group. ',
            react_1.default.createElement(components_1.MBText, { bold: true, onPress: openDirectory }, "Invite your team!"),
            '\n\n',
            'You can send team wide announcements and assign tasks ',
            'the whole team will be able to see.') }));
}
