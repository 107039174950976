"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWorkflowChannel = useWorkflowChannel;
var useChannelsLite_1 = require("./useChannelsLite");
/**
 * A hook that returns the first matching channel for a given workflow template id.
 * @param workflowTemplateId - The id of the workflow template.
 * @returns The channel for the given workflow template id, or undefined.
 */
function useWorkflowChannel(workflowTemplateId) {
    var channels = (0, useChannelsLite_1.useChannelsLite)().channels;
    return channels.find(function (channel) { return channel.workflowTemplateId === workflowTemplateId; });
}
