"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBPlusButton = void 0;
var react_redux_1 = require("react-redux");
var plusButton_1 = require("../components/plusButton");
var Actions = __importStar(require("../store/actions"));
function mapStateToProps(state, ownProps) {
    return {
        urgent: !!state.chatInput.urgentFromDraftId[ownProps.draftId],
    };
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
        onShowGIFModal: function () { return Actions.showGIFModal(ownProps.draftId)(dispatch); },
        onSelectAttachment: function (type) {
            return Actions.selectAttachment(type, ownProps.draftId, ownProps.validateUploads)(dispatch);
        },
        onSendAsUrgent: function () { return Actions.enableUrgent(ownProps.draftId)(dispatch); },
        onRemoveSendAsUrgent: function () { return Actions.disableUrgent(ownProps.draftId)(dispatch); },
    };
}
exports.MBPlusButton = (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps)(plusButton_1.MBPlusButton);
