"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getComponentList = getComponentList;
var utils_1 = require("@shared/data/cards/utils");
function getComponentList(workflowTemplate, viewTemplate) {
    return workflowTemplate.components.filter(function (component) { return isVisible(component, viewTemplate); });
}
function isVisible(component, viewTemplate) {
    var _a;
    var viewOptions = (_a = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (options) { return options.componentId === component.id; });
    if (!viewOptions) {
        return false;
    }
    return !(0, utils_1.isComponentHidden)(viewTemplate, viewOptions, component);
}
