"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateEmails = validateEmails;
exports.LookupEmailComponent = LookupEmailComponent;
exports.WorkflowEmailComponentLowCode = WorkflowEmailComponentLowCode;
exports.WorkflowEmailComponent = WorkflowEmailComponent;
// @ts-strict-ignore
var EmailValidator = __importStar(require("email-validator"));
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var form_1 = require("@shared/components/blocks/contexts/form");
var label_1 = require("@shared/components/blocks/utils/label");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var hooks_1 = require("../../hooks");
var WorkflowComponentWrapper_1 = require("./WorkflowComponentWrapper");
var WorkflowTextComponent_1 = require("./WorkflowTextComponent");
function validateEmails(input) {
    var valid = true;
    input === null || input === void 0 ? void 0 : input.forEach(function (email) {
        if (!EmailValidator.validate(email)) {
            valid = false;
        }
    });
    return valid;
}
// TODO make this repeatable
function getInputWidth() {
    return react_native_1.Dimensions.get('window').width > 400 ? 335 : 275;
}
function LookupEmailComponent(_a) {
    var initialData = _a.initialData, props = __rest(_a, ["initialData"]);
    return (react_1.default.createElement(WorkflowTextComponent_1.WorkflowTextComponent, __assign({}, props, { initialData: (0, lodash_1.isNil)(initialData) ? undefined : (0, lodash_1.flatten)(initialData), component: props.component, showIcon: props.showIcon })));
}
function WorkflowEmailComponentLowCode(props) {
    var _a = (0, react_1.useContext)(form_1.CardFormContext), setFieldError = _a.setFieldError, errors = _a.errors;
    return react_1.default.createElement(WorkflowEmailComponent, __assign({}, props, { setFieldError: setFieldError, errors: errors }));
}
function WorkflowEmailComponent(_a) {
    var initialData = _a.initialData, component = _a.component, onDataChanged = _a.onDataChanged, isReadOnly = _a.isReadOnly, showLabel = _a.showLabel, paddingHorizontal = _a.paddingHorizontal, iconPad = _a.iconPad, setFieldError = _a.setFieldError, errors = _a.errors;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var emailComponent = component;
    var error = errors[component.id];
    var _b = (0, react_1.useState)((initialData === null || initialData === void 0 ? void 0 : initialData.length) ? initialData.join(', ') : ''), inputValue = _b[0], setInputValue = _b[1];
    var _c = (0, react_1.useState)(true), isValid = _c[0], setIsValid = _c[1];
    var _d = (0, react_1.useState)(false), checkedInitialValidity = _d[0], setCheckedInitialValidity = _d[1];
    var readOnly = isReadOnly || emailComponent.readonly;
    (0, react_1.useEffect)(function () {
        if (checkedInitialValidity || !initialData) {
            return;
        }
        setIsValid(validateEmails(initialData));
        setCheckedInitialValidity(true);
    }, [checkedInitialValidity, initialData]);
    var onChange = (0, react_1.useCallback)(function (input) {
        var newValue = input ? input.replace(/ /g, '').split(',').filter(Boolean) : null;
        setInputValue(input);
        setIsValid(validateEmails(newValue));
        onDataChanged === null || onDataChanged === void 0 ? void 0 : onDataChanged(emailComponent.id, newValue);
    }, [onDataChanged, emailComponent]);
    var onFocus = function () {
        setIsValid(true);
        setFieldError(emailComponent.id, undefined);
    };
    var setValidityMessage = (0, react_1.useCallback)(function () {
        if (!isValid) {
            setFieldError(emailComponent.id, (0, i18n_1.i18n)('workflowTemplate.component.error.email.invalid'));
        }
    }, [isValid, setFieldError, emailComponent]);
    var onBlur = setValidityMessage;
    if (!(0, hooks_1.isInputComponent)(emailComponent)) {
        return null;
    }
    if (readOnly && !(initialData === null || initialData === void 0 ? void 0 : initialData.length)) {
        return null;
    }
    if (readOnly && (initialData === null || initialData === void 0 ? void 0 : initialData.length)) {
        return (react_1.default.createElement(components_1.MBView, { centerVertically: true, row: true, paddingHorizontal: paddingHorizontal, paddingVertical: 1 },
            !(0, lodash_1.isNil)(emailComponent.icon) && (react_1.default.createElement(components_1.MBView, { paddingRight: iconPad, style: [{ flexShrink: 0, flexGrow: 0 }] },
                react_1.default.createElement(components_1.MBIconV2, { color: (function () {
                        if (!(0, lodash_1.isNil)(error)) {
                            return colors.error.foreground;
                        }
                        return colors.accent;
                    })(), name: emailComponent.icon, size: 30 }))),
            react_1.default.createElement(components_1.MBView, null,
                showLabel && (react_1.default.createElement(components_1.MBView, null,
                    react_1.default.createElement(label_1.MBBlockLabel, { label: emailComponent.label }))),
                initialData.map(function (email) { return (react_1.default.createElement(components_1.MBTextBody1, { key: email }, email)); }))));
    }
    return (react_1.default.createElement(WorkflowComponentWrapper_1.WorkflowComponentWrapper, { paddingHorizontal: paddingHorizontal, iconPad: iconPad, component: emailComponent, showLabel: showLabel, error: error },
        react_1.default.createElement(components_1.MBView, { fullWidth: true },
            react_1.default.createElement(components_1.MBTextInput, { noBorder: true, noPadding: true, inputMode: "email", textContentType: "emailAddress", value: inputValue, onChangeText: onChange, placeholder: emailComponent.placeholder, onBlur: onBlur, onFocus: onFocus, style: { width: getInputWidth() } }))));
}
