"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useControlsActions = exports.useControlsState = void 0;
exports.ControlsProvider = ControlsProvider;
var react_1 = __importStar(require("react"));
var ComponentTypeUtils_1 = require("@shared/scenes/workflows/ComponentTypeUtils");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var context_1 = require("@shared/util/context");
var uuid_1 = require("@shared/util/uuid");
var PreserveViewState_1 = require("./PreserveViewState");
var mapCollectionCriteriaToState_1 = require("./mapCollectionCriteriaToState");
var reducer_1 = require("./reducer");
var _a = (0, context_1.createCtx)(), useControlsState = _a[0], ControlsStateProvider = _a[1];
exports.useControlsState = useControlsState;
var _b = (0, context_1.createCtx)(), useControlsActions = _b[0], ControlsActionsProvider = _b[1];
exports.useControlsActions = useControlsActions;
function ControlsProvider(_a) {
    var children = _a.children, workflowTemplateId = _a.workflowTemplateId, viewTemplate = _a.viewTemplate;
    var components = useInputComponents(workflowTemplateId);
    var availableFilters = (0, react_1.useMemo)(function () {
        return components
            .filter(function (c) { return c.isFilterable; })
            .filter(function (c) { return !(0, hooks_1.isEntityBatchComponent)(c); })
            .map(toControlOption);
    }, [components]);
    var availableSorts = (0, react_1.useMemo)(function () {
        return components.filter(function (c) { return c.isSortable; }).map(toControlOption);
    }, [components]);
    var availableGroups = (0, react_1.useMemo)(function () {
        return components.filter(hooks_1.isGroupableComponent).map(toControlOption);
    }, [components]);
    var initialState = (0, mapCollectionCriteriaToState_1.mapCollectionCriteriaToState)(viewTemplate.collectionCriteria, availableFilters, availableSorts, availableGroups);
    var _b = (0, react_1.useReducer)(reducer_1.reducer, initialState), state = _b[0], dispatch = _b[1];
    var _c = (0, react_1.useState)(state.selectedFilters), selectedFilters = _c[0], setSelectedFilters = _c[1];
    var _d = (0, react_1.useState)(state.selectedSorts), selectedSorts = _d[0], setSelectedSorts = _d[1];
    var _e = (0, react_1.useState)(state.selectedGroups), selectedGroups = _e[0], setSelectedGroups = _e[1];
    var _f = (0, react_1.useState)(null), search = _f[0], setSearch = _f[1];
    var _g = (0, react_1.useState)(false), isModified = _g[0], setIsModified = _g[1];
    var setFilterOption = (0, react_1.useCallback)(function (filter, operation, selection) {
        dispatch({ type: 'filter', operation: operation, payload: { filter: filter, selection: selection } });
    }, [dispatch]);
    var setGroupOption = (0, react_1.useCallback)(function (filter, groupIndex, selection) {
        dispatch({ type: 'group', groupIndex: groupIndex, payload: { filter: filter, selection: selection } });
    }, [dispatch]);
    var resetGroups = (0, react_1.useCallback)(function () {
        dispatch({ type: 'resetGroups' });
    }, [dispatch]);
    var resetState = (0, react_1.useCallback)(function (nextState) {
        setSelectedFilters(nextState.selectedFilters);
        setSelectedSorts(nextState.selectedSorts);
        setSelectedGroups(nextState.selectedGroups);
        dispatch({ type: 'reset', payload: nextState });
    }, [dispatch]);
    var revertToSaved = (0, react_1.useCallback)(function () {
        // revert state to saved version in viewTemplate
        var nextState = (0, mapCollectionCriteriaToState_1.mapCollectionCriteriaToState)(viewTemplate.collectionCriteria, availableFilters, availableSorts, availableGroups);
        resetState(nextState);
    }, [
        viewTemplate.collectionCriteria,
        availableFilters,
        availableGroups,
        availableSorts,
        resetState,
    ]);
    (0, react_1.useEffect)(function () {
        // reset state when viewTemplate changes
        revertToSaved();
    }, [viewTemplate.collectionCriteria, revertToSaved]);
    var sorts = (0, react_1.useMemo)(function () {
        var sortMap = new Map();
        selectedSorts.forEach(function (item) {
            var _a;
            var uuid = (0, uuid_1.v4)();
            var direction = (_a = item.direction) !== null && _a !== void 0 ? _a : 'ASC';
            sortMap.set(uuid, {
                sort: __assign({ uuid: uuid }, item),
                direction: direction,
            });
        });
        return sortMap;
    }, [selectedSorts]);
    var controlsStateValue = (0, react_1.useMemo)(function () { return (__assign(__assign({}, state), { availableFilters: availableFilters, selectedFilters: selectedFilters, sorts: sorts, availableSorts: availableSorts, selectedSorts: selectedSorts, availableGroups: availableGroups, selectedGroups: selectedGroups, search: search, isModified: isModified })); }, [
        availableFilters,
        availableGroups,
        availableSorts,
        search,
        selectedFilters,
        selectedGroups,
        selectedSorts,
        sorts,
        state,
        isModified,
    ]);
    var controlsActionsValue = (0, react_1.useMemo)(function () { return ({
        setFilterOption: setFilterOption,
        setSelectedFilters: setSelectedFilters,
        setSelectedSorts: setSelectedSorts,
        setGroupOption: setGroupOption,
        setSelectedGroups: setSelectedGroups,
        resetGroups: resetGroups,
        setSearch: setSearch,
        revertToSaved: revertToSaved,
    }); }, [resetGroups, setFilterOption, setGroupOption, revertToSaved, setSearch]);
    return (react_1.default.createElement(ControlsActionsProvider, { value: controlsActionsValue },
        react_1.default.createElement(ControlsStateProvider, { value: controlsStateValue },
            react_1.default.createElement(PreserveViewState_1.PreserveViewState, { viewTemplate: viewTemplate, setIsModified: setIsModified, resetState: resetState }),
            children)));
}
function useInputComponents(workflowTemplateId) {
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    return (0, react_1.useMemo)(function () {
        var _a;
        var components = (_a = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components) !== null && _a !== void 0 ? _a : [];
        return components.filter(hooks_1.isInputComponent);
    }, [workflowTemplate]);
}
function toControlOption(component) {
    var _a;
    return {
        id: component.id,
        type: component.type,
        label: (_a = component.label) !== null && _a !== void 0 ? _a : '',
        icon: (0, ComponentTypeUtils_1.getIcon)(component),
        component: component,
    };
}
