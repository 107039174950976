"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = void 0;
exports.default = reducer;
var Actions = __importStar(require("./actions"));
exports.initialState = {
    contentFromDraftId: {},
    urgentFromDraftId: {},
    attachmentsFromDraftId: {},
    showTooltipMentionManager: false,
    isGIFModalVisibleFromDraftId: {},
};
function reducer(state, action) {
    var _a, _b, _c, _d, _e, _f;
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case Actions.UPDATE_CONTENT: {
            var _g = action.value, draftId = _g.draftId, content = _g.content;
            return __assign(__assign({}, state), { contentFromDraftId: __assign(__assign({}, state.contentFromDraftId), (_a = {}, _a[draftId] = content, _a)) });
        }
        case Actions.UPDATE_URGENT: {
            var _h = action.value, draftId = _h.draftId, urgent = _h.urgent;
            return __assign(__assign({}, state), { urgentFromDraftId: __assign(__assign({}, state.urgentFromDraftId), (_b = {}, _b[draftId] = urgent, _b)) });
        }
        case Actions.ADD_ATTACHMENTS: {
            var _j = action.value, draftId = _j.draftId, newAttachments = _j.attachments;
            var oldAttachments = state.attachmentsFromDraftId[draftId] || [];
            return __assign(__assign({}, state), { attachmentsFromDraftId: __assign(__assign({}, state.attachmentsFromDraftId), (_c = {}, _c[draftId] = __spreadArray(__spreadArray([], oldAttachments, true), newAttachments, true), _c)) });
        }
        case Actions.REMOVE_ATTACHMENT: {
            var _k = action.value, draftId = _k.draftId, attachment_1 = _k.attachment;
            var oldAttachments = state.attachmentsFromDraftId[draftId] || [];
            var attachments = oldAttachments.filter(function (a) { return a.fileKey !== attachment_1.fileKey; });
            return __assign(__assign({}, state), { attachmentsFromDraftId: __assign(__assign({}, state.attachmentsFromDraftId), (_d = {}, _d[draftId] = attachments, _d)) });
        }
        case Actions.CLEAR_ATTACHMENTS:
            return __assign(__assign({}, state), { attachmentsFromDraftId: __assign(__assign({}, state.attachmentsFromDraftId), (_e = {}, _e[action.value] = [], _e)) });
        case Actions.TOGGLE_TOOLTIP_MENTION_MANAGER:
            return __assign(__assign({}, state), { showTooltipMentionManager: action.value });
        case Actions.TOGGLE_GIF_MODAL: {
            var _l = action.value, draftId = _l.draftId, visible = _l.visible;
            return __assign(__assign({}, state), { isGIFModalVisibleFromDraftId: __assign(__assign({}, state.isGIFModalVisibleFromDraftId), (_f = {}, _f[draftId] = visible, _f)) });
        }
        case Actions.HIDE_TOOLTIPS:
            return __assign(__assign({}, state), { showTooltipMentionManager: false });
        default:
            return state;
    }
}
