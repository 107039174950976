"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSentry = void 0;
var Sentry = __importStar(require("@sentry/browser"));
var react_native_1 = require("react-native");
var config_1 = require("@shared/config");
var env_1 = require("@shared/config/env");
var ignoreErrors_1 = require("./ignoreErrors");
exports.MBSentry = {
    init: function () {
        var dsn = config_1.Config.SENTRY_DSN;
        var release = process.env.REACT_APP_VERSION;
        var environment = __DEV__ ? 'development' : env_1.Environment.current();
        var enabled = environment !== 'development';
        var context = {
            dsn: dsn,
            release: release,
            environment: environment,
            enabled: enabled,
            ignoreErrors: ignoreErrors_1.ignoreErrors,
        };
        if (config_1.Config.DEBUG_SENTRY) {
            console.warn('[MBSentry] init', context);
        }
        Sentry.init(context);
    },
    identify: function (_a) {
        var id = _a.id, name = _a.name, businessId = _a.businessId;
        if (config_1.Config.DEBUG_SENTRY) {
            console.warn('[MBSentry] identify', { id: id, name: name, businessId: businessId });
        }
        Sentry.configureScope(function (scope) {
            scope.setTag('platform', react_native_1.Platform.OS);
            scope.setUser({
                id: String(id),
                username: name,
            });
        });
    },
    exception: function (_a) {
        var error = _a.error, data = _a.data;
        if (config_1.Config.DEBUG_SENTRY) {
            console.warn('[MBSentry] exception', error, data);
        }
        exports.MBSentry.breadcrumb({ data: data !== null && data !== void 0 ? data : {}, message: error.message, category: 'error' });
        Sentry.captureException(error);
    },
    // We log different types of events (analytics, redux, apollo) in breadcrumbs.
    // This makes it easy to understand what events led up to a crash.
    // https://docs.sentry.io/enriching-error-data/breadcrumbs/?platform=javascript
    breadcrumb: function (_a) {
        var level = _a.level, payload = __rest(_a, ["level"]);
        if (config_1.Config.DEBUG_SENTRY) {
            console.warn('[MBSentry]', payload.category, payload.message, payload.data);
        }
        Sentry.addBreadcrumb(__assign({ level: level || 'info' }, payload));
    },
    // TODO(fant): copy paste from index.native.ts
    breadcrumbFromApolloOperation: function (operation) {
        // https://github.com/graphql/graphql-js/blob/master/tstypes/language/ast.d.ts#L201
        var definition = operation.query.definitions[0];
        var type = definition.operation;
        var name = operation.operationName;
        exports.MBSentry.breadcrumb({
            message: "".concat(type, " ").concat(name),
            category: 'apollo',
            data: operation.variables,
        });
    },
    message: function (text) {
        Sentry.captureMessage(text);
    },
};
