"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChannelUserList = MBChannelUserList;
var client_1 = require("@apollo/client");
var react_1 = __importStar(require("react"));
var api_1 = require("@shared/api");
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/util/hooks");
var navigation_1 = require("@shared/util/navigation");
var notNil_1 = require("@shared/util/notNil");
var index_1 = require("./components/index");
var hooks_2 = require("./hooks");
function MBChannelUserList(_a) {
    var _b, _c;
    var channelSlug = _a.channelSlug;
    var isDesktop = (0, hooks_1.useIsDesktop)();
    var navigator = (0, navigation_1.useNavigator)();
    var channel = (_b = (0, client_1.useQuery)(api_1.Queries.channel.details, { variables: { slug: channelSlug }, fetchPolicy: 'cache-and-network' }).data) === null || _b === void 0 ? void 0 : _b.channel;
    var accessLevels = (_c = (0, client_1.useQuery)(api_1.Queries.channel.accessLevels).data) === null || _c === void 0 ? void 0 : _c.accessLevels;
    var navigateToAddUsers = (0, react_1.useCallback)(function () { var _a; return navigator.navigate('ChannelAddPeople', { channelSlug: (_a = channel === null || channel === void 0 ? void 0 : channel.slug) !== null && _a !== void 0 ? _a : '' }); }, [channel === null || channel === void 0 ? void 0 : channel.slug, navigator]);
    var resendInvite = (0, hooks_2.useResendInvite)();
    var removeUserFromChannel = (0, hooks_2.useRemoveUserFromChannel)(channelSlug);
    var updateAccessLevel = (0, hooks_2.useUpdateAccessLevel)();
    var users = (0, react_1.useMemo)(function () { var _a; return (_a = channel === null || channel === void 0 ? void 0 : channel.users.filter(notNil_1.notNil)) !== null && _a !== void 0 ? _a : []; }, [channel === null || channel === void 0 ? void 0 : channel.users]);
    if (!channel || !accessLevels) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true });
    }
    return (react_1.default.createElement(index_1.MBChannelUserList, { users: users, channel: channel, isDesktop: isDesktop, accessLevels: accessLevels, onAddUsers: navigateToAddUsers, onRemoveFromChannel: removeUserFromChannel, onResendInvite: resendInvite, onUpdateAccessLevel: updateAccessLevel }));
}
