"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedEntityPicker = RelatedEntityPicker;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var noCards_1 = require("@shared/components/blocks/lib/inputCards/noCards");
var quickCreateCard_1 = require("@shared/components/blocks/lib/inputCards/quickCreateCard");
var searchEmpty_1 = require("@shared/components/blocks/lib/inputCards/searchEmpty");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var multiSelect_1 = require("@shared/components/multiSelect");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var i18n_1 = require("@shared/util/i18n");
var useCreateRelatedEntity_1 = require("./useCreateRelatedEntity");
var useRelatedEntitySearch_1 = require("./useRelatedEntitySearch");
var useWorkflowSupportsQuickCreate_1 = require("./useWorkflowSupportsQuickCreate");
function RelatedEntityPicker(_a) {
    var _this = this;
    var entities = _a.entities, containerRef = _a.containerRef, onChange = _a.onChange, onClose = _a.onClose, component = _a.component, fields = _a.fields, canCreateNew = _a.canCreateNew;
    var _b = (0, useRelatedEntitySearch_1.useRelatedEntitySearch)(component, fields), onSearch = _b.onSearch, searchResults = _b.searchResults, loading = _b.loading, hasMore = _b.hasMore;
    var addNewEntity = (0, useCreateRelatedEntity_1.useCreateRelatedEntity)({
        workflowTemplateId: component.workflowTemplateId,
    });
    var handleCreateNewEntity = (0, react_1.useCallback)(function (name) { return __awaiter(_this, void 0, void 0, function () {
        var newEntity;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, addNewEntity(name)];
                case 1:
                    newEntity = _a.sent();
                    if (!(0, lodash_1.isNil)(newEntity)) {
                        onChange(__spreadArray(__spreadArray([], entities, true), [newEntity], false));
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [onChange, entities, addNewEntity]);
    var workflowSupportsQuickCreate = (0, useWorkflowSupportsQuickCreate_1.useWorkflowSupportsQuickCreate)({
        workflowTemplateId: component.workflowTemplateId,
    });
    var allowCreation = canCreateNew && workflowSupportsQuickCreate;
    var renderTitleBar = (0, react_1.useCallback)(function () {
        var _a;
        return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: (_a = component.label) !== null && _a !== void 0 ? _a : '', rightIcon: "exit", onRightIconPress: onClose }));
    }, [onClose, component.label]);
    var renderCreateNew = (0, react_1.useCallback)(function (query, creating) {
        var _a;
        if (!allowCreation) {
            return null;
        }
        if (creating) {
            return react_1.default.createElement(components_1.MBLoadingIndicator, { padding: 4 });
        }
        return react_1.default.createElement(quickCreateCard_1.QuickCreateCard, { channelName: (_a = component.label) !== null && _a !== void 0 ? _a : '', query: query });
    }, [component.label, allowCreation]);
    var match = (0, react_1.useCallback)(function (entity, query, exact) {
        var _a, _b;
        var name = (_b = (_a = entity.fields) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '';
        if (exact) {
            return name === query;
        }
        return true;
    }, []);
    var renderEmptyState = (0, react_1.useCallback)(function (query) {
        return (react_1.default.createElement(components_1.MBView, { paddingVertical: 3, paddingHorizontal: 2, center: true },
            query.length > 0 && react_1.default.createElement(searchEmpty_1.SearchEmpty, { keyword: query }),
            query.length === 0 && !loading && react_1.default.createElement(noCards_1.NoCards, null),
            query.length === 0 && loading && react_1.default.createElement(components_1.MBLoadingIndicator, null)));
    }, [loading]);
    var renderItem = (0, react_1.useCallback)(function (entity) { return react_1.default.createElement(EntityPickerItem, { entity: entity }); }, []);
    var isDesktop = (0, contentWrapper_1.useIsDesktop)();
    var fixedWidth = isDesktop ? (0, components_1.grid)(46) : undefined;
    return (react_1.default.createElement(components_1.MBBottomSheet, { containerRef: containerRef, renderTitleBar: !isDesktop ? renderTitleBar : undefined, layout: {
            offset: { x: (0, components_1.grid)(10), y: 0 },
            minWidth: fixedWidth,
            minHeight: (0, components_1.grid)(48),
        }, onClose: onClose },
        react_1.default.createElement(components_1.MBView, { style: { width: fixedWidth }, fill: true },
            react_1.default.createElement(multiSelect_1.MBMultiSelect, { onChange: onChange, keyExtractor: function (entity) { return entity.id; }, match: match, renderItem: renderItem, renderEmptyState: renderEmptyState, onCreate: handleCreateNewEntity, renderCreateNew: allowCreation ? renderCreateNew : undefined, values: searchResults, selectedValues: entities, onSearch: onSearch, helpText: hasMore ? (0, i18n_1.i18n)('card.blocks.cardsInput.cardListLimitHelp') : undefined, displaySelected: true }))));
}
function EntityPickerItem(_a) {
    var _b, _c;
    var entity = _a.entity;
    return react_1.default.createElement(components_1.MBTextBody1, { style: { paddingLeft: (0, components_1.grid)(1) } }, (_c = (_b = entity.fields) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '');
}
