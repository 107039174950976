"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextEditField = TextEditField;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/components/blocks/lib/inputTodo/hooks");
var TextField_1 = require("@shared/data/entity/fields/TextField");
var ComponentFocus_1 = require("@shared/scenes/cards/form/components/ComponentFocus");
var hooks_2 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var hooks_3 = require("@shared/util/hooks");
var EditFieldContainer_1 = require("./EditFieldContainer");
function TextEditField(_a) {
    var _b, _c, _d;
    var field = _a.field, component = _a.component, updateField = _a.updateField, error = _a.error;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var placeholder = (0, hooks_2.getComponentPlaceholder)(component);
    var debouncedScrollIntoView = (0, hooks_1.useDebouncedScrollTextInputIntoView)();
    var _e = (0, hooks_3.useBoolean)(false), isFocused = _e[0], onFocus = _e[1], onBlur = _e[2];
    var _f = (0, ComponentFocus_1.useComponentFocus)(), setComponentFocused = _f.setComponentFocused, setComponentBlurred = _f.setComponentBlurred;
    var handleFocus = (0, react_1.useCallback)(function () {
        onFocus();
        setComponentFocused();
    }, [onFocus, setComponentFocused]);
    var handleBlur = (0, react_1.useCallback)(function () {
        onBlur();
        setComponentBlurred();
    }, [onBlur, setComponentBlurred]);
    var updateText = (0, react_1.useCallback)(function (textValue) {
        updateField(component.id, !(0, lodash_1.isEmpty)(textValue) ? new TextField_1.TextField(textValue) : null);
    }, [updateField, component.id]);
    var onClear = (0, react_1.useCallback)(function () {
        updateField(component.id, null);
    }, [updateField, component.id]);
    var isWeb = react_native_1.Platform.OS === 'web';
    var hasValue = !(0, lodash_1.isEmpty)(field === null || field === void 0 ? void 0 : field.jsonBlob);
    var isReadOnly = !!component.readonly;
    var isMultiline = !!component.multiline;
    var showClearButton = hasValue && !isReadOnly;
    // render readonly text using the multiline input so that all text is visible,
    // even if the component is not a multiline component
    var showMultilineInput = isMultiline || isReadOnly;
    return (react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, showLabel: hasValue, onClear: showClearButton ? onClear : undefined, error: !isFocused ? error : undefined },
        isWeb && !showMultilineInput && (react_1.default.createElement(components_1.MBTextInput, { noPadding: true, value: (_b = field === null || field === void 0 ? void 0 : field.jsonBlob) !== null && _b !== void 0 ? _b : '', onChangeText: updateText, placeholder: placeholder, noBorder: true, editable: !isReadOnly, onFocus: handleFocus, onBlur: handleBlur, style: { paddingVertical: !hasValue ? (0, components_1.grid)(1.25) : undefined } })),
        isWeb && showMultilineInput && (react_1.default.createElement(components_1.MBAutosizeTextInput, { value: (_c = field === null || field === void 0 ? void 0 : field.jsonBlob) !== null && _c !== void 0 ? _c : '', onChangeText: updateText, placeholder: placeholder, disabled: isReadOnly, onFocus: handleFocus, onBlur: handleBlur, padding: !hasValue ? "".concat((0, components_1.grid)(1.25), "px 0") : 'none' })),
        !isWeb && (react_1.default.createElement(react_native_1.TextInput, { value: (_d = field === null || field === void 0 ? void 0 : field.jsonBlob) !== null && _d !== void 0 ? _d : '', onChangeText: updateText, editable: !isReadOnly, style: [
                components_1.MBTypographyStyles.body1,
                styles.input,
                { paddingVertical: !hasValue ? (0, components_1.grid)(1.25) : undefined },
            ], placeholder: placeholder, placeholderTextColor: !isFocused && !(0, lodash_1.isEmpty)(error) ? colors.error.foreground : colors.foregroundInactive, multiline: isMultiline, onFocus: function () {
                debouncedScrollIntoView();
                handleFocus();
            }, onContentSizeChange: debouncedScrollIntoView, onBlur: handleBlur }))));
}
var styles = react_native_1.StyleSheet.create({
    input: {
        maxHeight: 100,
        padding: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
    },
});
