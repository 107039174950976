"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubformEditField = SubformEditField;
exports.SubformNavigationContext = SubformNavigationContext;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var SubformsContent_1 = require("@shared/components/blocks/lib/inputSubforms/SubformsContent");
var SubformsSheet_1 = require("@shared/components/blocks/lib/inputSubforms/sheet/SubformsSheet");
var SubformField_1 = require("@shared/data/entity/fields/SubformField");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var useWorkspaceDetails_1 = require("@shared/scenes/workflows/hooks/useWorkspaceDetails");
var WorkflowView_1 = require("@shared/scenes/workflows/views/view-components/WorkflowView");
var styles_1 = require("@shared/styles");
var alert_1 = require("@shared/util/alert");
var hooks_2 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
var EditFieldContainer_1 = require("../EditFieldContainer");
var WorkflowCreate_1 = require("./WorkflowCreate");
function SubformEditField(_a) {
    var _b, _c;
    var field = _a.field, component = _a.component, workflowTemplateId = _a.workflowTemplateId, updateField = _a.updateField, error = _a.error, _d = _a.allowCreate, allowCreate = _d === void 0 ? true : _d;
    var _e = (0, hooks_2.useBoolean)(false), isSheetOpen = _e[0], openSheet = _e[1], closeSheet = _e[2];
    var containerRef = (0, react_1.useRef)(null);
    var workspaceDetails = (0, useWorkspaceDetails_1.useWorkspaceDetails)();
    var hasSubform = !(0, lodash_1.isNil)(field === null || field === void 0 ? void 0 : field.jsonBlob);
    var nav = (0, react_1.useContext)(navigation_1.NavStackContext);
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var isReadOnly = (_b = component.readonly) !== null && _b !== void 0 ? _b : false;
    var onSelectSubform = (0, react_1.useCallback)(function (subformWorkflowTemplate) {
        var _a, _b;
        var componentsWithDefaults = (_b = (_a = subformWorkflowTemplate.components) === null || _a === void 0 ? void 0 : _a.filter(hooks_1.isAuditTagComponent).filter(function (c) { var _a; return !(0, lodash_1.isEmpty)((_a = c.default) === null || _a === void 0 ? void 0 : _a.value); })) !== null && _b !== void 0 ? _b : [];
        var defaultData = componentsWithDefaults.reduce(function (acc, subformComponent) {
            var _a;
            acc[subformComponent.id] = { tags: (_a = subformComponent.default) === null || _a === void 0 ? void 0 : _a.value };
            return acc;
        }, {});
        updateField(component.id, new SubformField_1.SubformField({ workflowTemplateId: subformWorkflowTemplate.id, data: defaultData }));
        closeSheet();
    }, [component.id, updateField, closeSheet]);
    var navigateToAdd = (0, react_1.useCallback)(function () {
        var _a, _b;
        nav.push({
            name: 'WorkflowCreate',
            params: {
                channelSlug: (_b = (_a = workspaceDetails.get(workflowTemplateId)) === null || _a === void 0 ? void 0 : _a.baseNavigationPath) !== null && _b !== void 0 ? _b : '',
                originWorkflowTemplateId: workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.id,
                originSubformComponentId: component.id,
                workspaceDetails: workspaceDetails,
            },
        });
        closeSheet();
    }, [closeSheet, component.id, nav, workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.id, workflowTemplateId, workspaceDetails]);
    var onSelect = (0, react_1.useCallback)(function (subformId) {
        nav.push({
            name: 'subform',
            params: {
                workflowTemplateId: subformId,
                parentComponentId: component.id,
                isReadOnly: isReadOnly,
            },
        });
        closeSheet();
    }, [component.id, nav, closeSheet, isReadOnly]);
    var onClear = (0, react_1.useCallback)(function () {
        alert_1.MBAlert.confirm({
            message: (0, i18n_1.i18n)('card.blocks.subformsInput.confirmClear'),
            confirmText: (0, i18n_1.i18n)('common.ok'),
            onConfirm: function () {
                updateField(component.id, null);
            },
        });
    }, [component.id, updateField]);
    return (react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, showLabel: true, indentLabel: true, showIcon: false, error: error },
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: hasSubform || isReadOnly ? undefined : openSheet, style: {
                marginHorizontal: -(0, components_1.grid)(1),
                paddingVertical: (0, components_1.grid)(0.5),
            } },
            hasSubform && (react_1.default.createElement(SubformContainer, { field: field, onSelect: onSelect, onClear: onClear, containerRef: containerRef, isReadOnly: isReadOnly })),
            !hasSubform && react_1.default.createElement(EmptyContainer, { component: component, containerRef: containerRef }),
            isSheetOpen && (react_1.default.createElement(SubformsSheet_1.SubformsSheet, { containerRef: containerRef, blockLabel: (_c = component.label) !== null && _c !== void 0 ? _c : '', workflowTemplate: workflowTemplate, onClose: closeSheet, onSelect: onSelectSubform, navigateToAdd: allowCreate ? navigateToAdd : undefined })))));
}
function SubformContainer(_a) {
    var field = _a.field, onSelect = _a.onSelect, onClear = _a.onClear, containerRef = _a.containerRef, _b = _a.isReadOnly, isReadOnly = _b === void 0 ? false : _b;
    if ((0, lodash_1.isNil)(field === null || field === void 0 ? void 0 : field.subform)) {
        return null;
    }
    return (react_1.default.createElement(react_native_1.View, { ref: containerRef, style: styles.subformContainer },
        react_1.default.createElement(SubformsContent_1.SubformContent, { subform: field.subform, onSelect: onSelect, onClear: onClear, isReadOnly: isReadOnly })));
}
function EmptyContainer(_a) {
    var _b;
    var component = _a.component, containerRef = _a.containerRef;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var containerColors = {
        backgroundColor: colors.backgroundInactive,
        borderColor: colors.input.borderInactive,
    };
    return (react_1.default.createElement(components_1.MBView, { paddingHorizontal: 1 },
        react_1.default.createElement(react_native_1.View, { ref: containerRef },
            react_1.default.createElement(components_1.MBView, { style: [styles.container, containerColors], row: true, centerVertically: true, centerHorizontally: true, paddingHorizontal: 2 },
                react_1.default.createElement(components_1.MBView, { style: { marginRight: (0, components_1.grid)(1) } },
                    react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundActive, size: (0, components_1.grid)(5), name: (_b = (0, hooks_1.getComponentIcon)(component)) !== null && _b !== void 0 ? _b : 'list' })),
                react_1.default.createElement(components_1.MBTextSubtitle, { numberOfLines: 2, ellipsizeMode: "tail", color: colors.foregroundActive, style: { flexGrow: 0, flexShrink: 1 } }, component.placeholder)))));
}
function SubformNavigationContext(props) {
    return (react_1.default.createElement(navigation_1.MBNavStackProvider, { initialRoutes: [{ name: 'cardForm' }] },
        react_1.default.createElement(ConditionalNav, __assign({}, props))));
}
function ConditionalNav(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var fields = _a.fields, updateField = _a.updateField, children = _a.children;
    var navContext = (0, react_1.useContext)(navigation_1.NavStackContext);
    if (navContext.currentRoute.name === 'subform' && !(0, lodash_1.isNil)(fields)) {
        var workflowTemplateId_1 = (_c = (_b = navContext.currentRoute.params) === null || _b === void 0 ? void 0 : _b.workflowTemplateId) !== null && _c !== void 0 ? _c : '';
        var parentComponentId_1 = (_e = (_d = navContext.currentRoute.params) === null || _d === void 0 ? void 0 : _d.parentComponentId) !== null && _e !== void 0 ? _e : '';
        var isReadOnly = (_g = (_f = navContext.currentRoute.params) === null || _f === void 0 ? void 0 : _f.isReadOnly) !== null && _g !== void 0 ? _g : false;
        var field = fields[parentComponentId_1];
        var initialData = (0, lodash_1.isNil)(field === null || field === void 0 ? void 0 : field.jsonBlob) ? {} : (0, lodash_1.cloneDeep)(field.jsonBlob.data);
        var onSubmit = function (data) {
            updateField(parentComponentId_1, new SubformField_1.SubformField({ workflowTemplateId: workflowTemplateId_1, data: data }));
            navContext.pop();
        };
        return (react_1.default.createElement(WorkflowView_1.WorkflowView, { workflowTemplateId: workflowTemplateId_1, initialData: initialData, onSubmit: onSubmit, isReadOnly: isReadOnly }));
    }
    if (navContext.currentRoute.name === 'WorkflowCreate') {
        return (react_1.default.createElement(WorkflowCreate_1.WorkflowCreate, { channelSlug: (_j = (_h = navContext.currentRoute.params) === null || _h === void 0 ? void 0 : _h.channelSlug) !== null && _j !== void 0 ? _j : '', originWorkflowTemplateId: (_l = (_k = navContext.currentRoute.params) === null || _k === void 0 ? void 0 : _k.originWorkflowTemplateId) !== null && _l !== void 0 ? _l : '', workspaceDetails: (_o = (_m = navContext.currentRoute.params) === null || _m === void 0 ? void 0 : _m.workspaceDetails) !== null && _o !== void 0 ? _o : {}, originSubformComponentId: (_q = (_p = navContext.currentRoute.params) === null || _p === void 0 ? void 0 : _p.originSubformComponentId) !== null && _q !== void 0 ? _q : '' }));
    }
    return react_1.default.createElement(react_1.default.Fragment, null, children);
}
var styles = react_native_1.StyleSheet.create({
    container: {
        borderRadius: (0, components_1.grid)(1),
        borderStyle: 'dashed',
        borderWidth: 2,
        paddingVertical: (0, components_1.grid)(2),
        alignItems: 'center',
        justifyContent: 'center',
    },
    subformContainer: {
        paddingHorizontal: (0, components_1.grid)(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
});
