"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.emptyDraft = exports.initialState = void 0;
exports.default = reducer;
var Actions = __importStar(require("./actions"));
// TODO(fant): for some dumb reason, layoutMessages' exported props
// don't get exported from ./actions/index when running Jest...
var LayoutActions = __importStar(require("./actions/layoutMessages"));
exports.initialState = {
    cachedMessages: {},
    deliveryStatusFromMessageUuid: {},
    cachedMessageUuidsFromChannelSlug: {},
    layoutOptionsFromMessageId: {},
    messageDraftFromChannelSlug: {},
    messageBeingEditedFromDraftId: {},
};
exports.emptyDraft = {
    content: '',
    urgent: false,
    editing: null,
    attachments: [],
};
function reducer(state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case LayoutActions.UPDATE_MESSAGE_LAYOUT: {
            return __assign(__assign({}, state), { layoutOptionsFromMessageId: __assign(__assign({}, state.layoutOptionsFromMessageId), action.value) });
        }
        case Actions.ADD_MESSAGE_TO_CACHE: {
            var _q = action.value, channelSlug = _q.channelSlug, message = _q.message;
            return __assign(__assign({}, state), { cachedMessages: __assign(__assign({}, state.cachedMessages), (_a = {}, _a[message.meta.uuid] = message, _a)), cachedMessageUuidsFromChannelSlug: __assign(__assign({}, state.cachedMessageUuidsFromChannelSlug), (_b = {}, _b[channelSlug] = __spreadArray(__spreadArray([], (state.cachedMessageUuidsFromChannelSlug[channelSlug] || []), true), [
                    message.meta.uuid,
                ], false), _b)) });
        }
        case Actions.REMOVE_MESSAGE_FROM_CACHE: {
            var _r = action.value, channelSlug = _r.channelSlug, message_1 = _r.message;
            return __assign(__assign({}, state), { 
                // remove the cached message
                cachedMessages: __assign(__assign({}, state.cachedMessages), (_c = {}, _c[message_1.meta.uuid] = undefined, _c)), 
                // remove it's delivery status (simply for cleanup)
                deliveryStatusFromMessageUuid: __assign(__assign({}, state.deliveryStatusFromMessageUuid), (_d = {}, _d[message_1.meta.uuid] = undefined, _d)), 
                // remove its association with the channel
                cachedMessageUuidsFromChannelSlug: __assign(__assign({}, state.cachedMessageUuidsFromChannelSlug), (_e = {}, _e[channelSlug] = (state.cachedMessageUuidsFromChannelSlug[channelSlug] || []).filter(function (uuid) { return message_1.meta.uuid !== uuid; }), _e)) });
        }
        case Actions.UPDATE_MESSAGE_DELIVERY_STATUS: {
            var _s = action.value, message = _s.message, status_1 = _s.status;
            return __assign(__assign({}, state), { deliveryStatusFromMessageUuid: __assign(__assign({}, state.deliveryStatusFromMessageUuid), (_f = {}, _f[message.meta.uuid] = status_1, _f)) });
        }
        case Actions.PERSIST_MESSAGES_FROM_STORAGE: {
            var persistedState = action.value;
            return __assign(__assign({}, state), persistedState);
        }
        /////////////////////////// MESSAGE DRAFT ///////////////////////////
        case Actions.UPDATE_DRAFT_CONTENT: {
            var _t = action.value, content = _t.content, channelSlug = _t.channelSlug;
            var draft = state.messageDraftFromChannelSlug[channelSlug] || exports.emptyDraft;
            return __assign(__assign({}, state), { messageDraftFromChannelSlug: __assign(__assign({}, state.messageDraftFromChannelSlug), (_g = {}, _g[channelSlug] = __assign(__assign({}, draft), { content: content }), _g)) });
        }
        case Actions.UPDATE_DRAFT_URGENT: {
            var _u = action.value, urgent = _u.urgent, channelSlug = _u.channelSlug;
            var draft = state.messageDraftFromChannelSlug[channelSlug] || exports.emptyDraft;
            return __assign(__assign({}, state), { messageDraftFromChannelSlug: __assign(__assign({}, state.messageDraftFromChannelSlug), (_h = {}, _h[channelSlug] = __assign(__assign({}, draft), { urgent: urgent }), _h)) });
        }
        case Actions.UPDATE_DRAFT_EDITING: {
            var _v = action.value, editing = _v.editing, channelSlug = _v.channelSlug;
            var content = editing.content, urgent = editing.urgent;
            var draft = state.messageDraftFromChannelSlug[channelSlug] || exports.emptyDraft;
            return __assign(__assign({}, state), { messageDraftFromChannelSlug: __assign(__assign({}, state.messageDraftFromChannelSlug), (_j = {}, _j[channelSlug] = __assign(__assign({}, draft), { editing: editing, content: content, urgent: urgent }), _j)) });
        }
        case Actions.ADD_ATTACHMENT_TO_DRAFT: {
            var _w = action.value, attachment = _w.attachment, channelSlug = _w.channelSlug;
            var draft = state.messageDraftFromChannelSlug[channelSlug] || exports.emptyDraft;
            return __assign(__assign({}, state), { messageDraftFromChannelSlug: __assign(__assign({}, state.messageDraftFromChannelSlug), (_k = {}, _k[channelSlug] = __assign(__assign({}, draft), { attachments: __spreadArray(__spreadArray([], draft.attachments, true), [attachment], false) }), _k)) });
        }
        case Actions.REMOVE_ATTACHMENT_FROM_DRAFT: {
            var _x = action.value, attachment_1 = _x.attachment, channelSlug = _x.channelSlug;
            var draft = state.messageDraftFromChannelSlug[channelSlug] || exports.emptyDraft;
            return __assign(__assign({}, state), { messageDraftFromChannelSlug: __assign(__assign({}, state.messageDraftFromChannelSlug), (_l = {}, _l[channelSlug] = __assign(__assign({}, draft), { attachments: draft.attachments.filter(function (a) { return a.fileKey !== attachment_1.fileKey; }) }), _l)) });
        }
        case Actions.REMOVE_ALL_ATTACHMENTS_FROM_DRAFT: {
            var channelSlug = action.value;
            var draft = state.messageDraftFromChannelSlug[channelSlug] || exports.emptyDraft;
            return __assign(__assign({}, state), { messageDraftFromChannelSlug: __assign(__assign({}, state.messageDraftFromChannelSlug), (_m = {}, _m[channelSlug] = __assign(__assign({}, draft), { attachments: [] }), _m)) });
        }
        case Actions.REMOVE_DRAFT: {
            return __assign(__assign({}, state), { messageDraftFromChannelSlug: __assign(__assign({}, state.messageDraftFromChannelSlug), (_o = {}, _o[action.value] = undefined, _o)) });
        }
        case Actions.UPDATE_EDITING_MESSAGE: {
            var _y = action.value, message = _y.message, draftId = _y.draftId;
            return __assign(__assign({}, state), { messageBeingEditedFromDraftId: __assign(__assign({}, state.messageBeingEditedFromDraftId), (_p = {}, _p[draftId] = message, _p)) });
        }
        default:
            return state;
    }
}
