"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardUpdateRoute = CardUpdateRoute;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var api_1 = require("@shared/api");
var components_1 = require("@shared/components");
var scroll_1 = require("@shared/components/blocks/contexts/scroll");
var toast_1 = require("@shared/components/toast");
var CardEntityEditForm_1 = require("@shared/scenes/entity/edit/components/CardEntityEditForm");
var useEditCardEntity_1 = require("@shared/scenes/entity/edit/hooks/useEditCardEntity");
var constants_1 = require("@shared/styles/constants");
var launchDarkly_1 = require("@shared/util/featureFlags/launchDarkly");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
var notNil_1 = require("@shared/util/notNil");
var optionsButton_1 = require("../../details/containers/optionsButton");
var hooks_2 = require("../../hooks");
var ComponentFocus_1 = require("../components/ComponentFocus");
var constants_2 = require("./constants");
var container_1 = require("./container");
var hooks_3 = require("./hooks");
function CardUpdateRoute(props) {
    var isNoCodeUpdate = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.NO_CODE_UPDATE_FORM);
    var channel = (0, hooks_1.useChannel)({ channelSlug: props.params.channelSlug });
    var workflowTemplateId = channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId;
    var isNoCodeWorkflow = !(0, lodash_1.isNil)(workflowTemplateId);
    if (isNoCodeUpdate && isNoCodeWorkflow) {
        return react_1.default.createElement(NoCodeUpdateRoute, __assign({}, props));
    }
    return react_1.default.createElement(LowCodeUpdateRoute, __assign({}, props));
}
function LowCodeUpdateRoute(_a) {
    var _b, _c, _d, _e;
    var params = _a.params, location = _a.location;
    var channelSlug = params.channelSlug, templateSlug = params.templateSlug, cardId = params.cardId;
    var goBackOrShowChannel = useGoBackOrShowChannel(channelSlug);
    var channel = (0, hooks_1.useChannel)({ channelSlug: channelSlug });
    var cardDefinition = (0, hooks_2.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).cardDefinition;
    var title = (0, hooks_3.useFormTitle)(channelSlug, templateSlug, cardDefinition, cardId);
    var _f = (0, react_1.useState)(false), dirty = _f[0], setDirty = _f[1];
    var _g = (0, hooks_1.useFunctionState)(), onSubmit = _g[0], setOnSubmit = _g[1];
    var submitButtonTitle = (0, hooks_3.useFormSubmitButtonTitle)(channelSlug, (_b = cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.id) !== null && _b !== void 0 ? _b : undefined);
    var _h = (0, navigation_1.useNavigator)(), goBack = _h.goBack, replace = _h.replace;
    var fieldOverrides = (0, react_1.useMemo)(function () {
        if (!location.query.fields) {
            return undefined;
        }
        try {
            return JSON.parse(location.query.fields);
        }
        catch (error) {
            return undefined;
        }
    }, [location.query.fields]);
    var returnTo = (0, react_1.useMemo)(function () {
        if ((0, lodash_1.isNil)(location.query[constants_2.RETURN_CHANNEL_SLUG_QUERY])) {
            return undefined;
        }
        return {
            channelSlug: location.query[constants_2.RETURN_CHANNEL_SLUG_QUERY],
            cardId: location.query.returnCardId,
        };
    }, [location.query]);
    var onBack = (0, react_1.useMemo)(function () {
        return (0, lodash_1.once)(function () {
            if ((0, lodash_1.isNil)(returnTo)) {
                goBackOrShowChannel();
            }
            else {
                replace((0, lodash_1.isNil)(returnTo === null || returnTo === void 0 ? void 0 : returnTo.cardId) ? 'CardCreate' : 'CardUpdate', __assign(__assign({}, returnTo), { restoreSavedData: true }));
            }
        });
    }, [goBackOrShowChannel, replace, returnTo]);
    var showSaveButton = (0, hooks_3.useShouldShowUpdateSubmitButton)(dirty, (_c = channel === null || channel === void 0 ? void 0 : channel.id) !== null && _c !== void 0 ? _c : -1);
    return (react_1.default.createElement(components_1.MBModalOrMobile, { desktopModalWidth: constants_1.Constants.pageModalWidth, desktopModalHeight: 'calc(95vh - 76px)' /* height of bottom button */, title: title, paddingOnWeb: "none", onBack: onBack, desktopModalRightButton: react_1.default.createElement(components_1.MBView, { row: true, paddingRight: 1 },
            showSaveButton && react_1.default.createElement(components_1.MBHeaderButton, { title: submitButtonTitle, onPress: onSubmit }),
            react_1.default.createElement(optionsButton_1.MBCardDetailsOptionsButton, { hiddenCardOptions: ['edit'], cardId: cardId, channelSlug: channelSlug })), headerOnMobile: react_1.default.createElement(components_1.MBWebNavigationHeader, { title: title, rightButton: react_1.default.createElement(components_1.MBView, { row: true, paddingRight: 1 },
                showSaveButton && react_1.default.createElement(components_1.MBHeaderButton, { title: submitButtonTitle, onPress: onSubmit }),
                react_1.default.createElement(optionsButton_1.MBCardDetailsOptionsButton, { hiddenCardOptions: ['edit'], cardId: cardId, channelSlug: channelSlug })), onPressLeft: goBack }) },
        react_1.default.createElement(scroll_1.CardBlockScrollProvider, null,
            react_1.default.createElement(container_1.MBCardUpdateForm, { cardId: cardId, channelSlug: channelSlug, templateSlug: templateSlug, workflowTemplateId: (_d = channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId) !== null && _d !== void 0 ? _d : undefined, fieldOverrides: fieldOverrides, returnTo: returnTo, restoreSavedData: !!((_e = location.query) === null || _e === void 0 ? void 0 : _e.restoreSavedData), formDirty: dirty, onDirty: setDirty, onSubmitFormButtonCallback: setOnSubmit }))));
}
function NoCodeUpdateRoute(_a) {
    var _b, _c, _d, _e;
    var params = _a.params;
    var entityId = params.cardId, channelSlug = params.channelSlug, templateSlug = params.templateSlug;
    var goBackOrShowChannel = useGoBackOrShowChannel(channelSlug);
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var channelInfo = (0, hooks_1.useChannel)({ channelSlug: channelSlug });
    var fullChannel = (0, client_1.useQuery)(api_1.Queries.channel.details, { variables: { slug: channelSlug } });
    var onBack = (0, react_1.useMemo)(function () {
        return (0, lodash_1.once)(goBackOrShowChannel);
    }, [goBackOrShowChannel]);
    var _f = (0, useEditCardEntity_1.useEditCardEntity)({
        entityId: entityId,
        workflowTemplateId: channelInfo === null || channelInfo === void 0 ? void 0 : channelInfo.workflowTemplateId,
        viewTemplateId: templateSlug,
    }), components = _f.components, fields = _f.fields, updateField = _f.updateField, errors = _f.errors, isDirty = _f.isDirty, onPressSave = _f.onPressSave, status = _f.status, viewTemplateTitle = _f.viewTemplateTitle, isLoading = _f.isLoading, isSaving = _f.isSaving, threadId = _f.threadId;
    var isSaveSuccessful = status === 'success';
    var showUnsavedChangesWarning = !isSaveSuccessful && isDirty;
    (0, navigation_1.useUnsavedChangesNavigationWarning)(showUnsavedChangesWarning);
    (0, react_1.useEffect)(function () {
        if (isSaveSuccessful) {
            onBack();
            toast.show({
                icon: 'check-filled',
                message: (0, i18n_1.i18n)('card.update.success'),
            });
        }
    }, [isSaveSuccessful, onBack, toast]);
    var users = (0, react_1.useMemo)(function () { var _a, _b, _c; return (_c = (_b = (_a = fullChannel === null || fullChannel === void 0 ? void 0 : fullChannel.data) === null || _a === void 0 ? void 0 : _a.channel) === null || _b === void 0 ? void 0 : _b.users.filter(notNil_1.notNil)) !== null && _c !== void 0 ? _c : []; }, [(_c = (_b = fullChannel === null || fullChannel === void 0 ? void 0 : fullChannel.data) === null || _b === void 0 ? void 0 : _b.channel) === null || _c === void 0 ? void 0 : _c.users]);
    var groups = (0, react_1.useMemo)(function () { var _a, _b, _c; return (_c = (_b = (_a = fullChannel === null || fullChannel === void 0 ? void 0 : fullChannel.data) === null || _a === void 0 ? void 0 : _a.channel) === null || _b === void 0 ? void 0 : _b.groups.filter(notNil_1.notNil)) !== null && _c !== void 0 ? _c : []; }, [(_e = (_d = fullChannel === null || fullChannel === void 0 ? void 0 : fullChannel.data) === null || _d === void 0 ? void 0 : _d.channel) === null || _e === void 0 ? void 0 : _e.groups]);
    var submitButtonTitle = (0, i18n_1.i18n)('card.update.submit');
    return (react_1.default.createElement(components_1.MBModalOrMobile, { desktopModalWidth: constants_1.Constants.pageModalWidth, desktopModalHeight: 'calc(95vh - 76px)' /* height of bottom button */, title: viewTemplateTitle, paddingOnWeb: "none", onBack: onBack, desktopModalRightButton: react_1.default.createElement(components_1.MBView, { row: true, paddingRight: 1 },
            isDirty && (react_1.default.createElement(components_1.MBHeaderButton, { title: submitButtonTitle, onPress: onPressSave, loading: isSaving, disabled: isSaving })),
            react_1.default.createElement(optionsButton_1.MBCardDetailsOptionsButton, { hiddenCardOptions: ['edit'], cardId: entityId, channelSlug: channelSlug })), headerOnMobile: react_1.default.createElement(components_1.MBWebNavigationHeader, { title: viewTemplateTitle, rightButton: react_1.default.createElement(components_1.MBView, { row: true, paddingRight: 1 },
                react_1.default.createElement(SaveButton, { channel: channelInfo, isDirty: isDirty, onPressSave: onPressSave, isLoading: isSaving }),
                react_1.default.createElement(optionsButton_1.MBCardDetailsOptionsButton, { hiddenCardOptions: ['edit'], cardId: entityId, channelSlug: channelSlug })), onPressLeft: onBack }) },
        react_1.default.createElement(ComponentFocus_1.ComponentFocusProvider, null,
            react_1.default.createElement(scroll_1.CardBlockScrollProvider, null,
                isLoading && react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true }),
                !isLoading && (react_1.default.createElement(CardEntityEditForm_1.CardEntityEditForm, { fields: fields, updateField: updateField, components: components, errors: errors, workflowTemplateId: channelInfo === null || channelInfo === void 0 ? void 0 : channelInfo.workflowTemplateId, users: users, groups: groups, entityId: entityId, threadId: threadId, channelSlug: channelSlug }))))));
}
function SaveButton(_a) {
    var _b;
    var channel = _a.channel, isDirty = _a.isDirty, onPressSave = _a.onPressSave, isLoading = _a.isLoading;
    var showSaveButton = (0, hooks_3.useShouldShowUpdateSubmitButton)(isDirty, (_b = channel === null || channel === void 0 ? void 0 : channel.id) !== null && _b !== void 0 ? _b : -1);
    if (!showSaveButton) {
        return null;
    }
    return react_1.default.createElement(components_1.MBHeaderButton, { title: (0, i18n_1.i18n)('common.save'), onPress: onPressSave, loading: isLoading });
}
function useGoBackOrShowChannel(channelSlug) {
    var _a = (0, navigation_1.useNavigator)(), goBack = _a.goBack, replace = _a.replace;
    return (0, react_1.useCallback)(function () {
        var currentLocation = window.location.pathname;
        goBack();
        setTimeout(function () {
            var locationUnchanged = window.location.pathname === currentLocation;
            if (locationUnchanged) {
                replace('Channel', { channelSlug: channelSlug });
            }
        }, 10);
    }, [goBack, replace, channelSlug]);
}
