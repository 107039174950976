"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOpenCardOptionsMenu = useOpenCardOptionsMenu;
var lodash_1 = require("lodash");
var react_1 = require("react");
var cardSelection_1 = require("@shared/api/providers/cardSelection");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var render_1 = require("@shared/components/blocks/contexts/render");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var hooks_1 = require("@shared/scenes/cards/form/hooks");
var actionSheet_1 = require("@shared/util/actionSheet");
var analytics_1 = require("@shared/util/analytics");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
var ChannelPermissionsContext_1 = require("@shared/util/permissions/ChannelPermissionsContext");
var PrintContext_1 = require("@shared/util/print/PrintContext");
var useBulkEditMenuItem_1 = require("./useBulkEditMenuItem");
var useRemoveCardWithConfirmation_1 = require("./useRemoveCardWithConfirmation");
function useOpenCardOptionsMenu(card, options) {
    var _a;
    var hiddenCardOptions = options.hiddenCardOptions, onOpen = options.onOpen, onClose = options.onClose, onRemoved = options.onRemoved, onShare = options.onShare, onDownload = options.onDownload;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var navigate = (0, navigation_1.useNavigator)().navigate;
    var duplicateCard = (0, hooks_1.useDuplicateCard)();
    var removeCardWithConfirmation = (0, useRemoveCardWithConfirmation_1.useRemoveCardWithConfirmation)(card, onRemoved);
    var _b = (0, react_1.useContext)(ChannelPermissionsContext_1.ChannelPermissionsContext), canCreateCard = _b.canCreateCard, canUpdateCard = _b.canUpdateCard, canDeleteCard = _b.canDeleteCard;
    var isDesktop = (0, contentWrapper_1.useIsDesktop)();
    var printContext = (0, react_1.useContext)(PrintContext_1.PrintContext);
    var renderContext = (0, react_1.useContext)(render_1.CardRenderContext);
    var selectionState = (0, cardSelection_1.useCardSelectionState)();
    var _c = (0, cardSelection_1.useCardSelectionActions)(), setSelectionActive = _c.setActive, addCard = _c.addCard;
    var isCollectionView = renderContext && !['form', 'detail'].includes(renderContext);
    var cardSelectionEnabled = selectionState && isCollectionView && isDesktop;
    var isLowCodeCard = (0, lodash_1.isNil)(card.workflowTemplateId);
    var recurringEntitiesScheduleId = (_a = card.fields) === null || _a === void 0 ? void 0 : _a.entityBatch;
    var showBulkUpdateItem = !(0, lodash_1.isNil)(recurringEntitiesScheduleId) &&
        !(0, lodash_1.isNil)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId) &&
        !(hiddenCardOptions === null || hiddenCardOptions === void 0 ? void 0 : hiddenCardOptions.includes('bulkUpdate'));
    var cardId = card.id;
    var channelSlug = channel === null || channel === void 0 ? void 0 : channel.slug;
    var workflowTemplateId = channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId;
    var navigateToBulkUpdate = (0, useBulkEditMenuItem_1.useBulkEditMenuItem)({
        cardId: cardId,
        channelSlug: channelSlug,
        workflowTemplateId: workflowTemplateId,
        recurringEntitiesScheduleId: recurringEntitiesScheduleId,
    });
    return (0, react_1.useCallback)(function (anchor) {
        onOpen === null || onOpen === void 0 ? void 0 : onOpen();
        return actionSheet_1.MBActionSheet.show({
            parent: anchor,
            webVerticalAlignment: 'below',
            webHorizontalAlignment: 'right',
            onClose: onClose,
            options: [
                {
                    title: (0, i18n_1.i18n)('common.edit'),
                    hidden: (0, lodash_1.isNil)(channelSlug) || !canUpdateCard.enabled || (hiddenCardOptions === null || hiddenCardOptions === void 0 ? void 0 : hiddenCardOptions.includes('edit')),
                    onPress: function () {
                        navigate('CardUpdate', { cardId: cardId, channelSlug: channelSlug !== null && channelSlug !== void 0 ? channelSlug : '' });
                    },
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.duplicate'),
                    hidden: !canCreateCard.enabled || (hiddenCardOptions === null || hiddenCardOptions === void 0 ? void 0 : hiddenCardOptions.includes('duplicate')),
                    onPress: function () { return duplicateCard(card.id); },
                },
                {
                    title: (0, i18n_1.i18n)('common.share'),
                    hidden: hiddenCardOptions === null || hiddenCardOptions === void 0 ? void 0 : hiddenCardOptions.includes('share'),
                    onPress: onShare,
                },
                {
                    title: (0, i18n_1.i18n)('common.download'),
                    hidden: (0, lodash_1.isNil)(onDownload) || isLowCodeCard || (hiddenCardOptions === null || hiddenCardOptions === void 0 ? void 0 : hiddenCardOptions.includes('download')),
                    onPress: onDownload,
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.cardHistory'),
                    hidden: (0, lodash_1.isNil)(channelSlug) || (hiddenCardOptions === null || hiddenCardOptions === void 0 ? void 0 : hiddenCardOptions.includes('cardHistory')),
                    onPress: function () {
                        navigate('CardHistory', { cardId: cardId, channelSlug: channelSlug !== null && channelSlug !== void 0 ? channelSlug : '' });
                    },
                },
                {
                    title: (0, i18n_1.i18n)('card.bulkUpdate.menu.menuItem'),
                    hidden: !showBulkUpdateItem,
                    onPress: function () { return navigateToBulkUpdate(anchor); },
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.print'),
                    hidden: !printContext || (hiddenCardOptions === null || hiddenCardOptions === void 0 ? void 0 : hiddenCardOptions.includes('print')),
                    onPress: function () {
                        analytics_1.MBAnalytics.track('PrintCard', { cardId: card.id, channelSlug: channel === null || channel === void 0 ? void 0 : channel.slug });
                        printContext.onPrint();
                    },
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.selectMultiple'),
                    hidden: !cardSelectionEnabled || (hiddenCardOptions === null || hiddenCardOptions === void 0 ? void 0 : hiddenCardOptions.includes('selectMultiple')),
                    onPress: function () {
                        addCard(card);
                        setSelectionActive(true);
                    },
                },
                {
                    title: (0, i18n_1.i18n)('common.delete'),
                    destructive: true,
                    hidden: !canDeleteCard.enabled || (hiddenCardOptions === null || hiddenCardOptions === void 0 ? void 0 : hiddenCardOptions.includes('delete')),
                    onPress: function () { return removeCardWithConfirmation(anchor); },
                },
            ],
        });
    }, [
        onOpen,
        onClose,
        canUpdateCard.enabled,
        navigateToBulkUpdate,
        canCreateCard.enabled,
        onShare,
        onDownload,
        isLowCodeCard,
        printContext,
        cardSelectionEnabled,
        canDeleteCard.enabled,
        duplicateCard,
        card,
        channel === null || channel === void 0 ? void 0 : channel.slug,
        addCard,
        setSelectionActive,
        removeCardWithConfirmation,
        showBulkUpdateItem,
        hiddenCardOptions,
        navigate,
        cardId,
        channelSlug,
    ]);
}
