"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNativeCompatibleCollectionViewTemplate = useNativeCompatibleCollectionViewTemplate;
var lodash_1 = require("lodash");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var constants_1 = require("./constants");
function useNativeCompatibleCollectionViewTemplate(_a) {
    var _b;
    var workflowTemplateId = _a.workflowTemplateId;
    var _c = (0, hooks_1.useViewTemplates)({
        workflowTemplateId: workflowTemplateId,
        type: 'COLLECTION',
    }), viewTemplates = _c.viewTemplates, loading = _c.loading;
    return {
        viewTemplate: (_b = viewTemplates === null || viewTemplates === void 0 ? void 0 : viewTemplates.find(function (t) { return !(0, lodash_1.isNil)(t.subtype) && !constants_1.UNSUPPORTED_NATIVE_TEMPLATES.includes(t.subtype); })) !== null && _b !== void 0 ? _b : null,
        loading: loading,
    };
}
