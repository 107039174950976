"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.oldCardDraftRegex = void 0;
exports.getCardDraft = getCardDraft;
exports.saveCardDraft = saveCardDraft;
exports.setExpirationForOldCardDrafts = setExpirationForOldCardDrafts;
var lodash_1 = require("lodash");
var storage_1 = require("@shared/util/storage");
var TTLStorage_1 = require("@shared/util/storage/TTLStorage");
var ONE_DAY_TTL = 1000 * 60 * 60 * 24;
function getCardDraft(channelId, cardId) {
    return __awaiter(this, void 0, void 0, function () {
        var key;
        return __generator(this, function (_a) {
            key = getCardDraftStorageKey(channelId, cardId);
            return [2 /*return*/, TTLStorage_1.TTLStorage.get(key)];
        });
    });
}
function saveCardDraft(cardFields, channelId, cardId) {
    return __awaiter(this, void 0, void 0, function () {
        var key;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    key = getCardDraftStorageKey(channelId, cardId);
                    return [4 /*yield*/, TTLStorage_1.TTLStorage.set(key, cardFields, ONE_DAY_TTL)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
function getCardDraftStorageKey(channelId, cardId) {
    return "card-draft-".concat(channelId, "-").concat(cardId !== null && cardId !== void 0 ? cardId : 'new');
}
/**
 * setExpirationForOldCardDrafts
 *
 * This function is used to set the expiration for old card drafts. It's called
 * on startup to ensure that old card drafts are not stored indefinitely. It
 * searches for old keys in localstorage that match the pattern
 * {channelId}-{create|update}-{cardId?}
 *
 * It then attempts to save the card draft to the new storage format with a
 * one-day TTL and remove the old key if successful.
 */
exports.oldCardDraftRegex = /(\d+)-(create|update)-([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?/;
function setExpirationForOldCardDrafts() {
    return __awaiter(this, void 0, void 0, function () {
        var keys, draftKeys, _i, draftKeys_1, key, matches, _a, channelId, cardId, value, numericChannelId, parsed, stored, wasStoredCorrectly, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, storage_1.MBStorage.keys()];
                case 1:
                    keys = _b.sent();
                    draftKeys = keys.filter(function (key) { return exports.oldCardDraftRegex.test(key); });
                    _i = 0, draftKeys_1 = draftKeys;
                    _b.label = 2;
                case 2:
                    if (!(_i < draftKeys_1.length)) return [3 /*break*/, 11];
                    key = draftKeys_1[_i];
                    matches = key.match(exports.oldCardDraftRegex);
                    _a = matches !== null && matches !== void 0 ? matches : [], channelId = _a[1], cardId = _a[3];
                    return [4 /*yield*/, storage_1.MBStorage.get(key)];
                case 3:
                    value = _b.sent();
                    if ((0, lodash_1.isNil)(channelId) || (0, lodash_1.isNil)(value)) {
                        return [3 /*break*/, 10];
                    }
                    _b.label = 4;
                case 4:
                    _b.trys.push([4, 9, , 10]);
                    numericChannelId = Number(channelId);
                    parsed = JSON.parse(value);
                    return [4 /*yield*/, saveCardDraft(parsed, numericChannelId, cardId)];
                case 5:
                    _b.sent();
                    return [4 /*yield*/, getCardDraft(numericChannelId, cardId)];
                case 6:
                    stored = _b.sent();
                    wasStoredCorrectly = (0, lodash_1.isEqual)(parsed, stored);
                    if (!wasStoredCorrectly) return [3 /*break*/, 8];
                    return [4 /*yield*/, storage_1.MBStorage.remove(key)];
                case 7:
                    _b.sent();
                    _b.label = 8;
                case 8: return [3 /*break*/, 10];
                case 9:
                    e_1 = _b.sent();
                    console.error(e_1);
                    return [3 /*break*/, 10];
                case 10:
                    _i++;
                    return [3 /*break*/, 2];
                case 11: return [2 /*return*/];
            }
        });
    });
}
