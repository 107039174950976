"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowLookupComponentLowCode = WorkflowLookupComponentLowCode;
exports.WorkflowLookupComponent = WorkflowLookupComponent;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var form_1 = require("@shared/components/blocks/contexts/form");
var render_1 = require("@shared/components/blocks/contexts/render");
var template_1 = require("@shared/components/blocks/contexts/template");
var notNil_1 = require("@shared/util/notNil");
var hooks_1 = require("../../hooks");
var WorkflowAddressComponent_1 = require("./WorkflowAddressComponent");
var WorkflowDateComponent_1 = require("./WorkflowDateComponent");
var WorkflowEmailComponent_1 = require("./WorkflowEmailComponent");
var WorkflowNumberComponent_1 = require("./WorkflowNumberComponent");
var WorkflowTagComponent_1 = require("./WorkflowTagComponent");
var WorkflowTextComponent_1 = require("./WorkflowTextComponent");
function WorkflowLookupComponentLowCode(props) {
    var _a;
    var fields = (0, react_1.useContext)(form_1.CardFormContext).values;
    var template = (0, react_1.useContext)(template_1.CardTemplateContext).template;
    var renderContext = (0, react_1.useContext)(render_1.CardRenderContext);
    var relatedCardComponent = (0, hooks_1.isRelatedCardLookupComponent)(props.component)
        ? fields[props.component.relatedCardComponentId]
        : null;
    var lookupEntityIds = relatedCardComponent === null || relatedCardComponent === void 0 ? void 0 : relatedCardComponent.map(function (c) { return c.id; });
    var isExternalForm = ['nocode-public-create', 'nocode-public-readonly'].includes((_a = template === null || template === void 0 ? void 0 : template.context) !== null && _a !== void 0 ? _a : '') ||
        (template === null || template === void 0 ? void 0 : template.context) === 'create-external';
    var isCollectionView = ['list', 'table'].includes(renderContext);
    return (react_1.default.createElement(WorkflowLookupComponent, __assign({}, props, { lookupEntityIds: lookupEntityIds, isExternalForm: isExternalForm, isCollectionView: isCollectionView })));
}
function WorkflowLookupComponent(_a) {
    var component = _a.component, showLabel = _a.showLabel, showIcon = _a.showIcon, paddingHorizontal = _a.paddingHorizontal, iconPad = _a.iconPad, overrides = _a.overrides, lookupEntityIds = _a.lookupEntityIds, isExternalForm = _a.isExternalForm, isCollectionView = _a.isCollectionView;
    if (!(0, hooks_1.isRelatedCardLookupComponent)(component)) {
        throw new Error('Component is not a related card lookup component');
    }
    var _b = (0, hooks_1.useWorkflowEntities)(component.lookupWorkflowTemplateId, lookupEntityIds), relatedCardsWorkflowTemplate = _b.workflowTemplate, relatedCards = _b.workflowEntities;
    var relatedCardsInDisplayOrder = lookupEntityIds === null || lookupEntityIds === void 0 ? void 0 : lookupEntityIds.map(function (c) { return relatedCards.find(function (r) { return r.id === c; }); }).filter(function (c) { return !(0, lodash_1.isNil)(c); });
    var rawValues = relatedCardsInDisplayOrder === null || relatedCardsInDisplayOrder === void 0 ? void 0 : relatedCardsInDisplayOrder.map(function (c) { var _a; return (_a = c.fields) === null || _a === void 0 ? void 0 : _a[component.lookupComponentId]; }).filter(notNil_1.notNil);
    var lookupComponent = relatedCardsWorkflowTemplate === null || relatedCardsWorkflowTemplate === void 0 ? void 0 : relatedCardsWorkflowTemplate.components.find(function (c) { return c.id === component.lookupComponentId; });
    if (!(0, hooks_1.isInputComponent)(component) ||
        (((0, lodash_1.isNil)(rawValues) || rawValues.length === 0) && isCollectionView) ||
        isExternalForm ||
        (0, lodash_1.isNil)(lookupComponent)) {
        return null;
    }
    var label = !(0, lodash_1.isNil)(overrides) && 'label' in overrides && !(0, lodash_1.isNil)(overrides === null || overrides === void 0 ? void 0 : overrides.label)
        ? overrides.label
        : component.label;
    var icon = !(0, lodash_1.isNil)(overrides) && 'icon' in overrides && !(0, lodash_1.isNil)(overrides === null || overrides === void 0 ? void 0 : overrides.icon)
        ? overrides.icon
        : component.icon;
    var displayComponentValues = {
        label: label,
        icon: icon,
        placeholder: component.placeholder,
        readonly: component.readonly,
    };
    var displayComponent = __assign(__assign({}, lookupComponent), displayComponentValues);
    if ((0, hooks_1.isTextComponent)(displayComponent)) {
        return (react_1.default.createElement(WorkflowTextComponent_1.WorkflowTextComponent, { initialData: rawValues, component: displayComponent, showLabel: showLabel, paddingHorizontal: paddingHorizontal, showIcon: showIcon, iconPad: iconPad }));
    }
    if ((0, hooks_1.isNumberComponent)(displayComponent)) {
        return (react_1.default.createElement(WorkflowNumberComponent_1.WorkflowNumberComponent, { initialData: rawValues, component: displayComponent, showLabel: showLabel, showIcon: showIcon, paddingHorizontal: paddingHorizontal, iconPad: iconPad }));
    }
    if ((0, hooks_1.isDateComponent)(displayComponent)) {
        return (react_1.default.createElement(WorkflowDateComponent_1.WorkflowDateComponent, { initialData: rawValues, component: displayComponent, showLabel: showLabel, showIcon: showIcon, paddingHorizontal: paddingHorizontal, iconPad: iconPad }));
    }
    if ((0, hooks_1.isEmailComponent)(displayComponent)) {
        return (react_1.default.createElement(WorkflowEmailComponent_1.LookupEmailComponent, { initialData: rawValues, component: displayComponent, showLabel: showLabel, showIcon: showIcon, paddingHorizontal: paddingHorizontal, iconPad: iconPad }));
    }
    if ((0, hooks_1.isAddressComponent)(displayComponent)) {
        return (react_1.default.createElement(WorkflowAddressComponent_1.WorkflowAddressComponent, { initialData: rawValues, component: displayComponent, showLabel: showLabel, showIcon: showIcon, paddingHorizontal: paddingHorizontal, iconPad: iconPad }));
    }
    if ((0, hooks_1.isTagComponent)(displayComponent)) {
        return (react_1.default.createElement(WorkflowTagComponent_1.WorkflowTagComponent, { initialData: rawValues, component: displayComponent, showLabel: showLabel, showIcon: showIcon, paddingHorizontal: paddingHorizontal, iconPad: iconPad }));
    }
    if (!(0, lodash_1.isNil)(displayComponent === null || displayComponent === void 0 ? void 0 : displayComponent.type)) {
        console.error('Unsupported lookup component type', displayComponent === null || displayComponent === void 0 ? void 0 : displayComponent.type);
    }
    return null;
}
