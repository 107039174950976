"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.didFlingUp = didFlingUp;
exports.didFlingDown = didFlingDown;
exports.useSwipeGesture = useSwipeGesture;
var react_1 = require("react");
var react_native_1 = require("react-native");
var config_1 = require("@shared/config");
var hooks_1 = require("@shared/util/hooks");
function didFlingUp(vy) {
    return vy < -config_1.Config.GESTURE_EXIT_VELOCITY;
}
function didFlingDown(vy) {
    return vy > config_1.Config.GESTURE_EXIT_VELOCITY;
}
var GESTURE_START_HORIZONTAL_TOLERANCE = 4;
function useSwipeGesture(initialTop, fullTop, onSwipeClose, onStartSwipe, initialSize) {
    var keyboardVisible = (0, hooks_1.useKeyboardVisible)();
    var mode = (0, react_1.useRef)('half');
    var top = (0, react_1.useRef)(new react_native_1.Animated.Value(initialTop)).current;
    var toFull = (0, react_1.useCallback)(function () {
        react_native_1.Animated.spring(top, { toValue: fullTop, useNativeDriver: false }).start(function () {
            mode.current = 'full';
        });
    }, [top, fullTop]);
    var toFullFast = (0, react_1.useCallback)(function () {
        react_native_1.Animated.spring(top, { toValue: fullTop, speed: 24, useNativeDriver: false }).start(function () {
            mode.current = 'full';
        });
    }, [top, fullTop]);
    var toHalf = (0, react_1.useCallback)(function () {
        react_native_1.Keyboard.dismiss();
        react_native_1.Animated.spring(top, { toValue: initialTop, useNativeDriver: false }).start(function () {
            mode.current = 'half';
        });
    }, [top, initialTop]);
    (0, react_1.useEffect)(function () {
        if (keyboardVisible) {
            toFullFast();
        }
    }, [keyboardVisible, toFullFast]);
    (0, react_1.useEffect)(function () {
        if (initialSize === 'full') {
            toFull();
        }
    }, [initialSize, toFull]);
    var panResponder = (0, react_1.useRef)(react_native_1.PanResponder.create({
        onStartShouldSetPanResponder: function () { return true; },
        onMoveShouldSetPanResponder: function (_event, gestureState) {
            return Math.abs(gestureState.dy) >= 1 &&
                Math.abs(gestureState.dx) < GESTURE_START_HORIZONTAL_TOLERANCE;
        },
        onPanResponderGrant: function () {
            onStartSwipe();
            top.stopAnimation();
            top.setOffset(top._value);
        },
        onPanResponderMove: function (_event, gestureState) { return top.setValue(gestureState.dy); },
        onPanResponderRelease: function (_event, gestureState) {
            var dy = gestureState.dy, vy = gestureState.vy;
            if (dy === 0 && vy === 0) {
                return;
            }
            top.flattenOffset();
            if (mode.current === 'half') {
                if (dy < -initialTop / 2 || didFlingUp(vy)) {
                    // HALF -> FULL
                    toFull();
                }
                else if (dy > initialTop / 2 || didFlingDown(vy)) {
                    // HALF -> CLOSE
                    onSwipeClose();
                }
                else {
                    // HALF -> RESET
                    toHalf();
                }
            }
            else if (mode.current === 'full') {
                if (dy > initialTop) {
                    // FULL -> CLOSE
                    onSwipeClose();
                }
                else if (dy > initialTop / 2 || didFlingDown(vy)) {
                    // FULL -> HALF
                    toHalf();
                }
                else {
                    // FULL -> RESET
                    toFull();
                }
            }
        },
    })).current;
    return {
        panHandlers: panResponder.panHandlers,
        top: top,
    };
}
