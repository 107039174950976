/* @flow */
import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Button from '@src/components/Button';
import CloseIcon from '@src/components/ChatInput/CloseIcon';
import PureComponent from '@src/components/PureComponent';
import RoundIcon from '@src/components/RoundIcon';
import { actions } from '@src/core/Router';
import type { StoreState } from '@src/types/StoreTypes';
import businessPlusURL from './BusinessPlus.svg';
import styles from './NoBusinessContainer.css';

type Props = {
  currentBusinessId: ?number,
};

type State = {
  showModal: boolean,
};

class NoBusinessContainer extends PureComponent {
  props: Props;
  state: State = {
    showModal: true,
  };

  componentDidMount() {
    // This page is for when you don't have any business.
    if (this.props.currentBusinessId) {
      window.location = '/';
    }
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleGoToCreateBusiness = () => {
    actions.pushRoute('/newbusiness');
  };

  render() {
    return (
      <div className={styles.root}>
        <Modal
          className={styles.modal}
          overlayClassName={styles.overlay}
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          contentLabel="You no longer belong to a business!"
          shouldCloseOnOverlayClick={true}
        >
          <div className={styles.buttonWrapper}>
            <button type="button" className={styles.closeButton} onClick={this.handleCloseModal}>
              <CloseIcon />
            </button>
          </div>
          <div className={styles.content}>
            <RoundIcon url={businessPlusURL} />
            <div className={styles.text}>You no longer belong to a business!</div>
            <Button btnStyle="primary" btnSize="large" onClick={this.handleGoToCreateBusiness}>
              Create or Join a Business
            </Button>
          </div>
        </Modal>
        <h3 className={styles.header}>More about Coast</h3>
        <div className={styles.videoRow}>
          <iframe
            className={styles.video}
            title="Coast Introduction Video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/RBF9o6eVBkA?rel=0&amp;showinfo=0"
            frameBorder="0"
            allowFullScreen="allowFullScreen"
          />
        </div>
        <Button btnStyle="primary" btnSize="large" onClick={this.handleGoToCreateBusiness}>
          Create or Join a Business
        </Button>
      </div>
    );
  }
}

const withState = connect((state: StoreState) => ({
  currentBusinessId: state.appState.currentBusinessId,
}));

export default withState(NoBusinessContainer);
