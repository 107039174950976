"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBulkActionsNav = useBulkActionsNav;
var react_1 = require("react");
var navigation_1 = require("@shared/util/navigation");
var BulkActionsRoute;
(function (BulkActionsRoute) {
    BulkActionsRoute[BulkActionsRoute["main"] = 0] = "main";
    BulkActionsRoute[BulkActionsRoute["duplicateToDate"] = 1] = "duplicateToDate";
})(BulkActionsRoute || (BulkActionsRoute = {}));
// Note: This hook will return a new nav instance for each component that calls
// it. So best to call it once from your parent component and then pass the nav
// down as a prop to any children.
function useBulkActionsNav() {
    var nav = (0, navigation_1.useNavStack)([{ name: BulkActionsRoute.main }]);
    var isMainRoute = nav.currentRoute.name === BulkActionsRoute.main;
    var isDuplicateToRoute = nav.currentRoute.name === BulkActionsRoute.duplicateToDate;
    var showMainRoute = (0, react_1.useCallback)(function () { return nav.push({ name: BulkActionsRoute.main }); }, [nav]);
    var showDuplicateToRoute = (0, react_1.useCallback)(function () { return nav.push({ name: BulkActionsRoute.duplicateToDate }); }, [nav]);
    return (0, react_1.useMemo)(function () { return ({ nav: nav, isMainRoute: isMainRoute, isDuplicateToRoute: isDuplicateToRoute, showMainRoute: showMainRoute, showDuplicateToRoute: showDuplicateToRoute }); }, [nav, isMainRoute, isDuplicateToRoute, showMainRoute, showDuplicateToRoute]);
}
