"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useViewComponents = useViewComponents;
var lodash_1 = require("lodash");
var react_1 = require("react");
var useVisibleComponents_1 = require("@shared/scenes/entity/util/useVisibleComponents");
function useViewComponents(_a) {
    var workflowTemplate = _a.workflowTemplate, viewTemplate = _a.viewTemplate, _b = _a.includeServerManagedComponents, includeServerManagedComponents = _b === void 0 ? true : _b, _c = _a.includeInputComponentsOnly, includeInputComponentsOnly = _c === void 0 ? false : _c, _d = _a.isReadOnly, isReadOnly = _d === void 0 ? false : _d, fields = _a.fields;
    var visibleComponents = (0, useVisibleComponents_1.useVisibleComponents)({
        workflowTemplate: workflowTemplate,
        viewTemplate: viewTemplate,
        includeServerManagedComponents: includeServerManagedComponents,
        includeInputComponentsOnly: includeInputComponentsOnly,
    });
    return (0, react_1.useMemo)(function () {
        return visibleComponents
            .map(function (component) {
            var _a;
            var viewOptions = (_a = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (option) { return option.componentId === component.id; });
            var componentReadOnly = 'readonly' in component ? component.readonly : undefined;
            var overrideReadOnly = !(0, lodash_1.isNil)(viewOptions === null || viewOptions === void 0 ? void 0 : viewOptions.overrides) && 'readonly' in viewOptions.overrides
                ? viewOptions.overrides.readonly
                : undefined;
            if (!(0, lodash_1.isNil)(viewOptions === null || viewOptions === void 0 ? void 0 : viewOptions.overrides)) {
                var preparedOverrides_1 = {};
                Object.entries(viewOptions.overrides).forEach(function (_a) {
                    var key = _a[0], value = _a[1];
                    if (value !== null && key !== '__typename') {
                        preparedOverrides_1[key] = value;
                    }
                });
                return __assign(__assign(__assign({}, component), preparedOverrides_1), { readonly: isReadOnly || componentReadOnly || overrideReadOnly });
            }
            return __assign(__assign({}, component), { readonly: isReadOnly || componentReadOnly || overrideReadOnly });
        })
            // side effect of this is we don't show non-input components like button or referenced in
            .filter(function (component) {
            var _a;
            if (isReadOnly) {
                return (!(0, lodash_1.isNil)(fields === null || fields === void 0 ? void 0 : fields[component.id]) &&
                    !(0, lodash_1.isEmpty)(fields === null || fields === void 0 ? void 0 : fields[component.id]) &&
                    !((_a = fields === null || fields === void 0 ? void 0 : fields[component.id]) === null || _a === void 0 ? void 0 : _a.isEmpty));
            }
            return true;
        });
    }, [visibleComponents, viewTemplate, isReadOnly, fields]);
}
