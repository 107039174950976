"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.keyExtractor = keyExtractor;
exports.MBAutoAddChannels = MBAutoAddChannels;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var modal_1 = require("@shared/components/multiSelect/modal");
var typography_1 = require("@shared/components/typography");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
function keyExtractor(channel) {
    return String(channel.id);
}
function MBAutoAddChannels(_a) {
    var channels = _a.channels, selectedChannels = _a.selectedChannels, onSelectedChannels = _a.onSelectedChannels;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var match = (0, react_1.useCallback)(function (channel, query) {
        var lowercaseQuery = query.toLowerCase();
        return channel.name.toLowerCase().includes(lowercaseQuery);
    }, []);
    var onRemoveChannel = (0, react_1.useCallback)(function (channel) {
        onSelectedChannels(selectedChannels.filter(function (_a) {
            var id = _a.id;
            return id !== channel.id;
        }));
    }, [onSelectedChannels, selectedChannels]);
    var addMessage = !(selectedChannels === null || selectedChannels === void 0 ? void 0 : selectedChannels.length)
        ? (0, i18n_1.i18n)('business.settings.autoAddWorkspaces.addWorkspace')
        : (0, i18n_1.i18n)('business.settings.autoAddWorkspaces.addAnotherWorkspace');
    return (react_1.default.createElement(modal_1.MBMultiSelectModal, { label: (0, i18n_1.i18n)('search.results.channels'), onChange: onSelectedChannels, renderItem: function (channel) { return react_1.default.createElement(components_1.MBChannelRow, { channel: channel, subtitle: react_1.default.createElement(react_1.default.Fragment, null) }); }, values: channels, selectedValues: selectedChannels, match: match, keyExtractor: keyExtractor },
        react_1.default.createElement(components_1.MBView, { paddingRight: 1 },
            selectedChannels.map(function (channel) { return (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, key: channel.id },
                react_1.default.createElement(components_1.MBView, { flex: true },
                    react_1.default.createElement(components_1.MBChannelRow, { channel: channel, subtitle: react_1.default.createElement(react_1.default.Fragment, null) })),
                react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: function () { return onRemoveChannel(channel); } },
                    react_1.default.createElement(components_1.MBIcon, { color: colors.input.borderInactive, source: require('@shared/assets/icons/clear.png') })))); }),
            react_1.default.createElement(components_1.MBView, { padding: 2 },
                react_1.default.createElement(typography_1.MBTextBody1Bold, null, addMessage)))));
}
