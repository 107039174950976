"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCollectionViewTemplate = useCollectionViewTemplate;
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_native_1 = require("react-native");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var constants_1 = require("./constants");
var useCachedTemplateSlug_1 = require("./useCachedTemplateSlug");
function useCollectionViewTemplate(_a) {
    var _b, _c;
    var workflowTemplateId = _a.workflowTemplateId, channelSlug = _a.channelSlug, templateSlug = _a.templateSlug;
    var isNative = react_native_1.Platform.OS !== 'web';
    var cachedTemplateSlug = (0, useCachedTemplateSlug_1.useCachedTemplateSlug)(channelSlug, templateSlug);
    var _d = (0, react_1.useState)(!(0, lodash_1.isNil)(templateSlug) || !(0, lodash_1.isNil)(cachedTemplateSlug)), isQueryingSpecificTemplate = _d[0], setIsQueryingSpecificTemplate = _d[1];
    var _e = (0, hooks_1.useViewTemplates)({
        workflowTemplateId: workflowTemplateId,
        type: 'COLLECTION',
        skip: isQueryingSpecificTemplate,
    }), firstPageOfAllCollectionViewTemplates = _e.viewTemplates, loadingViewTemplates = _e.loading;
    var _f = (0, hooks_1.useViewTemplate)(workflowTemplateId, templateSlug !== null && templateSlug !== void 0 ? templateSlug : cachedTemplateSlug), selectedViewTemplate = _f.viewTemplate, loadingViewTemplate = _f.loading;
    (0, react_1.useEffect)(function () {
        // triggers the list query because the selected template was likely deleted
        if ((0, lodash_1.isNil)(selectedViewTemplate) && !loadingViewTemplate) {
            setIsQueryingSpecificTemplate(false);
        }
    }, [selectedViewTemplate, loadingViewTemplate]);
    var loading = loadingViewTemplate || loadingViewTemplates;
    var firstCollectionViewTemplate = firstPageOfAllCollectionViewTemplates === null || firstPageOfAllCollectionViewTemplates === void 0 ? void 0 : firstPageOfAllCollectionViewTemplates[0];
    // native falls back to a compatible template if no selected template is loaded,
    // web just uses the first template since all are supported
    var defaultViewTemplate = isNative
        ? (_b = firstPageOfAllCollectionViewTemplates === null || firstPageOfAllCollectionViewTemplates === void 0 ? void 0 : firstPageOfAllCollectionViewTemplates.find(function (t) { return !(0, lodash_1.isNil)(t.subtype) && !constants_1.UNSUPPORTED_NATIVE_TEMPLATES.includes(t.subtype); })) !== null && _b !== void 0 ? _b : firstCollectionViewTemplate
        : firstCollectionViewTemplate;
    return {
        viewTemplate: (_c = selectedViewTemplate !== null && selectedViewTemplate !== void 0 ? selectedViewTemplate : defaultViewTemplate) !== null && _c !== void 0 ? _c : null,
        loading: loading,
    };
}
