"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBundleInfo = useBundleInfo;
exports.useDevMode = useDevMode;
// @ts-strict-ignore
var react_1 = require("react");
var hooks_1 = require("@shared/scenes/developerMenu/hooks");
var hooks_2 = require("@shared/util/featureFlags/hooks");
function useBundleInfo() {
    return null;
}
function useDevMode(numTaps) {
    var _a = (0, react_1.useState)(0), tapCount = _a[0], setTapCount = _a[1];
    var _b = (0, hooks_2.useFeatureFlag)(hooks_1.DEV_TOOLS_FEATURE_FLAG), enabled = _b.enabled, toggle = _b.toggle;
    (0, react_1.useEffect)(function () {
        if (tapCount === numTaps && !enabled) {
            toggle();
        }
    }, [tapCount, numTaps, enabled, toggle]);
    var incrementTapCount = (0, react_1.useMemo)(function () {
        if (!enabled) {
            return function () { return setTapCount(tapCount + 1); };
        }
        return null;
    }, [tapCount, enabled]);
    return [false, incrementTapCount];
}
