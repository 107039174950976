"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useListUsers = useListUsers;
exports.useListUsersById = useListUsersById;
exports.useListUsersByName = useListUsersByName;
exports.useSortedUsersWithoutMe = useSortedUsersWithoutMe;
exports.getSortedUsersWithoutMe = getSortedUsersWithoutMe;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
function useListUsers(_a) {
    var _b, _c;
    var input = _a.input, customCacheKey = _a.customCacheKey, skip = _a.skip;
    var _d = (0, client_1.useQuery)(api_1.Queries.user.listUsers, { variables: { input: input, customCacheKey: customCacheKey }, skip: skip }), data = _d.data, loading = _d.loading, error = _d.error, fetchMore = _d.fetchMore;
    var ids = input.ids, name = input.name, limit = input.limit;
    var loadMore = (0, react_1.useCallback)(function () {
        var _a, _b;
        if (!(0, lodash_1.isNil)((_a = data === null || data === void 0 ? void 0 : data.listUsers) === null || _a === void 0 ? void 0 : _a.users) && ((_b = data === null || data === void 0 ? void 0 : data.listUsers) === null || _b === void 0 ? void 0 : _b.hasMore)) {
            var offset = data.listUsers.users.length;
            fetchMore({
                variables: {
                    input: { ids: ids, name: name, limit: limit, offset: offset },
                    customCacheKey: customCacheKey,
                },
            });
        }
    }, [data === null || data === void 0 ? void 0 : data.listUsers, fetchMore, ids, limit, name, customCacheKey]);
    return (0, react_1.useMemo)(function () {
        var _a, _b, _c, _d;
        return ({
            users: (_b = (_a = data === null || data === void 0 ? void 0 : data.listUsers) === null || _a === void 0 ? void 0 : _a.users) !== null && _b !== void 0 ? _b : [],
            hasMore: (_d = (_c = data === null || data === void 0 ? void 0 : data.listUsers) === null || _c === void 0 ? void 0 : _c.hasMore) !== null && _d !== void 0 ? _d : false,
            loadMore: loadMore,
            loading: loading,
            error: error,
        });
    }, [(_b = data === null || data === void 0 ? void 0 : data.listUsers) === null || _b === void 0 ? void 0 : _b.users, loadMore, loading, error, (_c = data === null || data === void 0 ? void 0 : data.listUsers) === null || _c === void 0 ? void 0 : _c.hasMore]);
}
function useListUsersById(ids) {
    var input = { ids: ids };
    var skip = (0, lodash_1.isEmpty)(ids);
    return useListUsers({ input: input, skip: skip });
}
function useListUsersByName(name) {
    return useListUsers({ input: { name: name.trim() } });
}
function useSortedUsersWithoutMe(users) {
    return (0, react_1.useMemo)(function () { return getSortedUsersWithoutMe(users); }, [users]);
}
function getSortedUsersWithoutMe(users) {
    return users.filter(function (user) { return !user.isMe; }).sort(function (a, b) { return a.fullName.localeCompare(b.fullName); });
}
