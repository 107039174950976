"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateEditField = DateEditField;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var rrule_1 = require("rrule");
var components_1 = require("@shared/components");
var scroll_1 = require("@shared/components/blocks/contexts/scroll");
var hooks_1 = require("@shared/components/blocks/lib/inputTodo/hooks");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var comboDatePicker_1 = require("@shared/components/dateAndTime/comboDatePicker");
var DateField_1 = require("@shared/data/entity/fields/DateField");
var BatchContext_1 = require("@shared/scenes/cards/entityBulkActions/BatchCreateContext/BatchContext");
var divider_1 = require("@shared/scenes/workflows/edit/components/optionsMenu/divider");
var styles_1 = require("@shared/styles");
var hooks_2 = require("@shared/util/hooks");
var EditFieldContainer_1 = require("../EditFieldContainer");
var BatchSchedule_1 = require("./BatchSchedule");
var RepeatText_1 = require("./RepeatText");
var RepeatTextButton_1 = require("./RepeatTextButton");
var useBatchRule_1 = require("./hooks/useBatchRule");
var useEntityBatch_1 = require("./hooks/useEntityBatch");
var utils_1 = require("./utils");
function DateEditFieldPicker(_a) {
    var _b;
    var field = _a.field, component = _a.component, updateField = _a.updateField, onClose = _a.onClose, containerRef = _a.containerRef, repeatPresets = _a.repeatPresets, filteredRepeatPresets = _a.filteredRepeatPresets, repeatCount = _a.repeatCount, setRepeatCount = _a.setRepeatCount, repeatUntil = _a.repeatUntil, setRepeatUntil = _a.setRepeatUntil, repeatEndType = _a.repeatEndType, setRepeatEndType = _a.setRepeatEndType, presetIndexSelected = _a.presetIndexSelected, setPresetIndexSelected = _a.setPresetIndexSelected, isCustomRepeatSelected = _a.isCustomRepeatSelected, resetBatchOptions = _a.resetBatchOptions;
    var _c = (0, BatchContext_1.useBatch)(), setRruleOptions = _c.setRruleOptions, rruleOptions = _c.rruleOptions, canBatchCreate = _c.canBatchCreate;
    var isBatchRuleEnabled = canBatchCreate && component.allowEntityBatchCreation;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var isDesktop = (0, hooks_2.useIsDesktop)();
    var isMobile = (0, hooks_2.useIsMobile)();
    var _d = (0, react_1.useState)(!(0, lodash_1.isNil)(rruleOptions) && !!isBatchRuleEnabled && !isMobile), showRepeatOptions = _d[0], setShowRepeatOptions = _d[1];
    var _e = (0, react_1.useState)(false), showingCustomOptions = _e[0], setShowingCustomOptions = _e[1];
    var isShowingMobileRepeatOptions = showRepeatOptions && isMobile;
    var scrollViewRef = (0, scroll_1.useCardBlockScrollContext)();
    // if no rule yet, set as first preset
    (0, react_1.useEffect)(function () {
        if (!(0, lodash_1.isNil)(rruleOptions) ||
            isCustomRepeatSelected ||
            !showRepeatOptions ||
            !isBatchRuleEnabled) {
            return;
        }
        setRruleOptions(filteredRepeatPresets[presetIndexSelected]);
    }, [
        rruleOptions,
        setRruleOptions,
        presetIndexSelected,
        filteredRepeatPresets,
        isCustomRepeatSelected,
        showRepeatOptions,
        isBatchRuleEnabled,
    ]);
    var onUpdateDate = (0, react_1.useCallback)(function (newDate) {
        updateField(component.id, !(0, lodash_1.isNil)(newDate.start) ? new DateField_1.DateField(newDate.start) : null);
        if ((0, lodash_1.isNil)(newDate.start)) {
            resetBatchOptions();
            setShowRepeatOptions(false);
        }
    }, [component.id, updateField, resetBatchOptions]);
    var renderTitleBar = (0, react_1.useCallback)(function () {
        var _a;
        if (isDesktop) {
            return null;
        }
        if (isShowingMobileRepeatOptions && !showingCustomOptions) {
            return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: "Repeat", leftIcon: "arrow-point-left", leftIconColor: styles_1.Colors.v2.purple, onLeftIconPress: function () { return setShowRepeatOptions(false); } }));
        }
        if (isShowingMobileRepeatOptions && showingCustomOptions) {
            return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: "Custom Repeat", leftIcon: "arrow-point-left", leftIconColor: styles_1.Colors.v2.purple, onLeftIconPress: function () { return setShowingCustomOptions(false); } }));
        }
        return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: (_a = component.label) !== null && _a !== void 0 ? _a : '', rightIcon: "exit", rightIconColor: colors.foregroundActive, onRightIconPress: onClose }));
    }, [
        colors.foregroundActive,
        component.label,
        onClose,
        isDesktop,
        isShowingMobileRepeatOptions,
        showingCustomOptions,
    ]);
    var handleClearRepeat = function () {
        if (isBatchRuleEnabled) {
            setShowRepeatOptions(false);
            resetBatchOptions();
        }
    };
    var handleAddRepeat = function () {
        setShowRepeatOptions(true);
    };
    var handleScrollViewLayout = (0, react_1.useCallback)(function () {
        if (react_native_1.Platform.OS === 'ios') {
            (0, hooks_1.scrollToKeyboard)(scrollViewRef === null || scrollViewRef === void 0 ? void 0 : scrollViewRef.current);
        }
    }, [scrollViewRef]);
    var showHighlightDates = !(0, lodash_1.isNil)(rruleOptions) && component.allowEntityBatchCreation && canBatchCreate;
    return (react_1.default.createElement(components_1.MBBottomSheet, { initialSize: "full", onClose: onClose, renderTitleBar: renderTitleBar, containerRef: containerRef, layout: {
            offset: { x: isDesktop ? (0, components_1.grid)(10) : (0, components_1.grid)(2), y: 0 },
            minHeight: 'fit-content',
        } },
        react_1.default.createElement(react_native_1.ScrollView, { keyboardShouldPersistTaps: "always", ref: scrollViewRef, onLayout: handleScrollViewLayout, contentContainerStyle: {
                width: '100%',
                alignItems: 'flex-start',
                paddingBottom: isMobile ? (0, components_1.grid)(4) : undefined,
                flexGrow: 1,
                flexShrink: 0,
                flexBasis: 'auto',
            } },
            (isDesktop || !isShowingMobileRepeatOptions) && (react_1.default.createElement(comboDatePicker_1.MBComboDatePicker, { label: (_b = component.label) !== null && _b !== void 0 ? _b : '', value: (0, lodash_1.isNil)(field === null || field === void 0 ? void 0 : field.date) ? undefined : { start: field === null || field === void 0 ? void 0 : field.date }, onChange: onUpdateDate, mode: { date: 'single', time: 'single' }, layoutStyle: { justifyContent: 'flex-start', minHeight: 'auto', flex: 0 }, highlightDates: showHighlightDates
                    ? (0, utils_1.getAllDates)({ rruleOptions: rruleOptions, includeStart: true, offsetTz: true }).map(function (date) {
                        return date.toISOString();
                    })
                    : undefined })),
            isBatchRuleEnabled && (react_1.default.createElement(react_1.default.Fragment, null,
                !isDesktop && !isShowingMobileRepeatOptions && react_1.default.createElement(divider_1.Divider, null),
                react_1.default.createElement(react_native_1.View, { style: { width: isDesktop ? (0, components_1.grid)(37) : '100%' } },
                    react_1.default.createElement(RepeatTextButton_1.RepeatTextButton, { onPress: handleAddRepeat, disabled: (0, lodash_1.isNil)(field === null || field === void 0 ? void 0 : field.date), isConfiguring: showRepeatOptions, rruleOptions: rruleOptions, onClear: handleClearRepeat })))),
            showRepeatOptions && isBatchRuleEnabled && (react_1.default.createElement(BatchSchedule_1.BatchScheduleComponent, { date: field === null || field === void 0 ? void 0 : field.date, setRruleOptions: setRruleOptions, rruleOptions: rruleOptions, showingCustomOptions: showingCustomOptions, setShowingCustomOptions: setShowingCustomOptions, presets: repeatPresets, filteredPresets: filteredRepeatPresets, count: repeatCount, setCount: setRepeatCount, until: repeatUntil, setUntil: setRepeatUntil, repeatEndType: repeatEndType, setRepeatEndType: setRepeatEndType, presetIndexSelected: presetIndexSelected, setPresetIndexSelected: setPresetIndexSelected, isCustomSelected: isCustomRepeatSelected })))));
}
function DateEditField(_a) {
    var field = _a.field, component = _a.component, updateField = _a.updateField, workflowTemplateId = _a.workflowTemplateId, entityBatchField = _a.entityBatchField, error = _a.error, _b = _a.includeWrapperPadding, includeWrapperPadding = _b === void 0 ? true : _b;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var entityBatch = (0, useEntityBatch_1.useEntityBatch)(entityBatchField === null || entityBatchField === void 0 ? void 0 : entityBatchField.id, workflowTemplateId).data;
    var containerRef = (0, react_1.useRef)(null);
    var onClear = (0, react_1.useCallback)(function () {
        updateField(component.id, null);
    }, [component.id, updateField]);
    var _c = (0, BatchContext_1.useBatch)(), rruleOptions = _c.rruleOptions, setRruleOptions = _c.setRruleOptions, canBatchCreate = _c.canBatchCreate;
    var isReadOnly = component.readonly;
    var showRepeatText = component.allowEntityBatchCreation && !(0, lodash_1.isNil)(rruleOptions);
    var _d = (0, react_1.useState)(false), isDatePickerOpen = _d[0], setIsDatePickerOpen = _d[1];
    var isBatchRuleEnabled = canBatchCreate && !!component.allowEntityBatchCreation;
    var _e = (0, useBatchRule_1.useBatchRule)({ rruleOptions: rruleOptions, setRruleOptions: setRruleOptions, field: field, isBatchRuleEnabled: isBatchRuleEnabled }), repeatPresets = _e.repeatPresets, filteredRepeatPresets = _e.filteredRepeatPresets, repeatCount = _e.repeatCount, setRepeatCount = _e.setRepeatCount, presetIndexSelected = _e.presetIndexSelected, setPresetIndexSelected = _e.setPresetIndexSelected, isCustomRepeatSelected = _e.isCustomRepeatSelected, repeatUntil = _e.repeatUntil, setRepeatUntil = _e.setRepeatUntil, repeatEndType = _e.repeatEndType, setRepeatEndType = _e.setRepeatEndType, resetBatchOptions = _e.resetBatchOptions;
    var handleClosePicker = (0, react_1.useCallback)(function () { return setIsDatePickerOpen(false); }, [setIsDatePickerOpen]);
    var handlePress = (0, react_1.useCallback)(function () {
        setIsDatePickerOpen(true);
    }, [setIsDatePickerOpen]);
    var handleClear = function () {
        onClear();
        if (isBatchRuleEnabled) {
            resetBatchOptions();
        }
    };
    (0, react_1.useEffect)(function () {
        if ((0, lodash_1.isNil)(entityBatch)) {
            return;
        }
        var rrule = entityBatch.getRecurringEntitiesSchedule.rrule;
        setRruleOptions(rrule_1.RRule.fromString(rrule).options);
    }, [entityBatch, setRruleOptions]);
    var hasValue = !!(field === null || field === void 0 ? void 0 : field.formattedDate);
    return (react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, onPress: !isReadOnly ? handlePress : undefined, onClear: hasValue && !isReadOnly ? handleClear : undefined, showLabel: hasValue, error: error, includeWrapperPadding: includeWrapperPadding },
        react_1.default.createElement(react_native_1.View, { ref: containerRef },
            hasValue && react_1.default.createElement(components_1.MBTextBody1, null, field.formattedDate),
            !hasValue && (react_1.default.createElement(components_1.MBView, { style: { paddingVertical: (0, components_1.grid)(1.25) } },
                react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundInactive }, component.placeholder))),
            showRepeatText && (react_1.default.createElement(react_native_1.View, { style: { paddingTop: (0, components_1.grid)(0.5) } },
                react_1.default.createElement(RepeatText_1.RepeatText, { rruleOptions: rruleOptions, includeStartDate: true })))),
        isDatePickerOpen && (react_1.default.createElement(DateEditFieldPicker, { field: field, component: component, updateField: updateField, onClose: handleClosePicker, containerRef: containerRef, repeatPresets: repeatPresets, filteredRepeatPresets: filteredRepeatPresets, presetIndexSelected: presetIndexSelected, setPresetIndexSelected: setPresetIndexSelected, repeatCount: repeatCount, setRepeatCount: setRepeatCount, repeatUntil: repeatUntil, setRepeatUntil: setRepeatUntil, repeatEndType: repeatEndType, setRepeatEndType: setRepeatEndType, isCustomRepeatSelected: isCustomRepeatSelected, resetBatchOptions: resetBatchOptions }))));
}
