"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = reducer;
var index_1 = require("./index");
/*
    This reducer does nothing except for
    logging all redux actions to Sentry
*/
function reducer(state, action) {
    if (state === void 0) { state = {}; }
    index_1.MBSentry.breadcrumb({
        message: action.type,
        category: 'redux',
        data: action,
    });
    return state;
}
