"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useElementSize = useElementSize;
var react_1 = require("react");
var uuid_1 = require("@shared/util/uuid");
var resizeObserver;
var callbacks = {};
/**
 * Returns current width and height of specified element.
 *
 * @param {Ref} ref element to use in size calculation
 * @param {Array} defaultSize dims to use server side and before the first measurement
 */
function useElementSize(ref, defaultSize) {
    var _a;
    if (defaultSize === void 0) { defaultSize = [0, 0]; }
    var _b = (0, react_1.useState)(defaultSize), size = _b[0], setSize = _b[1];
    // set unique id or reuse element's existing unique id
    var existingId = (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.getAttribute('resize-id');
    var elId = (0, react_1.useRef)(existingId || "resize-".concat((0, uuid_1.v4)()));
    var updateSizes = function (entries) {
        entries === null || entries === void 0 ? void 0 : entries.forEach(function (entry) {
            var target = entry.target.current || entry.target;
            callbacks[target.getAttribute('resize-id')].forEach(function (c) { return c(entry); });
        });
    };
    // all instances use the same observer
    resizeObserver = resizeObserver || new ResizeObserver(updateSizes);
    (0, react_1.useLayoutEffect)(function () {
        if (!(ref === null || ref === void 0 ? void 0 : ref.current)) {
            return;
        }
        var refRef = ref.current;
        refRef.setAttribute('resize-id', elId.current);
        // set initial size
        setSize([refRef.getBoundingClientRect().width, refRef.getBoundingClientRect().height]);
        // add callback for this instance
        // more than one callback can be added for an element
        // in case of two instances watching the same ref
        callbacks[elId.current] = __spreadArray(__spreadArray([], (callbacks[elId.current] || []), true), [
            function (entry) {
                var _a;
                if (!entry) {
                    return;
                }
                if ((_a = entry.contentBoxSize) === null || _a === void 0 ? void 0 : _a[0]) {
                    setSize([entry.contentBoxSize[0].inlineSize, entry.contentBoxSize[0].blockSize]);
                }
                else if (entry === null || entry === void 0 ? void 0 : entry.contentBoxSize) {
                    setSize([entry.contentBoxSize.inlineSize, entry.contentBoxSize[0].blockSize]);
                }
                else {
                    setSize([entry.contentRect.width, entry.contentRect.height]);
                }
            },
        ], false);
        // remove existing watch
        if (existingId) {
            resizeObserver.unobserve(refRef);
        }
        // watch for size changes
        resizeObserver.observe(refRef);
        // eslint-disable-next-line consistent-return
        return function () {
            if (!refRef) {
                return;
            }
            resizeObserver.unobserve(refRef);
        };
    }, [ref, existingId]);
    return size;
}
