"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardEntityEditForm = CardEntityEditForm;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var scroll_1 = require("@shared/components/blocks/contexts/scroll");
var BatchContext_1 = require("@shared/scenes/cards/entityBulkActions/BatchCreateContext/BatchContext");
var ComponentFocus_1 = require("@shared/scenes/cards/form/components/ComponentFocus");
var input_1 = require("@shared/scenes/chat/input/containers/input");
var FormErrors_1 = require("@shared/scenes/entity/components/FormErrors");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var EditField_1 = require("../../components/fields/EditField");
var SubformEditField_1 = require("../../components/fields/Subform/SubformEditField");
var getFieldError_1 = require("../../util/getFieldError");
var ThreadContainer_1 = require("./ThreadContainer");
function CardEntityEditForm(_a) {
    var fields = _a.fields, updateField = _a.updateField, components = _a.components, errors = _a.errors, workflowTemplateId = _a.workflowTemplateId, entityId = _a.entityId, threadId = _a.threadId, channelSlug = _a.channelSlug, users = _a.users, groups = _a.groups, _b = _a.style, style = _b === void 0 ? {} : _b;
    var isComponentFocused = (0, ComponentFocus_1.useComponentFocus)().isComponentFocused;
    var scrollView = (0, scroll_1.useCardBlockScrollContext)();
    var scrollToEnd = (0, react_1.useCallback)(function () {
        var _a;
        (_a = scrollView === null || scrollView === void 0 ? void 0 : scrollView.current) === null || _a === void 0 ? void 0 : _a.scrollToEnd();
    }, [scrollView]);
    var isWeb = react_native_1.Platform.OS === 'web';
    var isMobile = (0, hooks_1.useIsMobile)();
    var isDesktop = (0, hooks_1.useIsDesktop)();
    var showChat = !(0, lodash_1.isNil)(threadId) && !(0, lodash_1.isNil)(users) && !(0, lodash_1.isNil)(groups) && !(0, lodash_1.isNil)(channelSlug);
    var showChatInput = showChat && (isDesktop || !isComponentFocused);
    if ((0, lodash_1.isNil)(fields) || (0, lodash_1.isNil)(workflowTemplateId)) {
        return null;
    }
    return (react_1.default.createElement(BatchContext_1.BatchEditContextProvider, null,
        react_1.default.createElement(SubformEditField_1.SubformNavigationContext, { fields: fields, updateField: updateField },
            react_1.default.createElement(FormErrors_1.FormErrors, { errors: errors }),
            react_1.default.createElement(react_native_1.ScrollView, { ref: scrollView, style: { width: '100%' }, keyboardShouldPersistTaps: "handled", keyboardDismissMode: isDesktop ? 'none' : 'on-drag' },
                react_1.default.createElement(react_native_1.View, { style: [
                        styles.content,
                        isWeb && styles.webContent,
                        isWeb && isMobile && styles.mobileWebContent,
                        style,
                    ] },
                    components.map(function (component) { return (react_1.default.createElement(EditField_1.EditField, { key: component.id, field: fields[component.id], fields: fields, workflowTemplateId: workflowTemplateId, component: component, updateField: updateField, error: (0, getFieldError_1.getFieldError)(errors, component.id), entityId: entityId })); }),
                    showChat && react_1.default.createElement(ThreadContainer_1.ThreadContainer, { threadId: threadId, channelSlug: channelSlug })),
                react_1.default.createElement(components_1.MBNativeKeyboardSpacer, null)),
            showChatInput && (react_1.default.createElement(react_native_1.View, { style: [isWeb && styles.webContent, isWeb && isMobile && styles.mobileWebContent] },
                react_1.default.createElement(input_1.MBChatInput, { placeholder: (0, i18n_1.i18n)('chat.input.placeholder'), draftId: "thread:".concat(threadId), users: users, groups: groups, onSend: scrollToEnd, autoFocus: false, onFocus: !isWeb ? scrollToEnd : undefined }))))));
}
var styles = react_native_1.StyleSheet.create({
    content: {
        paddingTop: (0, components_1.grid)(1),
        paddingBottom: (0, components_1.grid)(8),
    },
    webContent: {
        width: '100%',
        paddingHorizontal: (0, components_1.grid)(7),
        alignSelf: 'center',
    },
    mobileWebContent: {
        paddingHorizontal: 0,
    },
});
