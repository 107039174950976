"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCachedTemplateSlug = useCachedTemplateSlug;
var lodash_1 = require("lodash");
var react_1 = require("react");
var hooks_1 = require("@shared/util/storage/hooks");
function useCachedTemplateSlug(channelSlug, templateSlug) {
    var key = (0, react_1.useMemo)(function () { return getTemplateSlugStorageKey(channelSlug); }, [channelSlug]);
    var stored = (0, hooks_1.useFromStorage)(key);
    var persistInStorage = (0, hooks_1.usePersistInStorage)(key);
    (0, react_1.useEffect)(function () {
        if (!(0, lodash_1.isNil)(channelSlug) && !(0, lodash_1.isNil)(templateSlug)) {
            persistInStorage(templateSlug);
            stored.refetch();
        }
        if ((0, lodash_1.isNil)(channelSlug) || (0, lodash_1.isNil)(templateSlug)) {
            stored.refetch();
        }
    }, [persistInStorage, stored, templateSlug, channelSlug]);
    return stored.value;
}
function getTemplateSlugStorageKey(channelSlug) {
    return ['CardCollection', 'previousTemplateSlug', 'v1', channelSlug].join('.');
}
