"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChannelsFromCache = useChannelsFromCache;
exports.useChannelFromCache = useChannelFromCache;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
var notNil_1 = require("@shared/util/notNil");
/**
 * A hook that uses a cache-only policy to retrieve channel list items.
 */
function useChannelsFromCache() {
    var _a, _b;
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var data = (0, client_1.useQuery)(api_1.Queries.channel.list, {
        variables: { businessId: businessId !== null && businessId !== void 0 ? businessId : -1 },
        skip: (0, lodash_1.isNil)(businessId),
        fetchPolicy: 'cache-only',
    }).data;
    return (0, react_1.useMemo)(function () { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.channels) === null || _c === void 0 ? void 0 : _c.filter(notNil_1.notNil)) !== null && _d !== void 0 ? _d : []; }, [(_b = (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.channels]);
}
function useChannelFromCache(_a) {
    var channelSlug = _a.channelSlug, channelId = _a.channelId;
    var channels = useChannelsFromCache();
    if (channelSlug) {
        return channels.find(function (channel) { return channel.slug === channelSlug; });
    }
    if (channelId) {
        return channels.find(function (channel) { return channel.id === channelId; });
    }
    return undefined;
}
