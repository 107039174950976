"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBreakpointOnWidthExceeds = useBreakpointOnWidthExceeds;
// @ts-strict-ignore
var react_1 = require("react");
/**
 * Manages a boolean breakpoint when resizing an MBView component in a way
 * that does not cause extra re-renders. It only toggles the breakpoint
 * when the value crosses the threshold regardless of how often onSize is invoked.
 * @param threshold - when width is above this value, breakpoint will be true
 * @param initialValue - initial value of breakpoint
 */
function useBreakpointOnWidthExceeds(threshold, initialValue) {
    var _a = (0, react_1.useState)(initialValue), breakpoint = _a[0], setBreakpoint = _a[1];
    var breakpointRef = (0, react_1.useRef)(initialValue);
    var handleOnSize = (0, react_1.useCallback)(function (_a) {
        var width = _a.width;
        if (width <= threshold && breakpointRef.current === true) {
            breakpointRef.current = false;
            setBreakpoint(false);
        }
        else if (width > threshold && breakpointRef.current === false) {
            breakpointRef.current = true;
            setBreakpoint(true);
        }
    }, [threshold]);
    return (0, react_1.useMemo)(function () { return ({ handleOnSize: handleOnSize, breakpoint: breakpoint }); }, [handleOnSize, breakpoint]);
}
