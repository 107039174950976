"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSaveNewEntity = useSaveNewEntity;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
var toFieldJson_1 = require("@shared/scenes/entity/util/toFieldJson");
var hooks_1 = require("@shared/scenes/workflows/hooks");
function useSaveNewEntity(workflowTemplateId) {
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var _a = (0, client_1.useMutation)(api_1.Mutations.entity.createWorkflowEntity), createEntityMutation = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    var saveEntity = (0, react_1.useCallback)(function (fields) {
        if ((0, lodash_1.isNil)(workflowTemplateId)) {
            return Promise.reject('Workflow template ID is required');
        }
        return createEntityMutation({
            variables: {
                input: {
                    fields: (0, toFieldJson_1.toFieldJson)(fields, workflowTemplate),
                    workflowTemplateId: workflowTemplateId,
                },
            },
        });
    }, [createEntityMutation, workflowTemplateId, workflowTemplate]);
    return {
        loading: loading,
        errors: !(0, lodash_1.isNil)(error) ? [{ description: error.message }] : [],
        saveEntity: saveEntity,
    };
}
