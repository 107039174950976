"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatImageAttachment = MBChatImageAttachment;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/components/loading/styles");
var hooks_1 = require("@shared/util/hooks");
var styles_2 = require("../styles");
// the smallest dimensions to render the image bubble
// sized to the loading spinner with some pad.
var MIN_IMAGE_SIZE = styles_1.UnvalidatedStyles.circularProgressBarSize.normal + 20;
// the tallest to allow an image to render, in vh
var MAX_VIEWPORT_HEIGHT = 50;
// the maximum dims we'll request from imgix for attachment bubble display.
// combined with imgix's auto compression,
// this fixes the issue that thumbs are usually too small to view inline
// but the full size image can be too big to render efficiently.
// based on a likely size these could display at on a 4k monitor.
var MAX_DIM = 1800;
/*
    Default image attachment. When pressed, it shows a modal with an image preview.
*/
function MBChatImageAttachment(_a) {
    var attachment = _a.attachment, isSending = _a.isSending, progress = _a.progress, maxHeight = _a.maxHeight, backgroundColor = _a.backgroundColor;
    var _b = (0, hooks_1.useImageSrcAndDimensions)({
        image: attachment,
        containerWidth: MAX_DIM,
        containerHeight: MAX_DIM,
        size: 'contain',
    }), src = _b.src, width = _b.width, height = _b.height, ratio = _b.ratio, isLandscape = _b.isLandscape, isPortrait = _b.isPortrait;
    var maximumHeight = !(0, lodash_1.isNil)(maxHeight) ? maxHeight : "".concat(MAX_VIEWPORT_HEIGHT, "vh");
    if (react_native_1.Platform.OS === 'web') {
        return (React.createElement(components_1.MBView, { center: true, style: {
                width: isLandscape ? width : null,
                height: !(0, lodash_1.isNil)(maxHeight) ? maxHeight : isPortrait ? height : null,
                minWidth: MIN_IMAGE_SIZE,
                minHeight: MIN_IMAGE_SIZE,
                maxWidth: isLandscape ? '100%' : null,
                maxHeight: isPortrait ? maximumHeight : null,
                backgroundColor: !(0, lodash_1.isNil)(backgroundColor) ? backgroundColor : null,
                flexShrink: 1,
            } },
            (isSending !== null && isSending !== void 0 ? isSending : (0, lodash_1.isNumber)(progress)) && (React.createElement(components_1.MBView, { absolute: true, center: true },
                React.createElement(components_1.MBCircularProgressBar, { progress: progress }))),
            !(0, lodash_1.isNil)(src) && (React.createElement("img", { src: src, style: {
                    maxWidth: isLandscape ? '100%' : null,
                    maxHeight: isPortrait ? maximumHeight : null,
                    width: isLandscape ? width : null,
                    height: isPortrait ? height : null,
                    opacity: isSending ? 0.5 : 1.0,
                }, alt: "" }))));
    }
    if (!width || !height) {
        return null;
    }
    return (React.createElement(components_1.MBAspect, { ratio: ratio, style: [
            {
                maxWidth: '100%',
                height: '100%',
            },
        ] },
        React.createElement(components_1.MBImage, { source: { uri: src }, style: [
                isSending && styles_2.Styles.semitransparent,
                {
                    maxHeight: Math.min(!(0, lodash_1.isNil)(maxHeight) ? maxHeight : height, react_native_1.Dimensions.get('window').height * (MAX_VIEWPORT_HEIGHT / 100)),
                },
            ], resizeMode: "contain" }),
        isSending && (0, lodash_1.isNumber)(progress) && (React.createElement(components_1.MBView, { absolute: true, center: true },
            React.createElement(components_1.MBCircularProgressBar, { progress: progress })))));
}
