"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_ACCESS_LEVEL = exports.RESET_SELECTED_USERS = exports.SET_WORKSPACE_LIST_SECTION = exports.SET_WORKFLOW_LIST_ITEM = exports.REMOVE_SELECTED_USER = exports.ADD_SELECTED_USER = void 0;
exports.addSelectedUser = addSelectedUser;
exports.removeSelectedUser = removeSelectedUser;
exports.resetSelectedUser = resetSelectedUser;
exports.setWorkflowListItem = setWorkflowListItem;
exports.setWorkspaceListSection = setWorkspaceListSection;
exports.setAccessLevel = setAccessLevel;
exports.ADD_SELECTED_USER = 'CHANNEL:CREATE:ADD_SELECTED_USER';
exports.REMOVE_SELECTED_USER = 'CHANNEL:CREATE:REMOVE_SELECTED_USER';
exports.SET_WORKFLOW_LIST_ITEM = 'CHANNEL:CREATE:SET_WORKFLOW_LIST_ITEM';
exports.SET_WORKSPACE_LIST_SECTION = 'CHANNEL:CREATE:SET_WORKSPACE_LIST_SECTION';
exports.RESET_SELECTED_USERS = 'CHANNEL:CREATE:RESET_SELECTED_USERS';
exports.SET_ACCESS_LEVEL = 'CHANNEL:CREATE:SET_ACCESS_LEVEL';
function addSelectedUser(user) {
    return {
        type: exports.ADD_SELECTED_USER,
        value: user,
    };
}
function removeSelectedUser(user) {
    return {
        type: exports.REMOVE_SELECTED_USER,
        value: user,
    };
}
function resetSelectedUser(users) {
    if (users === void 0) { users = []; }
    return {
        type: exports.RESET_SELECTED_USERS,
        value: users,
    };
}
function setWorkflowListItem(item) {
    return {
        type: exports.SET_WORKFLOW_LIST_ITEM,
        value: item,
    };
}
function setWorkspaceListSection(id) {
    return {
        type: exports.SET_WORKSPACE_LIST_SECTION,
        value: id,
    };
}
function setAccessLevel(accessLevel) {
    return {
        type: exports.SET_ACCESS_LEVEL,
        value: accessLevel,
    };
}
