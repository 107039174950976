"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBDefinitionList = MBDefinitionList;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var image_1 = require("@shared/components/image");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
function MBDefinitionList(_a) {
    var channelName = _a.channelName, onNewTemplate = _a.onNewTemplate, availableDefinitions = _a.availableDefinitions, onSelectDefinition = _a.onSelectDefinition;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    // Estimate of the number of rows supported by the container
    var shouldDisplayShadow = (availableDefinitions === null || availableDefinitions === void 0 ? void 0 : availableDefinitions.length) > 7;
    return (react_1.default.createElement(components_1.MBView, { paddingBottom: 1, style: styles.container },
        react_1.default.createElement(components_1.MBTextBody1, { style: styles.headerText },
            (0, i18n_1.i18n)('card.createButton.templatesFor'),
            react_1.default.createElement(components_1.MBTextBody1Bold, null, channelName)),
        react_1.default.createElement(react_native_1.ScrollView, { style: [{ marginBottom: (0, components_1.grid)(1) }, shouldDisplayShadow && styles.scrollView] },
            !(availableDefinitions === null || availableDefinitions === void 0 ? void 0 : availableDefinitions.length) && (react_1.default.createElement(components_1.MBView, { center: true },
                react_1.default.createElement(image_1.MBImage, { source: require('@shared/assets/icons/v2/check-todo.png'), tintColor: colors.highlightActive, style: { height: (0, components_1.grid)(18), width: '100%' }, resizeMode: "contain" }),
                react_1.default.createElement(components_1.MBTextBody1Bold, null, (0, i18n_1.i18n)('card.createButton.emptyStateTitle')),
                react_1.default.createElement(components_1.MBTextBody2, { color: colors.foregroundInactive, style: { textAlign: 'center' } }, (0, i18n_1.i18n)('card.createButton.emptyStatyDescription')))),
            availableDefinitions.map(function (definition) { return (react_1.default.createElement(components_1.MBTouchableOpacity, { key: definition.id, onPress: function () { return onSelectDefinition(definition); } },
                react_1.default.createElement(components_1.MBHover, { hoverStyle: { backgroundColor: colors.input.backgroundInactive } },
                    react_1.default.createElement(components_1.MBView, { row: true, padding: 1, centerVertically: true },
                        react_1.default.createElement(components_1.MBIconV2, { name: "list", color: colors.foregroundActive, size: (0, components_1.grid)(3) }),
                        react_1.default.createElement(components_1.MBTextBody1, { style: [styles.tinyPaddingLeft, styles.definitionName] }, definition.name))))); })),
        react_1.default.createElement(components_1.MBView, { style: styles.line }),
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onNewTemplate },
            react_1.default.createElement(components_1.MBHover, { paddingHorizontal: 2, paddingVertical: 1, centerVertically: true, row: true, hoverStyle: { backgroundColor: colors.input.backgroundInactive } },
                react_1.default.createElement(components_1.MBIconV2, { name: "plus-small-heavy", color: colors.foregroundActive, size: (0, components_1.grid)(2) }),
                react_1.default.createElement(components_1.MBTextBody1Bold, { style: styles.tinyPaddingLeft }, (0, i18n_1.i18n)('card.createButton.newTemplate'))))));
}
var styles = react_native_1.StyleSheet.create({
    container: {
        maxHeight: 400,
        width: 350,
    },
    // @ts-ignore
    scrollView: react_native_1.Platform.select({
        web: {
            boxShadow: 'inset 0px -15px 10px -15px rgba(0,0,0,0.2)',
            marginBottom: 0,
        },
    }),
    headerText: { padding: (0, components_1.grid)(2) },
    line: { borderTopWidth: 1, borderColor: '#e9e9e9', marginBottom: (0, components_1.grid)(1) },
    tinyPaddingLeft: { paddingLeft: (0, components_1.grid)(1) },
    definitionName: { flex: 1, flexWrap: 'wrap' },
});
