"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubformOptions = useSubformOptions;
var lodash_1 = require("lodash");
var react_1 = require("react");
var toast_1 = require("@shared/components/toast");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var hook_1 = require("@shared/util/actionSheet/hook");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
var hooks_2 = require("../hooks");
function useSubformOptions(_a) {
    var _this = this;
    var channelSlug = _a.channelSlug, workflowTemplateId = _a.workflowTemplateId, subformWorkflowTemplate = _a.subformWorkflowTemplate, componentId = _a.componentId, onShowRename = _a.onShowRename, onEnableMultiSelect = _a.onEnableMultiSelect;
    var _b = (0, react_1.useState)(false), loading = _b[0], setLoading = _b[1];
    var navigator = (0, navigation_1.useNavigator)();
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var updateWorkflow = (0, hooks_2.useUpdateWorkflowTemplate)()[0];
    var createWorkflow = (0, hooks_2.useCreateWorkflowTemplate)(true)[0];
    var updateSubformComponentWorkflows = (0, hooks_2.useUpdateSubformComponentWorkflows)(workflowTemplateId, componentId);
    var updateWorkflowTemplate = (0, hooks_2.useUpdateWorkflowTemplate)()[0];
    var onSubformRename = (0, react_1.useCallback)(function (newName) {
        updateWorkflowTemplate(__assign(__assign({}, subformWorkflowTemplate), { name: newName }));
    }, [subformWorkflowTemplate, updateWorkflowTemplate]);
    var onDuplicateSubform = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        var _id, createWorkflowInput, newWorkflowTemplate;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    _id = subformWorkflowTemplate.id, createWorkflowInput = __rest(subformWorkflowTemplate, ["id"]);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, , 4, 5]);
                    return [4 /*yield*/, createWorkflow(createWorkflowInput)];
                case 2:
                    newWorkflowTemplate = _b.sent();
                    return [4 /*yield*/, updateSubformComponentWorkflows(newWorkflowTemplate === null || newWorkflowTemplate === void 0 ? void 0 : newWorkflowTemplate.id)];
                case 3:
                    _b.sent();
                    toast.show({
                        message: (0, i18n_1.i18n)('workflowTemplate.subformDuplicated', {
                            subformName: (_a = subformWorkflowTemplate === null || subformWorkflowTemplate === void 0 ? void 0 : subformWorkflowTemplate.name) !== null && _a !== void 0 ? _a : '',
                        }),
                        icon: 'check-filled',
                    });
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [createWorkflow, subformWorkflowTemplate, toast, updateSubformComponentWorkflows]);
    var onDeleteSubform = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if ((0, lodash_1.isNil)(workflowTemplate)) {
                        return [2 /*return*/];
                    }
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    return [4 /*yield*/, updateWorkflow(__assign(__assign({}, workflowTemplate), { components: workflowTemplate.components.map(function (component) {
                                if (!(0, hooks_2.isSubformComponent)(component) || component.id !== componentId) {
                                    return component;
                                }
                                return __assign(__assign({}, component), { workflowTemplateIds: component.workflowTemplateIds.filter(function (id) { return id !== subformWorkflowTemplate.id; }) });
                            }) }))];
                case 2:
                    _a.sent();
                    toast.show({
                        message: (0, i18n_1.i18n)('workflowTemplate.subformDeleted', {
                            subformName: subformWorkflowTemplate.name,
                        }),
                        icon: 'check-filled',
                    });
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [componentId, subformWorkflowTemplate, toast, updateWorkflow, workflowTemplate]);
    var onEditSubform = (0, react_1.useCallback)(function () {
        navigator.navigate('WorkflowEdit', {
            channelSlug: channelSlug,
            workflowId: subformWorkflowTemplate === null || subformWorkflowTemplate === void 0 ? void 0 : subformWorkflowTemplate.id,
            queryString: {
                originWorkflowTemplateId: workflowTemplateId,
                originComponentId: componentId,
            },
        });
    }, [channelSlug, componentId, navigator, subformWorkflowTemplate === null || subformWorkflowTemplate === void 0 ? void 0 : subformWorkflowTemplate.id, workflowTemplateId]);
    var showOptions = (0, hook_1.useActionSheet)({
        webHorizontalAlignment: 'right',
        options: [
            {
                title: (0, i18n_1.i18n)('common.rename'),
                onPress: onShowRename,
            },
            {
                title: (0, i18n_1.i18n)('common.duplicate'),
                onPress: onDuplicateSubform,
            },
            {
                title: (0, i18n_1.i18n)('workflowTemplate.subformOptions.selectMultiple'),
                onPress: onEnableMultiSelect,
            },
            {
                title: (0, i18n_1.i18n)('common.delete'),
                onPress: onDeleteSubform,
                destructive: true,
            },
        ],
    });
    return { showOptions: showOptions, onEditSubform: onEditSubform, onSubformRename: onSubformRename, loading: loading };
}
