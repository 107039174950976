"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBillingLimitBanner = MBBillingLimitBanner;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var components_1 = require("@shared/components");
var config_1 = require("@shared/config");
var styles_1 = require("@shared/styles");
var analytics_1 = require("@shared/util/analytics");
var i18n_1 = require("@shared/util/i18n");
var linking_1 = require("@shared/util/linking");
function MBBillingLimitBanner(_a) {
    var slug = _a.slug, title = _a.title, message = _a.message, onDismiss = _a.onDismiss;
    var handleLearnMore = (0, react_1.useCallback)(function () {
        analytics_1.MBAnalytics.track('BillingLimitBanner::LearnMore', { slug: slug });
        linking_1.MBLinking.open(config_1.Config.PRICING_LEARN_MORE_URL);
    }, [slug]);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var handleDismiss = (0, react_1.useCallback)(function () { return onDismiss(slug); }, [onDismiss, slug]);
    return (react_1.default.createElement(components_1.MBView, { padding: 1, style: {
            position: 'absolute',
            width: '100%',
            height: '80%',
            alignItems: 'center',
            justifyContent: 'center',
        } },
        react_1.default.createElement(components_1.MBView, { style: {
                backgroundColor: styles_1.Colors.v2.green10,
                borderRadius: (0, components_1.grid)(1),
                shadowOffset: { width: 0, height: 2 },
                shadowColor: styles_1.Colors.v2.black,
                shadowOpacity: 0.4,
                shadowRadius: 10,
                zIndex: 900,
                elevation: 8,
                width: '100%',
                maxWidth: (0, components_1.grid)(60),
            }, padding: 2, row: true },
            react_1.default.createElement(components_1.MBView, { flex: true },
                react_1.default.createElement(components_1.MBView, { row: true, centerHorizontally: true },
                    react_1.default.createElement(components_1.MBView, { flex: true, row: isDesktop, center: true },
                        react_1.default.createElement(components_1.MBView, { paddingRight: isDesktop ? 1 : 0 },
                            react_1.default.createElement(components_1.MBIconV2, { name: "flag-wave", color: styles_1.Colors.v2.purple, size: (0, components_1.grid)(4) })),
                        react_1.default.createElement(components_1.MBTextBody1Bold, { align: "center" }, title)),
                    react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: handleDismiss, style: { position: 'absolute', top: 0, right: 0 } },
                        react_1.default.createElement(components_1.MBIconV2, { name: "exit-small", color: styles_1.Colors.v2.purple, size: (0, components_1.grid)(3) }))),
                react_1.default.createElement(components_1.MBView, { paddingVertical: 1, center: true },
                    react_1.default.createElement(components_1.MBView, { style: { width: isDesktop ? (0, components_1.grid)(43) : 'auto' } },
                        react_1.default.createElement(components_1.MBTextBody2, { align: "center" }, message))),
                react_1.default.createElement(components_1.MBView, { row: true, paddingTop: 1, centerHorizontally: true },
                    react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('billing.banners.learnMore'), size: "small", onPress: handleLearnMore }),
                    react_1.default.createElement(components_1.MBView, { style: { width: (0, components_1.grid)(1) } }),
                    react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('billing.banners.skip'), size: "small", theme: "transparent", borderColor: styles_1.Colors.v2.purple, onPress: handleDismiss }))))));
}
