"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapStateToCollectionCriteria = mapStateToCollectionCriteria;
var lodash_1 = require("lodash");
var CardsEntityService_1 = require("@shared/data/cards/CardsEntityService");
var notNil_1 = require("@shared/util/notNil");
function mapStateToCollectionCriteria(_a) {
    var filters = _a.filters, sorts = _a.sorts, groups = _a.groups;
    var updatedFilters = Array.from(filters.values()).map(CardsEntityService_1.getFilterComponent).filter(notNil_1.notNil);
    var updatedSorts = Array.from(sorts.values()).map(CardsEntityService_1.getSortComponent);
    var updatedGroups = groups
        .filter(function (group) { return !(0, lodash_1.isEmpty)(Array.from(group.values())); })
        .map(function (group) { return ({
        filterByComponents: Array.from(group.values()).map(CardsEntityService_1.getFilterComponent).filter(notNil_1.notNil),
    }); });
    return {
        filterByComponents: updatedFilters,
        sortByComponents: updatedSorts,
        groups: updatedGroups,
    };
}
