"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardDetails = MBCardDetails;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var hooks_1 = require("@shared/scenes/cards/form/hooks");
var hooks_2 = require("@shared/scenes/cards/hooks");
var hooks_3 = require("@shared/scenes/workflows/hooks");
var hooks_4 = require("@shared/util/hooks");
var navigation_1 = require("@shared/util/navigation");
var hooks_5 = require("@shared/util/permissions/hooks");
var workflow_1 = require("@shared/util/workflow");
var components_2 = require("../components");
function useCheckAccess(cardId, channelSlug) {
    var _a = (0, hooks_2.useCard)(cardId, { fetchPolicy: 'cache-and-network' }), card = _a.card, error = _a.error;
    var navigator = (0, navigation_1.useNavigator)();
    var noAccess = !card && (error === null || error === void 0 ? void 0 : error.message) === 'Forbidden resource';
    (0, react_1.useEffect)(function () {
        if (noAccess) {
            navigator.replace('CardDetailsNoAccess', { channelSlug: channelSlug });
        }
    }, [noAccess, navigator, channelSlug]);
}
function useCheckIfDetailsExist(cardId, channelSlug, loading, template) {
    var navigator = (0, navigation_1.useNavigator)();
    (0, react_1.useEffect)(function () {
        if (!loading && !template) {
            navigator.replace('CardUpdate', { cardId: cardId, channelSlug: channelSlug });
        }
    }, [loading, template, navigator, cardId, channelSlug]);
}
function LowCodeComponent(_a) {
    var _b;
    var card = _a.card, channel = _a.channel, channelSlug = _a.channelSlug, templateSlug = _a.templateSlug, handleEdit = _a.handleEdit, handleSubmit = _a.handleSubmit;
    var cardDefinition = (0, hooks_2.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, (_b = card.workflowTemplateId) !== null && _b !== void 0 ? _b : undefined, card.cardDefinitionId).cardDefinition;
    var _c = (0, hooks_2.useCardTemplate)({
        channelSlug: channelSlug,
        templateSlug: templateSlug,
        context: (0, lodash_1.isNil)(templateSlug) ? 'detail' : undefined,
        cardDefinition: cardDefinition,
    }), template = _c.template, loading = _c.loading;
    useCheckIfDetailsExist(card.id, channelSlug, loading, template);
    if (loading || (0, lodash_1.isNil)(template)) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true });
    }
    return react_1.default.createElement(components_2.MBCardDetails, { card: card, template: template, onEdit: handleEdit, onSubmit: handleSubmit });
}
function NoCodeComponent(_a) {
    var _b, _c;
    var card = _a.card, channelSlug = _a.channelSlug, templateSlug = _a.templateSlug, handleEdit = _a.handleEdit, handleSubmit = _a.handleSubmit;
    var _d = (0, hooks_3.useViewTemplateWithUITemplate)((_b = card.workflowTemplateId) !== null && _b !== void 0 ? _b : undefined, templateSlug), viewTemplate = _d.viewTemplate, loading = _d.loading;
    var template = (_c = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.cardUITemplate) === null || _c === void 0 ? void 0 : _c.template;
    useCheckIfDetailsExist(card.id, channelSlug, loading, template);
    if (loading || (0, lodash_1.isNil)(template)) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true });
    }
    return react_1.default.createElement(components_2.MBCardDetails, { card: card, template: template, onEdit: handleEdit, onSubmit: handleSubmit });
}
function CardDetails(_a) {
    var _this = this;
    var cardId = _a.cardId, channelSlug = _a.channelSlug, templateSlug = _a.templateSlug;
    var card = (0, hooks_2.useCard)(cardId, { fetchPolicy: 'cache-and-network' }).card;
    var channel = (0, hooks_4.useChannel)({ channelSlug: channelSlug });
    var permission = (0, hooks_5.usePermission)({ action: 'update', subject: 'Card', channelId: channel === null || channel === void 0 ? void 0 : channel.id });
    var navigator = (0, navigation_1.useNavigator)();
    var navigateToCardUpdate = (0, react_1.useCallback)(function () {
        var _a;
        return navigator.navigate('CardUpdate', {
            cardId: cardId,
            channelSlug: channelSlug,
            templateSlug: templateSlug,
            workflowTemplateId: (_a = channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId) !== null && _a !== void 0 ? _a : undefined,
        });
    }, [navigator, cardId, channelSlug, templateSlug, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId]);
    var updateCard = (0, hooks_1.useUpdateCard)();
    var handleEdit = (0, react_1.useCallback)(function () { return (permission.enabled ? navigateToCardUpdate() : function () { }); }, [permission.enabled, navigateToCardUpdate]);
    var handleSubmit = (0, react_1.useCallback)(function (fields) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!(0, lodash_1.isNil)(card)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateCard(card, fields)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [card, updateCard]);
    (0, react_1.useEffect)(function () {
        var _a;
        if (!(0, lodash_1.isNil)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId) && react_native_1.Platform.OS !== 'web') {
            navigator.replace('CardUpdate', {
                cardId: cardId,
                channelSlug: channelSlug,
                templateSlug: templateSlug,
                workflowTemplateId: (_a = channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId) !== null && _a !== void 0 ? _a : undefined,
            });
        }
    }, [cardId, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, channelSlug, navigator, templateSlug]);
    useCheckAccess(cardId, channelSlug);
    if ((0, lodash_1.isNil)(card)) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true });
    }
    var isNoCode = (0, workflow_1.isNoCodeCard)(card);
    var commonProps = {
        card: card,
        channelSlug: channelSlug,
        templateSlug: templateSlug,
        handleEdit: handleEdit,
        handleSubmit: handleSubmit,
    };
    return isNoCode ? (react_1.default.createElement(NoCodeComponent, __assign({}, commonProps))) : (react_1.default.createElement(LowCodeComponent, __assign({}, commonProps, { channel: channel })));
}
function MBCardDetails(props) {
    return (react_1.default.createElement(channel_1.MBCardChannelProvider, { channelSlug: props.channelSlug },
        react_1.default.createElement(CardDetails, __assign({}, props))));
}
