"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCreateLinkForm = MBCreateLinkForm;
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var formField_1 = require("@shared/components/form/formField");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
function MBCreateLinkForm(_a) {
    var _this = this;
    var onCreate = _a.onCreate;
    var _b = React.useState(''), name = _b[0], setName = _b[1];
    var _c = React.useState(''), url = _c[0], setUrl = _c[1];
    var navigator = (0, navigation_1.useNavigator)();
    var create = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onCreate({ name: name, url: url })];
                case 1:
                    _a.sent();
                    // Clear the form so that no navigation warning is shown when leaving
                    setName('');
                    setUrl('');
                    setTimeout(function () {
                        if (react_native_1.Platform.OS === 'web') {
                            navigator.navigate('BaseToolsTab');
                        }
                        else {
                            navigator.goBack();
                        }
                    }, 0);
                    return [2 /*return*/];
            }
        });
    }); }, [onCreate, name, url, setName, setUrl, navigator]);
    var canSubmit = !!name && !!url;
    var isEmpty = !name && !url;
    (0, navigation_1.useUnsavedChangesNavigationWarning)(!isEmpty);
    return (React.createElement(components_1.MBView, { fill: true, padding: "normal" },
        React.createElement(components_1.MBText, { size: "small", color: styles_1.Colors.v2.purple }, (0, i18n_1.i18n)('tools.create.description')),
        React.createElement(formField_1.MBFormField, { title: (0, i18n_1.i18n)('tools.create.name.title'), children: function (props) { return (React.createElement(components_1.MBTextInput, __assign({}, props, { autoFocus: true, value: name, onChangeText: setName, placeholder: (0, i18n_1.i18n)('tools.create.name.placeholder') }))); } }),
        React.createElement(formField_1.MBFormField, { title: (0, i18n_1.i18n)('tools.create.url.title'), children: function (props) { return (React.createElement(components_1.MBTextInput, __assign({}, props, { value: url, onChangeText: setUrl, placeholder: (0, i18n_1.i18n)('tools.create.url.placeholder') }))); } }),
        React.createElement(components_1.MBView, { flex: true }),
        React.createElement(components_1.MBView, { paddingTop: "large", centerHorizontally: true },
            React.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('tools.create.cta'), disabled: !canSubmit, onPress: create, style: { width: 200 } })),
        React.createElement(components_1.MBNativeKeyboardSpacer, null)));
}
