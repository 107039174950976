"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityWidgetItem = EntityWidgetItem;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_redux_1 = require("react-redux");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var hooks_1 = require("@shared/util/hooks");
var useEntityWidget_1 = require("../hooks/useEntityWidget");
var DashboardStyles_1 = require("./DashboardStyles");
var EntityWidgetOverflowOptions_1 = require("./EntityWidgetOverflowOptions");
function EntityWidgetItem(props) {
    var entityWidget = props.entityWidget, addFavorite = props.addFavorite, removeFavorite = props.removeFavorite;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _a = (0, useEntityWidget_1.useEntityWidget)(entityWidget), loading = _a.loading, count = _a.count, shouldShowRefresh = _a.shouldShowRefresh, refresh = _a.refresh, navigate = _a.navigate;
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var _b = (0, hooks_1.useBoolean)(false), isHovering = _b[0], onHoverStart = _b[1], onHoverEnd = _b[2];
    var isFavorite = entityWidget.isFavorite;
    var showOverflowButton = isDesktop && !shouldShowRefresh;
    var showStaticFavoriteIcon = isDesktop && !isHovering && !shouldShowRefresh && isFavorite;
    var showTouchableFavoriteIcon = !isDesktop && !isHovering && !shouldShowRefresh;
    var widgetStyles = [DashboardStyles_1.DashboardStyles.widget, { backgroundColor: colors.background }];
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: navigate, style: DashboardStyles_1.DashboardStyles.touchable },
        react_1.default.createElement(components_1.MBHover, { onHoverStart: onHoverStart, onHoverEnd: onHoverEnd, style: DashboardStyles_1.DashboardStyles.wrapper, hoverContainerStyle: {
                display: 'flex',
                flex: 1,
            } },
            react_1.default.createElement(components_1.MBView, { style: widgetStyles },
                shouldShowRefresh && react_1.default.createElement(RefreshButton, { refresh: refresh }),
                showOverflowButton && react_1.default.createElement(EntityOverFlowButton, __assign({}, props, { isButtonVisible: isHovering })),
                showStaticFavoriteIcon && react_1.default.createElement(StaticFavoriteIcon, null),
                showTouchableFavoriteIcon && (react_1.default.createElement(TouchableFavoriteIcon, { entityWidget: entityWidget, addFavorite: addFavorite, removeFavorite: removeFavorite })),
                react_1.default.createElement(react_native_1.View, { style: DashboardStyles_1.DashboardStyles.content },
                    react_1.default.createElement(WidgetCount, { count: count, loading: loading }),
                    react_1.default.createElement(WidgetSubtitle, { entityWidget: entityWidget })),
                react_1.default.createElement(WidgetChannelName, { entityWidget: entityWidget })))));
}
function EntityOverFlowButton(props) {
    return (react_1.default.createElement(components_1.MBView, { style: DashboardStyles_1.DashboardStyles.overflowButton },
        react_1.default.createElement(EntityWidgetOverflowOptions_1.EntityWidgetOverlowOptions, __assign({}, props))));
}
function StaticFavoriteIcon() {
    return (react_1.default.createElement(components_1.MBView, { style: DashboardStyles_1.DashboardStyles.favoriteIcon },
        react_1.default.createElement(components_1.MBIconV2, { name: 'star', color: styles_1.Colors.v2.green30, size: (0, components_1.grid)(3) })));
}
function TouchableFavoriteIcon(_a) {
    var entityWidget = _a.entityWidget, addFavorite = _a.addFavorite, removeFavorite = _a.removeFavorite;
    var onPress = function () {
        if (entityWidget.isFavorite) {
            removeFavorite(entityWidget.id);
        }
        else {
            addFavorite(entityWidget.id);
        }
    };
    var color = entityWidget.isFavorite ? styles_1.Colors.v2.green30 : styles_1.Colors.v2.gray10;
    return (react_1.default.createElement(react_native_1.TouchableOpacity, { style: DashboardStyles_1.DashboardStyles.favoriteIcon, onPress: onPress },
        react_1.default.createElement(components_1.MBIconV2, { name: 'star', color: color, size: (0, components_1.grid)(3) })));
}
function RefreshButton(_a) {
    var refresh = _a.refresh;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBView, { style: DashboardStyles_1.DashboardStyles.refreshButton },
        react_1.default.createElement(components_1.MBIconButton, { source: require('@shared/assets/icons/v2/arrow-refresh.png'), size: "small", inverted: true, onPress: refresh, color: colors.foregroundInactive })));
}
function WidgetCount(_a) {
    var count = _a.count, loading = _a.loading;
    var value = formatWidgetCount(count);
    return (react_1.default.createElement(components_1.MBView, null,
        loading && (react_1.default.createElement(components_1.MBView, { style: DashboardStyles_1.DashboardStyles.countSpinner },
            react_1.default.createElement(components_1.MBLoadingIndicator, null))),
        !loading && react_1.default.createElement(components_1.MBTextH3, { style: DashboardStyles_1.DashboardStyles.count }, value)));
}
var MAX_WIDGET_COUNT = 10000;
function formatWidgetCount(count) {
    var value = count.toLocaleString();
    if (count >= MAX_WIDGET_COUNT) {
        return "".concat(value, "+");
    }
    else {
        return value;
    }
}
function WidgetSubtitle(_a) {
    var entityWidget = _a.entityWidget;
    return (react_1.default.createElement(components_1.MBTextSubtitle, { numberOfLines: 3, ellipsizeMode: 'tail', style: DashboardStyles_1.DashboardStyles.subtitle }, entityWidget.name));
}
function WidgetChannelName(_a) {
    var entityWidget = _a.entityWidget;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive, style: { flexGrow: 0 } }, entityWidget.channelName));
}
