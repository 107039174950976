"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeCustomCacheKeyLink = exports.removeTypenameFromMutationLink = exports.errorLoggerLink = void 0;
exports.customRequestHeadersLink = customRequestHeadersLink;
exports.authLink = authLink;
var client_1 = require("@apollo/client");
var context_1 = require("@apollo/client/link/context");
var error_1 = require("@apollo/client/link/error");
var utilities_1 = require("@apollo/client/utilities");
var lodash_1 = __importStar(require("lodash"));
var react_native_1 = require("react-native");
var config_1 = require("@shared/config");
var util_1 = require("@shared/scenes/auth/util");
var store_1 = require("@shared/store");
var omitTypeName_1 = require("@shared/util/omitTypeName");
var sentry_1 = require("@shared/util/sentry");
var ALLOWED_UNAUTHENTICATED_MUTATIONS = [
    'AcceptBusinessInviteMutation',
    'RequestAuthCodeMutation',
    'VerifyAuthCodeMutation',
];
// Log all GraphQL errors to sentry
// https://www.npmjs.com/package/apollo-link-error
exports.errorLoggerLink = (0, error_1.onError)(function (_a) {
    var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, operation = _a.operation;
    if (!!networkError) {
        sentry_1.MBSentry.breadcrumb({
            category: 'apollo',
            message: networkError.message,
            data: {
                operation: operation.operationName,
                variables: JSON.stringify(operation.variables),
            },
        });
    }
    if (!!graphQLErrors) {
        graphQLErrors.forEach(function (error) {
            sentry_1.MBSentry.breadcrumb({
                category: 'apollo',
                message: error.message,
                data: {
                    operation: operation.operationName,
                    variables: JSON.stringify(operation.variables),
                },
            });
            sentry_1.MBSentry.exception({ error: new Error(error.message) });
        });
    }
});
function customRequestHeadersLink(headers) {
    var _this = this;
    return (0, context_1.setContext)(function (_request, previousContext) { return __awaiter(_this, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _a = previousContext;
                    _b = [__assign({}, previousContext.headers)];
                    return [4 /*yield*/, headers()];
                case 1:
                    _a.headers = __assign.apply(void 0, _b.concat([(_c.sent())]));
                    return [2 /*return*/, previousContext];
            }
        });
    }); });
}
function authLink() {
    return new client_1.ApolloLink(function (operation, forward) {
        var context = operation.getContext();
        context.headers = __assign(__assign({}, context.headers), { 
            // Note(fant): for legacy reasons, this is "WEB" | "IOS" | "ANDROID"
            'x-ts-platform': react_native_1.Platform.OS.toUpperCase(), 'x-coast-business-id': store_1.store === null || store_1.store === void 0 ? void 0 : store_1.store.getState().appState.currentBusinessId, 'x-coast-client-version': config_1.Config.VERSION_STRING, authorization: (function () {
                var authToken = util_1.MBAuthUtil.getAuthToken();
                var bizAuthToken = util_1.MBAuthUtil.getBizAuthToken();
                if (!!authToken) {
                    return "Bearer ".concat(authToken);
                }
                if (!!bizAuthToken) {
                    return "Bearer ".concat(bizAuthToken);
                }
                return undefined;
            })() });
        if (lodash_1.default.isNil(context.headers.authorization) && !isAllowedUnauthenticatedMutation(operation)) {
            return null;
        }
        operation.setContext(context);
        return forward(operation);
    });
}
exports.removeTypenameFromMutationLink = new client_1.ApolloLink(function (operation, forward) {
    if (isMutation(operation)) {
        operation.variables = (0, omitTypeName_1.omitTypeName)(operation.variables);
        return forward(operation);
    }
    return forward(operation);
});
// We have to remove dynamic @connection keys in order to not confuse the server.
// Then they have to be re-added in the keyArgs func.
// See listUsers for example.
// This assumes any dynamic @connection key is called "customCacheKey".
// It's still possible to hardcode any @connection key in the query.
// See messages for an example of this.
// https://github.com/apollographql/apollo-client/issues/11099
// https://community.apollographql.com/t/multiple-lists-w-same-query/6663/6
exports.removeCustomCacheKeyLink = new client_1.ApolloLink(function (operation, forward) {
    var queryWithRemovedCustomCacheKey = (0, utilities_1.removeArgumentsFromDocument)([{ name: 'customCacheKey' }], operation.query);
    operation.query = !(0, lodash_1.isNil)(queryWithRemovedCustomCacheKey)
        ? queryWithRemovedCustomCacheKey
        : operation.query;
    return forward(operation);
});
function isAllowedUnauthenticatedMutation(operation) {
    return (isMutation(operation) && ALLOWED_UNAUTHENTICATED_MUTATIONS.includes(operation.operationName));
}
function isMutation(operation) {
    var definition = (0, utilities_1.getMainDefinition)(operation.query);
    return (definition === null || definition === void 0 ? void 0 : definition.kind) === 'OperationDefinition' && (definition === null || definition === void 0 ? void 0 : definition.operation) === 'mutation';
}
