"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBReactionPicker = MBReactionPicker;
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var animation_1 = require("@shared/components/animation");
var styles_1 = require("@shared/styles");
var getTestId_1 = require("@shared/util/getTestId");
var styles_2 = require("./styles");
var index_1 = require("./index");
function MBReactionPickerSelectedIcon() {
    return (React.createElement(components_1.MBView, { padding: "small", style: styles_2.Styles.pickReactionSelectedImageWrapper },
        React.createElement(components_1.MBImage, { style: styles_2.Styles.pickReactionSelectedImage, tintColor: styles_1.Colors.v2.green, source: require('@shared/assets/icons/checkmark/filled.png') })));
}
function MBReactionPickerItem(_a) {
    var reaction = _a.reaction, onToggleReaction = _a.onToggleReaction, isSelected = _a.isSelected, index = _a.index;
    var _b = React.useState(false), isHovered = _b[0], setIsHovered = _b[1];
    var onPress = function () { return onToggleReaction(reaction.type, !isSelected); };
    return (React.createElement(components_1.MBHover, { onHoverStart: function () { return setIsHovered(true); }, onHoverEnd: function () { return setIsHovered(false); }, testID: (0, getTestId_1.getTestId)('reaction-picker-item', reaction.emoji) },
        React.createElement(components_1.MBTouchableOpacity, { onPress: onPress },
            react_native_1.Platform.OS === 'web' && (React.createElement(animation_1.MBAnimation, { scale: { from: 0, to: 0.9 }, disabled: !isHovered, type: "spring", style: [styles_1.SharedStyles.absoluteCover, styles_2.Styles.pickerWebHoverIndicator] })),
            React.createElement(components_1.MBView, { center: true, style: [styles_2.Styles.pickReaction, index !== 0 && styles_2.Styles.pickReactionMarginLeft] },
                React.createElement(components_1.MBText, { style: styles_2.Styles.pickReactionEmoji }, reaction.emoji),
                isSelected && React.createElement(MBReactionPickerSelectedIcon, null)))));
}
/*
    This component lets the user pick a reaction. This is shown in a tooltip
    form when a reaction button is pressed.
*/
function MBReactionPicker(_a) {
    var onToggleReaction = _a.onToggleReaction, selected = _a.selected, testID = _a.testID;
    return (React.createElement(animation_1.MBAnimation, { translateY: { from: 40, to: 0 }, opacity: { from: 0, to: 1 }, type: "spring" },
        React.createElement(animation_1.MBAnimation, { scale: { from: 0, to: 1 }, type: "spring" },
            React.createElement(components_1.MBRound, { row: true, wrap: true, style: styles_2.Styles.picker, testID: testID }, index_1.reactions.map(function (reaction, index) { return (React.createElement(MBReactionPickerItem, { key: index, index: index, isSelected: !!selected && !!selected[reaction.type], reaction: reaction, onToggleReaction: onToggleReaction })); })))));
}
