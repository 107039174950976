"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSlideToggleGesture = useSlideToggleGesture;
var react_1 = require("react");
var react_native_1 = require("react-native");
var config_1 = require("@shared/config");
var pager_1 = require("@shared/scenes/cards/agenda/components/calendar/pager");
function useSlideToggleGesture(onChangeActive) {
    var open = (0, react_1.useRef)(false);
    var height = (0, react_1.useRef)(new react_native_1.Animated.Value(0)).current;
    var pan = (0, react_1.useRef)(new react_native_1.Animated.ValueXY({ x: 0, y: 0 })).current;
    var animClosed = (0, react_1.useRef)(function () {
        pan.flattenOffset();
        react_native_1.Animated.parallel([
            react_native_1.Animated.spring(pan, {
                toValue: { x: 0, y: 0 },
                overshootClamping: true,
                useNativeDriver: false,
            }),
            react_native_1.Animated.spring(height, {
                toValue: 0,
                overshootClamping: true,
                useNativeDriver: false,
            }),
        ]).start(function () {
            open.current = false;
            onChangeActive(false);
        });
    }).current;
    var animOpen = (0, react_1.useRef)(function () {
        pan.flattenOffset();
        react_native_1.Animated.parallel([
            react_native_1.Animated.spring(pan, {
                toValue: { x: 0, y: pager_1.AGENDA_CALENDAR_HEIGHT },
                overshootClamping: true,
                useNativeDriver: false,
            }),
            react_native_1.Animated.spring(height, {
                toValue: pager_1.AGENDA_CALENDAR_HEIGHT,
                overshootClamping: true,
                useNativeDriver: false,
            }),
        ]).start(function () {
            open.current = true;
            onChangeActive(true);
        });
    }).current;
    var panResponder = (0, react_1.useRef)(react_native_1.PanResponder.create({
        onStartShouldSetPanResponder: function () { return false; },
        onMoveShouldSetPanResponder: function (_event, gestureState) {
            return Math.abs(gestureState.dy) >= 1 && Math.abs(gestureState.dx) < 2;
        },
        onPanResponderGrant: function () {
            var y = pan.y;
            pan.setOffset({ x: 0, y: y._value });
        },
        onPanResponderMove: function (_event, gestureState) {
            if (!open.current && gestureState.dy >= 0 && gestureState.dy <= pager_1.AGENDA_CALENDAR_HEIGHT) {
                height.setValue(gestureState.dy);
            }
            if (open.current && gestureState.dy <= 0 && gestureState.dy >= -pager_1.AGENDA_CALENDAR_HEIGHT) {
                height.setValue(pager_1.AGENDA_CALENDAR_HEIGHT + gestureState.dy);
            }
        },
        onPanResponderRelease: function (_event, gestureState) {
            var mid = open.current ? -(pager_1.AGENDA_CALENDAR_HEIGHT / 2) : pager_1.AGENDA_CALENDAR_HEIGHT / 2;
            if ((gestureState.dy < mid && gestureState.vy <= config_1.Config.GESTURE_EXIT_VELOCITY) ||
                gestureState.vy < -config_1.Config.GESTURE_EXIT_VELOCITY) {
                animClosed();
            }
            else if ((gestureState.dy >= mid && gestureState.vy >= -config_1.Config.GESTURE_EXIT_VELOCITY) ||
                gestureState.vy > config_1.Config.GESTURE_EXIT_VELOCITY) {
                animOpen();
            }
        },
    })).current;
    var toggle = (0, react_1.useCallback)(function () { return (open.current ? animClosed() : animOpen()); }, [animClosed, animOpen]);
    return { toggle: toggle, height: height, panHandlers: panResponder.panHandlers, open: open.current };
}
