"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.narrowCardFieldStyles = exports.wideCardFieldStyles = exports.headerFieldStyles = void 0;
var components_1 = require("@shared/components");
exports.headerFieldStyles = {
    addressStyle: { width: '100%' },
    dateStyle: { width: '100%' },
    emailStyle: { width: '100%' },
    fileStyle: { width: '100%' },
    geolocationStyle: { width: '100%' },
    numberStyle: { width: '100%' },
    personStyle: { width: '100%' },
    relatedCardStyle: { width: '100%' },
    signatureStyle: { width: '100%' },
    subformStyle: { width: '100%' },
    tagStyle: { width: '100%' },
    textStyle: { width: '100%' },
    timeTrackerStyle: { width: '100%' },
    todoStyle: { width: '100%' },
    inputButtonStyle: { width: '100%' },
    entityBatchStyle: { width: '100%' },
};
exports.wideCardFieldStyles = {
    addressStyle: { maxWidth: 300 },
    dateStyle: { maxWidth: 300, paddingVertical: (0, components_1.grid)(1) },
    emailStyle: { maxWidth: 300 },
    fileStyle: { maxWidth: 300 },
    geolocationStyle: { maxWidth: 300 },
    numberStyle: { maxWidth: 300 },
    personStyle: { maxWidth: 300 },
    relatedCardStyle: { maxWidth: 300 },
    signatureStyle: { maxWidth: 300 },
    subformStyle: { maxWidth: 300 },
    tagStyle: { maxWidth: 300 },
    tagItemStyle: { maxWidth: (0, components_1.grid)(25) },
    textStyle: { maxWidth: 300 },
    timeTrackerStyle: { maxWidth: 300 },
    todoStyle: { maxWidth: 300 },
    inputButtonStyle: { maxWidth: 300 },
    entityBatchStyle: { maxWidth: 300 },
};
exports.narrowCardFieldStyles = {
    addressStyle: { maxWidth: '100%', marginBottom: -(0, components_1.grid)(0.5) },
    dateStyle: { maxWidth: '100%', paddingVertical: (0, components_1.grid)(1) },
    emailStyle: { maxWidth: '100%' },
    fileStyle: { maxWidth: '100%', marginBottom: -(0, components_1.grid)(1) },
    geolocationStyle: { maxWidth: '100%', marginBottom: (0, components_1.grid)(1) },
    numberStyle: { maxWidth: '100%' },
    personStyle: { maxWidth: '100%' },
    relatedCardStyle: { maxWidth: '100%' },
    signatureStyle: { maxWidth: '100%', marginBottom: (0, components_1.grid)(1) },
    subformStyle: { maxWidth: '100%', marginBottom: -(0, components_1.grid)(1) },
    tagStyle: { maxWidth: '100%' },
    textStyle: {
        maxWidth: '100%',
    },
    timeTrackerStyle: { maxWidth: '100%' },
    todoStyle: { maxWidth: '100%', marginBottom: -(0, components_1.grid)(1) },
    inputButtonStyle: { maxWidth: '100%' },
    entityBatchStyle: { maxWidth: '100%' },
};
